import {Skeleton, Table, TableBody, TableCell, TableRow} from '@mui/material'

const SearchLoadingScreen = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <Table style={{width: '100%', height: '100%'}}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={8} style={{padding: 4}}>
              <Skeleton variant="text" height={50} width="100%" />
            </TableCell>
          </TableRow>
          {[...Array(10)].map((_, index) => (
            <TableRow key={index}>
              <TableCell
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={30} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={30} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={100} />
              </TableCell>
              <TableCell>
                <Skeleton variant="text" width={30} />
              </TableCell>
              <TableCell
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Skeleton variant="text" width={100} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}
export default SearchLoadingScreen

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import InfoView from 'src/components/view/itemView/InfoView'
import GeneralNotes from 'src/components/select-app/GeneralNotes'
import {IconButton, Stack} from '@mui/material'
import ChecklistDataGrid from 'src/components/select-app/checklist/Checklist'
import TaskIcon from 'src/images/task.svg?react'
import {UsingType, ViewType} from 'src/entities/View'
import {Control} from 'src/entities/PermissionData'
import {PermissionType} from '@madisoncres/title-general-package'
import ChecklistPopup from 'src/components/itemView/ChecklistPopup'

const GeneralInfoTab = observer(() => {
  const {
    permissionStore: {getColumnsPermissionType, getPermissionType},
    itemStore: {currentItem, myRolesId},
    boardStore: {
      currentBoard,
      internalScreenViews: fileDataViews,
      getViewIdByType
    },
    selectStore: {setIsChecklistView, isChecklistView}
  } = useMainStore()

  const showControl = (
    control: Control,
    viewType: ViewType,
    usingType: UsingType
  ) => {
    const generalNotesPermissionType = getPermissionType(
      getViewIdByType(viewType, usingType),
      undefined,
      undefined,
      control
    )

    return generalNotesPermissionType
  }

  const generalNotesPermissionType = showControl(
    Control.GeneralNotes,
    ViewType.GeneralInfo,
    UsingType.App
  )
  const checkListPermissionType = showControl(
    Control.Checklist,
    ViewType.GeneralInfo,
    UsingType.App
  )
  const checkListWFDataPermissionType = showControl(
    Control.checklistWFData,
    ViewType.FileData,
    UsingType.Item
  )

  if (!currentBoard?.views.length) return <div style={{width: '45%'}} />

  return (
    <Stack
      pl={4}
      pr={2}
      mt={1}
      width="100%"
      height="100%"
      overflow="auto"
      position="relative"
    >
      {isChecklistView === false ? (
        <>
          <Stack mb={4} spacing={4}>
            {fileDataViews?.map(v => (
              <InfoView
                key={v.id}
                view={v}
                currentItem={currentItem!}
                permissionsColumn={getColumnsPermissionType(v, myRolesId)}
              />
            ))}
          </Stack>
          {generalNotesPermissionType !== PermissionType.Invisible && (
            <Stack pb={3}>
              <GeneralNotes />
            </Stack>
          )}
          <Stack direction="row" position="absolute" right={16}>
            {checkListWFDataPermissionType !== PermissionType.Invisible && (
              <ChecklistPopup />
            )}
            {checkListPermissionType !== PermissionType.Invisible && (
              <IconButton onClick={() => setIsChecklistView()}>
                <TaskIcon />
              </IconButton>
            )}
          </Stack>
        </>
      ) : (
        <ChecklistDataGrid />
      )}
    </Stack>
  )
})

export default GeneralInfoTab

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {useEffect} from 'react'
import {
  ConversationControl,
  ConversationPermissionEvent
} from '@madisoncres/title-general-package'
import PermissionData, {
  Control,
  PermissionType
} from 'src/entities/PermissionData'
import config from 'src/config'
import {lazy} from '@loadable/component'
import {ViewType} from 'src/entities/View'

const ConversationList = lazy<{conversationTypeId: number; viewId: string}>(
  () => import('cbChat/ConversationList')
)

const preparePermissions = (permissions: PermissionData[]) => {
  const newPermission = {
    [ConversationControl.Assign]: PermissionType.Invisible,
    [ConversationControl.Status]: PermissionType.Invisible,
    [ConversationControl.Filter]: PermissionType.Invisible
  } as Record<ConversationControl, PermissionType>
  permissions.forEach(p => {
    switch (p.controlId) {
      case Control.Create:
        newPermission[ConversationControl.Create] = p.permissionTypeId
        return

      case Control.Reply:
        newPermission[ConversationControl.Reply] = p.permissionTypeId
        return
      case Control.SeeAll:
        newPermission[ConversationControl.SeeAll] = p.permissionTypeId
        return
    }
  })
  return newPermission
}

interface ChatProps {
  viewId: number
}

const Chat = observer(({viewId}: ChatProps) => {
  const mainStore = useMainStore()
  const {currentItem, myRolesId} = mainStore.itemStore
  const {getPermissionTypesByView} = mainStore.permissionStore

  useEffect(() => {
    const permissions = getPermissionTypesByView(myRolesId, viewId)
    ConversationPermissionEvent.dispatch({
      permissions: preparePermissions(permissions),
      entityId: currentItem?.id ?? 0,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.chat
    })
  }, [viewId, myRolesId, currentItem?.id, getPermissionTypesByView])

  return (
    <ConversationList
      conversationTypeId={config.conversationType.chat}
      viewId={ViewType[ViewType.Chat]}
    />
  )
})

export default Chat

import {generatePath, useNavigate} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {IconButton, Stack, Typography, Tooltip} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {useMainStore} from 'src/context/Main'
import {PATH_WORKFLOW} from 'src/routes/paths'
import Label from 'src/components/label/Label'
import {LabelColor} from 'src/components/label'
import Column, {ColumnType, Direction} from 'src/entities/Column'
import Item from 'src/entities/Item'
import {PermissionType} from 'src/entities/PermissionData'
import ChecklistPopup from './ChecklistPopup'
import React, {useState} from 'react'
import CommonHeader from 'src/components/layout/CommonHeader'
import {Tabs} from 'src/store/SharedStore'
import CopyIcon from 'src/images/copy.svg?react'

const renderColumn = (item: Item, column: Column) => {
  switch (column.type) {
    case ColumnType.Label:
      const data = JSON.parse(
        column.data?.find(
          d =>
            d.columnValue ===
            item
              .getValue(column.dbTableId, column.dbColumn, column.roleId)
              ?.toString()
        )?.data ?? '{}'
      )
      return (
        <>
          <Typography
            variant="subtitle2"
            sx={{color: theme => theme.palette.grey[600]}}
          >
            {column.name}:
          </Typography>
          <Label color={data.color as LabelColor}>{data.text}</Label>
        </>
      )
    case ColumnType.Checklist: {
      return <ChecklistPopup />
    }
  }
}

const ItemViewAppBar = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentItem, myRolesId} = mainStore.itemStore
  const {getPermissionTypeByRoles} = mainStore.permissionStore
  const {currentTab} = mainStore.sharedStore
  const [tooltipText, setTooltipText] = useState('Copy')

  const navigate = useNavigate()

  const getPrevPath = () => {
    if (currentTab === Tabs.Reports) return generatePath(PATH_WORKFLOW.reports)
    return generatePath(PATH_WORKFLOW.boards, {boardId: currentBoard?.id})
  }

  const onClickBack = () => {
    navigate(getPrevPath())
  }

  const headerView = currentBoard?.itemHeaderView
  const headerColumns = headerView
    ? headerView.columns?.filter(
        c =>
          getPermissionTypeByRoles(headerView.id, myRolesId, c.viewColumnId) !==
          PermissionType.Invisible
      )
    : []

  const handleCopy = () => {
    navigator.clipboard
      .writeText(currentItem?.order?.fileNumber || '')
      .then(() => {
        setTooltipText('Copied!')
        setTimeout(() => {
          setTooltipText('Copy')
        }, 1500)
      })
      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }

  if (!currentItem) return <></>
  return (
    <CommonHeader height={45}>
      <Stack direction="row" justifyContent="space-between" position="static">
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
          <IconButton onClick={onClickBack}>
            <ArrowBackIcon />
          </IconButton>
          <Stack direction="row" alignItems="center" p={3}>
            <Stack alignItems="start">
              <Typography variant="subtitle1">
                {currentItem?.order?.fullFileNumber}
              </Typography>
            </Stack>
            <Stack alignItems="end">
              <Tooltip title={tooltipText} arrow placement="top">
                <IconButton onClick={handleCopy}>
                  <CopyIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>

          {headerColumns
            ?.filter(c => c.direction === Direction.Left)
            .map(c => (
              <React.Fragment key={c.id}>
                {' '}
                {renderColumn(currentItem, c)}
              </React.Fragment>
            ))}
        </Stack>
        <Stack direction="row">
          {headerColumns
            ?.filter(c => c.direction === Direction.Right)
            .map(c => (
              <React.Fragment key={c.id}>
                {' '}
                {renderColumn(currentItem, c)}
              </React.Fragment>
            ))}
        </Stack>
      </Stack>
    </CommonHeader>
  )
})

export default ItemViewAppBar

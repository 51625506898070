function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = ''
const ROOTS_WORKFLOW = ''

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  logout: path(ROOTS_AUTH, '/logout'),
  accessDenied: path(ROOTS_AUTH, '/accessDenied')
}

export const PATH_WORKFLOW = {
  root: ROOTS_WORKFLOW,
  departments: path(ROOTS_WORKFLOW, '/departments/:departmentId'),
  boards: path(ROOTS_WORKFLOW, `/boards/:boardId`),
  items: path(ROOTS_WORKFLOW, `/boards/:boardId/items/:itemId`),
  itemsPart: 'items/:itemId',
  itemWSPart: 'orderservice/:fileNumber/:isCommertial/:tw/workspace/EditPro',
  itemWSBasePart: 'orderservice/:fileNumber',
  reports: path(ROOTS_WORKFLOW, '/reports')
}

import {
  PermissionType,
  ConversationControl,
  DocumentPreviewEvent,
  DocumentPreviewDetails,
  ConversationPermissionEvent,
  ConversationEntityChangeEvent
} from '@madisoncres/title-general-package'
import {observer} from 'mobx-react-lite'
import {Suspense, useEffect} from 'react'

import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import {Control} from 'src/entities/PermissionData'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import {lazy} from '@loadable/component'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
const ConversationList = lazy<{
  conversationTypeId: number
  viewId: string
}>(() => import('cbChat/ConversationList'))

const ChatList = observer(({viewId, item}: ChatTabProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {getPermissionTypeByRoles} = mainStore.permissionStore
  const {user, roles} = mainStore.loginStore

  useEffect(() => {
    ConversationEntityChangeEvent.dispatch({
      viewId: `${ViewType[ViewType.Chat]}Tab`,
      entityId: item?.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.chat,
      additionalInfo: {
        fileNumber: item?.order?.fileNumber,
        boardId: currentBoard?.id
      }
    })
  }, [item?.id, item?.order?.fileNumber, currentBoard?.id])

  useEffect(() => {
    const rolesId = item.statusAssignees
      .filter(a => a.userId === user?.id)
      .map(a => a.roleId) as number[]

    const filterPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.Filter
    )
    const draftPermissionType = getPermissionTypeByRoles(
      viewId,
      roles?.map(role => role as number),
      undefined,
      Control.Draft
    )

    const permissions = {
      [ConversationControl.Create]: PermissionType.Invisible,
      [ConversationControl.Assign]: PermissionType.Invisible,
      [ConversationControl.Status]: PermissionType.Invisible,
      [ConversationControl.Filter]: PermissionType.Invisible,
      [ConversationControl.SeeAll]: PermissionType.Write
    } as Record<ConversationControl, PermissionType>

    permissions[ConversationControl.Filter] = filterPermissionType
    permissions[ConversationControl.Draft] = draftPermissionType

    ConversationPermissionEvent.dispatch({
      entityId: item.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.chat,
      permissions: permissions
    })
  }, [viewId, user, item, getPermissionTypeByRoles, roles])

  return (
    <CuiErrorBoundary>
      <Suspense fallback={<></>}>
        <ConversationList
          conversationTypeId={config.conversationType.chat}
          viewId={ViewType[ViewType.Chat]}
        />
      </Suspense>
    </CuiErrorBoundary>
  )
})

interface ChatTabProps {
  viewId: number
  item: Item
}

const ChatTab = observer(({viewId, item}: ChatTabProps) => {
  useEffect(() => {
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [])

  return (
    <>
      <ChatList viewId={viewId} item={item} />
    </>
  )
})

export default ChatTab

import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {Stack} from '@mui/material'
import ItemViewAppBar from 'src/components/itemView/ItemViewAppBar'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {useMainStore} from 'src/context/Main'
import {useCallback, useEffect} from 'react'
import {OrderCompany} from 'src/entities/Order'
import {ViewType} from 'src/entities/View'
import {DocumentOpenMenuEvent} from '@madisoncres/title-general-package'
import {OpenMenuDetails} from '@madisoncres/title-general-package/src/customEvent/DocumentOpenMenuEvent'

const ItemViewWorkspace = observer(() => {
  const {currentBoard} = useMainStore().boardStore
  const {currentItem, setCurrentView, currentView, setOpemMenu} =
    useMainStore().itemStore
  const navigaete = useNavigate()
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()
  const docsView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.Documents
  )

  const openMenuDocsCallback = useCallback(
    (e: CustomEvent<OpenMenuDetails | undefined>) => {
      if (!e?.detail) return

      const {isOpen} = e.detail

      if (isOpen) {
        if (currentView !== docsView) {
          setSearchParams(prev => {
            prev.set('view', ViewType[ViewType.Documents])
            return prev
          })
          setCurrentView(docsView)
        }
      } else {
        setSearchParams(prev => {
          prev.delete('view')
          return prev
        })
        setCurrentView(undefined)
      }
    },
    [currentView, docsView, setSearchParams, setCurrentView]
  )

  useEffect(() => {
    if (currentItem?.order?.fileNumber) {
      DocumentOpenMenuEvent.subscribe(e => {
        openMenuDocsCallback(e)
      })
      setOpemMenu(true)
      const tw =
        currentItem.order?.state ??
        (currentItem.order.orderCompany === OrderCompany.Titlewave ? 'NJ' : '')
      const basePath = generatePath(PATH_WORKFLOW.itemWSBasePart, {
        fileNumber: currentItem.order.fileNumber
      })

      if (!window.location.href.includes(basePath) && tw) {
        const path = generatePath(PATH_WORKFLOW.itemWSPart, {
          fileNumber: currentItem.order.fileNumber,
          isCommertial: currentItem.order.isCommertial ? 1 : 0,
          tw: tw
        })

        navigaete(
          {
            pathname: path,
            search:
              location.search !== '' ? location.search : window.location.search
          },
          {replace: true}
        )
      }
    }
  }, [
    currentItem,
    currentItem?.order?.state,
    setOpemMenu,
    navigaete,
    location.pathname,
    location.search,
    openMenuDocsCallback
  ])

  return (
    <>
      <Stack>
        <ItemViewAppBar />
      </Stack>
      <Stack direction="row" overflow="auto">
        <Outlet />
      </Stack>
    </>
  )
})

export default ItemViewWorkspace

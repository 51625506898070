import {makeAutoObservable} from 'mobx'
import User from 'src/entities/User'
import {toLocalDate} from 'src/utils/date'

export enum Operation {
  Add = 1,
  Delete,
  Edit
}

export default class StatusAssignee {
  id?: number

  itemId: number

  userId: number

  user?: User

  roleId: number

  statusId?: number

  operation?: Operation

  subStatuses: number[]

  comment?: string

  additionalInfo?: string

  createdAt?: Date

  constructor(statusAssignee: StatusAssignee) {
    this.id = statusAssignee.id
    this.itemId = statusAssignee.itemId
    this.userId = statusAssignee.userId
    this.user = statusAssignee.user
    this.roleId = statusAssignee.roleId
    this.statusId = statusAssignee.statusId
    this.operation = statusAssignee.operation
    this.subStatuses = statusAssignee.subStatuses
    this.comment = statusAssignee.comment
    this.additionalInfo = statusAssignee.additionalInfo

    this.createdAt = statusAssignee.createdAt
      ? toLocalDate(statusAssignee.createdAt)
      : undefined

    makeAutoObservable(this)
  }

  setStatusAndSubId(
    parentStatusId: number,
    subStatusId: number,
    isAdded: boolean,
    isOverride: boolean
  ) {
    this.statusId = parentStatusId
    if (isOverride) {
      this.subStatuses = [subStatusId]
    } else if (isAdded) {
      //add sub status
      if (!this.subStatuses.includes(subStatusId))
        this.subStatuses.push(subStatusId)
    } else {
      //delete sub status
      this.subStatuses = this.subStatuses.filter(id => id !== subStatusId)
    }
  }

  setStatusId(statusId: number | undefined, isOverrideSubStatus?: boolean) {
    this.statusId = statusId
    if (isOverrideSubStatus) this.subStatuses = []
  }

  setSubStatusId(subStatusIds: number[]) {
    this.subStatuses = subStatusIds
  }

  addSubStatusIds(subStatusIds: number[]) {
    this.subStatuses.push(...subStatusIds)
  }

  deleteSubStatusIds(subStatusIds: number[]) {
    subStatusIds.forEach(statusId => {
      let index
      while ((index = this.subStatuses.indexOf(statusId)) !== -1) {
        this.subStatuses.splice(index, 1)
      }
    })
  }

  setComment(comment: string | undefined) {
    this.comment = comment
  }

  setAdditionalInfo(info?: string) {
    this.additionalInfo = info
  }
}

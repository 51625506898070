import {useEffect, useState} from 'react'
import {useMainStore} from 'src/context/Main'
import {
  Avatar,
  CircularProgress,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import {observer} from 'mobx-react-lite'
import MergeFilesDialog from 'src/components/itemView/MergeFileDialog'

const ItemViewMailboxHeader = observer(() => {
  const mainStore = useMainStore()
  const {currentItem} = mainStore.itemStore
  const {fixFileNumber, currentBoard} = mainStore.boardStore
  const [fn, setFn] = useState('')
  const [mergeItemId, setMergeItemId] = useState<number>()
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (currentItem) setFn(currentItem.getFileNumberText)
  }, [currentItem, currentItem?.order, setFn])

  const handleFixFnRes = (data: any) => {
    if (data.srcItem !== null) {
      setMergeItemId(data.srcItem.id)
    }
  }

  const onFixFn = async (event: React.FocusEvent<HTMLDivElement>) => {
    const newFn = event.target.textContent?.toUpperCase()
    if (newFn && newFn !== fn) {
      setFn(newFn)
      try {
        setIsLoading(true)
        setFn(newFn)
        const data = await fixFileNumber(
          currentItem!.id,
          currentBoard?.id!,
          newFn
        )
        if (!data) setFn(currentItem!.getFileNumberText())
        else handleFixFnRes(data)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setIsEditing(false)
      event.preventDefault()
      onFixFn(event as unknown as React.FocusEvent<HTMLDivElement>)
    }
  }

  return (
    <>
      <Stack direction="row">
        {fn && (
          <Stack direction="row">
            <Typography variant="h5">
              {currentItem?.order?.prefix && currentItem?.order?.prefix !== null
                ? currentItem?.order?.prefix + ' - '
                : ''}
            </Typography>
            <Tooltip
              disableHoverListener={isEditing}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    backgroundColor: theme => theme.palette.background.default,
                    color: theme => theme.palette.text.primary,
                    boxShadow: theme => theme.shadows[3]
                  },
                  '& .MuiTooltip-arrow': {
                    color: theme => theme.palette.background.default,
                    '&::before': {
                      boxShadow: theme => theme.shadows[3]
                    }
                  }
                }
              }}
              placement="right"
              arrow
              title={
                isEditing ? (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Avatar
                      variant="rounded"
                      sx={{bgcolor: theme => theme.palette.error.lighter}}
                    >
                      <ErrorIcon color="error" />
                    </Avatar>
                    <Typography variant="caption">
                      <Typography
                        component="span"
                        color="error"
                        variant="inherit"
                      >
                        Important:
                      </Typography>{' '}
                      Changing the file number will merge/rename the current
                      file and delete it.
                    </Typography>
                  </Stack>
                ) : (
                  ''
                )
              }
            >
              <Typography
                onBlur={onFixFn}
                suppressContentEditableWarning={true}
                contentEditable={
                  currentItem?.order !== null || currentItem?.name === null
                }
                variant="h5"
                onKeyDown={handleKeyDown}
                onClick={() => setIsEditing(true)}
              >
                {currentItem?.name && currentItem?.order === null
                  ? currentItem.name
                  : fn}
              </Typography>
            </Tooltip>
          </Stack>
        )}
        {isLoading && (
          <CircularProgress sx={{m: 1}} size={20} color="inherit" />
        )}
      </Stack>
      {mergeItemId && <MergeFilesDialog srcItemId={mergeItemId} />}
    </>
  )
})

export default ItemViewMailboxHeader

import React, {useState, useEffect, useRef, useCallback} from 'react'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import {IconButton, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import TimeTracking from 'src/entities/TimeTracking'
import {calculateMilliseconds, calculateTime, StartStop} from 'src/utils/date'
import Item from 'src/entities/Item'
import TimeTrackingPopover from 'src/components/view/columnTypes/TimeTrackingPopover'

export interface TimeTrackingProps {
  item: Item
  roleId: number
}

const TimeTrackingCol = observer(({item, roleId}: TimeTrackingProps) => {
  const {
    startTimeTracking,
    stopTimeTracking,
    currentTimeTracking,
    setCurrentTimeTracking
  } = useMainStore().boardStore

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const intervalRef = useRef<number | null>(null)
  const [openMilliseconds, setOpenMilliseconds] = useState<number>(0)
  const [totalMilliseconds, setTotalMilliseconds] = useState<number>(
    calculateMilliseconds(
      item.timeTrackings
        .filter((t: TimeTracking) => t.roleId === roleId && t.stop)
        .map(t => ({start: t.start, stop: t.stop}) as StartStop)
    )
  )
  const calculatedTime = React.useMemo(() => {
    return calculateTime(totalMilliseconds + openMilliseconds)
  }, [totalMilliseconds, openMilliseconds])

  const onOpenPopover = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  const updateTotalMilliseconds = useCallback(() => {
    setTotalMilliseconds(
      calculateMilliseconds(
        item.timeTrackings
          .filter((t: TimeTracking) => t.roleId === roleId && t.stop)
          .map(t => ({start: t.start, stop: t.stop}) as StartStop)
      )
    )
  }, [item.timeTrackings, setTotalMilliseconds, roleId])

  const onStart = useCallback(() => {
    intervalRef.current = window.setInterval(() => {
      setOpenMilliseconds(
        calculateMilliseconds(
          [
            item.timeTrackings.find(
              (t: TimeTracking) => t.roleId === roleId && !t.stop
            )!
          ].map(t => ({start: t.start, stop: t.stop}) as StartStop)
        )
      )
    }, 1000)
  }, [item.timeTrackings, roleId])

  const onStop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    updateTotalMilliseconds()
    setOpenMilliseconds(0)
  }, [updateTotalMilliseconds])

  const onClickTimeTracking = () => {
    if (item.timeTrackings.find(t => t.roleId === roleId && !t.stop)) {
      stopTimeTracking(new Date(), item.id, roleId || 0)
      onStop()
    } else {
      startTimeTracking(new Date(), item.id, roleId || 0)
      onStart()
    }
  }

  useEffect(() => {
    if (
      currentTimeTracking &&
      currentTimeTracking.roleId === roleId &&
      currentTimeTracking.itemId === item.id
    ) {
      if (currentTimeTracking.start && currentTimeTracking.stop) {
        updateTotalMilliseconds()
      } else if (currentTimeTracking.stop) {
        onStop()
      } else if (currentTimeTracking.start) {
        onStart()
      } else {
        updateTotalMilliseconds()
      }
      setCurrentTimeTracking(undefined)
    }
  }, [
    currentTimeTracking,
    item.id,
    onStart,
    onStop,
    roleId,
    setCurrentTimeTracking,
    updateTotalMilliseconds
  ])

  useEffect(() => {
    if (item.timeTrackings.find(t => t.roleId === roleId && !t.stop)) {
      onStart()
    }
  }, [item.timeTrackings, onStart, roleId])

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return (
    <>
      <div
        style={{
          width: '150px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={e => {
          onOpenPopover(e)
        }}
      >
        <Stack width="30%">
          <IconButton
            onClick={e => {
              e.stopPropagation()
              onClickTimeTracking()
            }}
          >
            {item.timeTrackings.find(t => t.roleId === roleId && !t.stop) ? (
              <PauseCircleIcon color="primary" />
            ) : (
              <PlayCircleIcon color="primary" />
            )}
          </IconButton>
        </Stack>
        <Stack width="70%" textAlign="center">
          <Typography fontSize="14px">{`${
            calculatedTime.hours ? `${calculatedTime.hours}h` : ''
          } ${calculatedTime.minutes ? `${calculatedTime.minutes}m` : ''} ${
            calculatedTime.seconds ? `${calculatedTime.seconds}s` : ''
          }`}</Typography>
        </Stack>
      </div>
      {anchorEl && (
        <TimeTrackingPopover
          anchorEl={anchorEl}
          onClose={onClosePopover}
          timeTrackings={item.timeTrackings.filter(
            (t: TimeTracking) => t.roleId === roleId
          )}
          openMilliseconds={openMilliseconds}
          updateTotalMilliseconds={updateTotalMilliseconds}
          itemId={item.id}
          roleId={roleId}
        />
      )}
    </>
  )
})

export default TimeTrackingCol

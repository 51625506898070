import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import ItemData from 'src/entities/ItemData'
import Document, {DocumentType} from 'src/entities/Document'
import Order from './Order'
import StatusAssignee from './StatusAssignee'
import {DBColumn, DbTable} from 'src/entities/Column'
import {isThisMonth, isToday} from 'date-fns'
import {toLocalDate} from 'src/utils/date'
import config from 'src/config'
import Update from 'src/entities/Update'
import TimeTracking from 'src/entities/TimeTracking'
import {NyPublicFolderType} from '@madisoncres/title-general-package'
import {RoleId} from 'src/entities/PermissionData'

type Guid = string

export default class Item implements Base {
  id: number

  name?: string

  boardId: number

  itemGroupId: number

  isMain: boolean

  itemData: ItemData[]

  fileNumber: string

  guid: Guid

  documents: Document[]

  order?: Order

  priority: boolean

  flowStatusId: number

  hasNewEmail: boolean

  hasAttachments: boolean

  firstEmailReceivedAt?: Date

  lastEmailReceivedAt?: Date

  questionStatusId?: number

  mailboxQuestionStatusId?: number

  priorityStatusId?: number

  statusId?: number

  statusAssignees: StatusAssignee[]

  isPriorityEditable: boolean

  checklist?: string

  disabledChecklist?: string

  startedOn?: Date

  completedOn?: Date

  countySearchStatusId?: number

  isChecklistEnabled: boolean

  isArchive: boolean

  inboundInAssignee?: boolean

  comment?: string

  commentCreatedOn?: Date

  commentUserId?: number

  backtitleStatusId?: number

  dT2orWebsiteStatusId?: number

  searchTypeStatusId?: number

  invoice?: number

  copyCharges?: number

  createdAt?: Date

  updates?: Update[]

  timeTrackings: TimeTracking[] = []

  invoiceStatusId: number

  relatedToFileNumber?: string

  emailBody?: string

  emailSender?: string

  additionalRecordingStatusId?: number

  finalStatusId?: number

  escrowId?: number

  requestTypes?: string

  nyPublicFolderType?: NyPublicFolderType

  emailsStatusSummary?: Map<number, number> = new Map<number, number>()

  freeText?: string

  isDayWorkEmailsAllAssigned?: boolean

  acrisId?: string

  hasUnreadEmail?: boolean

  constructor(item: Item) {
    this.id = item.id
    this.name = item.name
    this.boardId = item.boardId
    this.itemGroupId = item.itemGroupId
    this.isMain = item.isMain
    this.fileNumber = item.fileNumber
    this.guid = item.guid
    this.itemData = item.itemData.map(d => new ItemData(d))
    this.documents = item.documents?.map(i => new Document(i)) || []
    this.order = item.order ? new Order(item.order) : item.order
    this.priority = item.priority
    this.flowStatusId = item.flowStatusId
    this.questionStatusId = item.questionStatusId
    this.mailboxQuestionStatusId = item.mailboxQuestionStatusId
    this.priorityStatusId = item.priorityStatusId
    this.statusId = item.statusId
    this.hasNewEmail = item.hasNewEmail
    this.hasAttachments = item.hasAttachments
    this.firstEmailReceivedAt = item.firstEmailReceivedAt
      ? toLocalDate(item.firstEmailReceivedAt)
      : undefined
    this.lastEmailReceivedAt = item.lastEmailReceivedAt
      ? toLocalDate(item.lastEmailReceivedAt)
      : undefined

    this.statusAssignees = item.statusAssignees?.map(s => new StatusAssignee(s))
    this.isPriorityEditable = item.isPriorityEditable
    this.checklist = item.checklist
    this.disabledChecklist = item.disabledChecklist
    this.startedOn = item.startedOn ? toLocalDate(item.startedOn) : undefined
    this.completedOn = item.completedOn
      ? toLocalDate(item.completedOn)
      : undefined
    this.countySearchStatusId = item.countySearchStatusId
    this.isChecklistEnabled = item.isChecklistEnabled
    this.isArchive = item.isArchive
    this.inboundInAssignee = item.inboundInAssignee
    this.comment = item.comment
    this.commentCreatedOn = item.commentCreatedOn
      ? toLocalDate(item.commentCreatedOn)
      : undefined
    this.commentUserId = item.commentUserId
    this.backtitleStatusId = item.backtitleStatusId
    this.dT2orWebsiteStatusId = item.dT2orWebsiteStatusId
    this.searchTypeStatusId = item.searchTypeStatusId
    this.invoice = item.invoice
    this.copyCharges = item.copyCharges
    this.createdAt = item.createdAt
    this.updates = item.updates?.map(u => new Update(u))
    this.timeTrackings = item.timeTrackings?.map(
      t =>
        new TimeTracking({
          ...t,
          start: new Date(t.start.toString().replace(' ', 'T') + 'Z'),
          stop: t.stop
            ? new Date(t.stop.toString().replace(' ', 'T') + 'Z')
            : t.stop
        })
    )
    this.invoiceStatusId = item.invoiceStatusId
    this.relatedToFileNumber = item.relatedToFileNumber
    this.emailBody = item.emailBody?.replace(/user_id-\d+/g, '')
    this.emailSender = item.emailSender
    this.additionalRecordingStatusId = item.additionalRecordingStatusId
    this.finalStatusId = item.finalStatusId
    this.escrowId = item.escrowId
    this.requestTypes = item.requestTypes
    this.nyPublicFolderType = item.nyPublicFolderType
    this.emailsStatusSummary = item.emailsStatusSummary
    this.isDayWorkEmailsAllAssigned = item.isDayWorkEmailsAllAssigned
    this.hasUnreadEmail = item.hasUnreadEmail
    this.freeText = item.freeText
    this.acrisId = item.acrisId

    makeAutoObservable(this)
  }

  get invoices() {
    return this.documents?.filter(d => d.documentType === DocumentType.Invoice)
  }

  get lwProcessor() {
    //213 courtesy  status
    return this.priorityStatusId === 213
      ? this.order?.checklist27Assignee
      : this.order?.lwProcessor
  }

  getStatusAssignees(roleId: RoleId) {
    return this.statusAssignees.filter(
      sa => sa.userId !== -1 && sa.roleId === roleId
    )
  }

  getFileNumberText = () => {
    return this.order?.fileNumber ?? config.unknownFileNumberText
  }

  getFullNumberText = () => {
    return this.order?.fullFileNumber ?? config.unknownFileNumberText
  }

  isOpen = () => {
    return ![
      FlowStatus.Done,
      FlowStatus.Cancelled,
      NJFlowStatus.Done,
      NJFlowStatus.Cancelled
    ].includes(this.flowStatusId)
  }

  isClosed = () => {
    return !this.isOpen()
  }

  isClosedToday = () => {
    return (
      this.isClosed() &&
      this.completedOn !== undefined &&
      isToday(this.completedOn)
    )
  }

  isClosedOnCurrentMonth = () => {
    return this.completedOn !== undefined && isThisMonth(this.completedOn)
  }

  isOpenOrIsClosedToday = () => {
    return this.isOpen() || this.isClosedToday()
  }

  statusAssigneesWithoutDefault = () => {
    return this.statusAssignees.filter(s => s.userId !== -1) || []
  }

  defaultStatusAssignees = () => {
    return this.statusAssignees.filter(s => s.userId === -1) || []
  }

  setDocuments(documents: Document[]) {
    this.documents = documents
  }

  setGroup = (itemGroupId: number) => {
    this.itemGroupId = itemGroupId
  }

  setIsArchive = (isArchive: boolean) => {
    this.isArchive = isArchive
  }

  setEmailsStatusSummary = (emailsStatusSummary: Map<number, number>) => {
    this.emailsStatusSummary = emailsStatusSummary
  }

  setItemProperty = (propertyName: string, value: any) => {
    if (propertyName in this) {
      ;(this as any)[propertyName] = value
    } else {
      console.log('Property not found or inaccessible.')
    }
  }

  isPropertyEditable = (headerName: string) => {
    const propertyName = 'is' + headerName + 'Editable'
    if (propertyName in this) return (this as any)[propertyName] !== false
    return true
  }

  setStatusAssignees = (
    addItems: StatusAssignee[],
    deletedListIds: number[]
  ) => {
    deletedListIds.forEach(dId => {
      const index = this.statusAssignees.findIndex(sa => sa.id === dId)
      if (index > -1) this.setDeleteStatusAssignee(index)
    })

    addItems.forEach(newItem => {
      const isExist = this.statusAssignees.find(j => j.id === newItem.id)
      if (!isExist) this.setAddStatusAssignee(newItem)
    })
  }

  setStatusAssigneesByRole = (roleId: number, statusId: number) => {
    this.statusAssignees.forEach(s => {
      if (s.roleId === roleId) s.setStatusId(statusId)
    })
  }

  setUpdates = (updates?: Update[]) => {
    this.updates = updates
  }

  setHasUnreadEmail = (hasUnreadEmail: boolean) => {
    this.hasUnreadEmail = hasUnreadEmail
  }

  addOrUpdateStatusAssignee = (
    statusAssignee: StatusAssignee,
    isMultiUpdate: boolean
  ) => {
    const currentStatusAssignee = this.statusAssignees.find(
      s => s.id === statusAssignee.id
    )
    if (currentStatusAssignee) {
      currentStatusAssignee.setStatusId(statusAssignee.statusId)
      if (statusAssignee.additionalInfo !== null) {
        currentStatusAssignee.setAdditionalInfo(statusAssignee.additionalInfo)
      }
    } else {
      this.setAddStatusAssignee(statusAssignee)
    }
    if (isMultiUpdate) {
      this.setStatusAssigneesByRole(
        statusAssignee.roleId,
        statusAssignee.statusId!
      )
    }
  }

  deleteStatusAssignee = (statusAssignee: StatusAssignee) => {
    const index = this.statusAssignees.findIndex(
      s =>
        (statusAssignee.id && s.id === statusAssignee.id) ||
        (s.userId === statusAssignee.userId &&
          s.roleId === statusAssignee.roleId)
    )
    if (index > -1) this.setDeleteStatusAssignee(index)
  }

  isUserAssignee = ({
    userId,
    roleToFilter
  }: {
    userId?: number
    roleToFilter?: number
  }) => {
    return !!this.statusAssignees.find(sa => {
      const userMatches = sa.userId === userId
      const roleMatches =
        roleToFilter !== undefined ? sa.roleId === roleToFilter : true
      return userMatches && roleMatches
    })
  }

  getDefaultRoleStatus = (roleId?: number) => {
    const statusAssignee = this.defaultStatusAssignees().find(
      s => s.roleId === roleId
    )
    if (statusAssignee) {
      return statusAssignee.statusId
    }
    return undefined
  }

  getFirstStatusAssignee = (roleId?: number, dbColumn?: DBColumn) => {
    const statusAssignees = this.statusAssignees
      .slice()
      .sort(
        (a, b) =>
          new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime()
      )

    const statusAssignee = statusAssignees.find(
      s => s.roleId === roleId && s.userId !== -1
    )

    if (statusAssignee && dbColumn)
      return statusAssignee[dbColumn?.name as keyof StatusAssignee]

    return undefined
  }

  getStatusAssigneeByRole = (roleId: number) => {
    return this.statusAssigneesWithoutDefault().filter(s => s.roleId === roleId)
  }

  getStatusAssigneeWithDefaultsByRole = (roleId: number) => {
    return this.statusAssignees.filter(s => s.roleId === roleId)
  }

  getValue = (dbTable: DbTable, dbColumn?: DBColumn, roleId?: number) => {
    switch (dbTable) {
      case DbTable.Order:
        return this.order?.[dbColumn?.name as keyof Order]
      case DbTable.AssigneStatus:
        return dbColumn?.name === 'statusAssignees' || dbColumn === undefined
          ? this.getDefaultRoleStatus(roleId)
          : this.getFirstStatusAssignee(roleId, dbColumn)
      case DbTable.Item:
      default: {
        if (typeof this[dbColumn?.name as keyof Item] === 'function') {
          const func = this[dbColumn?.name as keyof Item] as Function
          if (func.length === 0) {
            return func()
          } else {
            return func()()
          }
        } else {
          return this[dbColumn?.name as keyof Item]
        }
      }
    }
  }

  getUserRoles = (userId?: number) => {
    return this.statusAssignees
      .filter(s => s.userId === userId)
      .map(s => s.roleId)
  }

  setOrderProperties = (order: Order) => {
    if (this.order) {
      this.order.id = order.id
      this.order.isRush = order.isRush
      this.order.county = order.county
      this.order.dueDate = order.dueDate
      this.order.propertyUse = order.propertyUse
      this.order.orderType = order.orderType
      this.order.transactionType = order.transactionType
      this.order.state = order.state
      this.order.guid = order.guid
      this.order.closer = order.closer
      this.order.orderEntry = order.orderEntry
      this.order.orderStatus = order.orderStatus
      this.order.titleCompany = order.titleCompany
      this.order.propertyAddress = order.propertyAddress
      this.order.parcelNumber = order.parcelNumber
      this.order.salesPrice = order.salesPrice
      this.order.buyersNames = order.buyersNames
      this.order.sellersNames = order.sellersNames
      this.order.checklist4Notes = order.checklist4Notes
      this.order.checklist5Notes = order.checklist5Notes
      this.order.checklist6Notes = order.checklist6Notes
      this.order.searchOrderBy = order.searchOrderBy
      this.order.propertyType = order.propertyType
      this.order.prefix = order.prefix
      this.order.loanAmount = order.loanAmount
      this.order.closingDate = order.closingDate
      this.order.dt2DueDate = order.dt2DueDate
      this.order.njDueDate = order.njDueDate
      this.order.city = order.city
      this.order.zipCode = order.zipCode
      this.order.closerName = order.closerName
      this.order.closerPhone = order.closerPhone
      this.order.closerEmail = order.closerEmail
      this.order.lwProcessor = order.lwProcessor
    } else this.order = new Order(order)
    this.name = this.order.fileNumber
  }

  setOrder = (order: Order) => {
    this.order = new Order(order)
    this.name = order.fileNumber
  }

  setChecklist = (data: string) => {
    this.checklist = data
  }

  setComment = (
    comment?: string,
    commentCreatedOn?: Date,
    commentUserId?: number
  ) => {
    this.comment = comment
    this.commentCreatedOn = commentCreatedOn
      ? toLocalDate(commentCreatedOn)
      : undefined
    this.commentUserId = commentUserId
  }

  setItem = (item: Item, hasUnreadEmail?: boolean) => {
    this.itemGroupId = item.itemGroupId
    this.fileNumber = item.fileNumber
    this.isMain = item.isMain
    this.name = item.name
    this.guid = item.guid
    this.priority = item.priority
    this.flowStatusId = item.flowStatusId
    this.questionStatusId = item.questionStatusId
    this.statusId = item.statusId
    this.hasNewEmail = item.hasNewEmail
    this.hasAttachments = item.hasAttachments
    this.firstEmailReceivedAt = item.firstEmailReceivedAt
      ? toLocalDate(item.firstEmailReceivedAt)
      : undefined
    this.lastEmailReceivedAt = item.lastEmailReceivedAt
      ? toLocalDate(item.lastEmailReceivedAt)
      : undefined
    this.isPriorityEditable = item.isPriorityEditable
    this.checklist = item.checklist
    this.disabledChecklist = item.disabledChecklist
    this.startedOn = item.startedOn ? toLocalDate(item.startedOn) : undefined
    this.completedOn = item.completedOn
      ? toLocalDate(item.completedOn)
      : undefined
    this.countySearchStatusId = item.countySearchStatusId
    this.isChecklistEnabled = item.isChecklistEnabled
    this.isArchive = item.isArchive
    // If the item is filtered into the inbound group,
    // the 'inboundInAssignee' property will be added to the specific item
    // as a shallow copy, indicating its assignment to the inbound group.
    this.inboundInAssignee = item.inboundInAssignee
    this.comment = item.comment
    this.commentCreatedOn = item.commentCreatedOn
      ? toLocalDate(item.commentCreatedOn)
      : undefined
    this.commentUserId = item.commentUserId
    this.backtitleStatusId = item.backtitleStatusId
    this.dT2orWebsiteStatusId = item.dT2orWebsiteStatusId
    this.searchTypeStatusId = item.searchTypeStatusId
    this.invoice = item.invoice
    this.priorityStatusId = item.priorityStatusId
    this.relatedToFileNumber = item.relatedToFileNumber
    this.emailBody = item.emailBody
    this.emailSender = item.emailSender
    if (hasUnreadEmail) this.hasUnreadEmail = true
  }

  setAddStatusAssignee = (newAssignee: StatusAssignee) => {
    this.statusAssignees.push(new StatusAssignee(newAssignee))
  }

  setDeleteStatusAssignee = (index: number) => {
    this.statusAssignees.splice(index, 1)
  }
}

export enum FlowStatus {
  Unassigned = 1,
  InProgress,
  OnHold,
  Cancelled,
  Done
}

export enum NJFlowStatus {
  Unassigned = 161,
  InProgress = 162,
  OnHold = 163,
  Cancelled = 164,
  Done = 166
}

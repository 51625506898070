import {useState} from 'react'
import {
  Typography,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Chip,
  Box,
  IconButton,
  Stack
} from '@mui/material'
import {
  getGridDateOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterInputBoolean,
  GridFilterInputValue,
  GridFilterInputValueProps,
  GridFilterItem,
  GridFilterOperator,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import LabelCol, {LabelProps} from 'src/components/view/columnTypes/LabelCol'
import {ListItemAssignee} from 'src/components/custom/CuiAssigneeAutocomplete'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {useMainStore} from 'src/context/Main'
import {ColumnType, DbTable, DynamicGridColumn} from 'src/entities/Column'
import DownIcon from 'src/images/down.svg?react'
import MoreMenu from 'src/components/view/MoreMenu'
import OpenItemView from 'src/components/view/columnTypes/OpenItemView'
import CheckCol from 'src/components/view/columnTypes/CheckCircleIconCol'
import CommentCol, {
  CommaCommentCol
} from 'src/components/view/columnTypes/CommentCol'
import Tooltip from '@mui/material/Tooltip'
import AssigneeCell from 'src/components/view/columnTypes/AssigneeCell'
import Member from 'src/entities/Member'
import {
  assigneesFilterFunc,
  userAssigneesFilterFunc,
  singleSelectFilterFunc
} from 'src/components/view/columnTypes/SingleSelectFilterOperator'
import CuiGroupAvatars from 'src/components/custom/CuiGroupAvatars'
import StatusAssignee from 'src/entities/StatusAssignee'
import SelectLinkIcon from 'src/images/select.svg?react'
import HomePageIcon from 'src/images/homePage.svg?react'
import VectorIcon from 'src/images/vector.svg?react'
import config from 'src/config'
import {fDate, fDateTime} from 'src/utils/formatTime'
import AssigneeStatus, {
  StatusLabel
} from 'src/components/view/columnTypes/AssigneeStatus'
import {PermissionType} from 'src/entities/PermissionData'
import Item from 'src/entities/Item'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'
import User from 'src/entities/User'
import {moveObjectToStart} from 'src/utils/array'
import Board from 'src/entities/Board'
import GridAssigneeStatus from 'src/components/view/columnTypes/GridAssigneeStatus'
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {ViewType} from 'src/entities/View'
import {hasValue} from 'src/utils/validations'
import UpdateCol from 'src/components/view/columnTypes/UpdateCol'
import TimeTrackingCol from 'src/components/view/columnTypes/TimeTrackingCol'
import LinkToDocsCol from 'src/components/view/columnTypes/LinkToDocsCol'
import TimeTracking from 'src/entities/TimeTracking'
import {calculateMilliseconds, calculateTime, StartStop} from 'src/utils/date'
import InfoPopupCol from 'src/components/view/columnTypes/InfoPopupCol'
import CuiWhiteTooltip from 'src/components/custom/CuiWhiteTooltip'
import redirectWebsite from 'src/utils/redirectWebsite'
import EmailsStatusSummary from 'src/components/view/columnTypes/EmailsStatusSummary'
import LinkToMailboxCol from './LinkToMailboxCol'
import dayjs, {Dayjs} from 'dayjs'
import RestoreItemCol from 'src/components/view/columnTypes/RestoreItemCol'
import {Property} from 'src/entities/Order'

const valueGetter = (
  params: GridValueFormatterParams,
  column: DynamicGridColumn,
  getStatusesWithComment: (item: Item) => string[],
  viewType?: ViewType
) => {
  switch (column.type) {
    case ColumnType.TimeTracking:
      if (params.id) {
        const item = params.api.getRow(params.id) as Item
        const tt = item.timeTrackings
          .filter((t: TimeTracking) => t.roleId === column.roleId && t.stop)
          .map(t => ({start: t.start, stop: t.stop}) as StartStop)
        return calculateMilliseconds(tt)
      }
      return null
    case ColumnType.Date:
    case ColumnType.DateTime:
      return params.value ? new Date(params.value) : params.value
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.FileNumber:
    case ColumnType.MultilineText:
      const value = params.value ?? column.defaultValue
      if (!params.id) return value

      const item = params.api.getRow(params.id) as Item
      return [value]
        .concat(
          ...column.subColumns.map(
            sc =>
              item
                .getValue(sc.dbTableId as DbTable, sc.dbColumn, sc.roleId)
                ?.toString() ??
              sc.defaultValue ??
              ''
          )
        )
        .filter(t => t)
        .join(', ')
    case ColumnType.CommaComment:
    case ColumnType.Comment:
      return params.id
        ? getStatusesWithComment(params.api.getRow(params.id) as Item).join(
            ', '
          )
        : params.value
    case ColumnType.UserAssignee:
      return params.id && viewType === ViewType.Report
        ? params.api
            .getRow(params.id)
            .getStatusAssigneeByRole(column.roleId)
            .map((a: StatusAssignee) => a.user?.fullName)
            .filter((n: string) => n)
            .join(', ')
        : params.value

    default:
      if (typeof params.value === 'function') {
        const func = params.value as Function
        if (func.length === 0) {
          return func()
        } else {
          return func()()
        }
      } else {
        return params.value
      }
  }
}

const valueFormatter = (
  params: GridValueFormatterParams,
  column: DynamicGridColumn,
  getStatusesWithComment: (item: Item) => string[]
) => {
  switch (column.type) {
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.CommaComment:
    case ColumnType.Comment:
    case ColumnType.UserAssignee:
    case ColumnType.FileNumber:
    case ColumnType.MultilineText:
      return valueGetter(
        params,
        column,
        getStatusesWithComment,
        ViewType.Report
      ).replace(/\r?\n/g, ' ')
    case ColumnType.Date:
      return params.value ? fDate(params.value) : ''
    case ColumnType.Label:
    case ColumnType.Status: {
      let lv = (column.data as LabelProps[])?.find(
        d => d.value === params.value?.toString()
      )
      return lv?.text || ''
    }
    case ColumnType.Labels: {
      const values = (params.value as string)?.split(',')
      const lv = column.data?.filter(d => values?.includes(d.value))
      return lv?.map(l => l.text).join(',')
    }
    case ColumnType.Documents:
      return params.value?.length && params.value[0].name

    case ColumnType.HasAttachments: {
      return params.value
    }
    case ColumnType.Dollar: {
      return params.value
    }
    case ColumnType.DateTime:
      return params.value ? fDateTime(params.value) : ''
    case ColumnType.TimeTracking:
      if (params.id) {
        const item = params.api.getRow(params.id) as Item
        const tt = item.timeTrackings
          .filter((t: TimeTracking) => t.roleId === column.roleId && t.stop)
          .map(t => ({start: t.start, stop: t.stop}) as StartStop)
        const interval = calculateMilliseconds(tt)
        const totalTime = interval > 0 ? calculateTime(interval) : undefined
        return totalTime
          ? `${totalTime.hours ? `${totalTime.hours}h` : ''} ${
              totalTime.minutes ? `${totalTime.minutes}m` : ''
            } ${totalTime.seconds ? `${totalTime.seconds}s` : ''}`
          : ''
      }
      break
    default:
      break
  }
}

const escapeRegExp = (str: string): string =>
  str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')

export const getHighlightedText = (
  value: string,
  searchQuery: string | null
) => {
  if (!searchQuery) return <span>{value}</span>

  let regexPattern: string

  const quotedMatch = searchQuery.match(/^"(.*)"$/)

  if (quotedMatch) {
    // Exact phrase match (allow whitespace, including new lines)
    regexPattern = `(${escapeRegExp(quotedMatch[1].trim()).replace(
      /\s+/g,
      '[\\s\\n\\r]+'
    )})`
  } else {
    // Match words separately
    const searchTerms = searchQuery.match(/\S+/g) || []
    regexPattern = `(${searchTerms.map(term => escapeRegExp(term)).join('|')})`
  }

  try {
    const regex = new RegExp(regexPattern, 'gi')

    return (
      <span>
        {value.split(regex).map((part, i) =>
          part.match(regex) ? (
            <span
              key={i}
              style={{fontWeight: 'bold', backgroundColor: 'yellow'}}
            >
              {part}
            </span>
          ) : (
            <span key={i}>{part}</span>
          )
        )}
      </span>
    )
  } catch (error) {
    console.error('Invalid regex:', error)
    return <span>{value}</span>
  }
}

export const renderCell = (
  params: GridRenderCellParams,
  column: DynamicGridColumn,
  searchQuery: string | null,
  board?: Board
) => {
  switch (column.type) {
    case ColumnType.Text: {
      return (
        <CuiOverflowTypography fontSize="14px">
          {getHighlightedText(params.value, searchQuery)}
        </CuiOverflowTypography>
      )
    }
    case ColumnType.Date:
      return (
        <Typography fontSize="14px">
          {fDate(params.value, column.format)}
        </Typography>
      )
    case ColumnType.DateTime:
      return <Typography fontSize="14px">{fDateTime(params.value)}</Typography>
    case ColumnType.BoldText: {
      return (
        <Typography
          fontSize="14px"
          fontWeight={
            !column.data ||
            column.data.length === 0 ||
            column.data?.find(d => d.text === params.value)
              ? 800
              : 400
          }
        >
          {getHighlightedText(params.value ?? column.defaultValue, searchQuery)}
        </Typography>
      )
    }
    case ColumnType.MultilineText: {
      return (
        <CuiOverflowTypography
          variant="body2"
          title={params.row.note}
          sx={{
            fontStyle: 'normal',
            color: theme => (params.value ? 'black' : theme.palette.grey[500])
          }}
          lineClamp={2}
          withLineBreak
        >
          {getHighlightedText(params.value || '', searchQuery)}
        </CuiOverflowTypography>
      )
    }
    case ColumnType.Label: {
      const lv = column.data?.find(d => d.value === params.value?.toString())
      const isEditable = !!(
        column.definition.editable &&
        params.row.isPropertyEditable(column.definition.headerName)
      )
      if (lv)
        return (
          <LabelCol
            pointer={isEditable}
            text={lv?.text || ''}
            color={lv?.color || 'primary'}
            searchQuery={searchQuery}
          />
        )
      return <></>
    }
    case ColumnType.Labels: {
      const values = (params.value as string)?.split(',')
      const lv = column.data?.filter(d => values?.includes(d.value))
      if (lv?.length)
        return (
          <CuiWhiteTooltip title={lv.map(l => l.text).join(',')}>
            <Stack spacing={0.5} direction={'row'} sx={{maxWidth: '100%'}}>
              {lv.map((l, i) => (
                <LabelCol
                  text={l?.text || ''}
                  color={l?.color || 'default'}
                  thinPadding
                  key={i}
                  searchQuery={searchQuery}
                />
              ))}
            </Stack>
          </CuiWhiteTooltip>
        )
      return <></>
    }
    case ColumnType.GridStatus:
    case ColumnType.Status: {
      if (column.dbTableId === DbTable.Item) {
        const lv = column.data?.find(d => d.value === params.value?.toString())
        const isEditable = !!(
          column.definition.editable &&
          params.row.isPropertyEditable(column.definition.headerName)
        )
        if (lv)
          return (
            <LabelCol
              pointer={isEditable}
              text={lv?.text || ''}
              color={lv?.color || 'primary'}
            />
          )
        return <></>
      } else {
        var currentStatusAssignee = params.row.statusAssignees.find(
          (s: StatusAssignee) => s.roleId === column.roleId
        )
        // To do - remove once all the items have the defaults status assignee rows.
        if (!currentStatusAssignee) return <></>

        return (
          <StatusLabel
            column={column}
            currentStatusAssignee={currentStatusAssignee}
          />
        )
      }
    }

    case ColumnType.Assignee: {
      return <AssigneeCell item={params.row} column={column} />
    }

    case ColumnType.UserAssignee: {
      if (column.viewColumnId === 343) {
        const assignees = params.row.getStatusAssigneeByRole(
          column.roleId
        ) as StatusAssignee[]
        const usersId = moveObjectToStart<StatusAssignee>(
          assignees,
          assignees.find(a => a.roleId === board?.defaultRole?.id)
        )
          .map(s => s.user?.id)
          .filter(Boolean)

        const updaeUsers = board?.members
          .filter((m: Member) => usersId.includes(m.userId))
          .map((m: Member) => m.user) as User[]
        return (
          <CuiGroupAvatars
            max={4}
            size={24}
            users={updaeUsers}
            borderedUserId={
              assignees.find(d => d.user && d.roleId === board?.defaultRole?.id)
                ?.user?.id
            }
          />
        )
      }

      return <AssigneeCell item={params.row} column={column} />
    }

    case ColumnType.More:
      return (
        <MoreMenu itemId={params.row.id} itemGroupId={params.row.itemGroupId} />
      )

    case ColumnType.HomePage: {
      return (
        <IconButton
          onClick={() => {
            window.open(`${config.linkToHP}${params.row.order?.fileNumber}`)
          }}
        >
          <HomePageIcon />
        </IconButton>
      )
    }
    case ColumnType.Select: {
      return (
        <IconButton
          onClick={() => {
            window.open(`${config.linkToSelect}${params.row.order.guid}`)
          }}
        >
          <SelectLinkIcon />
        </IconButton>
      )
    }

    case ColumnType.FileNumber:
      return (
        <OpenItemView
          item={params.row}
          value={params.value ?? column.defaultValue}
          fontSize={14}
          searchText={searchQuery}
        />
      )

    case ColumnType.Contin: {
      return (
        <IconButton
          onClick={() => {
            redirectWebsite(`${config.continLink}`)
          }}
        >
          <VectorIcon />
        </IconButton>
      )
    }

    case ColumnType.HasAttachments:
      return <CheckCol value={params.value} />

    case ColumnType.MailInfo:
      return <InfoPopupCol item={params.row} />

    case ColumnType.Comment:
      return <CommentCol item={params.row} />

    case ColumnType.CommaComment:
      return <CommaCommentCol item={params.row} />

    case ColumnType.Update:
      return <UpdateCol item={params.row} />

    case ColumnType.Dollar: {
      if (!hasValue(params.value)) return <></>

      if (typeof params.value === 'string') {
        return params.value ? (
          <>
            {getHighlightedText(
              params.value
                .split(',')
                .map(num => {
                  let formatted = parseFloat(num).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })
                  return `$ ${formatted}`
                })
                .join(', '),
              searchQuery
            )}
          </>
        ) : (
          <></>
        )
      } else {
        return typeof params.value === 'number' && params.value ? (
          <>
            {getHighlightedText(
              `$ ${Number(params.value).toLocaleString('en-US')}`,
              searchQuery
            )}
          </>
        ) : (
          <></>
        )
      }
    }
    case ColumnType.TimeTracking: {
      return <TimeTrackingCol item={params.row} roleId={column.roleId!} />
    }
    case ColumnType.LinkToDocs: {
      return <LinkToDocsCol item={params.row} />
    }
    case ColumnType.EmailStatus: {
      return <EmailsStatusSummary value={params.value} column={column} />
    }
    case ColumnType.LinkToMailbox: {
      return <LinkToMailboxCol item={params.row} />
    }
    case ColumnType.Restore: {
      return <RestoreItemCol item={params.row} />
    }
    case ColumnType.CombinedAddresses: {
      const addresses = params.row.order?.properties?.map(
        (p: Property) => p.address
      )
      if (!addresses) return <></>

      const text = addresses.join(', ')

      return (
        <Tooltip title={getHighlightedText(text || '', searchQuery)} arrow>
          <CuiOverflowTypography
            variant="body2"
            sx={{
              fontStyle: 'normal',
              color: 'black'
            }}
            lineClamp={2}
            withLineBreak
          >
            {getHighlightedText(text || '', searchQuery)}
          </CuiOverflowTypography>
        </Tooltip>
      )
    }
    default:
      return <></>
  }
}

const onChange = async (
  column: DynamicGridColumn,
  params: GridRenderEditCellParams,
  value: any
) => {
  params.api.setEditCellValue({
    id: params.id,
    field: params.field,
    value: value
  })

  try {
    await column.onChange?.(
      Number(params.id),
      Number(params.field),
      value,
      params.value,
      column.roleId
    )
  } catch (error) {
    params.api.setEditCellValue({
      id: params.id,
      field: params.field,
      value: params.value
    })
  }
}

export const renderEditCell = (
  params: GridRenderEditCellParams,
  column: DynamicGridColumn
) => {
  switch (column.type) {
    case ColumnType.Status: {
      if (column.dbTableId === DbTable.Item) {
        return (
          <RenderEditLabelCell
            value={params.value}
            values={column.data || []}
            onChange={async e => {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: e.target.value
              })

              try {
                await column.onChange?.(
                  Number(params.id),
                  Number(params.field),
                  e.target.value,
                  params.value,
                  column.roleId
                )
              } catch (error) {
                params.api.setEditCellValue({
                  id: params.id,
                  field: params.field,
                  value: params.value
                })
              }
            }}
          />
        )
      } else {
        return (
          <AssigneeStatus
            column={column}
            item={params.row}
            permissionType={PermissionType.Write}
            isCell={true}
          />
        )
      }
    }
    case ColumnType.GridStatus: {
      return (
        <GridAssigneeStatus
          column={column}
          item={params.row}
          permissionType={PermissionType.Write}
          isCell={true}
        />
      )
    }
    case ColumnType.Label: {
      return (
        <RenderEditLabelCell
          value={params.value}
          values={column.data || []}
          onChange={async e => {
            params.api.setEditCellValue({
              id: params.id,
              field: params.field,
              value: e.target.value
            })

            try {
              await column.onChange?.(
                Number(params.id),
                Number(params.field),
                e.target.value,
                params.value,
                column.roleId
              )
            } catch (error) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: params.value
              })
            }
          }}
        />
      )
    }
    case ColumnType.Dollar: {
      return (
        <RenderEditTextField
          initValue={params.value ?? ''}
          onChange={(value: any) => {
            onChange(column, params, value)
          }}
          type="number"
        />
      )
    }
    case ColumnType.Text:
    case ColumnType.BoldText: {
      return (
        <RenderEditTextField
          isUpperCase={column.isUpperCase}
          initValue={params.value ?? ''}
          onChange={(value: any) => {
            onChange(column, params, value)
          }}
        />
      )
    }
    case ColumnType.MultilineText: {
      return (
        <RenderEditMultilineText
          initValue={params.value ?? ''}
          onChange={(value: any) => {
            onChange(column, params, value)
          }}
        />
      )
    }
    case ColumnType.Date: {
      return (
        <RenderEditDate
          initValue={params.value ?? ''}
          onChange={(value: any) => {
            onChange(column, params, value)
          }}
        />
      )
    }
    default:
      break
  }
}

export interface RenderEditLabelCellProps {
  value: any
  values: any[]
  onChange: (event: any) => void
}

export const RenderEditLabelCell = ({
  value,
  values,
  onChange
}: RenderEditLabelCellProps) => {
  const [open, setOpen] = useState(true)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)

  return (
    <Select
      fullWidth
      sx={{
        borderRadius: 0
      }}
      value={value}
      onChange={e => {
        onChange(e)
        setOpen(false)
      }}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      renderValue={val => {
        const lv = values?.find(v => v.value === val.toString())
        return (
          <LabelCol
            pointer
            text={lv?.text || ''}
            color={lv?.color || 'primary'}
          />
        )
      }}
    >
      {values?.map((v, i) => (
        <MenuItem value={JSON.parse(v.value)} key={i}>
          <LabelCol pointer text={v.text} color={v.color} />
        </MenuItem>
      ))}
    </Select>
  )
}

export interface RenderEditTextFieldProps {
  initValue: any
  onChange: (event: any) => void
  type?: React.HTMLInputTypeAttribute
  isUpperCase?: boolean
}

export const RenderEditTextField = ({
  initValue,
  onChange,
  type,
  isUpperCase
}: RenderEditTextFieldProps) => {
  const [value, setValue] = useState(initValue)

  const handleChange = (event: any) => {
    setValue(
      isUpperCase ? event.target.value.toUpperCase() : event.target.value
    )
  }

  return (
    <TextField
      type={type || 'text'}
      value={value}
      onChange={handleChange}
      fullWidth
      sx={{borderRadius: 'none'}}
      onKeyDown={ev => {
        if (ev.key === 'Enter' && value !== initValue) {
          onChange(type === 'number' && value === '' ? undefined : value)
        }
      }}
      onBlur={() =>
        value !== initValue &&
        onChange(type === 'number' && value === '' ? undefined : value)
      }
    />
  )
}

const RenderEditMultilineText = observer(
  ({initValue, onChange}: RenderEditTextFieldProps) => {
    const [value, setValue] = useState(initValue)

    const handleChange = (event: any) => {
      setValue(event.target.value)
    }

    return (
      <TextField
        rows={2}
        multiline
        value={value}
        fullWidth
        onBlur={(e: any) => {
          if (value !== initValue) onChange(e.target.value)
        }}
        onKeyDown={ev => {
          if (ev.key === 'Enter') {
            ev.stopPropagation()
          }
        }}
        onChange={handleChange}
      />
    )
  }
)

export interface RenderEditDateProps {
  initValue: any
  onChange: (event: any) => void
}

export const RenderEditDate = ({initValue, onChange}: RenderEditDateProps) => {
  const [value, setValue] = useState<Date>()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const handleChange = (val: any) => {
    setValue(val)
  }

  return (
    <div
      onKeyDown={ev => {
        if (
          ev.key === 'Enter' &&
          value &&
          fDate(value) &&
          value !== initValue
        ) {
          onChange(fDate(value))
        }
      }}
      onBlur={() => {
        if (!isCalendarOpen && value && fDate(value) && value !== initValue) {
          onChange(fDate(value))
        }
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          onChange={handleChange}
          onOpen={() => setIsCalendarOpen(true)}
          onClose={() => {
            setIsCalendarOpen(false)
          }}
        />
      </LocalizationProvider>
    </div>
  )
}

interface TooltipProps {
  text: string
  content: JSX.Element
}

const TooltipComponent = ({text, content}: TooltipProps) => {
  return (
    <Tooltip title={text} arrow>
      <Stack
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}
      >
        {content}
      </Stack>
    </Tooltip>
  )
}

const renderTooltip = (
  column: DynamicGridColumn,
  params: any,
  content?: JSX.Element
) => {
  if (!column.tooltipText || !content) return content
  switch (column.dbColumnName) {
    case 'priority':
      if (params.row.isPriorityEditable) return content
      return <TooltipComponent text={column.tooltipText} content={content} />
    case 'comment':
      if (params.row.comment) return content
      return <TooltipComponent text={column.tooltipText} content={content} />
    default:
      return <TooltipComponent text={column.tooltipText} content={content} />
  }
}

export const createColumn = (
  column: DynamicGridColumn,
  hasEditPermission: (item: Item, viewColumnId: number) => boolean,
  getStatusesWithComment: (item: Item) => string[],
  searchQuery: string | null,
  board?: Board,
  viewType?: ViewType
) => {
  const c = {
    ...column.definition,
    editable: column.definition.editable && column.type !== ColumnType.Assignee,
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    renderCell: params =>
      renderTooltip(
        column,
        params,
        renderCell(params, column, searchQuery, board)
      ),
    renderEditCell: params =>
      column.definition.editable &&
      hasEditPermission(params.row, column.viewColumnId)
        ? renderEditCell(params, column)
        : renderTooltip(
            column,
            params,
            renderCell(params, column, searchQuery, board)
          ),
    valueGetter: params =>
      valueGetter(params, column, getStatusesWithComment, viewType),
    valueFormatter: params =>
      valueFormatter(params, column, getStatusesWithComment),
    resizable: !!column.definition.headerName,
    sortable: !!column.definition.headerName
  } as GridColDef
  switch (column.type) {
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.FileNumber:
    case ColumnType.MultilineText:
      const filterOperators = getGridStringOperators()
      filterOperators.splice(1, 0, textFilterOperator)
      return {
        ...c,
        filterOperators: filterOperators
      } as GridColDef
    case ColumnType.Documents:
      return {
        ...c,
        filterOperators: [searchFilterOperator]
      } as GridColDef
    case ColumnType.Assignee:
      return {
        ...c,
        type: 'singleSelect',
        filterOperators: assigneesFilterFunc()
      } as GridColDef
    case ColumnType.UserAssignee:
      return {
        ...c,
        type: 'singleSelect',
        filterOperators: userAssigneesFilterFunc(column.roleId)
      } as GridColDef
    case ColumnType.Label:
    case ColumnType.Status:
    case ColumnType.HasAttachments:
      return {
        ...c,
        type: 'singleSelect',
        filterOperators: singleSelectFilterFunc(column.data)
      } as GridColDef
    case ColumnType.Labels:
      return {
        ...c,
        type: 'singleSelect',
        filterOperators: singleSelectFilterFunc(column.data, ColumnType.Labels)
      } as GridColDef
    case ColumnType.EmailStatus:
      return {
        ...c,
        type: 'singleSelect',
        filterOperators: singleSelectFilterFunc(
          column.data?.filter(d => d.value),
          ColumnType.EmailStatus
        ),
        sortComparator: (v1, v2) => {
          const emailStatusCountV1 = v1
          const labelV1 = column
            .data!.map(d => ({
              ...d,
              emailCount:
                d.value && emailStatusCountV1?.has(Number(d.value))
                  ? emailStatusCountV1.get(Number(d.value))
                  : ''
            }))
            .filter(label => label.emailCount > 0)[0]
          const emailStatusCountV2 = v2
          const labelV2 = column
            .data!.map(d => ({
              ...d,
              emailCount:
                d.value && emailStatusCountV2?.has(Number(d.value))
                  ? emailStatusCountV2.get(Number(d.value))
                  : ''
            }))
            .filter(label => label.emailCount > 0)[0]
          return (labelV1?.text.toLowerCase() || '').localeCompare(
            labelV2?.text.toLowerCase() || ''
          )
        }
      } as GridColDef
    case ColumnType.Date:
    case ColumnType.DateTime:
      return {
        ...c,
        filterOperators: [...getGridDateOperators(), ...dateFilterOperators]
      }
    default:
      return c
  }
}

const searchFilterOperator: GridFilterOperator = {
  label: 'Has Docs?',
  value: 'has-docs?',
  getApplyFilterFn: (filterItem: GridFilterItem) => {
    if (!filterItem.field || !filterItem.operator || !filterItem.value) {
      return null
    }
    return (params: any) => {
      if (filterItem.value === 'true') return params.value?.length > 0
      return params.value?.length === 0
    }
  },
  InputComponent: GridFilterInputBoolean
}

const textFilterOperator: GridFilterOperator = {
  label: "doesn't contain",
  value: 'doesNotContain',
  getApplyFilterFn: filterItem => {
    if (!filterItem.field || !filterItem.operator || !filterItem.value) {
      return null
    }

    return ({value}): boolean => {
      return !value
        ?.toString()
        .toLowerCase()
        .includes(filterItem.value.toLowerCase())
    }
  },
  InputComponent: GridFilterInputValue
}

function DateRangeInput({item, applyValue}: any) {
  const [start, setStart] = useState<Dayjs | null>(
    item.value?.start ? dayjs(item.value.start) : null
  )
  const [end, setEnd] = useState<Dayjs | null>(
    item.value?.end ? dayjs(item.value.end) : null
  )

  const handleStartChange = (newStart: Dayjs | null) => {
    setStart(newStart)
    applyValue({
      ...item,
      value: {start: newStart?.toISOString(), end: end?.toISOString()}
    })
  }

  const handleEndChange = (newEnd: Dayjs | null) => {
    setEnd(newEnd)
    applyValue({
      ...item,
      value: {start: start?.toISOString(), end: newEnd?.toISOString()}
    })
  }

  return (
    <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        dateFormats={{keyboardDate: 'MM/DD/YY'}}
      >
        <DatePicker
          label="Start Date"
          value={start}
          onChange={handleStartChange}
          slots={{
            textField: params => (
              <TextField {...params} size="small" variant="outlined" />
            )
          }}
        />
        <DatePicker
          label="End Date"
          value={end}
          onChange={handleEndChange}
          slots={{
            textField: params => (
              <TextField {...params} size="small" variant="outlined" />
            )
          }}
        />
      </LocalizationProvider>
    </div>
  )
}

const dateFilterOperators: GridFilterOperator[] = [
  {
    label: 'Last week',
    value: 'lastWeek',
    getApplyFilterFn: () => {
      const oneWeekAgo = new Date()
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)
      return ({value}) => {
        const rowDate = new Date(value)
        return rowDate >= oneWeekAgo && rowDate <= new Date()
      }
    }
  },
  {
    label: 'Date range',
    value: 'dateRange',
    InputComponent: DateRangeInput,
    getApplyFilterFn: filterItem => {
      if (
        !filterItem.value ||
        !filterItem.value.start ||
        !filterItem.value.end
      ) {
        return null
      }
      const {start, end} = filterItem.value
      return ({value}) => {
        const rowDate = new Date(value)
        return rowDate >= new Date(start) && rowDate <= new Date(end)
      }
    }
  }
]
export interface GridLabelFilterInputValueProps
  extends GridFilterInputValueProps {
  data: any[] | undefined
}

export const LabelInputComponent = observer(
  ({data, item, applyValue}: GridLabelFilterInputValueProps) => {
    return (
      <Box>
        <Autocomplete
          multiple
          size="small"
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                <LabelCol
                  pointer
                  text={option.text || ''}
                  color={option.color || 'primary'}
                />
              </li>
            )
          }}
          getOptionLabel={option => option.text}
          limitTags={1}
          popupIcon={<DownIcon />}
          value={item.value}
          onChange={(_, newValue) => {
            applyValue({...item, value: newValue})
          }}
          isOptionEqualToValue={(option, v) =>
            option.id ? option.id === v.id : option === v
          }
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                color={option.color}
                variant="soft"
                label={option.text}
                {...getTagProps({index})}
                key={option.value}
              />
            ))
          }
          options={data || []}
          renderInput={params => <TextField {...params} label="Value" />}
        />
      </Box>
    )
  }
)

export const AssigneeInputComponent = observer(
  (props: GridFilterInputValueProps) => {
    const {item, applyValue} = props

    const {
      boardStore: {currentBoard}
    } = useMainStore()

    return (
      <Box>
        <Autocomplete
          multiple
          size="small"
          renderOption={(props, option: Member) => (
            <ListItemAssignee
              {...props}
              key={option.userId}
              user={option.user}
              size={20}
            />
          )}
          getOptionLabel={option =>
            `${option.user.firstName} ${option.user.lastName}`
          }
          limitTags={1}
          popupIcon={<DownIcon />}
          value={item.value}
          onChange={(_, newValue) => {
            applyValue({...item, value: newValue})
          }}
          isOptionEqualToValue={(option, v) =>
            option.userId ? option.userId === v.userId : option === v
          }
          renderTags={(value: Member[], getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                avatar={
                  <CuiAvatar
                    name={`${option.user.firstName} ${option.user.lastName}`}
                    value={`${option.user.firstName[0]}${option.user.lastName[0]}`}
                    size={20}
                    isHere={option.user.isHere}
                  />
                }
                label={`${option.user.firstName} ${option.user.lastName}`}
                variant="filled"
                {...getTagProps({index})}
                key={option.userId}
              />
            ))
          }
          options={currentBoard?.members || []}
          renderInput={params => <TextField {...params} label="Value" />}
        />
      </Box>
    )
  }
)

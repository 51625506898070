import {
  ListSubheader,
  Divider,
  TextField,
  Button,
  Popover,
  Stack,
  Autocomplete
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useRef, useState} from 'react'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {useMainStore} from 'src/context/Main'
import Member from 'src/entities/Member'
import {ListItemAssignee} from '../custom/CuiAssigneeAutocomplete'

import DownIcon from 'src/images/down.svg?react'

interface UsersListItemProps {
  availableMembers?: Member[]
  notAvailableMembers?: Member[]
  onChoose: (userId?: number) => void
  currentAssignee?: number
  anchorEl: HTMLElement
}

const UsersListItem = observer(
  ({
    availableMembers,
    notAvailableMembers,
    onChoose,
    currentAssignee,
    anchorEl
  }: UsersListItemProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const options = [
      {
        title: 'Available',
        options: availableMembers?.map(m => m.user) || []
      },
      {
        title: 'Not Available',
        options: notAvailableMembers?.map(m => m.user) || []
      }
    ].flatMap(group =>
      group.options.map(member => ({group: group.title, member}))
    )

    useEffect(() => {
      if (anchorEl && inputRef.current) {
        inputRef.current.focus()
        setTimeout(() => {
          setIsOpen(true)
        }, 200)
      }
    }, [anchorEl])

    return (
      <>
        {options?.length ? (
          <Stack sx={{maxHeight: 'none', overflow: 'visible', m: 1}}>
            <Autocomplete
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              value={options?.find(m => m.member.id === currentAssignee)}
              onChange={(_, value) => onChoose(value?.member.id)}
              fullWidth
              popupIcon={<DownIcon />}
              isOptionEqualToValue={(option, v) =>
                option.member.id
                  ? option.member.id === v.member.id
                  : option === v
              }
              options={options || []}
              groupBy={option => option.group}
              getOptionLabel={option => option.member.fullName}
              renderInput={params => {
                const [firstWord, lastWord] = (() => {
                  const words = (params.inputProps.value as string)
                    .trim()
                    .split(/\s+/)
                  return [words[0], words[words.length - 1]]
                })()
                const isValid = options.find(
                  o => o.member.fullName === params.inputProps.value
                )
                return (
                  <TextField
                    {...params}
                    inputRef={inputRef}
                    label="Assign user"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: currentAssignee &&
                        isValid &&
                        params.inputProps.value && (
                          <CuiAvatar
                            name={`${firstWord} ${lastWord}`}
                            value={`${firstWord[0]}${lastWord[0]}`}
                            size={24}
                            isHere={
                              options.find(
                                o =>
                                  o.member.fullName ===
                                  (params.inputProps.value as string)
                              )?.member.isHere
                            }
                          />
                        )
                    }}
                  />
                )
              }}
              renderGroup={params => (
                <React.Fragment key={params.key}>
                  <ListSubheader sx={{zIndex: 4}}>{params.group}</ListSubheader>
                  {params.children}
                </React.Fragment>
              )}
              renderOption={(props, option: any) => (
                <ListItemAssignee
                  {...props}
                  key={option.member.id}
                  user={option.member}
                  size={20}
                />
              )}
            />
          </Stack>
        ) : (
          <Divider
            variant="middle"
            sx={{
              m: 'auto',
              mt: 1,
              mb: 3,
              width: '40%',
              borderStyle: 'dashed',
              borderColor: theme => theme.palette.grey[500]
            }}
          />
        )}
      </>
    )
  }
)

interface UserMenuListProps {
  anchorEl: HTMLElement
  ignoreUsers?: number[]
  onChoose: (userId?: number, comment?: string) => void
  onCancel: () => void
  enableUnassign?: boolean
}

const UserMenuList = observer(
  ({
    anchorEl,
    ignoreUsers,
    onChoose,
    onCancel,
    enableUnassign
  }: UserMenuListProps) => {
    const {currentBoard} = useMainStore().boardStore

    const [comment, setComment] = useState('')
    const [assignee, setAssignee] = useState<number>()

    const availableMembers = ignoreUsers
      ? currentBoard?.availableMembers.filter(
          m => !ignoreUsers.includes(m.user.id)
        )
      : currentBoard?.availableMembers

    const notAvailableMembers = ignoreUsers
      ? currentBoard?.notAvailableMembers.filter(
          m => !ignoreUsers.includes(m.user.id)
        )
      : currentBoard?.notAvailableMembers

    return (
      <>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onCancel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <UsersListItem
            availableMembers={availableMembers}
            notAvailableMembers={notAvailableMembers}
            onChoose={setAssignee}
            currentAssignee={assignee}
            anchorEl={anchorEl}
          />

          <Stack>
            <TextField
              value={comment}
              onChange={e => setComment(e.target.value)}
              placeholder="Write a comment…"
              sx={{m: 1}}
            />
            <Stack alignItems="end">
              <Button
                disabled={enableUnassign ? false : !assignee}
                sx={{width: '30%', m: 1}}
                onClick={() => onChoose(assignee, comment)}
                variant="contained"
              >
                {enableUnassign ? (assignee ? 'Assign' : 'Unassign') : 'Assign'}
              </Button>
            </Stack>
          </Stack>
        </Popover>
      </>
    )
  }
)

export default UserMenuList

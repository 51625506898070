import {makeAutoObservable} from 'mobx'
import {AzureStorageStore} from 'src/store/AzureStorageStore'
import {BoardStore} from 'src/store/BoardStore'
import {DepartmentStore} from 'src/store/DepartmentStore'
import {ItemStore} from 'src/store/ItemStore'
import {LoginStore} from 'src/store/LoginStore'
import {PermissionStore} from 'src/store/PermissionStore'
import SignalrStore from 'src/store/SignalrStore'
import {SelectStore} from 'src/store/SelectStore'
import {ViewStore} from 'src/store/ViewStore'
import HistoryStore from 'src/store/HistoryStore'
import OrderStore from 'src/store/OrderStore'
import NotificationStore from 'src/store/NotificationStore'
import UserSettingsStore from 'src/store/UserSettingsStore'
import UserStore from 'src/store/UserStore'
import ReportStore from 'src/store/ReportStore'
import SharedStore from 'src/store/SharedStore'
import {MocBoardStore} from 'src/store/MocBoardStrore'
import SearchStore from 'src/store/SearchStore'

export class MainStore {
  readonly loginStore: LoginStore

  readonly boardStore: BoardStore

  readonly viewStore: ViewStore

  readonly azureStorageStore: AzureStorageStore

  readonly selectStore: SelectStore

  readonly departmentStore: DepartmentStore

  readonly signalrStore: SignalrStore

  readonly itemStore: ItemStore

  readonly orderStore: OrderStore

  readonly notificationStore: NotificationStore

  errorMessage: string = ''

  readonly permissionStore: PermissionStore

  readonly historyStore: HistoryStore

  readonly userSettingsStore: UserSettingsStore

  readonly userStore: UserStore

  readonly reportStore: ReportStore

  readonly sharedStore: SharedStore

  readonly mocBoardStore: MocBoardStore

  readonly searchStore: SearchStore

  constructor() {
    this.loginStore = new LoginStore(this)
    this.signalrStore = new SignalrStore(this)
    this.sharedStore = new SharedStore(this)
    this.azureStorageStore = new AzureStorageStore(this)
    this.departmentStore = new DepartmentStore(this)
    this.viewStore = new ViewStore(this)
    this.permissionStore = new PermissionStore(this)
    this.boardStore = new BoardStore(this)
    this.itemStore = new ItemStore(this)
    this.selectStore = new SelectStore(
      this.boardStore,
      this.loginStore,
      this.itemStore,
      this.viewStore
    )
    this.historyStore = new HistoryStore(this)
    this.orderStore = new OrderStore(this)
    this.notificationStore = new NotificationStore(this)
    this.userSettingsStore = new UserSettingsStore(this)
    this.userStore = new UserStore(this)
    this.reportStore = new ReportStore(this)
    this.mocBoardStore = new MocBoardStore(this)
    this.searchStore = new SearchStore(this)
    makeAutoObservable(this)

    this.loginStore.init()
  }

  setErrorMessage = (message: string) => {
    this.errorMessage = message
  }
}

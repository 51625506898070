import {Alert, IconButton, Snackbar, Stack} from '@mui/material'
import Close from '@mui/icons-material/Close'
import {useEffect, useState} from 'react'
import WarningIcon from 'src/images/warningSnackbarIcon.svg?react'
import SuccessIcon from 'src/images/successSnackbarIcon.svg?react'
import {ConnectingStatus} from '@madisoncres/title-general-package/src/stores/SignalStore'
import {observer} from 'mobx-react-lite'

export interface CuiSnackbarAlertReconnectedProps {
  status: ConnectingStatus
  isOpen: boolean
}
const CuiSnackbarAlertReconnected = ({
  status,
  isOpen
}: CuiSnackbarAlertReconnectedProps) => {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (status === ConnectingStatus.None || !isOpen) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [status, isOpen])

  const onClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') return
    setOpen(false)
  }

  if (status === ConnectingStatus.None) return null

  return (
    <>
      <Snackbar
        autoHideDuration={isOpen ? null : 0}
        open={open}
        onClose={onClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        sx={{
          width: '541px',
          '& .MuiPaper-root': {
            backgroundColor: 'white',
            boxShadow:
              '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)' // Add box shadow
          }
        }}
      >
        <Alert
          icon={false}
          action={
            <IconButton onClick={onClose} aria-label="close" size="small">
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          <Stack
            direction="row"
            sx={{
              backgroundColor: 'white',
              color: 'black',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {status === ConnectingStatus.ConnectedSuccessed ? (
              <SuccessIcon />
            ) : (
              <WarningIcon />
            )}
            <Stack
              mb={1}
              ml={2}
              sx={{
                fontSize: '15px',
                color: 'black',
                fontWeight: 'bold',
                textAlign: 'left',
                lineHeight: '2'
              }}
            >
              {status === ConnectingStatus.ConnectedSuccessed ? (
                <>
                  Great news! You’re connected again and we’re syncing <br />
                  all the changes.
                </>
              ) : (
                <>
                  Your internet seems unstable. Feel free to keep working —{' '}
                  <br />
                  we’ll sync your changes once you’re back online.
                </>
              )}
            </Stack>
          </Stack>
        </Alert>
      </Snackbar>
    </>
  )
}

export default observer(CuiSnackbarAlertReconnected)

import {observer} from 'mobx-react-lite'
import View from 'src/entities/View'
import DisplayView from 'src/components/itemTable/DisplayView'
import {useMainStore} from 'src/context/Main'
import {useMemo} from 'react'
import FileViewToolbar from 'src/components/search/FileViewToolbar'
import {Box, Stack} from '@mui/material'
import NoSearchData from 'src/components/search/NoSearchData'
import InfoPopup from 'src/components/infoPopup/InfoPopup'
import NoResults from 'src/components/search/NoResults'
import LoadingScreen from 'src/components/search/LoadingScreen'

interface FileViewProps {
  view: View
}

const FileView = observer(({view}: FileViewProps) => {
  const mainStore = useMainStore()
  const {
    selectedBoard,
    collapseGroups,
    setAllCollapseGroup,
    setCollapseGroup,
    query,
    getItems,
    lastSearchLoadingStatus,
    assigneeIdSearch,
    startDate,
    endDate
  } = mainStore.searchStore
  const {getGroupItems} = mainStore.boardStore

  const displayViewProps = useMemo(
    () => ({
      currentBoard: selectedBoard,
      getGroupItems,
      view,
      currentView: view,
      collapseGroups,
      setAllCollapseGroup,
      setCollapseGroup,
      hiddenColumns: view?.columns
        ?.filter(c => !c.sort)
        .map(c => c.id.toString())
    }),
    [
      selectedBoard,
      getGroupItems,
      view,
      collapseGroups,
      setAllCollapseGroup,
      setCollapseGroup
    ]
  )

  return (
    <Stack width="100%" height="83vh" p={2}>
      <Stack flex={1}>
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: 'background.paper'
          }}
        >
          <FileViewToolbar />
        </Box>
      </Stack>
      <Stack height="100%" overflow="auto">
        {lastSearchLoadingStatus && !getItems.length ? (
          <LoadingScreen />
        ) : !query && !assigneeIdSearch && !startDate && !endDate ? (
          <NoSearchData />
        ) : !getItems.length && !lastSearchLoadingStatus ? (
          <NoResults />
        ) : (
          <DisplayView key={view.id} {...displayViewProps} />
        )}
      </Stack>
      <InfoPopup />
    </Stack>
  )
})

export default FileView

import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import MenuInfoView from 'src/components/view/itemView/MenuInfoView'

const MenuItemData = observer(() => {
  const {currentItem, isUnknownFN} = useMainStore().itemStore
  return <>{!isUnknownFN && <MenuInfoView item={currentItem!} />}</>
})

export default MenuItemData

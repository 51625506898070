import {generatePath, Link as RouterLink} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {PATH_WORKFLOW} from 'src/routes/paths'
// @mui
import {Stack, Box, Link, useTheme} from '@mui/material'
// config
import {NAV} from 'src/config'
// utils
import {hideScrollbarX} from 'src/utils/cssStyles'
// components
import LogoIcon from 'src/images/logo_Dark.svg?react'
import LogoReconnectedIcon from 'src/images/logo_reconnected.svg?react'
import {NavSectionMini, NavSectionData} from 'src/components/nav-section'
import GroupIcon from 'src/images/group.svg?react'
import GroupSelectedIcon from 'src/images/groupSelected.svg?react'
import ReportsIcon from 'src/images/ic_analytics.svg?react'
import {PermissionType} from '@madisoncres/title-general-package'
import {ViewType} from 'src/entities/View'
import {ConnectingStatus} from '@madisoncres/title-general-package/src/stores/SignalStore'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import CuiSnackbarAlertStatus from 'src/components/custom/CuiSnackbarAlertStatus'
import MyQuestionsIcon from 'src/images/my_questions.svg?react'
import MyQuestionsSelectedIcon from 'src/images/my_questions_selected.svg?react'
import SearchIcon from 'src/images/search.svg?react'
import SearchSelectedIcon from 'src/images/searchSelected.svg?react'

interface IconProps {
  isSelected?: boolean
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const Icon = ({isSelected, IconComponent}: IconProps) => {
  const theme = useTheme()
  const iconColor = isSelected
    ? theme.palette.primary.light
    : theme.palette.grey[600]

  return <IconComponent fill={iconColor} />
}

const MainNavMini = observer(() => {
  const mainStore = useMainStore()
  const {currentMainDepartment} = mainStore.departmentStore
  const {currentBoard, boards, loadingViewsStatus, currentMember} =
    mainStore.boardStore
  const {getViewPermissionType} = mainStore.permissionStore
  const {messageStatusAboutReconnectedTrying} = mainStore.signalrStore
  const {isManager, user, updateStatus, setIsHereError, isHereError} =
    mainStore.loginStore

  const isLoadingViews =
    Object.values(loadingViewsStatus).length > 0
      ? Object.values(loadingViewsStatus).some(value => value === true)
      : true

  const wfUrl = currentBoard?.id
    ? generatePath(PATH_WORKFLOW.boards, {boardId: currentBoard.id})
    : currentMainDepartment?.id
      ? generatePath(PATH_WORKFLOW.departments, {
          departmentId: currentMainDepartment.id
        })
      : ''

  const navConfig = () => {
    const navConfig: NavSectionData[] = [
      {
        subheader: '',
        items: [
          {
            title: 'Workflow',
            path: wfUrl,
            icon: <GroupIcon />,
            iconSelected: <GroupSelectedIcon />,
            subPath: ['boards', 'items']
          }
        ]
      }
    ]

    if (isLoadingViews && !isManager) return navConfig

    const reportViews = currentBoard
      ? currentBoard.views.filter(v => v.viewType === ViewType.Report)
      : boards
          .flatMap(board => board.views)
          .filter(v => v.viewType === ViewType.Report)

    const hasReportesPermission = reportViews?.reduce((accumulator, view) => {
      const permissionType = getViewPermissionType(
        currentMember?.roleId,
        view.id
      )

      if (permissionType !== PermissionType.Invisible || accumulator)
        return true
      return false
    }, false)

    if (
      reportViews &&
      reportViews.length > 0 &&
      (hasReportesPermission || isManager)
    ) {
      navConfig[0].items.push({
        title: 'Reports',
        path: 'reports',
        icon: <Icon IconComponent={ReportsIcon} />,
        iconSelected: <Icon IconComponent={ReportsIcon} isSelected />
      })
    }

    if (currentBoard?.hasMyQS) {
      navConfig[0].items.push({
        title: 'My Questions',
        path: 'myQuestions',
        icon: <MyQuestionsIcon />,
        iconSelected: <MyQuestionsSelectedIcon />
      })
    }
    navConfig.push({
      subheader: '',
      items: [
        {
          title: 'Search',
          path: 'search',
          icon: <SearchIcon />,
          iconSelected: <SearchSelectedIcon />
        }
      ]
    })
    return navConfig
  }

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV.W_DASHBOARD_MINI}
      }}
    >
      <Stack
        py={5}
        sx={{
          background: theme =>
            `var(--12-background-dark-01-default,${theme.palette.grey[900]})`,
          pb: 2,
          height: 1,
          width: NAV.W_DASHBOARD_MINI,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX
        }}
      >
        <Stack justifyContent="space-between" height="100%" alignItems="center">
          <Stack>
            <Stack justifyContent="center" alignItems="center" p={2} mb={4}>
              <Link component={RouterLink} to="/" sx={{display: 'contents'}}>
                {messageStatusAboutReconnectedTrying ===
                ConnectingStatus.Reconnected ? (
                  <LogoReconnectedIcon />
                ) : (
                  <LogoIcon />
                )}
              </Link>
            </Stack>
            <NavSectionMini data={navConfig()} />
          </Stack>
          <CuiAvatar
            sx={{cursor: 'pointer'}}
            onClick={() => {
              updateStatus()
            }}
            name={user?.fullName}
            value={user?.initials}
            size={40}
            isHere={user?.isHere}
          />
          {isHereError && (
            <CuiSnackbarAlertStatus
              onClose={() => {
                setIsHereError(false)
              }}
            />
          )}
        </Stack>
      </Stack>
    </Box>
  )
})

export default MainNavMini

import {observer} from 'mobx-react-lite'
import {generatePath, Link} from 'react-router-dom'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import {PATH_WORKFLOW} from 'src/routes/paths'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'
import {useState} from 'react'

interface ItemViewProps {
  item: Item
  value: string
  fontSize?: number
}

const OpenItemViewInfo = observer(({item, value, fontSize}: ItemViewProps) => {
  const [isHovered, setIsHovered] = useState(false)

  const {setCurrentItem, setCurrentHoverItem} = useMainStore().itemStore
  const {items, setOpenedInfoPopupItemId, boards} = useMainStore().boardStore

  const hasPermissionToBoard = boards.findIndex(b => b.id === item.boardId) > -1

  return item.isArchive || !hasPermissionToBoard ? (
    <CuiOverflowTypography
      color="gray"
      fontSize={fontSize}
      fontWeight="bold"
      sx={{cursor: 'default'}}
    >
      {value}
    </CuiOverflowTypography>
  ) : (
    <Link
      to={generatePath(PATH_WORKFLOW.items, {
        boardId: item.boardId,
        itemId: item.id
      })}
      onClick={() => {
        setCurrentItem(items.find(i => i.id === item.id))
        setCurrentHoverItem(undefined)
        setOpenedInfoPopupItemId(undefined)
      }}
      onMouseEnter={() => {
        setIsHovered(true)
        setCurrentHoverItem(items.find(i => i.id === item.id))
      }}
      onMouseLeave={() => {
        setIsHovered(false)
        setCurrentHoverItem(undefined)
      }}
    >
      <CuiOverflowTypography
        color={isHovered ? 'primary' : 'black'}
        fontSize={fontSize}
        fontWeight="bold"
      >
        {value}
      </CuiOverflowTypography>
    </Link>
  )
})

export default OpenItemViewInfo

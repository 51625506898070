import {generatePath, useNavigate} from 'react-router-dom'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {useMainStore} from 'src/context/Main'
import {useState} from 'react'

export interface MergeFilesDialogProps {
  srcItemId: number
}

const MergeFilesDialog = ({srcItemId}: MergeFilesDialogProps) => {
  const mainStore = useMainStore()
  const {currentBoard, deleteItem} = mainStore.boardStore
  const {currentItem} = mainStore.itemStore
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()

  const onClose = () => {
    setOpen(false)
    navigate(
      generatePath(PATH_WORKFLOW.items, {
        boardId: currentBoard?.id,
        itemId: srcItemId
      })
    )
    deleteItem(currentItem!.id)
  }

  return (
    <>
      <CuiAlertDialog
        title="Merge Files"
        okButtonText="Ok"
        open={open}
        body={
          'File number exists. Items have been merged and you are being redirected.'
        }
        okClick={onClose}
        close={onClose}
      />
    </>
  )
}

export default MergeFilesDialog

import Base from 'src/entities/Base'
import {GridColDef} from '@mui/x-data-grid-pro'
import {makeAutoObservable} from 'mobx'
import {stringToLowerCase} from 'src/utils/stringHelper'
import Role from 'src/entities/RoleSettings'

export default class Column implements Base {
  id: number

  name: string

  type: ColumnType

  data?: ColumnData[]

  isEditable: boolean

  width: number

  direction: Direction

  viewColumnId: number

  roleId: number

  dbTableId: number

  dbColumn: DBColumn

  tooltip: string

  isUpperCase: boolean

  format?: string

  defaultValue?: string

  parentViewColumnId: number

  subViewColumns: Column[]

  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

  pinnedDirection?: Direction

  isFilterable: boolean

  sort: number

  constructor(c: Column) {
    this.id = c.id
    this.name = c.name
    this.type = c.type
    this.data = c.data
    this.isEditable = c.isEditable
    this.width = c.width
    this.direction = c.direction
    this.viewColumnId = c.viewColumnId
    this.roleId = c.roleId
    this.dbTableId = c.dbTableId
    this.dbColumn = new DBColumn(c.dbColumn)
    this.tooltip = c.tooltip
    this.defaultValue = c.defaultValue
    this.parentViewColumnId = c.parentViewColumnId
    this.subViewColumns = c.subViewColumns?.map(c => new Column(c))
    this.color = c.color
    this.pinnedDirection = c.pinnedDirection
    this.isFilterable = c.isFilterable
    this.sort = c.sort
    this.format = c.format
    this.isUpperCase = c.isUpperCase

    makeAutoObservable(this)
  }
}

export class ColumnData implements Base {
  id: number

  data: string

  sort: number

  columnValue: string

  subColumnData?: ColumnData[]

  isMultiSelect: boolean

  isOverrideSubStatus: boolean

  columnTypeId: ColumnType

  roleId: number

  commentText?: string

  commentTitle?: string

  constructor(co: ColumnData) {
    this.id = co.id
    this.data = co.data
    this.sort = co.sort
    this.columnValue = co.columnValue
    this.subColumnData = co.subColumnData
    this.isMultiSelect = co.isMultiSelect
    this.isOverrideSubStatus = co.isOverrideSubStatus
    this.columnTypeId = co.columnTypeId
    this.roleId = co.roleId
    this.commentText = co.commentText
    this.commentTitle = co.commentTitle
    makeAutoObservable(this)
  }
}

export class DBColumn implements Base {
  id: number

  name: string

  constructor(dbc: DBColumn) {
    this.id = dbc.id
    this.name = stringToLowerCase(dbc.name)
    makeAutoObservable(this)
  }
}

export enum ColumnType {
  Text = 1,
  BoldText,
  Label,
  Documents,
  Assignee,
  More,
  FileNumber,
  HomePage,
  Select,
  DateTime,
  Status,
  HasAttachments,
  UserAssignee,
  Checklist,
  MailInfo,
  SubRoleStatus,
  Comment,
  CommaComment,
  Intel,
  GoogleMaps,
  Workspace,
  Dollar,
  Date,
  GridStatus,
  Update,
  TimeTracking,
  LinkToDocs,
  EmailStatus,
  Labels,
  Contin,
  LinkToMailbox,
  MultilineText,
  Restore,
  CombinedAddresses
}

export interface DynamicGridColumn {
  definition: GridColDef
  type: ColumnType
  data?: any[]
  tooltipText: string
  defaultValue?: string
  roleId?: number
  role?: Role
  subColumns: Column[]
  dbColumnName: string
  format?: string
  isUpperCase: boolean
  viewColumnId: number
  dbTableId: number
  onChange?: (
    itemId: number,
    field: number,
    value: any,
    prevValue: any,
    roleId?: number
  ) => void
}

export enum Direction {
  Right = 1,
  Left = 2,
  Up = 3,
  Down = 4
}

export enum DbTable {
  Order = 1,
  Item = 2,
  AssigneStatus = 3,
  ItemData = 4
}

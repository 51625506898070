import {useCallback, useMemo, useEffect, useRef, useState} from 'react'
import {Box} from '@mui/material'
import {observer} from 'mobx-react-lite'
import View from 'src/entities/View'
import {useMainStore} from 'src/context/Main'
import GroupItems from 'src/components/itemTable/GroupItems'
import User from 'src/entities/User'
import NoResults from 'src/components/itemTable/NoResults'
import {createColumn} from 'src/components/view/columnTypes/viewHelper'
import {DynamicGridColumn} from 'src/entities/Column'
import ViewGroup from 'src/entities/ViewGroup'
import {PermissionType} from 'src/entities/PermissionData'
import Board from 'src/entities/Board'
import Item from 'src/entities/Item'
import {
  GridApiPro,
  GridFilterModel,
  GridLogicOperator
} from '@mui/x-data-grid-pro'
import * as _ from 'lodash'
import {UserSettingKey} from 'src/entities/UserSettings'
import {FunctionUsed, Tabs} from 'src/store/SharedStore'

export const baseFilterModel = {
  items: [],
  linkOperator: GridLogicOperator.And,
  quickFilterValues: []
} as GridFilterModel

interface DisplayViewProps {
  currentBoard?: Board
  getGroupItems: (group: ViewGroup) => Item[] | undefined
  getUpdateFunction?: any
  currentView?: View
  collapseGroups: Record<number, boolean>
  setCollapseGroup: (id: number, isCollapse: boolean) => void
  setAllCollapseGroup: () => void
  Toolbar?: React.JSXElementConstructor<any> | null
  FilterPanel?: React.JSXElementConstructor<any>
  hiddenColumns?: string[]
  initialFilterModel?: GridFilterModel
  isExternalFilter?: boolean
  setGroupGridpiRef?: (groupId: number, apiRef: GridApiPro) => void
}

const DisplayView = observer(
  ({
    currentBoard,
    getGroupItems,
    getUpdateFunction,
    currentView,
    collapseGroups,
    setCollapseGroup,
    Toolbar,
    FilterPanel,
    hiddenColumns,
    initialFilterModel,
    isExternalFilter,
    setGroupGridpiRef
  }: DisplayViewProps) => {
    const emptyFilterModel = initialFilterModel || baseFilterModel

    const mainStore = useMainStore()
    const {user} = mainStore.loginStore
    const {
      loadingItemsStatus,
      getStatusesWithComment,
      quickSearchValue,
      setQuickSearchValue
    } = mainStore.boardStore
    const {getColumnsPermissionType, getPermissionTypeByRoles} =
      mainStore.permissionStore
    const {getUserSettingsByKey} = mainStore.userSettingsStore
    const {conditions, currentTab} = mainStore.sharedStore
    const {query: searchQuery} = mainStore.searchStore
    const [assigneeFilter, setAssigneeFilter] = useState<User>()
    const [isFiltered, setIsFiltered] = useState<boolean>(false)
    const [showBAFiles, setShowBAFiles] = useState<boolean | undefined>(
      conditions[FunctionUsed.ShowBAFiles]?.[0].viewIds?.includes(
        currentView?.id || 0
      )
        ? false
        : undefined
    )

    const [numOfResultsByGroup, setNumOfResultsByGroup] = useState<
      Record<number, number>
    >(
      currentView?.visibleViewGroups.reduce(
        (acc, obj) => ({...acc, [obj.id]: undefined}),
        {}
      ) || {}
    )

    const filtersSettings = getUserSettingsByKey(
      UserSettingKey.Filters,
      currentView?.id
    )?.value
    const [filterModel, setFilterModel] = useState({
      ...emptyFilterModel,
      quickFilterValues: quickSearchValue
    } as GridFilterModel)

    useEffect(() => {
      if (filtersSettings && typeof filtersSettings === 'string')
        setFilterModel({
          ...JSON.parse(filtersSettings)
        } as GridFilterModel)
    }, [filtersSettings])

    useEffect(() => {
      if (quickSearchValue) {
        setFilterModel(
          f =>
            ({
              ...f,
              quickFilterValues: quickSearchValue
            }) as GridFilterModel
        )
      }
    }, [quickSearchValue])

    const toolbarRef = useRef<HTMLDivElement>()

    const hasEditPermission = useCallback(
      (item: Item, viewColumnId: number) => {
        const myRoles = item.getUserRoles(user?.id)

        const permissionType = currentView
          ? getPermissionTypeByRoles(currentView?.id, myRoles, viewColumnId)
          : PermissionType.Readonly

        return permissionType !== PermissionType.Readonly
      },
      [currentView, getPermissionTypeByRoles, user?.id]
    )

    const getStatusesWithCommentAsString = useCallback(
      (item: Item) => {
        return getStatusesWithComment(item, currentBoard)
      },
      [currentBoard, getStatusesWithComment]
    )

    const columns = useMemo(() => {
      const permissions = getColumnsPermissionType(currentView)
      return (
        currentView?.columns
          ?.filter(c => permissions.get(c.id) !== PermissionType.Invisible)
          .map(c =>
            createColumn(
              {
                definition: {
                  field: c.id.toString(),
                  headerName: c.name,
                  editable:
                    c.isEditable &&
                    permissions.get(c.id) === PermissionType.Write,
                  flex: c.width / 100,
                  minWidth: c.width,
                  filterable: c.isFilterable
                },
                type: c.type,
                data: c.data?.map(d => {
                  const data = JSON.parse(d.data)
                  return {
                    value: d.columnValue,
                    ...d,
                    ...data
                  }
                }),
                tooltipText: c.tooltip,
                defaultValue: c.defaultValue,
                roleId: c.roleId,
                role: c.roleId
                  ? currentBoard?.getRoleById(c.roleId)
                  : undefined,
                subColumns: c.subViewColumns,
                dbColumnName: c.dbColumn.name,
                format: c.format,
                isUpperCase: c.isUpperCase,
                viewColumnId: c.viewColumnId,
                dbTableId: c.dbTableId,
                onChange: c.isEditable
                  ? getUpdateFunction(c.dbTableId)
                  : undefined
              } as DynamicGridColumn,
              hasEditPermission,
              getStatusesWithCommentAsString,
              currentTab === Tabs.Search && searchQuery ? searchQuery : null,
              currentBoard,
              currentView.viewType
            )
          ) || []
      )
    }, [
      getUpdateFunction,
      getColumnsPermissionType,
      currentView,
      currentBoard,
      hasEditPermission,
      getStatusesWithCommentAsString,
      searchQuery,
      currentTab
    ])

    const setNumOfResults = useCallback(
      (groupId: number, numOfRows: number) => {
        setNumOfResultsByGroup(prev => ({...prev, [groupId]: numOfRows}))
      },
      []
    )

    useEffect(() => {
      // reset filters for search tab
      if (currentTab === Tabs.Search) {
        setIsFiltered(false)
        setFilterModel({
          ...emptyFilterModel
        })
        setAssigneeFilter(undefined)
        setQuickSearchValue([])
      }
    }, [currentTab, emptyFilterModel, setQuickSearchValue])

    useEffect(() => {
      const isFilterModelChanged =
        !_.isEqual(baseFilterModel, filterModel) || !!assigneeFilter

      if (isFilterModelChanged !== isFiltered) {
        setIsFiltered(isFilterModelChanged)
      }
    }, [filterModel, assigneeFilter, isFiltered])

    const hasResults =
      Object.values(numOfResultsByGroup).filter(v => v).length > 0
    const isLoading =
      Object.values(numOfResultsByGroup).filter(v => v === undefined).length > 0

    return (
      <>
        <Box
          id="toolbar-box"
          ref={toolbarRef}
          sx={{position: 'sticky', top: 0, zIndex: 3}}
        />
        {(!!filterModel.items.length ||
          !!filterModel.quickFilterValues?.length ||
          assigneeFilter) &&
          !hasResults &&
          !isLoading &&
          currentTab !== Tabs.Search && (
            <NoResults
              resetFilter={() => {
                setFilterModel({...emptyFilterModel})
                setAssigneeFilter(undefined)
              }}
            />
          )}
        <Box hidden={isLoading} sx={{position: 'sticky'}}>
          {currentView?.viewGroups.map((group, index) => (
            <GroupItems
              view={currentView}
              group={group}
              getGroupItems={getGroupItems}
              columns={columns}
              hasToolbar={index === 0}
              Toolbar={Toolbar}
              FilterPanel={FilterPanel}
              filterModel={filterModel}
              setFilterModel={setFilterModel}
              isExternalFilter={isExternalFilter}
              assigneeFilter={assigneeFilter}
              setAssigneeFilter={setAssigneeFilter}
              showBAFiles={showBAFiles}
              setShowBAFiles={setShowBAFiles}
              hiddenColumns={hiddenColumns}
              setGroupGridpiRef={setGroupGridpiRef}
              setNumOfResults={setNumOfResults}
              isLoadingBoardItems={loadingItemsStatus[currentBoard!.id]}
              currentViewId={currentView.id}
              toolbarHeight={toolbarRef.current?.clientHeight}
              key={group.id}
              numOfResults={numOfResultsByGroup[group.id]}
              isExpanded={!collapseGroups[group.id]}
              setCollapseGroup={setCollapseGroup}
              isFiltered={isFiltered}
            />
          ))}
        </Box>
      </>
    )
  }
)

export default DisplayView

import React, {useState} from 'react'
import {
  Stack,
  Popper,
  Paper,
  Typography,
  ClickAwayListener
} from '@mui/material'
import {DynamicGridColumn} from 'src/entities/Column'
import Label from 'src/components/label'
import ChevronIcon from 'src/images/ic_chevron_right.svg?react'
import {observer} from 'mobx-react-lite'
export interface LabelProps {
  value: number
  text: string
  color: string
}

export interface EmailsStatusSummaryProps {
  column: DynamicGridColumn
  value: Map<number, number>
}

const EmailsStatusSummary = observer(
  ({column, value}: EmailsStatusSummaryProps) => {
    const emailCounts = value
    const labelsWithEmails = emailCounts
      ? column
          .data!.map(d => ({
            ...d,
            emailCount:
              d.value && emailCounts.has(Number(d.value))
                ? emailCounts.get(Number(d.value))
                : 0
          }))
          .filter(label => label.emailCount > 0)
      : []

    const mainLabels = labelsWithEmails.slice(0, 3)
    const extraLabels = labelsWithEmails.slice(3)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleShowMoreClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const open = Boolean(anchorEl)

    return (
      <Stack spacing={0.5} p={0.5} justifyContent="center" width="100%">
        {mainLabels.map(label => (
          <Stack
            key={label.value}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <Label color={label.color} width="100%" sx={{fontSize: '10px'}}>
              {label.text}
            </Label>
            <Typography variant="body2" color={label.color} fontWeight="bold">
              {label.emailCount}
            </Typography>
          </Stack>
        ))}
        {extraLabels.length > 0 && (
          <Stack alignItems="center">
            <Stack
              direction="row"
              justifyContent="center"
              spacing={0.5}
              alignItems="center"
              onClick={handleShowMoreClick}
              style={{margin: 0, cursor: 'pointer'}}
            >
              <Typography fontSize="10px" color="grey">
                Show All
              </Typography>{' '}
              <ChevronIcon />
            </Stack>
            <Popper open={open} anchorEl={anchorEl} style={{zIndex: 1300}}>
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Paper sx={{padding: 1}}>
                  <Stack spacing={0.5}>
                    {extraLabels.map(label => (
                      <Stack
                        key={label.value}
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        justifyContent="space-between"
                      >
                        <Label
                          color={label.color}
                          width="100%"
                          sx={{fontSize: '10px'}}
                        >
                          {label.text}
                        </Label>
                        <Typography
                          variant="body2"
                          color={label.color}
                          fontWeight="bold"
                        >
                          {label.emailCount}
                        </Typography>
                      </Stack>
                    ))}
                  </Stack>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Stack>
        )}
      </Stack>
    )
  }
)

export default EmailsStatusSummary

import {
  Divider,
  IconButton,
  Stack,
  Typography,
  Popover,
  Grid,
  Button
} from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import { useMainStore } from 'src/context/Main'
import TimeTracking from 'src/entities/TimeTracking'
import {
  calculateTime,
  formatDate,
  formatTime,
  formatTimeDifference
} from 'src/utils/date'
import ResponsiveDateRangePickers from 'src/components/view/columnTypes/TimeTrackingAddManually'

interface TimeTrackingPopoverProps {
  onClose: () => void
  anchorEl: HTMLElement | null
  timeTrackings: TimeTracking[]
  openMilliseconds: number
  updateTotalMilliseconds: () => void
  itemId: number
  roleId: number
}

const TimeTrackingPopover = observer(
  ({
    onClose,
    anchorEl,
    timeTrackings,
    openMilliseconds,
    updateTotalMilliseconds,
    itemId,
    roleId
  }: TimeTrackingPopoverProps) => {
    const { currentBoard, deleteTimeTracking } = useMainStore().boardStore

    const [isAddingManually, setIsAddingManually] = useState(false)

    const calculatedTime = useMemo(() => {
      return calculateTime(openMilliseconds)
    }, [openMilliseconds])

    return (
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        slotProps={{
          paper: {
            sx: {
              p: 3,
              width: 400,
              ml: 0.75
            }
          }
        }}
      >
        {isAddingManually ? (
          <ResponsiveDateRangePickers
            onBack={() => {
              setIsAddingManually(false)
            }}
            itemId={itemId}
            roleId={roleId}
            updateTotalMilliseconds={updateTotalMilliseconds}
          />
        ) : (
          <>
            <Typography align="center" fontSize="18px" fontWeight={700} mb={2}>
              Time Tracking Log
            </Typography>
            <Stack alignItems="center" mb={2}>
              <Button
                onClick={() => {
                  setIsAddingManually(true)
                }}
              >
                Add session manually
              </Button>
            </Stack>
            {timeTrackings.find(t => !t.stop) && (
              <Stack justifyContent="center" alignItems="center" m={2}>
                <Typography>Currently active session</Typography>
                <Typography variant="h3">{`${String(
                  calculatedTime.hours
                ).padStart(2, '0')}:${String(calculatedTime.minutes).padStart(
                  2,
                  '0'
                )}:${String(calculatedTime.seconds).padStart(
                  2,
                  '0'
                )}`}</Typography>
                <Divider />
              </Stack>
            )}
            <Stack spacing={2}>
              {timeTrackings
                .filter(t => t.stop)
                .map(t => {
                  const user = currentBoard?.getUserMember(t.createdBy)
                  return (
                    <Grid container key={t.id} alignItems="center">
                      <Grid item md={1.5} textAlign="center">
                        <CuiAvatar
                          name={user?.fullName || 'System User'}
                          value={user?.initials || 'SU'}
                          size={30}
                          isHere={user?.isHere}
                        />
                      </Grid>
                      <Grid item md={2} textAlign="center">
                        <Typography fontSize="14px">
                          {formatDate(new Date(t.start))}
                        </Typography>
                      </Grid>
                      <Grid item md={5} textAlign="center">
                        <Typography fontSize="14px">
                          {formatTime(new Date(t.start))} -{' '}
                          {formatTime(new Date(t.stop!))}
                        </Typography>
                      </Grid>
                      <Grid item md={2} textAlign="center">
                        <Typography fontSize="14px">
                          {formatTimeDifference(
                            new Date(t.start),
                            new Date(t.stop!)
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={1.5} textAlign="center">
                        <IconButton
                          onClick={() => {
                            deleteTimeTracking(t.itemId, t.id)
                            updateTotalMilliseconds()
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                })}
            </Stack>
          </>
        )}
      </Popover>
    )
  }
)

export default TimeTrackingPopover

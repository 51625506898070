import {GridApiPro} from '@mui/x-data-grid-pro'
import {makeAutoObservable, reaction} from 'mobx'
import Board from 'src/entities/Board'
import Column, {DbTable} from 'src/entities/Column'
import Item from 'src/entities/Item'
import View, {ViewType} from 'src/entities/View'
import ViewGroup from 'src/entities/ViewGroup'
import {MainStore} from 'src/store/MainStore'
import {downloadBlob} from 'src/utils/download'
import {fDate} from 'src/utils/formatTime'
import {delimiter, exportItemReportAsExcel} from 'src/utils/itemReport'
import BoardReport, {PeriodFilter} from '../entities/Report'
import config from '../config'
import Condition from '../entities/Condition'
import {FunctionUsed} from './SharedStore'
import {OrderCompany} from 'src/entities/Order'
import {
  intervalToTimeTrackingFormat,
  monthStringToNumber,
  resetTime
} from 'src/utils/date'

export type Filter = {
  filterKey: string
  filterValue: any
  additionalValue?: any
}

export default class ReportStore {
  selectedBoard?: Board

  view?: View

  currentReport?: BoardReport

  periodFilters?: PeriodFilter[]

  currentPeriodFilter?: PeriodFilter

  currentFieldFilters?: Filter[] = []

  collapseGroups: Record<number, boolean> = {}

  groupGetDataAsCSV: Record<number, () => string> = {}

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    //reaction(() => this.selectedBoard?.report, this.setReport)

    reaction(
      () => [
        this.selectedBoard,
        this.selectedBoard?.reports,
        this.periodFilters,
        this.selectedBoard?.views,
        this.owner?.sharedStore?.conditions
      ],
      ([selectedBoard, report, periodFilters, views, conditions]) => {
        if (selectedBoard && report && periodFilters && views && conditions) {
          this.setCurrentReport()
          this.updateView()
          this.setCurrentFieldFilter(undefined)
        }
      }
    )

    reaction(
      () => [this.currentReport],
      ([currentReport]) => {
        if (currentReport) {
          this.setCurrentPeriodFilter(undefined)
        }
      }
    )

    reaction(
      () => this.owner.loginStore.isMsalAuth,
      isMsalAuth => {
        if (isMsalAuth) this.getPeriodFilters()
      }
    )
  }

  getReportTabs = () => {
    return this.owner.boardStore.currentBoard?.views.filter(
      v => v.viewType === ViewType.Report
    )
  }

  getPeriodFilters = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/periodFilters`)
      .then(res => {
        return res.json()
      })
      .then((data: PeriodFilter[]) => {
        if (data) {
          this.setPeriodFilters(data.map(p => new PeriodFilter(p)))
        }
      })
  }

  setPeriodFilters = (data: PeriodFilter[]) => {
    this.periodFilters = data
  }

  setCurrentPeriodFilter = (periodFilter: PeriodFilter | undefined) => {
    if (periodFilter?.functionName) this.currentPeriodFilter = periodFilter
    else if (periodFilter)
      //case of selected period, but no period.functionName. i.e. the period is "ALL"
      this.currentPeriodFilter = undefined
    //case of undefined. no selected period filter.
    else
      this.currentPeriodFilter = this.periodFilters?.find(
        f => f.id === this.currentReport?.periodFilters[0]
      )
  }

  setCurrentFieldFilter = (filters: Filter[] | undefined) => {
    if (filters) {
      this.currentFieldFilters = [
        ...(this.currentFieldFilters || []),
        ...filters
      ]
    } else {
      this.currentFieldFilters = []
    }
  }

  removeFieldFilter = (columnId: number) => {
    this.currentFieldFilters = this.currentFieldFilters?.filter(
      filter => filter.filterKey !== columnId.toString()
    )
  }

  updateFieldFilter = (newFilter: Filter) => {
    const index = this.currentFieldFilters?.findIndex(
      filter => filter.filterKey === newFilter.filterKey
    )

    if (index !== undefined && index !== -1) {
      this.currentFieldFilters![index] = newFilter
    } else {
      this.currentFieldFilters?.push(newFilter)
    }
  }

  setSelectedBoard = (board?: Board) => {
    this.owner.boardStore.setCurrentBoard(board)
    this.selectedBoard = board
  }

  setView = (view?: View) => {
    this.view = view
    this.periodFilters?.forEach(filter => {
      if (!this.view?.columns?.some(c => c.dbColumn.name === filter!.name)) {
        this.view?.columns?.push(
          new Column({
            id: filter?.value,
            name: filter?.functionName,
            dbColumn: {id: filter?.value, name: filter?.functionName},
            dbTableId: DbTable.Item,
            sort: 0
          } as Column)
        )
      }
    })
  }

  updateView = () => {
    this.setCurrentFieldFilter(undefined)
    if (this.currentReport)
      this.setView(
        this.selectedBoard?.views?.find(
          v => v.id === this.currentReport?.viewId
        )
      )
    else
      this.setView(
        this.selectedBoard?.views?.find(v => v.viewType === ViewType.Report)
      )
  }

  setCurrentReport = (report?: BoardReport) => {
    if (report) {
      this.currentReport = report
      this.updateView()
    } else {
      this.currentReport = this.selectedBoard?.reports[0]
      this.updateView()
    }
  }

  setCollapseGroup = (id: number, isCollapse: boolean) => {
    this.collapseGroups[id] = isCollapse
  }

  setAllCollapseGroup = () => {
    this.collapseGroups =
      Object.values(this.collapseGroups).filter(v => v).length > 0
        ? {}
        : this.view?.viewGroups.reduce((acc: any, g) => {
            acc[g.id] = true
            return acc
          }, {})
  }

  setGroupGetDataAsCSV = (groupId: number, apiRef: GridApiPro) =>
    (this.groupGetDataAsCSV[groupId] = () => {
      let csvData = apiRef.getDataAsCsv({
        includeHeaders: false,
        delimiter: delimiter
      })
      if (this.owner.boardStore.currentBoard?.id === 7)
        csvData = this.formatCsvDate(csvData)
      const csvDataWithTotals = csvData + '\n' + this.getTotalData(apiRef)
      return csvDataWithTotals
    })

  formatCsvDate = (csvData: string) => {
    const datePattern = /([A-Za-z]+) (\d{2}) (\d{4})/g
    return csvData.replace(datePattern, (_match, month, day, year) => {
      const monthNumber = monthStringToNumber(month)
      const dayNumber = parseInt(day, 10)
      return `${monthNumber}/${dayNumber}/${year}`
    })
  }

  getTotalData = (apiRef: GridApiPro) => {
    const rowsIds = apiRef.getAllRowIds()
    let totals: Record<string, number> = {}
    rowsIds.forEach(rowId => {
      this.currentReport?.fieldsWithTotals?.forEach(columnId => {
        const value = apiRef.getCellValue(rowId, columnId)
        if (typeof value === 'number')
          totals[columnId] = (totals[columnId] || 0) + value
      })
    })
    const hasTotalValue = Object.values(totals).some(value => value > 0)
    if (hasTotalValue) {
      let totalLine = 'Totals'
      let sumOfTotals = 0
      apiRef.getAllColumns().forEach(column => {
        if (
          this.currentReport?.fieldsWithTotals?.includes(column.field) &&
          totals[column.field]
        ) {
          const totalValue = totals[column.field] || 0
          totalLine += intervalToTimeTrackingFormat(totals[column.field])
          sumOfTotals += totalValue
          totalLine += delimiter
        } else {
          if (
            this.view?.columns?.find(c => c.id.toString() === column.field)
              ?.sort
          )
            //if the column is visible in the csv- need to add delimiter, if no. no need to add delimiter.
            totalLine += delimiter
        }
      })

      totalLine += intervalToTimeTrackingFormat(sumOfTotals) //add addional cell of sum of totals

      return totalLine
    } else return ''
  }

  getItemWithColumn(item: Item) {
    const columnsData = this.view?.columns?.reduce((a, c) => {
      const value = item.getValue(c.dbTableId, c.dbColumn, c.roleId)

      const getValue = (value: any) => {
        if (typeof value === 'function') {
          return (value as Function)().toString()
        } else {
          return value?.toString()
        }
      }
      return {
        ...a,
        [c.id]: getValue(value)
      }
    }, {})

    return {...item, ...columnsData} as Item
  }

  getGroupItems = (group: ViewGroup) => {
    const periodFilters: Filter[] = this.currentPeriodFilter
      ? [
          {
            filterKey: this.currentPeriodFilter!.value.toString(),
            filterValue: true
          }
        ]
      : []
    const conditions =
      this.owner.sharedStore.conditions[FunctionUsed.FilterReport]

    const condition = conditions?.find(
      (condition: Condition) =>
        condition.boardIds?.includes(this.selectedBoard!.id) &&
        condition.viewIds?.includes(this.view!.id)
    )

    const orderCompanyConditions =
      this.owner.sharedStore.conditions[
        FunctionUsed.ShowOnlySpecificOrderCopmanyFiles
      ]
    const orderCompanyCondition = orderCompanyConditions?.find(
      (condition: Condition) =>
        condition.boardIds?.includes(this.selectedBoard!.id) &&
        condition.viewIds?.includes(this.view!.id)
    )

    const items = this.selectedBoard?.activeItems.reduce(
      (items: Item[], item: Item) => {
        let isToPush = true

        // Condition related to itemGroupIds
        if (!group.itemGroupIds.includes(item.itemGroupId)) {
          isToPush = false
        }

        // Condition related to status assignee
        if (condition?.rolesToApply && condition.statusIds) {
          const currentStatus = item.getStatusAssigneeWithDefaultsByRole(
            condition.rolesToApply[0]
          )[0]?.statusId
          if (
            currentStatus &&
            condition.statusIds.includes(currentStatus) &&
            this.currentPeriodFilter?.name.toLowerCase() !== 'all'
          ) {
            isToPush = false
          }
        }

        // Condition to filter by order company
        if (orderCompanyCondition && orderCompanyCondition.additionalInfo) {
          const additionalInfoNum = Number(orderCompanyCondition.additionalInfo)
          const orderCompany =
            additionalInfoNum in OrderCompany
              ? (additionalInfoNum as OrderCompany)
              : undefined
          if (item.order?.orderCompany !== orderCompany) {
            isToPush = false
          }
        }

        if (isToPush) {
          items.push(this.getItemWithColumn(item))
        }

        return items
      },
      [] as Item[]
    )

    if (!items) return []

    const itemsFilteredByFields = this.filterItems(
      items,
      this.currentFieldFilters || []
    )
    const itemsFilteredByPeriod = this.filterItems(
      itemsFilteredByFields,
      periodFilters
    )

    if (this.selectedBoard?.id) {
      const condition = this.owner?.sharedStore?.conditions[
        FunctionUsed.SortGroup
      ]?.find(
        c =>
          this.selectedBoard?.id &&
          c.boardIds?.includes(this.selectedBoard.id) &&
          c.viewGroupIds?.includes(group.id)
      )

      return condition
        ? this.owner.boardStore.sortByBoard(
            itemsFilteredByPeriod,
            this.selectedBoard.id
          )
        : itemsFilteredByPeriod
    }

    return itemsFilteredByPeriod
  }

  exportExcel = async () => {
    const department = this.owner.departmentStore.departments.find(d =>
      d.boards.some(b => b.id === this.selectedBoard?.id)
    )
    const columns = this.view?.columns?.filter(c => c.sort)

    const buffer = await exportItemReportAsExcel(
      department?.name,
      this.selectedBoard?.name,
      columns,
      this.view?.viewGroups,
      this.groupGetDataAsCSV
    )

    downloadBlob(
      new Blob([buffer]),
      `${this.selectedBoard?.name} ${fDate(new Date())}.xlsx`.replace(
        /(\s|-)/g,
        '_'
      )
    )
  }

  // this function groups filters by filterKey
  groupFiltersByKey = (filters: Filter[]): Map<string, Filter[]> => {
    return filters.reduce((groups, filter) => {
      const {filterKey} = filter
      if (!groups.has(filterKey)) {
        groups.set(filterKey, [])
      }
      groups.get(filterKey)!.push(filter)
      return groups
    }, new Map<string, Filter[]>())
  }

  filterItems = (items: Item[], filters: Filter[]): Item[] => {
    if (filters.length === 0) return items

    // Group filters by filterKey
    const filterGroups = this.groupFiltersByKey(filters)

    return items.filter(item => {
      return Array.from(filterGroups.entries()).every(
        ([filterKey, groupFilters]) => {
          //filters with different filterKey -check that all the filter are pass.
          return groupFilters.some(({filterValue, additionalValue}) => {
            //filters with same filterKey - check if at least one filter in the group matches
            const value = item[filterKey as keyof Item]

            if (!value || !filterValue) {
              return false
            }

            if (filterValue instanceof Date && additionalValue) {
              const valueDate = resetTime(new Date(value.toString()))
              return valueDate >= filterValue && valueDate <= additionalValue //check if value in the date range.
            }
            return value.toString() === filterValue.toString()
          })
        }
      )
    })
  }
}

import {IconButton} from '@mui/material'
import {useNavigate, generatePath} from 'react-router-dom'
import Item from 'src/entities/Item'
import DocsIcon from 'src/images/docs.svg?react'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {DocumentExpandMailEvent} from '@madisoncres/title-general-package'

export interface LinkToDocsColProps {
  item: Item
}

export default function LinkToDocsCol({item}: LinkToDocsColProps) {
  const navigate = useNavigate()

  const onClick = () => {
    const path = generatePath(PATH_WORKFLOW.items, {
      boardId: item.boardId,
      itemId: item.id
    })
    const search = 'view=Documents'
    navigate({
      pathname: path,
      search
    })
    DocumentExpandMailEvent.dispatch({})
  }

  return (
    <IconButton onClick={onClick}>
      <DocsIcon />
    </IconButton>
  )
}

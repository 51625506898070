import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import CuiSnackbarAlertReconnected from 'src/components/custom/CuiSnackbarAlertReconnected'
import {useEffect, useState} from 'react'
import {ConnectingOnlineStatus} from 'src/store/SharedStore'
import CuiSnackbarAlertOnlineReconnected from '../custom/CuiSnackbarAlertOnlineReconnected'
import {runInAction} from 'mobx'

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    // User entered the tab
    ;(document.getElementById('favicon') as HTMLLinkElement).href =
      '/favicon.ico'
  }
}

export const handleNewNotification = () => {
  if (document.visibilityState !== 'visible') {
    ;(document.getElementById('favicon') as HTMLLinkElement).href =
      '/favicon_dot.ico'
  }
}

const ListenersApp = () => {
  const {setIsNeedToRefresh, setConnectedOnlineStatus, connectedOnlineStatus} =
    useMainStore().sharedStore
  const {messageStatusAboutReconnectedTrying} = useMainStore().signalrStore
  const {isAuth} = useMainStore().loginStore

  const [lastActivityTime, setLastActivityTime] = useState(Date.now())

  const handleUserActivity = () => {
    setLastActivityTime(Date.now())
  }

  useEffect(() => {
    const onOnline = () => {
      runInAction(() => {
        setConnectedOnlineStatus(ConnectingOnlineStatus.Connected)
      })
    }

    const onOffline = () => {
      runInAction(() => {
        setConnectedOnlineStatus(ConnectingOnlineStatus.None)
      })
    }
    document.addEventListener('keydown', handleUserActivity)
    document.addEventListener('click', handleUserActivity)
    window.addEventListener('online', onOnline)
    window.addEventListener('offline', onOffline)

    return () => {
      document.removeEventListener('keydown', handleUserActivity)
      document.removeEventListener('click', handleUserActivity)
      window.removeEventListener('online', onOnline)
      window.removeEventListener('offline', onOffline)
    }
  }, [setConnectedOnlineStatus])

  useEffect(() => {
    const checkInactivity = () => {
      const currentTime = Date.now()
      const timeDifference = currentTime - lastActivityTime
      //to do: 48 * 60 * 60 * 1000 - 5 * 60 * 1000
      if (timeDifference >= 8 * 60 * 60 * 1000 - 5 * 60 * 1000) {
        setIsNeedToRefresh(true)
        clearInterval(intervalId)
      }
    }
    //to do: 60 * 60 * 1000
    const intervalId = setInterval(checkInactivity, 60 * 60 * 1000)

    return () => clearInterval(intervalId)
  }, [lastActivityTime, setIsNeedToRefresh])

  // Listeners
  document.addEventListener('visibilitychange', handleVisibilityChange)

  if (!isAuth) return null
  return (
    <>
      <CuiSnackbarAlertReconnected
        status={messageStatusAboutReconnectedTrying}
        isOpen={connectedOnlineStatus === ConnectingOnlineStatus.None}
      />
      <CuiSnackbarAlertOnlineReconnected
        status={connectedOnlineStatus}
        setStatus={setConnectedOnlineStatus}
      />
    </>
  )
}

export default observer(ListenersApp)

import {observer} from 'mobx-react-lite'
import {generatePath, Link} from 'react-router-dom'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import {PATH_WORKFLOW} from 'src/routes/paths'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'
import {useState} from 'react'
import {IconButton, Stack, Tooltip} from '@mui/material'
import CopyIcon from 'src/images/copy.svg?react'
import {getHighlightedText} from 'src/components/view/columnTypes/viewHelper'
import ArchiveIcon from 'src/images/archive.svg?react'

interface ItemViewProps {
  item: Item
  value: string
  fontSize?: number
  searchText: string | null
}

const OpenItemView = observer(
  ({item, value, fontSize, searchText}: ItemViewProps) => {
    const [isHovered, setIsHovered] = useState(false)
    const [tooltipText, setTooltipText] = useState('Copy')

    const {
      setCurrentItem,
      setCurrentHoverItem,
      setOpemMenu,
      setIsOpemViewMoal
    } = useMainStore().itemStore
    const {items, boards} = useMainStore().boardStore

    const hasPermissionToBoard =
      boards.findIndex(b => b.id === item.boardId) > -1

    const handleCopy = () => {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          setTooltipText('Copied!')
          setTimeout(() => {
            setTooltipText('Copy')
          }, 1500)
        })
        .catch(err => {
          console.error('Failed to copy: ', err)
        })
    }

    return (
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        p={3}
        position="relative"
      >
        {item.isArchive && (
          <Stack position="absolute" left={0}>
            <ArchiveIcon />
          </Stack>
        )}
        <Stack width="70%" alignItems="start" direction="row" spacing={1}>
          {item.isArchive || !hasPermissionToBoard ? (
            <CuiOverflowTypography
              fontSize={fontSize}
              fontWeight="bold"
              sx={{cursor: 'default', color: theme => theme.palette.grey[600]}}
            >
              {getHighlightedText(value, searchText)}
            </CuiOverflowTypography>
          ) : (
            <Link
              to={generatePath(PATH_WORKFLOW.items, {
                boardId: item.boardId,
                itemId: item.id
              })}
              onClick={() => {
                setOpemMenu(true)
                setIsOpemViewMoal(true)
                setCurrentItem(items.find(i => i.id === item.id))
                setCurrentHoverItem(undefined)
              }}
              onMouseEnter={() => {
                setIsHovered(true)
                setCurrentHoverItem(items.find(i => i.id === item.id))
              }}
              onMouseLeave={() => {
                setIsHovered(false)
                setCurrentHoverItem(undefined)
              }}
            >
              <CuiOverflowTypography
                color={isHovered ? 'primary' : 'black'}
                sx={{textDecoration: isHovered ? 'underline' : 'none'}}
                fontSize={fontSize}
                fontWeight="bold"
              >
                {getHighlightedText(value, searchText)}
              </CuiOverflowTypography>
            </Link>
          )}
        </Stack>
        <Stack width="30%" alignItems="end">
          <Tooltip title={tooltipText} arrow placement="top">
            <IconButton onClick={handleCopy}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    )
  }
)

export default OpenItemView

import {useEffect, useState} from 'react'
import {Popover} from '@mui/material'
import Boards from 'src/components/moc/Boards'
import Items from 'src/components/moc/Items'
import Board from 'src/entities/Board'

interface MocModalProps {
  anchorEl: HTMLElement
  currentBoardId: number
  availableBoards: Board[]
  onClose: () => void
}

const MocModal = ({
  anchorEl,
  currentBoardId,
  availableBoards,
  onClose
}: MocModalProps) => {
  const [selectedBoard, setSelectedBoard] = useState<number>()
  const [rerender, setRerender] = useState(false)

  useEffect(() => {
    if (rerender) setRerender(false)
  }, [rerender])

  return (
    <Popover
      disablePortal
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      slotProps={{paper: {sx: {width: 300}}}}
    >
      {!selectedBoard && availableBoards.length > 1 ? (
        <Boards
          currentBoardId={currentBoardId}
          availableBoards={availableBoards}
          onSelectedBoard={boardId => setSelectedBoard(boardId)}
          onClose={onClose}
        />
      ) : (
        <Items
          boardId={selectedBoard ?? currentBoardId}
          onBack={() =>
            availableBoards.length > 1 ? setSelectedBoard(undefined) : onClose()
          }
          onClose={onClose}
          onRerender={setRerender}
        />
      )}
    </Popover>
  )
}

export default MocModal

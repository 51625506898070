import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import Available from 'src/components/available/Available'
import Invoice from 'src/components/invoice/Invoice'
import WorkflowContainer from 'src/components/workspace/Container'
import LoginPage from 'src/components/auth/LoginPage'
import Logout from 'src/components/auth/Logout'
import ProtectedRoute from 'src/components/auth/ProtectedRoutes'
import PreviewViewer from 'src/components/Preview/PreviewViewer'
import DepartmentList from 'src/components/department/DepartmentsPage'
import ItemView from 'src/components/itemView/ItemView'
import BoardView from 'src/components/workspace/BoardView'
import Main from 'src/components/layout/Main'
import BoardContainer from 'src/components/workspace/BoardContainer'
import DepartmentView from 'src/components/workspace/DepartmentView'
import Search from 'src/components/search/Search'
import AccessDenied from 'src/components/auth/AccessDenied'
import ReportView from 'src/components/report/ReportView'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import {Suspense} from 'react'
import loadable from '@loadable/component'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import SystemUpdate from 'src/components/systemUpdate/SystemUpdate'
import MyQuestions from 'src/components/chat/MyQuestions'

const WorkspaceRoutes = loadable(() => import('workspace/Workspace'))

const Router = observer(() => {
  const {updateSystem, setUpdateSystem} = useMainStore().sharedStore
  const router = createBrowserRouter([
    {
      path: '/login',
      element: <LoginPage />
    },

    {
      path: '/preview',
      element: <PreviewViewer />
    },
    {
      path: '/logout',
      element: <Logout />
    },
    {
      path: '/accessDenied',
      element: <AccessDenied />
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <Main />
        </ProtectedRoute>
      ),
      children: [
        {
          path: '',
          element: <DepartmentList />
        },
        {
          path: 'available',
          element: <Available />
        },
        {
          path: 'invoice',
          element: <Invoice />
        },
        {
          path: 'reports',
          element: <ReportView />
        },
        {
          path: 'myQuestions',
          element: <MyQuestions />
        },
        {
          path: 'search',
          element: <Search />
        },
        {
          element: <WorkflowContainer />,
          children: [
            {
              path: 'departments/:departmentId',
              element: <DepartmentView />
            },
            {
              path: 'boards/:boardId/',
              element: <BoardContainer />,
              children: [
                {
                  path: '',
                  element: <BoardView />
                }
              ]
            }
          ]
        },
        {
          path: 'boards/:boardId/items/:itemId',
          element: <BoardContainer />,
          children: [
            {
              path: '',
              element: <ItemView />,
              children: [
                {
                  path: '*',
                  element: (
                    <CuiErrorBoundary>
                      <Suspense>
                        <WorkspaceRoutes isWF={true} />
                      </Suspense>
                    </CuiErrorBoundary>
                  )
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '*', // For WS new tab routes
      element: (
        <CuiErrorBoundary>
          <Suspense>
            <WorkspaceRoutes isWF={true} />
          </Suspense>
        </CuiErrorBoundary>
      )
    }
  ])

  return (
    <>
      <RouterProvider router={router} />
      {updateSystem && (
        <SystemUpdate
          time={updateSystem}
          close={() => setUpdateSystem(undefined)}
          disableBackdropClick
        />
      )}
    </>
  )
})

export default Router

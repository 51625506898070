import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {Navigate, useLocation} from 'react-router-dom'
import LoginButton from 'src/components/auth/LoginButton'
import {useMainStore} from 'src/context/Main'
import LayerIcon from 'src/images/layer.svg?react'
import {PATH_AUTH} from 'src/routes/paths'

const LoginPage = observer(function LoginPage() {
  const location = useLocation()
  const {loginStore} = useMainStore()

  const {from} = (location.state as any) || {from: {pathname: '/'}}

  if (loginStore.isAuth) {
    return <Navigate replace to={from} />
  }
  if (loginStore.isAccessDenied) {
    return <Navigate to={PATH_AUTH.accessDenied} />
  }
  return (
    <>
      <Stack
        height={200}
        sx={{
          opacity: 0.8,
          background: 'linear-gradient(135deg, #5BE584 0%, #007B55 100%)'
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="white" variant="h3">
          Welcome to The HUB
        </Typography>
        <LoginButton color="white" />
      </Stack>
      <Stack justifyContent="center" alignItems="center" sx={{mt: 7}}>
        <LayerIcon />
      </Stack>
    </>
  )
})

export default LoginPage

export function toLocalDate(date: Date | string) {
  if (typeof date === 'object') return date

  date = date.endsWith('Z') ? date : date.split('+')[0] + 'Z'
  return new Date(date)
}

export function formatTimeDifference(startDate: Date, endDate: Date) {
  const totalMilliseconds = endDate.getTime() - startDate.getTime()
  const totalSeconds = Math.floor(totalMilliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  // Format to "HH:MM:SS"
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
  return formattedTime
}

export function formatDate(date: Date) {
  // Format date to "7 May"
  const dateFormatter = new Intl.DateTimeFormat('en-GB', {
    day: 'numeric',
    month: 'short'
  })
  return dateFormatter.format(date)
}

export function formatTime(date: Date) {
  // Format time to "12:21 PM"
  const timeFormatter = new Intl.DateTimeFormat('en-GB', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
  return timeFormatter.format(date)
}

export function getLocalDateString(date: Date) {
  // Convert to local date and time string (without UTC conversion)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0')
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  const seconds = String(date.getSeconds()).padStart(2, '0')

  // Construct a local date-time string
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export function getLocalBaseDateString(date: Date) {
  // Convert to local date and time string (without UTC conversion)
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0')

  // Construct a local date-time string
  return `${year}-${month}-${day}`
}

export function getLocalDateWithTimezone(date: Date) {
  const localTimeString = getLocalDateString(date) // Same as the function above

  // Get the timezone offset in hours and minutes
  const timezoneOffset = -date.getTimezoneOffset()
  const sign = timezoneOffset >= 0 ? '+' : '-'
  const offsetHours = String(
    Math.floor(Math.abs(timezoneOffset) / 60)
  ).padStart(2, '0')
  const offsetMinutes = String(Math.abs(timezoneOffset) % 60).padStart(2, '0')

  return `${localTimeString}${sign}${offsetHours}:${offsetMinutes}`
}

export interface StartStop {
  start: Date
  stop: Date
}

export function calculateMilliseconds(timeIntervals: StartStop[]) {
  let totalMilliseconds = 0
  timeIntervals?.forEach(interval => {
    const startTime = new Date(interval.start).getTime()
    const endTime = new Date(interval.stop || new Date()).getTime()
    totalMilliseconds += endTime - startTime
  })
  return totalMilliseconds
}

export function resetTime(date: Date) {
  const reset = new Date(date)
  reset.setHours(0, 0, 0, 0)
  return reset
}

export function calculateTime(totalMilliseconds: number) {
  const totalSeconds = Math.floor(totalMilliseconds / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60
  return {hours, minutes, seconds}
}

export function intervalToTimeTrackingFormat(interval: number) {
  const totalTime = interval > 0 ? calculateTime(interval) : undefined
  return totalTime
    ? `${totalTime.hours ? `${totalTime.hours}h` : ''} ${
        totalTime.minutes ? `${totalTime.minutes}m` : ''
      } ${totalTime.seconds ? `${totalTime.seconds}s` : ''}`
    : ''
}

export function monthStringToNumber(
  monthName:
    | 'Jan'
    | 'Feb'
    | 'Mar'
    | 'Apr'
    | 'May'
    | 'Jun'
    | 'Jul'
    | 'Aug'
    | 'Sep'
    | 'Oct'
    | 'Nov'
    | 'Dec'
): number {
  const months: {[key: string]: number} = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12
  }
  return months[monthName] || -1
}

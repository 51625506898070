import {MainStore} from 'src/store/MainStore'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {
  GlobalSearchRequestEvent,
  GetBoardsEvent,
  GlobalSearchRequestDetails
} from '@madisoncres/title-general-package'
import View, {UsingType} from 'src/entities/View'
import PeriodFilter from 'src/entities/PeriodFilter'
import Board from 'src/entities/Board'
import config from 'src/config'
import Item from 'src/entities/Item'
import StatusAssignee from 'src/entities/StatusAssignee'
import {uniqueKey} from 'src/utils/uuid'
import {getLocalBaseDateString} from 'src/utils/date'

export default class SearchStore {
  currentView?: View

  selectedBoard?: Board

  currentPeriodFilter?: PeriodFilter

  collapseGroups: Record<number, boolean> = {}

  items: Item[] = []

  query?: string = ''

  assigneeIdSearch: number | null = null

  currentUUID?: String

  searchData: any[] = []

  allBoards: Board[] = []

  searchInArchive: boolean = false

  startDate?: Date

  endDate?: Date

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    GlobalSearchRequestEvent.subscribe(this.searchCallback)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      async isFirstLoggedin => {
        if (isFirstLoggedin) {
          this.getAllBoards()
        }
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )

    reaction(
      () => this.selectedBoard,
      selectedBoard => {
        if (selectedBoard) {
          if (selectedBoard.views?.length) {
            this.setCurrentView(this.getViewTabs[0])
          }

        
        }
      }
    )

    reaction(
      () => this.allBoards,
      allBoards => {
        if (allBoards.length) {
          if (this.owner.boardStore.currentBoard) {
            const boardId = this.owner.boardStore.currentBoard.id
            this.setSelectedBoard(this.getBoardById(boardId))
          } else this.setSelectedBoard(allBoards[0])
        }
      }
    )

    reaction(
      () => ({
        items: this.items.slice(),
        archiveIds: this.owner.boardStore.archiveItemsId?.slice()
      }),
      ({items, archiveIds}) => {
        if (items.length && archiveIds?.length) {
          const itemMap = new Map(this.items.map(item => [item.id, item]))

          for (const archiveId of archiveIds) {
            const item = itemMap.get(archiveId)
            if (item) item.isArchive = true
          }
        }
      }
    )
  }

  searchCallback = (e: CustomEvent<GlobalSearchRequestDetails | undefined>) => {
    if (e.detail) {
      const board = this.allBoards.find(b => b.id === e.detail?.boardId)
      if (board && board.id !== this.selectedBoard?.id) {
        this.setSelectedBoard(board)
        this.onSearch(this.query)
      }
    }
  }

  getBoardById = (boardId?: number) => {
    return this.allBoards.find(b => b.id === boardId)
  }

  getAllBoards = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/All`)
      .then(res => res.json())
      .then((data: Board[]) => {
        const boards = data.map(d => new Board(d)).filter(b => b.id !== 5) // To do: remove filter once adding support in commercial request board

        GetBoardsEvent.dispatch({boards: boards})
        this.setAllBoards(boards)
      })
  }

  setAllBoards = (allBoards: Board[]) => {
    this.allBoards = allBoards
  }

  setCurrentView = (view?: View) => {
    this.currentView = view
  }

  setStartDate = (date?: Date) => {
    this.startDate = date
  }

  setEndDate = (date?: Date) => {
    this.endDate = date
  }

  setSearchInArchive = (searchArchives: boolean) => {
    this.searchInArchive = searchArchives
  }

  setAssigneeId = (id: number | null) => {
    this.assigneeIdSearch = id
  }

  setSelectedFilterDate = (from?: Date, to?: Date) => {
    runInAction(() => {
      this.startDate = from
      this.endDate = to
      this.onSearch(this.query)
    })
  }

  setSelectedBoard = (board?: Board, resetSearch: boolean = false) => {
    if (!board) return

    this.setAssigneeId(null)

    if (this.selectedBoard !== board) {
      this.setStartDate(undefined)
      this.setEndDate(undefined)
    }

    if (resetSearch) {
      if (this.selectedBoard !== board) {
        GlobalSearchRequestEvent.dispatch({
          boardId: this.selectedBoard!.id,
          query: '',
          searchInArchive: this.searchInArchive
        })
        this.setQuery('')
        this.resetItems()
        this.selectedBoard = board
      }
    } else {
      this.selectedBoard = board
      this.onSearch(this.query)
    }
  }

  setQuery = (query: string) => {
    this.query = query
  }

  get getItems() {
    if (this.searchInArchive) return this.items
    return this.items.filter(i => !i.isArchive)
  }

  setItems(items: Item[]) {
    const data = items.map(
      i =>
        new Item({
          ...i,
          statusAssignees: i.statusAssignees?.map(
            sa =>
              new StatusAssignee({
                ...sa,
                user: this.owner.boardStore.boardsMembers
                  ?.getMembersByBoardId(this.selectedBoard!.id)
                  .find(u => u.id === sa.userId)
              } as StatusAssignee)
          )
        } as Item)
    )
    this.items.push(...data)
  }

  setCurrentUUID() {
    this.currentUUID = uniqueKey()
    console.log('set uuid:', this.currentUUID)
    this.resetItems()
  }

  resetItems() {
    this.items = []
  }

  get lastSearchLoadingStatus() {
    return this.searchData[this.searchData?.length - 1]?.isLoading
  }

  onSearch = async (value: string = '') => {
    if (!value && !this.assigneeIdSearch && !this.startDate && !this.endDate) {
      this.resetItems()
      runInAction(() => {
        this.searchData = []
      })
      return
    }

    this.setCurrentUUID()
    GlobalSearchRequestEvent.dispatch({
      boardId: this.selectedBoard!.id,
      query: value,
      searchInArchive: this.searchInArchive
    })

    var page = 0
    var currentSearchUUID = this.currentUUID
    this.searchData?.push({uuid: this.currentUUID, isLoading: true})

    var body = {
      query: this.query,
      assigneeId: this.assigneeIdSearch,
      startDate: this.startDate ? getLocalBaseDateString(this.startDate) : null,
      endDate: this.endDate ? getLocalBaseDateString(this.endDate) : null
    }
    try {
      do {
        page++
        const response = await this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/Search/SearchFiles/${this.selectedBoard?.id}`,
          {
            method: 'POST',
            body: JSON.stringify({...body, page}),
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )

        const items = await response.json()

        if (currentSearchUUID === this.currentUUID) this.setItems(items)
        if (items.length < 100 || currentSearchUUID !== this.currentUUID) {
          const state = this.searchData?.find(s => s.uuid === currentSearchUUID)
          state.isLoading = false
          break
        }
      } while (true)
    } catch (ex: any) {
      const state = this.searchData?.find(s => s.uuid === currentSearchUUID)
      state.isLoading = false
      console.log('Failed to get search files.')
    }
  }

  get getViewTabs() {
    return (
      this.selectedBoard?.views.filter(v => v.usingType === UsingType.Search) ||
      []
    )
  }

  onTabClick = (newValue: string) => {
    this.setCurrentView(
      this.selectedBoard?.views.find(
        v => v.id === Number(newValue.split('-')[0])
      )
    )
  }

  setCollapseGroup = (id: number, isCollapse: boolean) => {
    this.collapseGroups[id] = isCollapse
  }

  setAllCollapseGroup = () => {
    this.collapseGroups =
      Object.values(this.collapseGroups).filter(v => v).length > 0
        ? {}
        : this.currentView?.viewGroups.reduce((acc: any, g) => {
            acc[g.id] = true
            return acc
          }, {})
  }

  listener = () => {
    this.owner.signalrStore.on('SetIsArchiveInSearch', (itemId: number) => {
      const item = this.items.find(i => i.id === itemId)
      item?.setIsArchive(true)

      this.owner.boardStore.setArchiveItemsId(
        this.owner.boardStore.archiveItemsId?.filter(id => id !== itemId)
      )
    })
  }
}

import {useRef, useEffect, useState} from 'react'
import {Typography, TypographyProps} from '@mui/material'
import CuiWhiteTooltip from 'src/components/custom/CuiWhiteTooltip'

interface CuiOverflowTypographyProps extends TypographyProps {
  title?: string
  lineClamp?: number
  withLineBreak?: boolean
}
export default function CuiOverflowTypography({
  title,
  children,
  lineClamp,
  withLineBreak,
  ...props
}: CuiOverflowTypographyProps) {
  const [hoverStatus, setHover] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)
  const compareSize = () => {
    if (textElementRef.current !== null) {
      const compare =
        textElementRef.current.scrollWidth >
          textElementRef.current.clientWidth ||
        textElementRef.current.scrollHeight >
          textElementRef.current.clientHeight
      setHover(compare)
    }
  }

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize()
    window.addEventListener('resize', compareSize)
    return () => window.removeEventListener('resize', compareSize) // remove resize listener
  }, [
    textElementRef.current?.clientWidth,
    textElementRef.current?.clientHeight
  ])

  return (
    <CuiWhiteTooltip
      title={title || children}
      disableHoverListener={!hoverStatus}
    >
      <Typography
        ref={textElementRef}
        {...props}
        sx={{
          ...props.sx,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          overflowWrap: 'anywhere',
          whiteSpace: lineClamp
            ? withLineBreak
              ? 'pre-wrap'
              : 'normal'
            : 'nowrap',
          ...(lineClamp && {
            '-webkit-box-orient': 'vertical',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            '-webkit-line-clamp': `${lineClamp}`
          })
        }}
      >
        {children}
      </Typography>
    </CuiWhiteTooltip>
  )
}

import React, {Suspense, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {Box, Stack, Tab, Tabs} from '@mui/material'
import CuiResizable from 'src/components/custom/CuiResizable'
import UserMenuList from 'src/components/user/UserMenuList'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import FavoriteSelectField from 'src/components/select-app/favoriteFields/FavoriteSelectField'
import MailBoxPreview from 'src/components/itemView/MailBoxPreview'
import loadable from '@loadable/component'
import MocModal from 'src/components/moc/MocModal'
import {
  MailboxAssignEvent,
  MailboxMocClickEvent,
  MailboxNavigateEntityEvent,
  MailboxOnAssignClickDetails,
  MailboxOnAssignClickEvent,
  MailboxViewEmailEvent,
  NavigateEntityDetails,
  OnMocClickDetails
} from '@madisoncres/title-general-package'
import {generatePath, useNavigate} from 'react-router-dom'
import {PATH_WORKFLOW} from 'src/routes/paths'
import {UserSettingKey} from 'src/entities/UserSettings'
import {UsingType, ViewType} from 'src/entities/View'

const MailboxView = loadable(() => import('cbMailbox/Mailbox'))
const ConversationView = loadable(() => import('cbMailbox/ConversationView'))

interface MailboxProps {}

const Mailbox = observer(({}: MailboxProps) => {
  const mainStore = useMainStore()
  const {currentBoard, getViewIdByType} = mainStore.boardStore
  const {currentItem} = mainStore.itemStore
  const {updateUserSettings, getUserSettingsByKey} = mainStore.userSettingsStore
  const [tab, setTab] = useState(0)
  const [anchorEl, setAnchorEl] = useState<HTMLElement>()
  const [assignee, setAssignee] = useState<{
    anchorEl: HTMLButtonElement | undefined
    currentAssignee: number | undefined
  }>({anchorEl: undefined, currentAssignee: undefined})
  const navigate = useNavigate()

  const viewId = getViewIdByType(ViewType.MailBox, UsingType.ItemMenu)

  const resizableWidth =
    getUserSettingsByKey(
      UserSettingKey.MaiboxResizeWidth,
      viewId
    )?.value?.replace(/['"]+/g, '') || '60%'

  const onCloseMoc = () => {
    setAnchorEl(undefined)
  }

  const onClose = () => {
    setAssignee({anchorEl: undefined, currentAssignee: undefined})
  }

  const onCancel = () => {
    MailboxAssignEvent.dispatch(undefined)
    onClose()
  }

  const onAssigned = (userId?: number, comment = '') => {
    MailboxAssignEvent.dispatch({userId, comment})
    onClose()
  }

  useEffect(() => {
    const callback = (e: CustomEvent<MailboxOnAssignClickDetails>) => {
      setAssignee({
        anchorEl: e.detail.anchor,
        currentAssignee: e.detail.currentAssigneeId
      })
    }
    MailboxOnAssignClickEvent.subscribe(callback)
    return () => MailboxOnAssignClickEvent.unsubscribe(callback)
  }, [])

  useEffect(() => {
    const callback = (e: CustomEvent<NavigateEntityDetails>) => {
      if (e.detail.id) {
        navigate(
          generatePath(PATH_WORKFLOW.items, {
            boardId: currentBoard?.id,
            itemId: e.detail.id
          })
        )
      }
    }
    MailboxNavigateEntityEvent.subscribe(callback)
    return () => MailboxNavigateEntityEvent.unsubscribe(callback)
  }, [navigate, currentBoard?.id])

  useEffect(() => {
    const callback = (e: CustomEvent<OnMocClickDetails>) => {
      setAnchorEl(e.detail.anchor)
    }
    MailboxMocClickEvent.subscribe(callback)
    return () => MailboxMocClickEvent.unsubscribe(callback)
  }, [])

  useEffect(() => {
    const callback = () => {
      setTab(0)
    }
    MailboxViewEmailEvent.subscribe(callback)
    return () => MailboxViewEmailEvent.unsubscribe(callback)
  }, [])

  return (
    <Stack sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
      <CuiResizable
        minWidth="20%"
        size={{width: resizableWidth, height: '100%'}}
        maxWidth="90%"
        enable={{right: true}}
        onResizeStop={(_e, _direction, ref) => {
          if (ref && ref.parentElement) {
            const resizedWidth = ref.getBoundingClientRect().width
            const parentWidth = ref.parentElement.getBoundingClientRect().width
            if (parentWidth > 0) {
              const percentageWidth = (resizedWidth / parentWidth) * 100
              updateUserSettings(
                `${percentageWidth.toFixed(2)}%`,
                UserSettingKey.MaiboxResizeWidth,
                viewId
              )
            }
          }
        }}
        style={{height: '100%', flex: `0 0 ${resizableWidth}`, borderRight: 1}}
      >
        <CuiErrorBoundary>
          <Suspense fallback={<></>}>
            <MailboxView />
          </Suspense>
        </CuiErrorBoundary>
      </CuiResizable>
      <Box
        sx={{
          flex: `1 1 ${100 - parseFloat(resizableWidth)}%`,
          borderLeft: 1,
          borderColor: 'grey.300',
          overflow: 'auto'
        }}
      >
        <Tabs
          value={tab}
          onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
            setTab(newValue)
          }}
          sx={{pl: 2}}
        >
          <Tab value={0} key={0} label="View Mail" />
          <Tab value={1} key={1} label="View Doc" />
          {currentItem?.order && <Tab value={2} key={2} label="Select" />}
        </Tabs>
        {tab === 2 && <FavoriteSelectField />}
        <Stack display={tab === 0 ? 'block' : 'none'} sx={{height: '84vh'}}>
          <ConversationView />
        </Stack>
        <Stack
          display={tab === 1 ? 'block' : 'none'}
          sx={{height: '92.5%', width: '100%'}}
        >
          <MailBoxPreview setTab={setTab} />
        </Stack>
      </Box>
      {assignee.anchorEl && (
        <UserMenuList
          ignoreUsers={
            assignee.currentAssignee ? [assignee.currentAssignee] : undefined
          }
          anchorEl={assignee.anchorEl}
          onChoose={onAssigned}
          onCancel={onCancel}
          enableUnassign
        />
      )}
      {currentBoard && anchorEl && (
        <MocModal
          currentBoardId={currentBoard.id}
          availableBoards={[currentBoard].concat(
            currentBoard.mocAvailableBoards
          )}
          onClose={onCloseMoc}
          anchorEl={anchorEl}
        />
      )}
    </Stack>
  )
})

export default Mailbox

import {GridFilterModel} from '@mui/x-data-grid-pro'
import {makeAutoObservable} from 'mobx'

export default class UserSettings {
  id: number

  userSettingKey: UserSettingKey

  userId: number

  viewId?: number

  value: string

  constructor(userSettings: UserSettings) {
    this.id = userSettings.id
    this.userSettingKey = userSettings.userSettingKey
    this.userId = userSettings.userId
    this.viewId = userSettings.viewId ?? undefined
    this.value = userSettings.value

    makeAutoObservable(this)
  }

  setId = (id: number) => {
    this.id = id
  }

  setValue = (value: string) => {
    this.value = value
  }
}

export enum UserSettingKey {
  ColumnSettings = 1,
  ColumnSort,
  FavoriteSelectField,
  Filters,
  FavoriteFilters,
  MaiboxResizeWidth,
  ItemViewResizeWidth
}

export interface FavoriteFilterSetting {
  name: string
  filterModel: GridFilterModel | undefined
}

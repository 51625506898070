import React, {useMemo, useState} from 'react'
import * as ReactDOM from 'react-dom'
import {
  Button,
  Chip,
  Stack,
  ToggleButton,
  Switch,
  FormControlLabel,
  Typography
} from '@mui/material'
import {AccountBox as AccountBoxIcon} from '@mui/icons-material'
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import EyeOffIcon from 'src/images/eyeOff.svg?react'
import PersonIcon from 'src/images/person.svg?react'
import User from 'src/entities/User'
import {useMainStore} from 'src/context/Main'
import CuiAssigneeAutocomplete from 'src/components/custom/CuiAssigneeAutocomplete'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import {Control} from 'src/entities/PermissionData'
import {PermissionType} from '@madisoncres/title-general-package'
import {FunctionUsed} from 'src/store/SharedStore'
import AllFilesEmailsStatusSummary from 'src/components/view/AllFilesEmailsStatusSummary'

interface CustomToolbarProps {
  setFilterButtonEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | null>
  >
  onOpenColumnVisibilityPanel: () => void
  assigneeFilter?: User
  setAssigneeFilter: React.Dispatch<React.SetStateAction<User | undefined>>
  showBAFiles?: boolean
  setShowBAFiles: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const Toolbar = observer(
  ({
    setFilterButtonEl,
    onOpenColumnVisibilityPanel,
    assigneeFilter,
    setAssigneeFilter,
    showBAFiles,
    setShowBAFiles
  }: CustomToolbarProps) => {
    const mainStore = useMainStore()
    const {currentBoard, showAllMyAssignFiles, setShowAllMyAssignFiles} =
      mainStore.boardStore
    const {conditions} = mainStore.sharedStore
    const {
      showAssignNewFileButton,
      currentView,
      collapseGroups,
      setAllCollapseGroup
    } = mainStore.viewStore
    const {getPermissionType} = mainStore.permissionStore

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>()

    const showAllMyFilesPermissionType = useMemo(() => {
      if (!currentBoard?.views.length) return false

      const showAllMyFilesPermissionType = getPermissionType(
        currentView?.id,
        undefined,
        undefined,
        Control.ShowAllMyFiles
      )
      if (showAllMyFilesPermissionType === PermissionType.Invisible) {
        setShowAllMyAssignFiles(true)
      }
      return showAllMyFilesPermissionType
    }, [
      currentBoard?.views.length,
      currentView?.id,
      getPermissionType,
      setShowAllMyAssignFiles
    ])

    const ShowBAFilesCondition = useMemo(() => {
      return conditions[FunctionUsed.ShowBAFiles]?.[0].viewIds?.includes(
        currentView?.id || 0
      )
    }, [conditions, currentView?.id])

    return (
      <React.Fragment>
        {ReactDOM.createPortal(
          <GridToolbarContainer
            sx={{
              backgroundColor: 'white',
              flexDirection: 'column',
              alignItems: 'start'
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={'space-between'}
            >
              <Stack direction="row" alignItems="center">
                <Stack
                  onClick={() => setAllCollapseGroup()}
                  justifyContent="center"
                  sx={{cursor: 'pointer', ml: 0.8, mr: 1.5}}
                >
                  {Object.values(collapseGroups).filter(v => v).length > 0 ? (
                    <UnfoldMoreIcon />
                  ) : (
                    <UnfoldLessIcon />
                  )}
                </Stack>
                <GridToolbarQuickFilter />
                <GridToolbarFilterButton ref={setFilterButtonEl} />
                {assigneeFilter ? (
                  <Chip
                    size="small"
                    avatar={
                      <CuiAvatar
                        name={assigneeFilter.fullName}
                        value={assigneeFilter.initials}
                        size={20}
                        isHere={assigneeFilter.isHere}
                      />
                    }
                    label={assigneeFilter.fullName}
                    variant="filled"
                    onDelete={() => {
                      setAssigneeFilter(undefined)
                    }}
                  />
                ) : (
                  <>
                    <Button
                      onClick={e => {
                        setAnchorEl(e.currentTarget)
                      }}
                      sx={{px: '5px', py: '4px'}}
                      startIcon={<PersonIcon />}
                    >
                      Person
                    </Button>
                    {anchorEl && (
                      <CuiAssigneeAutocomplete
                        anchorEl={anchorEl}
                        options={currentBoard?.members.map(m => m.user) || []}
                        value={assigneeFilter}
                        onChangeAssignee={(value: User | undefined) => {
                          setAnchorEl(undefined)
                          setAssigneeFilter(value)
                        }}
                        onClose={() => setAnchorEl(undefined)}
                      />
                    )}
                  </>
                )}
                <Button
                  onClick={onOpenColumnVisibilityPanel}
                  sx={{px: '5px', py: '4px'}}
                  startIcon={<EyeOffIcon />}
                >
                  Hide
                </Button>
                {currentView?.isAssignedOnly &&
                  showAllMyFilesPermissionType !== PermissionType.Invisible && (
                    <FormControlLabel
                      checked={showAllMyAssignFiles}
                      onChange={(_, c) => {
                        setShowAllMyAssignFiles(c)
                      }}
                      control={<Switch />}
                      label={
                        <Typography
                          sx={{fontWeight: 'bold', fontSize: 'smaller'}}
                        >
                          View all my files
                        </Typography>
                      }
                    />
                  )}
                {ShowBAFilesCondition && (
                  <FormControlLabel
                    checked={showBAFiles}
                    onChange={(_, c) => {
                      setShowBAFiles(c)
                    }}
                    control={<Switch />}
                    label={
                      <Typography
                        sx={{fontWeight: 'bold', fontSize: 'smaller'}}
                      >
                        View BA files
                      </Typography>
                    }
                  />
                )}
              </Stack>
              <Stack direction="row">
                {showAssignNewFileButton &&
                  getPermissionType(
                    currentView?.id,
                    undefined,
                    undefined,
                    Control.AssignFile
                  ) !== PermissionType.Invisible && (
                    <ToggleButton
                      value={!!currentView?.isInboundVisible}
                      selected={!!currentView?.isInboundVisible}
                      color={
                        !!currentView?.isInboundVisible ? 'primary' : undefined
                      }
                      onClick={() => currentView?.setInboundGroup()}
                      sx={{height: '35px', backgroundColor: 'transparent'}}
                    >
                      <AccountBoxIcon />
                      &nbsp;Assign File
                    </ToggleButton>
                  )}
              </Stack>
            </Stack>
            {getPermissionType(
              currentView?.id,
              undefined,
              undefined,
              Control.AllFilesEmailsStatusSummary
            ) !== PermissionType.Invisible && <AllFilesEmailsStatusSummary />}
          </GridToolbarContainer>,
          document.getElementById('toolbar-box')!
        )}
      </React.Fragment>
    )
  }
)

export default Toolbar

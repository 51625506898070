import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {useEffect} from 'react'
import {
  ConversationControl,
  ConversationPermissionEvent,
  ConversationRoleChangeEvent,
  ConversationSendButtonStateEvent
} from '@madisoncres/title-general-package'
import PermissionData, {
  Control,
  PermissionType,
  RoleId
} from 'src/entities/PermissionData'
import {FunctionUsed} from 'src/store/SharedStore'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import {lazy} from '@loadable/component'
import {OrderCompany} from 'src/entities/Order'

const ConversationList = lazy<{conversationTypeId: number; viewId: string}>(
  () => import('cbChat/ConversationList')
)

const preparePermissions = (permissions: PermissionData[]) => {
  const newPermission = {
    [ConversationControl.Assign]: PermissionType.Invisible,
    [ConversationControl.Status]: PermissionType.Invisible,
    [ConversationControl.Filter]: PermissionType.Invisible,
    [ConversationControl.SeeAll]: PermissionType.Write
  } as Record<ConversationControl, PermissionType>
  permissions.forEach(p => {
    switch (p.controlId) {
      case Control.Create:
        newPermission[ConversationControl.Create] = p.permissionTypeId
        return
      case Control.Reply:
        newPermission[ConversationControl.Reply] = p.permissionTypeId
        return
    }
  })
  return newPermission
}

interface ProofingProps {
  viewId: number
}

const Proofing = observer(({viewId}: ProofingProps) => {
  const mainStore = useMainStore()
  const {currentItem, myRolesId} = mainStore.itemStore
  const {currentBoard, currentMember} = mainStore.boardStore
  const {getPermissionTypesByView} = mainStore.permissionStore
  const {conditions} = mainStore.sharedStore

  useEffect(() => {
    ConversationPermissionEvent.dispatch({
      entityId: currentItem?.id ?? 0,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.proofing,
      permissions: preparePermissions(
        getPermissionTypesByView(myRolesId, viewId)
      )
    })
  }, [getPermissionTypesByView, myRolesId, viewId, currentItem?.id])

  useEffect(() => {
    ConversationRoleChangeEvent.dispatch({
      entityId: currentItem?.id ?? 0,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.proofing,
      roleId:
        myRolesId?.[0] ??
        (currentMember?.roleId === RoleId.Manager
          ? RoleId.Manager
          : undefined) ??
        0
    })
  }, [currentItem?.id, myRolesId, currentMember?.roleId])

  useEffect(() => {
    const requierdStatus = conditions[
      FunctionUsed.RequierdStatusBeforeSendProofer
    ]?.find(c => c.boardIds?.includes(currentBoard?.id || 0))?.statusIds ?? [13]
    const isSendButtonDisabled =
      currentItem?.order?.orderCompany === OrderCompany.Select &&
      (currentItem?.countySearchStatusId === undefined ||
        !requierdStatus.includes(currentItem?.countySearchStatusId))
    const isSendButtonDisabledReason = isSendButtonDisabled
      ? 'Hold on! Please upload county search and update status before handing it in for proofing. '
      : ''

    ConversationSendButtonStateEvent.dispatch({
      entityId: currentItem?.id ?? 0,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.proofing,
      isDisable: isSendButtonDisabled,
      reason: isSendButtonDisabledReason
    })
  }, [
    getPermissionTypesByView,
    myRolesId,
    viewId,
    currentItem?.id,
    conditions,
    currentBoard?.id,
    currentItem?.countySearchStatusId,
    currentItem?.order?.orderCompany
  ])

  return (
    <ConversationList
      conversationTypeId={config.conversationType.proofing}
      viewId={ViewType[ViewType.Proofing]}
    />
  )
})

export default Proofing

import {Stack, Tab, Tabs, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'
import BoardReport from 'src/entities/Report'

export const TabView = (
  labelInfo: number | undefined,
  labelColor: LabelColor,
  text: string
) => {
  return (
    <Stack direction="row" spacing={1}>
      {labelInfo && <Label color={labelColor}>{labelInfo}</Label>}

      <Typography variant="body2">{text}</Typography>
    </Stack>
  )
}

const ReportTabs = observer(() => {
  const {reportStore} = useMainStore()
  const {selectedBoard, currentReport, setCurrentReport} = reportStore

  if (!selectedBoard || !selectedBoard.reports?.length) {
    return <></>
  }

  return (
    <Stack
      spacing={2}
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 2
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{width: '100%', bgcolor: 'background.neutral'}}
        spacing={1}
      >
        <Tabs
          value={currentReport}
          onChange={(_: React.ChangeEvent<{}>, newValue: BoardReport) => {
            setCurrentReport(newValue)
          }}
          sx={{pl: 2}}
        >
          {selectedBoard?.reports?.map(r => {
            const view = selectedBoard.views.find(v => v.id === r.viewId)
            return (
              <Tab
                value={r}
                key={r.id}
                label={TabView(
                  view?.count,
                  view?.color as LabelColor,
                  view?.name || ''
                )}
              />
            )
            return null
          })}
        </Tabs>
      </Stack>
    </Stack>
  )
})

export default ReportTabs

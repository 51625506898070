import {Alert, Button, IconButton, Snackbar, Stack} from '@mui/material'
import Close from '@mui/icons-material/Close'
import {useEffect, useState} from 'react'

import {observer} from 'mobx-react-lite'

import {ConnectingOnlineStatus} from 'src/store/SharedStore'
import {WorkspaceViewerRefreshEvent} from '@madisoncres/title-general-package'
import AlertIcon from 'src/images/ic_alert.svg?react'

export interface CuiSnackbarAlertOnlineReconnectedProps {
  status: ConnectingOnlineStatus
  setStatus: (status: ConnectingOnlineStatus) => void
}

const CuiSnackbarAlertOnlineReconnected = ({
  status,
  setStatus
}: CuiSnackbarAlertOnlineReconnectedProps) => {
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (status === ConnectingOnlineStatus.Connected) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [status])

  const onClose = (_: any, reason?: string) => {
    if (reason === 'clickaway') return
    setOpen(false)
    setStatus(ConnectingOnlineStatus.ConnectedSuccessed)
  }

  const onRefresh = () => {
    WorkspaceViewerRefreshEvent.dispatch({})
    setOpen(false)
    setStatus(ConnectingOnlineStatus.ConnectedSuccessed)
  }

  if (status === ConnectingOnlineStatus.None) return null

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      sx={{
        maxWidth: '800px',
        width: '90%',
        '& .MuiPaper-root': {
          backgroundColor: 'white',
          boxShadow:
            '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)'
        }
      }}
    >
      <Alert
        icon={false}
        action={
          <IconButton onClick={onClose} aria-label="close" size="small">
            <Close fontSize="inherit" />
          </IconButton>
        }
      >
        <Stack
          direction="row"
          sx={{
            backgroundColor: 'white',
            color: 'black',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <AlertIcon />
          <Stack
            ml={2}
            sx={{
              fontSize: '15px',
              color: 'black',
              textAlign: 'left',
              lineHeight: '2',
              flex: 1,
              direction: 'ltr'
            }}
          >
            <>
              You’re back online
              <br />
              The latest changes are now being synced. <br />
              <Button
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  textAlign: 'left',
                  display: 'block',
                  padding: 0,
                  minWidth: 'auto',
                  backgroundColor: 'transparent',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    textDecoration: 'underline',
                    color: 'primary.dark'
                  }
                }}
                onClick={onRefresh}
              >
                Click here to refresh the viewer
              </Button>
            </>
          </Stack>
        </Stack>
      </Alert>
    </Snackbar>
  )
}

export default observer(CuiSnackbarAlertOnlineReconnected)

import {Stack, Tab, Tabs, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'

export const TabView = (
  labelInfo: number | undefined,
  labelColor: LabelColor,
  text: string
) => {
  return (
    <Stack direction="row" spacing={1}>
      {labelInfo && <Label color={labelColor}>{labelInfo}</Label>}

      <Typography variant="body2">{text}</Typography>
    </Stack>
  )
}

const SearchHeader = observer(() => {
  const mainStore = useMainStore()
  const {getViewTabs, currentView, onTabClick} = mainStore.searchStore

  return (
    <Stack px={1} overflow="auto">
      <Tabs
        value={currentView?.id ? `${currentView?.id}-view` : false}
        onChange={(_: React.ChangeEvent<{}>, newValue: string) => {
          onTabClick(newValue)
        }}
        sx={{pl: 2}}
      >
        {getViewTabs?.map(v => (
          <Tab
            value={`${v.id}-view`}
            key={v.id}
            label={TabView(v.count, v.color as LabelColor, v.name)}
          />
        ))}
      </Tabs>
    </Stack>
  )
})

export default SearchHeader

import {IconButton} from '@mui/material'
import {Stack} from '@mui/system'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import InfoIcon from 'src/images/info.svg?react'

interface InfoPopupColProps {
  item: Item
}

const InfoPopupCol = observer(({item}: InfoPopupColProps) => {
  const {setOpenedInfoPopupItemId} = useMainStore().boardStore

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              setOpenedInfoPopupItemId(item.id)
            }}
          >
            <InfoIcon />
          </IconButton>
        </Stack>
      </Stack>
    </>
  )
})

export default InfoPopupCol

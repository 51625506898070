import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import DisplayView from 'src/components/itemTable/DisplayView'
import {ViewType} from 'src/entities/View'
import BoardHeader from 'src/components/workspace/BoardHeader'
import Toolbar from 'src/components/workspace/table/Toolbar'
import FilterPanel from 'src/components/workspace/table/FilterPanel'
import {useEffect} from 'react'
import {Tabs} from 'src/store/SharedStore'
import InfoPopup from 'src/components/infoPopup/InfoPopup'

const DisplayBoard = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard, getUpdateFunction, getGroupItems} = mainStore.boardStore
  const {currentView, collapseGroups, setCollapseGroup, setAllCollapseGroup} =
    mainStore.viewStore
  const {setCurrentTab} = mainStore.sharedStore

  useEffect(() => {
    setCurrentTab(Tabs.Workflow)
  }, [setCurrentTab])

  return (
    <>
      <Stack px={1}>
        <BoardHeader />
      </Stack>
      <Stack px={1} overflow="auto">
        {currentView?.viewType === ViewType.Table ? (
          <DisplayView
            key={currentView?.id}
            currentBoard={currentBoard}
            getUpdateFunction={getUpdateFunction}
            getGroupItems={getGroupItems}
            currentView={currentView}
            collapseGroups={collapseGroups}
            setAllCollapseGroup={setAllCollapseGroup}
            setCollapseGroup={setCollapseGroup}
            Toolbar={Toolbar}
            FilterPanel={FilterPanel}
          />
        ) : (
          <></>
        )}
      </Stack>
      <InfoPopup />
    </>
  )
})

export default DisplayBoard

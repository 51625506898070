import {Dialog, DialogTitle, IconButton, Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import InfoPopupTabs from 'src/components/infoPopup/InfoPopupTabs'
import ExpandIcon from 'src/images/expand.svg?react'
import CloseFullScreenIcon from 'src/images/close_fullscreen.svg?react'
import CloseIcon from '@mui/icons-material/Close'
import OpenItemViewInfo from 'src/components/view/columnTypes/OpenItemViewInfo'
import {useMainStore} from 'src/context/Main'
import {useState} from 'react'
import {Tabs} from 'src/store/SharedStore'

const InfoPopup = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard, openedInfoPopupItemId, setOpenedInfoPopupItemId} =
    mainStore.boardStore
  const {setCurrentItem} = mainStore.itemStore
  const {currentTab} = mainStore.sharedStore
  const {selectedBoard, items} = mainStore.searchStore

  const [isFullScreen, SetIsFullScreen] = useState(false)

  if (!openedInfoPopupItemId) return null

  const isSearch = currentTab === Tabs.Search
  const board = isSearch ? selectedBoard : currentBoard
  const itemList = isSearch ? items : board?.items
  const item = itemList?.find(i => i.id === openedInfoPopupItemId)

  const onClose = () => {
    setOpenedInfoPopupItemId(undefined)
    setCurrentItem(undefined)
  }

  return (
    <>
      {item && (
        <Dialog
          fullWidth
          maxWidth="md"
          PaperProps={{sx: {height: '100%', display: 'flex'}}}
          onClose={onClose}
          open
          fullScreen={isFullScreen}
          sx={!isFullScreen ? {height: '80%', m: 'auto'} : {}}
        >
          <DialogTitle>
            <Stack direction="row" justifyContent="space-between">
              <OpenItemViewInfo item={item} value={item.getFullNumberText()} />
              <Stack direction="row" spacing={1}>
                <IconButton onClick={() => SetIsFullScreen(!isFullScreen)}>
                  {isFullScreen ? <CloseFullScreenIcon /> : <ExpandIcon />}
                </IconButton>
                <IconButton onClick={() => setOpenedInfoPopupItemId(undefined)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          </DialogTitle>
          <InfoPopupTabs item={item} currentBoard={board} />
        </Dialog>
      )}
    </>
  )
})
export default InfoPopup

import {
  Checkbox,
  IconButton,
  styled,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip
} from '@mui/material'
import {useMainStore} from 'src/context/Main'

import ChecklistIcon from 'src/images/checklist.svg?react'
import InfoIcon from 'src/images/ic_info.svg?react'
import {useState} from 'react'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {observer} from 'mobx-react-lite'
import {DBColumn} from 'src/entities/Column'

const StyledIconButton = styled(IconButton)(() => ({width: 56}))

const ChecklistPopup = observer(() => {
  const {currentBoard, updateItem} = useMainStore().boardStore
  const {currentItem} = useMainStore().itemStore
  const [displayChecklist, setDisplayChecklist] = useState(false)
  const checkedValue = [
    currentItem?.checklist?.split(',').map(x => +x) ?? [0],
    currentItem?.disabledChecklist?.split(',').map(x => +x) ?? [0]
  ]
  const [checked, setChecked] = useState(checkedValue)
  const [hover, setHover] = useState<number>()

  const onToggle = (checklistId: number, index: number) => {
    const currentIndex = checked[index].indexOf(checklistId)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked[index].push(checklistId)
    } else {
      newChecked[index].splice(currentIndex, 1)
    }
    setChecked([...newChecked])
  }

  const onSubmit = () => {
    try {
      updateItem(
        currentItem!.id,
        undefined,
        checked[0].toString(),
        currentItem!.checklist,
        {id: 27, name: 'Checklist'} as DBColumn
      )
      updateItem(
        currentItem!.id,
        undefined,
        checked[1].toString(),
        currentItem!.disabledChecklist,
        {id: 79, name: 'DisabledChecklist'} as DBColumn
      )
    } finally {
      setDisplayChecklist(false)
    }
  }

  return (
    <>
      <StyledIconButton onClick={() => setDisplayChecklist(true)}>
        <ChecklistIcon />
      </StyledIconButton>
      <CuiAlertDialog
        fullWidth
        maxWidth="xs"
        title={'Checklist'}
        isFullWidth={true}
        showMessage={false}
        showCancel={true}
        okButtonText="Done"
        open={displayChecklist}
        body={
          <FormGroup>
            {currentBoard?.checklist?.map(c => {
              return (
                <Stack
                  key={c.id}
                  direction="row"
                  alignItems="center"
                  justifyContent={
                    checked[1].indexOf(c.id) !== -1 ? 'space-between' : 'start'
                  }
                  spacing={2}
                  onMouseEnter={() => setHover(c.id)}
                  onMouseLeave={() => setHover(undefined)}
                >
                  <FormControlLabel
                    label={c.name}
                    disabled={
                      !currentItem?.isChecklistEnabled ||
                      checked[1].indexOf(c.id) !== -1
                    }
                    control={<Checkbox />}
                    checked={checked[0].indexOf(c.id) !== -1}
                    onChange={() => onToggle(c.id, 0)}
                  />
                  {(hover === c.id && checked[0].indexOf(c.id) === -1) ||
                  checked[1].indexOf(c.id) !== -1 ? (
                    <Tooltip title="N/A" arrow placement="top">
                      <IconButton
                        onClick={e => {
                          e.currentTarget.blur()
                          onToggle(c.id, 1)
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Stack>
              )
            })}
          </FormGroup>
        }
        close={() => {
          setDisplayChecklist(false)
          setChecked(checkedValue)
        }}
        okClick={onSubmit}
        disableOkButton={!currentItem?.isChecklistEnabled}
      />
    </>
  )
})

export default ChecklistPopup

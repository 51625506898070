import {
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'
import {Stack} from '@mui/system'
import {useMainStore} from 'src/context/Main'
import SearchIcon from 'src/images/globalSearch.svg?react'
import SearchHeader from './SearchHeader'
import {observer} from 'mobx-react-lite'
import DisplaySearchView from 'src/components/search/DisplaySearchView'
import {useEffect, useState} from 'react'
import {Tabs} from 'src/store/SharedStore'
import CommonHeader from 'src/components/layout/CommonHeader'
import InfoAlertIcon from 'src/images/ic_info_alert.svg?react'

const Search = observer(() => {
  const mainStore = useMainStore()
  const {setCurrentTab} = mainStore.sharedStore
  const {currentBoard} = mainStore.boardStore
  const {
    onSearch,
    selectedBoard,
    setSelectedBoard,
    query,
    setQuery,
    setSearchInArchive,
    searchInArchive,
    allBoards
  } = mainStore.searchStore

  const [searchValue, setSearchValue] = useState(query || '')

  useEffect(() => {
    setSearchValue(query || '')
  }, [query])

  useEffect(() => {
    if (!selectedBoard) {
      const board = currentBoard !== undefined ? currentBoard : allBoards?.[0]
      setSelectedBoard(board)
    }
  }, [selectedBoard, allBoards, currentBoard, setSelectedBoard])

  useEffect(() => {
    setCurrentTab(Tabs.Search)
  }, [setCurrentTab])

  return (
    <Stack direction="column" width="100%" height="100%">
      <CommonHeader>
        <></>
      </CommonHeader>
      <Stack pr={3} pl={3} spacing={2} width="100%" direction="row">
        <TextField
          value={searchValue}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              const {value} = e.target as HTMLInputElement
              const trimmedValue = value.trim()
              if (trimmedValue !== value) {
                setSearchValue(trimmedValue)
              }
              setQuery(trimmedValue)
              onSearch(trimmedValue)
            }
          }}
          onChange={e => {
            const {value} = e.target
            setSearchValue(value)

            if (value === '') {
              setQuery(value)
              onSearch('')
            }
          }}
          placeholder="Search..."
          type="search"
          variant="standard"
          sx={{flexGrow: 1}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        <FormControlLabel
          checked={searchInArchive}
          onChange={(_, c) => {
            setSearchInArchive(c)
          }}
          control={<Switch />}
          label="Search archives"
          sx={{whiteSpace: 'nowrap'}}
        />
        <Stack height={'100%'} justifyContent={'center'}>
          <Tooltip
            title={
              'Updates may take up to 5 minutes to appear. To see the latest data, please search again'
            }
          >
            <span>
              <InfoAlertIcon />
            </span>
          </Tooltip>
        </Stack>
      </Stack>
      <Stack />
      <Stack height="100%">
        <Stack px={1}>
          <SearchHeader />
        </Stack>
        <Stack px={1}>
          <DisplaySearchView />
        </Stack>
      </Stack>
    </Stack>
  )
})

export default Search

import {useState} from 'react'
import {IconButton} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import CuiGroupAvatars from 'src/components/custom/CuiGroupAvatars'
import Item from 'src/entities/Item'
import AssigneeDialog from 'src/components/view/AssigneeDialog'
import {DynamicGridColumn} from 'src/entities/Column'
import {RoleId} from 'src/entities/PermissionData'
import {moveObjectToStart} from 'src/utils/array'
import StatusAssignee from 'src/entities/StatusAssignee'
import {FunctionUsed, Tabs} from 'src/store/SharedStore'
import User from 'src/entities/User'

export interface AssigneeProps {
  column: DynamicGridColumn
  item: Item
}

const AssigneeCell = observer(({column, item}: AssigneeProps) => {
  const mainStore = useMainStore()
  const {currentView} = mainStore.viewStore
  const {user} = mainStore.loginStore
  const {currentMember, currentBoard} = mainStore.boardStore
  const {conditions, currentTab} = mainStore.sharedStore
  const {users} = mainStore.userStore
  const {view: reportView} = mainStore.reportStore
  const {currentView: searchView, selectedBoard} = mainStore.searchStore
  const [openDialog, setOpenDialog] = useState(false)
  const onClose = () => setOpenDialog(false)

  const conditionSpecificRole =
    conditions[FunctionUsed.ShowOnlySpecificRoleInAssigneeColumn]?.[0]
  const condition = conditions[FunctionUsed.AssigneeCell]?.[0]
  const boardCondition = conditions[FunctionUsed.EveryoneCanAssignInBoard]?.[0]
  const NJViewsCondition = conditions[FunctionUsed.AssignedColumnInNJViews]?.[0]
  const showOnlySpecificStatuses =
    conditions[FunctionUsed.ShowOnlySpecificStatusesInAssigneeColumn]?.[0]

  var onlySpecificRole = false
  var assignBoardRole = false // board 7
  var commercialCanAssign = false // board 4
  var canAssignByStatus = false // board 12
  var canOnlyRemoveAssignee = false
  var assignedNJView = false // show assigned columns also the view is not defined as assigned only

  const NJ_BOARD_ID = 12
  const SENDER_ROLE_ID = 8
  const READER_ROLE_ID = 4
  const NY_WORK_BOARD_ID = 13

  const view = (() => {
    switch (currentTab) {
      case Tabs.Search:
        return searchView
      case Tabs.Reports:
        return reportView
      default:
        return currentView
    }
  })()

  const board = currentTab === Tabs.Search ? selectedBoard : currentBoard

  if (
    conditionSpecificRole &&
    conditionSpecificRole?.viewIds?.includes(view?.id!)
  ) {
    onlySpecificRole = true
  }

  if (NJViewsCondition.viewIds?.includes(view!.id)) {
    assignedNJView = true
  }

  if (item.boardId === NJ_BOARD_ID && column.roleId) {
    const NJConditions = conditions[FunctionUsed.CanSelfAssignNJ]
    // sender
    if (column.roleId === SENDER_ROLE_ID && item.inboundInAssignee) {
      const readingStatusToShow = NJConditions.find(
        c => c.rolesToApply?.includes(SENDER_ROLE_ID)
      )?.statusIds?.[0]
      const readingStatus = item
        .defaultStatusAssignees()
        .find(sa => sa.roleId === READER_ROLE_ID)?.statusId
      const userAssignee = item
        .statusAssigneesWithoutDefault()
        .find(sa => sa.roleId === SENDER_ROLE_ID)
      // Will have the + only if has not sender assigned
      if (readingStatusToShow === readingStatus && !userAssignee)
        canAssignByStatus = true
    } else {
      const NJRoleStatuses = NJConditions.find(
        c => c.rolesToApply?.includes(column.roleId!)
      )?.statusIds
      const assigneesInThatRole = item
        .defaultStatusAssignees()
        .find(sa => sa.roleId === column.roleId)
      const userAssigneSpesificRole = item
        .statusAssigneesWithoutDefault()
        .find(sa => sa.roleId === column.roleId)
      if (
        assigneesInThatRole?.statusId &&
        NJRoleStatuses?.includes(assigneesInThatRole?.statusId) &&
        !userAssigneSpesificRole &&
        (column.roleId === READER_ROLE_ID ||
          (item.inboundInAssignee && column.roleId !== READER_ROLE_ID))
      ) {
        canAssignByStatus = true
      }
    }
  }

  // in all files view in NJ - users can only remove assignees
  if (
    item.boardId === NJ_BOARD_ID &&
    !view?.isAssignedOnly &&
    currentMember?.roleId === RoleId.User &&
    !assignedNJView
  ) {
    canOnlyRemoveAssignee = true
  }

  //all boards in boardCondition (DT2, POST-CLOSING)
  assignBoardRole = !!(
    currentBoard?.id && boardCondition.boardIds?.includes(currentBoard?.id)
  )

  const subRoles = column?.subColumns?.map((c: any) => c.roleId)
  const canSelfAssign = column.role?.selfAssign && item.inboundInAssignee

  const getStatusAssignees = () =>
    item.statusAssigneesWithoutDefault().map(
      sa =>
        new StatusAssignee({
          ...sa,
          user: sa.user ?? users.find(u => u.id === sa.userId)
        } as StatusAssignee)
    )

  var relevantDataList = canSelfAssign
    ? []
    : onlySpecificRole
      ? getStatusAssignees().filter(sa => sa.roleId === column.roleId)
      : getStatusAssignees()

  if (view?.isAssignedOnly || assignBoardRole || assignedNJView) {
    const releventRoleIds = [column.roleId, ...subRoles]
    relevantDataList = relevantDataList.filter(sa =>
      releventRoleIds.includes(sa.roleId)
    )
  }

  if (showOnlySpecificStatuses.viewIds?.includes(view!.id)) {
    relevantDataList = relevantDataList.filter(
      sa =>
        sa.statusId &&
        showOnlySpecificStatuses.statusIds?.includes(sa.statusId!)
    )
  }

  let usersList = moveObjectToStart<StatusAssignee>(
    relevantDataList,
    relevantDataList.find(d => d.roleId === currentBoard?.defaultRole?.id)
  )
    .map(s => s.user)
    .filter(u => u !== undefined) as User[]

  usersList.forEach(u => (u.isHere = users.find(us => us.id === u?.id)?.isHere))

  const canAssignSubRoles = !!(
    subRoles.length > 0 && usersList.find(u => u.id === user?.id)
  )

  const hasRoleAnyoneCanAssign = [column.roleId, ...subRoles].some(
    r => condition.rolesToApply?.includes(r)
  )

  if (hasRoleAnyoneCanAssign || canAssignSubRoles || canSelfAssign) {
    commercialCanAssign = true
  }

  let canAssign =
    onlySpecificRole === false &&
    (commercialCanAssign ||
      (!view?.isAssignedOnly && currentMember?.roleId === RoleId.Manager) ||
      canAssignByStatus ||
      assignBoardRole ||
      canOnlyRemoveAssignee ||
      assignedNJView)

  if (currentBoard?.id === NY_WORK_BOARD_ID || currentTab === Tabs.Search)
    canAssign = false

  const isMultiAssign = conditions[
    FunctionUsed.isMultiAssign
  ]?.[0].boardIds?.includes(currentBoard?.id ?? 0)

  return (
    <>
      {canAssign && (
        <IconButton onClick={() => setOpenDialog(true)}>
          <AddIcon />
        </IconButton>
      )}
      <CuiGroupAvatars
        max={4}
        size={24}
        users={usersList}
        borderedUserId={
          relevantDataList.find(
            d => d.user && d.roleId === board?.defaultRole?.id
          )?.user?.id
        }
      />
      {openDialog && (
        <AssigneeDialog
          item={item}
          statusAssigneesList={relevantDataList}
          onClose={onClose}
          parentRole={canAssignSubRoles ? column.roleId : undefined}
          initialRoleId={
            (assignBoardRole ||
              canSelfAssign ||
              canAssignByStatus ||
              assignedNJView) &&
            column.roleId
              ? column.roleId
              : null
          }
          initialUserId={canSelfAssign && user ? user?.id : null}
          condition={condition}
          boardCondition={boardCondition}
          canOnlyRemoveAssignee={canOnlyRemoveAssignee}
          isMultiAssign={isMultiAssign}
        />
      )}
    </>
  )
})

export default AssigneeCell

import {Stack, Divider} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {NAV} from 'src/config'
import {hideScrollbarX} from 'src/utils/cssStyles'
import DoubleArrowLeft from 'src/images/double_arrow_left.svg?react'
import DoubleArrowRight from 'src/images/double_arrow_right.svg?react'
import IconButton from '@mui/material/IconButton'
import MenuList from 'src/components/itemView/MenuList'
import MenuItemData from 'src/components/itemView/MenuItemData'
import MenuStatusTimeline from 'src/components/itemView/MenuStatusTimeline'
import {useMainStore} from 'src/context/Main'

interface ExpandMenuButtonProps {
  isOpen: boolean
}

const Menu = observer(() => {
  const mainStore = useMainStore()
  const {isUnknownFN, setOpemMenu, isOpenMenu} = mainStore.itemStore
  const ExpandMenuButton = ({isOpen}: ExpandMenuButtonProps) => {
    return (
      <Stack>
        <IconButton
          onClick={() => setOpemMenu(!isOpen)}
          sx={{
            width: isOpen ? '20%' : '100%',
            p: 0,
            top: 10
          }}
        >
          {isOpenMenu ? <DoubleArrowLeft /> : <DoubleArrowRight />}
        </IconButton>{' '}
        <Divider sx={{mt: 1, borderStyle: 'dashed'}} />
      </Stack>
    )
  }

  return (
    <Stack
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: isOpenMenu ? NAV.W_ITEM : NAV.W_ITEM_CLOSED
      }}
    >
      <Stack
        sx={{
          height: '100%',
          background: theme => theme.palette.grey[800],
          pb: 1,
          width: isOpenMenu ? NAV.W_ITEM : NAV.W_ITEM_CLOSED,
          borderRight: theme => `dashed 1px ${theme.palette.divider}`,
          ...hideScrollbarX
        }}
      >
        {isOpenMenu ? (
          <Stack height="100vh">
            <ExpandMenuButton isOpen={true} />
            <Stack height="100%" justifyContent={'space-between'}>
              <MenuList />
              {!isUnknownFN && (
                <Stack spacing={2} pb={2} alignItems={'center'}>
                  <MenuItemData />
                  <MenuStatusTimeline />
                </Stack>
              )}
            </Stack>
          </Stack>
        ) : (
          <ExpandMenuButton isOpen={false} />
        )}
      </Stack>
    </Stack>
  )
})

export default Menu

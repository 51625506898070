import {
  OnAssignClickDetails,
  PermissionType,
  ConversationAssignEvent,
  ConversationControl,
  ConversationOnAssignClickEvent,
  DocumentPreviewEvent,
  DocumentPreviewDetails,
  ConversationPermissionEvent,
  ConversationEntityChangeEvent
} from '@madisoncres/title-general-package'
import {observer} from 'mobx-react-lite'
import {Suspense, useEffect, useState} from 'react'
import UserMenuList from 'src/components/user/UserMenuList'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import {Control} from 'src/entities/PermissionData'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import {lazy} from '@loadable/component'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
const ConversationList = lazy<{
  conversationTypeId: number
  viewId: string
}>(() => import('cbChat/ConversationList'))

const QuestionList = observer(({viewId, item}: QuestionTabProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {getPermissionTypeByRoles} = mainStore.permissionStore
  const {user, roles} = mainStore.loginStore

  useEffect(() => {
    ConversationEntityChangeEvent.dispatch({
      viewId: `${ViewType[ViewType.Question]}Tab`,
      entityId: item?.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.question,
      additionalInfo: {
        fileNumber: item?.order?.fileNumber,
        boardId: currentBoard?.id
      }
    })
  }, [item?.id, item?.order?.fileNumber, currentBoard?.id])

  useEffect(() => {
    const rolesId = item.statusAssignees
      .filter(a => a.userId === user?.id)
      .map(a => a.roleId) as number[]
    const assignPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.Assign
    )
    const seeAllPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.SeeAll
    )
    const filterPermissionType = getPermissionTypeByRoles(
      viewId,
      rolesId,
      undefined,
      Control.Filter
    )
    const draftPermissionType = getPermissionTypeByRoles(
      viewId,
      roles?.map(role => role as number),
      undefined,
      Control.Draft
    )

    const permissions = {} as Record<ConversationControl, PermissionType>
    Object.keys(ConversationControl).forEach(key => {
      const enumKey = Number(key) as ConversationControl
      if (!isNaN(enumKey)) {
        permissions[enumKey] = PermissionType.Readonly
      }
    })

    permissions[ConversationControl.Filter] = filterPermissionType
    permissions[ConversationControl.SeeAll] = seeAllPermissionType
    permissions[ConversationControl.Assign] = assignPermissionType
    permissions[ConversationControl.Draft] = draftPermissionType

    ConversationPermissionEvent.dispatch({
      entityId: item.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.question,
      permissions: permissions
    })
  }, [viewId, user, item, getPermissionTypeByRoles, roles])

  return (
    <CuiErrorBoundary>
      <Suspense fallback={<></>}>
        <ConversationList
          conversationTypeId={config.conversationType.question}
          viewId={ViewType[ViewType.Question]}
        />
      </Suspense>
    </CuiErrorBoundary>
  )
})

interface QuestionTabProps {
  viewId: number
  item: Item
}

const QuestionTab = observer(({viewId, item}: QuestionTabProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
  const [currentAssignee, setCurrentAssignee] = useState<number>()

  const onClose = () => {
    setAnchorEl(undefined)
  }

  const onCancel = () => {
    ConversationAssignEvent.dispatch(undefined)
    onClose()
  }

  const onAssigned = (userId?: number, comment = '') => {
    ConversationAssignEvent.dispatch(
      userId
        ? {
            userId,
            entityId: item.id,
            entityTypeId: config.itemEntityTypeId,
            conversationTypeId: config.conversationType.question,
            comment: comment
          }
        : undefined
    )
    onClose()
  }

  useEffect(() => {
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [])

  useEffect(() => {
    const callback = (e: CustomEvent<OnAssignClickDetails>) => {
      const details = e.detail
      if (
        details.entityId === item.id &&
        details.entityTypeId === config.itemEntityTypeId &&
        details.conversationTypeId === config.conversationType.question
      ) {
        setAnchorEl(details.anchor)
        setCurrentAssignee(details.currentAssigneeId)
      }
    }
    ConversationOnAssignClickEvent.subscribe(callback)

    return () => ConversationOnAssignClickEvent.unsubscribe(callback)
  }, [item.id])

  return (
    <>
      <QuestionList viewId={viewId} item={item} />
      {anchorEl && (
        <UserMenuList
          ignoreUsers={currentAssignee ? [currentAssignee] : undefined}
          anchorEl={anchorEl}
          onChoose={onAssigned}
          onCancel={onCancel}
        />
      )}
    </>
  )
})

export default QuestionTab

import MocBoard, {ItemFileNumber} from 'src/entities/MocBoard'
import {MainStore} from 'src/store/MainStore'
import {makeAutoObservable, runInAction} from 'mobx'
import config from 'src/config'

export class MocBoardStore {
  isChecking: boolean = false

  mocBoards: MocBoard[] = []

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
  }

  getMocBoard = (boardId: number) => {
    return this.mocBoards.find(b => b.id === boardId)!
  }

  setBoardsItemFileNumbers = (boardids: number[]) => {
    boardids.forEach(b => this.getMocBoardItems(b))
  }

  setIsChecking = (isChecking: boolean) => {
    this.isChecking = isChecking
  }

  deleteItem = (itemId: number) => {
    runInAction(() => {
      const mocBoard = this.mocBoards.find(b =>
        b.itemFileNumbers.find(i => i.id === itemId)
      )
      const indexToRemove = mocBoard?.itemFileNumbers.findIndex(
        i => i.id === itemId
      )
      if (indexToRemove && indexToRemove !== -1)
        mocBoard?.itemFileNumbers.splice(indexToRemove, 1)
    })
  }

  addOrUpdateItem = (itemId: number, boardId: number, fileNumber: string) => {
    const mocBoard = this.mocBoards.find(b => b.id === boardId)
    const itemFileNumber = mocBoard?.itemFileNumbers.find(i => i.id === itemId)
    if (!itemFileNumber) {
      runInAction(() => {
        mocBoard?.itemFileNumbers.unshift(
          new ItemFileNumber({
            id: itemId,
            fileNumber: fileNumber
          } as ItemFileNumber)
        )
      })
    } else if (itemFileNumber.fileNumber !== fileNumber)
      itemFileNumber.setFilenumber(fileNumber)
  }

  getMocBoardItems = (boardId: number) => {
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${boardId}/MocBoard`)
      .then(res => {
        return res.json()
      })
      .then((data: MocBoard) => {
        if (data) {
          this.mocBoards.push(new MocBoard(data))
        }
      })
  }

  validateFileNumber = async (boardId: number, fileNumber: string) => {
    this.setIsChecking(true)
    return this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Items/${boardId}/IsExistInSelectOrSutton/${fileNumber}`
      )
      .then(res => res.json())
      .then((data: boolean) => {
        if (!data)
          this.owner.setErrorMessage(
            `File number doesn't exist in Select ${
              this.owner.boardStore.boards.find(b => b.id === boardId)
                ?.isIncludeSuttonItems
                ? ' or Sutton'
                : ''
            }.`
          )
        return data
      })
      .finally(() => this.setIsChecking(false))
  }

  addItems = async (
    boardId: number,
    fileNumbers: string[],
    state?: string,
    address?: string
  ) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/addItems/${boardId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fileNumbers: fileNumbers,
            state: state,
            address: address
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to add items by file number.')
      }
      this.mocBoards
        .find(b => b.id === boardId)
        ?.itemFileNumbers.unshift(
          ...(data as ItemFileNumber[]).map(
            i =>
              new ItemFileNumber({
                id: i.id,
                fileNumber: i.fileNumber
              } as ItemFileNumber)
          )
        )
      return data as ItemFileNumber[]
    } catch (error) {
      this.owner.setErrorMessage('Failed to add items, please try again.')
    }
  }
}

import {Stack, Typography} from '@mui/material'
import Column, {ColumnType} from 'src/entities/Column'
import View from 'src/entities/View'

import {observer} from 'mobx-react-lite'
import {fDate} from 'src/utils/formatTime'
import React from 'react'
import Order from 'src/entities/Order'
import CuiOverflowTypography from 'src/components/custom/CuiOverflowTypography'

export interface MenuOrderViewProps {
  view?: View
  currentOrder?: Order
}

const getValue = (value: string, type: ColumnType, format?: string) => {
  switch (type) {
    case ColumnType.Date: {
      return fDate(value, format)
    }
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.MultilineText:
    default:
      return value !== undefined ? value : ''
    case ColumnType.Dollar:
      //return value ? value.split(',')
      //.map(item => item.trim()) : []
      if (typeof value === 'string') {
        return value
          ? value
              .split(',')
              .map(num => {
                let formatted = parseFloat(num).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
                return `$ ${formatted}`
              })
              .join(', ')
          : []
      } else {
        return value ? `$ ${Number(value).toLocaleString('en-US')}` : []
      }
  }
}

const render = (column: Column, value: any) => {
  const formattedValue = getValue(value, column.type, column.format)

  return (
    <Stack
      width={'40%'}
      m={0.5}
      justifyContent={'space-between'}
      key={column.id}
    >
      <Typography sx={{fontSize: 10, fontWeight: 700, color: '#919EAB'}}>
        {column.name.toUpperCase()}
      </Typography>
      <CuiOverflowTypography sx={{fontSize: 12, fontWeight: 400}}>
        {formattedValue || '----'}
      </CuiOverflowTypography>
    </Stack>
  )
}

const MenuOrderView = observer(({view, currentOrder}: MenuOrderViewProps) => {
  return (
    <Stack direction="row" alignItems="right">
      <Stack useFlexGap direction="row" spacing={1} sx={{flexWrap: 'wrap'}}>
        {view?.columns!.map(c => (
          <React.Fragment key={c.id}>
            {render(c, currentOrder?.[c.dbColumn?.name as keyof Order])}
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  )
})

export default MenuOrderView

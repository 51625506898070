import {
  Stack,
  Tab,
  Tabs,
  Typography,
  FormControlLabel,
  Switch,
  IconButton,
  Button
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'
import DayWorkButton from 'src/components/workspace/DayWorkButton'
import CloseIcon from 'src/images/close.svg?react'
import {UsingType, ViewType} from 'src/entities/View'
import {Control, PermissionType} from 'src/entities/PermissionData'
import BackHandOutlinedIcon from '@mui/icons-material/BackHandOutlined'

export const tabView = (
  labelInfo: number | undefined,
  labelColor: LabelColor,
  text: string
) => {
  return (
    <Stack direction="row" spacing={1}>
      {labelInfo && <Label color={labelColor}>{labelInfo}</Label>}

      <Typography variant="body2">{text}</Typography>
    </Stack>
  )
}

const BoardHeader = observer(() => {
  const mainStore = useMainStore()
  const {getPermissionType} = mainStore.permissionStore
  const {
    currentBoard,
    setWorkflowUser,
    getViewIdByType,
    getViewTabs,
    currentMember,
    setMemberStatus
  } = mainStore.boardStore
  const {currentView, currentItemView, itemViews, onTabClick, onCloseItem} =
    mainStore.viewStore
  const {user} = mainStore.loginStore

  const showWorkflowControl = () => {
    if (!currentBoard?.views.length) return false

    const wfBtnPermissionType = getPermissionType(
      getViewIdByType(ViewType.Header, UsingType.App),
      undefined,
      undefined,
      Control.WfUser
    )

    return wfBtnPermissionType
  }

  const wfBtnPermissionType = showWorkflowControl()

  return (
    <Stack
      spacing={2}
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'background.paper',
        zIndex: 2
      }}
    >
      <Stack direction="row" justifyContent="end">
        {/* <Typography>Good Morning</Typography> */}
        {currentBoard?.workflowUser &&
          currentBoard?.workflowUser.id !== user?.id && (
            <Typography color="primary" fontWeight="bold" fontSize="13px">
              {currentBoard.workflowUser.fullName} | Workflow
            </Typography>
          )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{width: '100%', bgcolor: 'background.neutral'}}
        spacing={1}
      >
        <Tabs
          value={
            currentItemView?.id
              ? `${currentItemView?.id}-item`
              : currentView?.id
                ? `${currentView?.id}-view`
                : false
          }
          onChange={(_: React.ChangeEvent<{}>, newValue: string) => {
            onTabClick(newValue)
          }}
          sx={{pl: 2}}
        >
          {getViewTabs()?.map(v => (
            <Tab
              value={`${v.id}-view`}
              key={v.id}
              label={tabView(v.count, v.color as LabelColor, v.name)}
            />
          ))}
          {itemViews.map(iv => (
            <Tab
              value={`${iv.id}-item`}
              key={iv.id}
              label={
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Typography>{iv.fileNumber}</Typography>
                  <IconButton
                    onClick={e => {
                      onCloseItem(iv)
                      e.stopPropagation()
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Stack>
              }
            />
          ))}
        </Tabs>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
        >
          {wfBtnPermissionType !== PermissionType.Invisible && (
            <FormControlLabel
              disabled={wfBtnPermissionType === PermissionType.Readonly}
              checked={currentBoard?.workflowUser?.id === user?.id}
              onChange={(_, c) => {
                setWorkflowUser(c)
              }}
              control={<Switch />}
              label="Workflow"
            />
          )}
          {currentBoard?.isUpdateMemberDayWorker && <DayWorkButton />}
          <Button
            startIcon={<BackHandOutlinedIcon />}
            sx={{
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'transparent'
              },
              color: currentMember?.isAvailable ? 'primary' : 'grey.600'
            }}
            size="medium"
            onClick={() => setMemberStatus(!currentMember?.isAvailable)}
          >
            I am available
          </Button>
        </Stack>
      </Stack>
    </Stack>
  )
})

export default BoardHeader

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {FavoriteFilterSetting} from 'src/entities/UserSettings'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  GridCallbackDetails,
  GridFilterForm,
  GridFilterItem,
  GridFilterModel
} from '@mui/x-data-grid-pro'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {useState} from 'react'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'

interface FavoriteFiltersProps {
  onFilterModelChange: (
    newValue: GridFilterModel,
    details: GridCallbackDetails<'filter'> | undefined
  ) => void
  onSaveFavoriteFilters: (
    favoriteFilters: FavoriteFilterSetting[],
    name?: string
  ) => void
  favoriteFilters: FavoriteFilterSetting[]
}

const FavoriteFilters = observer(
  ({
    onFilterModelChange,
    onSaveFavoriteFilters,
    favoriteFilters
  }: FavoriteFiltersProps) => {
    const onRemoveFavoriteFilter = (nameToRemove: string) => {
      onSaveFavoriteFilters(
        favoriteFilters.filter(f => f.name !== nameToRemove)
      )
    }

    const onEditFavoriteFilter = (name: string, newItem: GridFilterItem) => {
      const favoriteFilterToEdit = favoriteFilters.find(
        filter => filter.name === name
      )
      const filterItemIndex =
        favoriteFilterToEdit?.filterModel?.items?.findIndex(
          item => item.id === newItem.id
        )
      if (filterItemIndex && filterItemIndex !== -1) {
        favoriteFilterToEdit!.filterModel!.items![filterItemIndex!] = newItem
        onSaveFavoriteFilters(favoriteFilters)
      }
    }

    const onRemoveFilterFromFavorite = (
      name: string,
      deletedId: string | number | undefined
    ) => {
      const favoriteFilterToEdit = favoriteFilters.find(
        filter => filter.name === name
      )
      const filterItemIndex =
        favoriteFilterToEdit?.filterModel?.items?.findIndex(
          item => item.id === deletedId
        )
      if (filterItemIndex && filterItemIndex !== -1) {
        favoriteFilterToEdit!.filterModel!.items!.splice(filterItemIndex, 1)
        onSaveFavoriteFilters(favoriteFilters)
      }
    }

    return (
      <>
        {favoriteFilters.map((f: FavoriteFilterSetting) => (
          <FavoriteFilter
            key={f.name}
            favoriteFilter={f}
            onFilterModelChange={onFilterModelChange}
            onRemoveFavoriteFilter={onRemoveFavoriteFilter}
            onEditFavoriteFilter={onEditFavoriteFilter}
            onRemoveFilterFromFavorite={onRemoveFilterFromFavorite}
          />
        ))}
      </>
    )
  }
)

interface FavoriteFilterProps {
  favoriteFilter: FavoriteFilterSetting
  onFilterModelChange: (
    newValue: GridFilterModel,
    details: GridCallbackDetails<'filter'> | undefined
  ) => void
  onRemoveFavoriteFilter: (name: string) => void
  onEditFavoriteFilter: (name: string, newFilterItem: GridFilterItem) => void
  onRemoveFilterFromFavorite: (
    name: string,
    deletedId: string | number | undefined
  ) => void
}

export const FavoriteFilter = observer(
  ({
    favoriteFilter,
    onFilterModelChange,
    onRemoveFavoriteFilter,
    onEditFavoriteFilter,
    onRemoveFilterFromFavorite
  }: FavoriteFilterProps) => {
    const [isDelete, setIsDelete] = useState(false)

    return (
      <>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{flexDirection: 'row-reverse'}}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                ml: 1
              }}
            >
              {favoriteFilter.name}
              <Stack
                sx={{ml: 3}}
                direction="row"
                justifyItems={'center'}
                justifyContent={'center'}
              >
                <Tooltip title={'Apply'}>
                  <IconButton
                    sx={{color: 'grey'}}
                    size="small"
                    onClick={e => {
                      e.stopPropagation()
                      onFilterModelChange(
                        favoriteFilter.filterModel!,
                        undefined
                      )
                    }}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={'Remove from favorites'}>
                  <IconButton
                    sx={{color: 'grey'}}
                    size="small"
                    onClick={e => {
                      e.stopPropagation()
                      setIsDelete(true)
                    }}
                  >
                    {' '}
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {favoriteFilter.filterModel?.items.map((i: GridFilterItem) => (
              <GridFilterForm
                key={i.id}
                item={i}
                hasMultipleFilters={false}
                applyFilterChanges={i =>
                  onEditFavoriteFilter(favoriteFilter.name, i)
                }
                applyMultiFilterOperatorChanges={() => {}}
                deleteFilter={i =>
                  onRemoveFilterFromFavorite(favoriteFilter.name, i.id)
                }
              />
            ))}
          </AccordionDetails>
        </Accordion>
        {isDelete && (
          <CuiAlertDialog
            isFullWidth
            showMessage={false}
            title="Delete Favorite Filters"
            okButtonText="Delete"
            showCancel
            open
            body={'Are you sure you want to delete this saved filters?'}
            okClick={() => {
              onRemoveFavoriteFilter(favoriteFilter.name)
              setIsDelete(false)
            }}
            close={() => {
              setIsDelete(false)
            }}
          />
        )}
      </>
    )
  }
)

export default FavoriteFilters

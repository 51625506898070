import {Status} from '@madisoncres/title-general-package/src/stores/SignalStore'
import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import {MainStore} from 'src/store/MainStore'
import Condition from 'src/entities/Condition'
import {SignalrDisconnectionEvent} from '@madisoncres/title-general-package'

export enum ConnectingOnlineStatus {
  None,
  Connected,
  ConnectedSuccessed
}
export default class SharedStore {
  currentTab?: Tabs

  conditions: Record<string, Condition[]> = {}

  isIncorrectVersion: boolean = false

  isNeedToRefresh: boolean = false

  currentVersion: string = '-1'

  updateSystem?: Date

  connectedOnlineStatus: ConnectingOnlineStatus = ConnectingOnlineStatus.None

  setConditions = (conditions: any[]) => {
    conditions.forEach(c => {
      const {functionUsed, ...otherProps} = c
      if (this.conditions[functionUsed]) {
        this.conditions[functionUsed].push(new Condition(otherProps))
      } else {
        this.conditions[functionUsed] = [new Condition(otherProps)]
      }
    })
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) {
          this.getLastVersion()
          this.getConditions()
        }
      }
    )

    reaction(
      () => this.owner.signalrStore.status,
      status => {
        if (status === Status.IncorrectVersion) {
          this.setIsIncorrectVersion(true)
          this.owner.signalrStore.stopConnection(true)
          SignalrDisconnectionEvent.dispatch({isDisconnection: true})
        }
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setCurrentTab = (tab: Tabs) => {
    this.currentTab = tab
  }

  getConditions = async () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Conditions`)
      .then(res => {
        return res.json()
      })
      .then((data: any[]) => {
        if (data) {
          this.setConditions(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setCurrentVersion = (version: string) => {
    this.currentVersion = version
  }

  setIsIncorrectVersion = (isIncorrectVersion: boolean) => {
    this.isIncorrectVersion = isIncorrectVersion
  }

  setConnectedOnlineStatus = (status: ConnectingOnlineStatus) => {
    this.connectedOnlineStatus = status
  }

  setIsNeedToRefresh = (isNeedToRefresh: boolean) => {
    this.isNeedToRefresh = isNeedToRefresh
    if (isNeedToRefresh) {
      this.owner.signalrStore.stopConnection(true)
      SignalrDisconnectionEvent.dispatch({isDisconnection: true})
    }
  }

  getLastVersion = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/version`, {
        headers: {
          Version: '-1'
        }
      })
      .then(res => {
        return res.json()
      })
      .then((data: string) => {
        if (data) {
          this.setIsIncorrectVersion(false)
          this.setCurrentVersion(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setUpdateSystem = (updateSystem: Date | undefined) => {
    this.updateSystem = updateSystem
  }

  listener = () => {
    this.owner.signalrStore.on('SystemUpdate', (time: Date) => {
      this.setUpdateSystem(new Date(time))
    })
  }
}

export enum Tabs {
  Workflow = 1,
  Reports,
  Search
}

export enum FunctionUsed {
  ApplyMyAssignFilterCondition = 1,
  AssigneeCell,
  FilterViewFiles,
  EveryoneCanAssignInBoard,
  FilterAssigneeFilesByRole,
  InboundGroupItems,
  CombinedInboundGroupItems,
  MoveToAvaibleGroup,
  FilterReport = 10,
  InboundItemsNJ,
  ShowBAFiles,
  TrackingViewCondition,
  CanSelfAssignNJ,
  RequierdStatusBeforeSendProofer,
  InvoiceViewCondition,
  QuestionNJViewFiles,
  AssignedColumnInNJViews,
  ShowOnlySpecificOrderCopmanyFiles,
  ToAssigneStatusId = 22,
  ShowOnlySpecificStatusesInAssigneeColumn,
  MyNotHandledFiles,
  NYPublicFolderTypeIsDayWork,
  AnsweredLWStatusId,
  isMultiAssign,
  isAllowedUnknowFN = 31,
  DayWorkAndWaitingForAssignment = 32,
  SuttonEditableColumns,
  ShowOnlySpecificRoleInAssigneeColumn = 34,
  WorkingOnItStatusId,
  DoneStatusId,
  SortGroup = 37
}

export enum Microfrontend {
  Chat
}

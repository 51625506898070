import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useEffect, useMemo} from 'react'
import DisplayView from 'src/components/itemTable/DisplayView'
import CommonHeader from 'src/components/layout/CommonHeader'
import ReportToolbar from 'src/components/report/ReportToolbar'
import {useMainStore} from 'src/context/Main'
import {Tabs} from 'src/store/SharedStore'

const ReportView = observer(function ReportView() {
  const mainStore = useMainStore()
  const {
    selectedBoard,
    view,
    getGroupItems,
    collapseGroups,
    setAllCollapseGroup,
    setCollapseGroup,
    setSelectedBoard,
    setGroupGetDataAsCSV
  } = mainStore.reportStore
  const {boards, currentBoard} = mainStore.boardStore
  const {setCurrentTab} = mainStore.sharedStore

  useEffect(() => {
    if (!selectedBoard)
      setSelectedBoard(currentBoard !== undefined ? currentBoard : boards?.[0])
  }, [selectedBoard, boards, currentBoard, setSelectedBoard])

  useEffect(() => {
    setCurrentTab(Tabs.Reports)
  }, [setCurrentTab])

  const displayViewProps = useMemo(
    () => ({
      currentBoard: selectedBoard,
      getGroupItems,
      Toolbar: ReportToolbar,
      currentView: view,
      collapseGroups,
      setAllCollapseGroup,
      setCollapseGroup,
      hiddenColumns: view?.columns
        ?.filter(c => !c.sort)
        .map(c => c.id.toString()),
      isExternalFilter: true,
      setGroupGridpiRef: setGroupGetDataAsCSV
    }),
    [
      selectedBoard,
      getGroupItems,
      view,
      collapseGroups,
      setAllCollapseGroup,
      setCollapseGroup,
      setGroupGetDataAsCSV
    ]
  )

  return (
    <Stack flex={1} height="100vh" width="1px" overflow={'hidden'}>
      <CommonHeader>
        <Stack>
          <Typography variant="h6">Reports</Typography>
        </Stack>
      </CommonHeader>
      <Stack width="100%" pb={3} px={1} overflow="auto">
        {selectedBoard && view && (
          <DisplayView key={view.id} {...displayViewProps} />
        )}
      </Stack>
    </Stack>
  )
})

export default ReportView

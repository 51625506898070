import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material'
import UpdateSystem from 'src/images/illustration_coming_soon.svg?react'
import CloseIcon from '@mui/icons-material/Close'

export interface SystemUpdateProps {
  close: () => void
  disableBackdropClick?: boolean
  time: Date
}

export default function SystemUpdate({
  close,
  disableBackdropClick,
  time
}: SystemUpdateProps) {
  const onClose = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      close()
    }
  }

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Stack alignItems="center" spacing={2}>
          <IconButton onClick={close} sx={{alignSelf: 'end'}}>
            <CloseIcon />
          </IconButton>
          <UpdateSystem />
          <Typography variant="h5">System Update</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems="center" spacing={1} m={2}>
          <Typography fontSize="16px" fontWeight="400">
            System will update automatically at: {time.getHours()}:
            {time.getMinutes()}
          </Typography>
          <Typography fontSize="16px" fontWeight="400">
            You won&apos;t be able to work on the system
          </Typography>
          <Typography fontSize="16px" fontWeight="400">
            for approximately 15 minutes after the update begins.
          </Typography>
          <Typography fontSize="16px" fontWeight="700">
            Please save your work.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

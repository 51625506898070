import {ReactElement} from 'react'
import {LabelColor} from 'src/components/label'
import Label from 'src/components/label/Label'
import {getHighlightedText} from 'src/components/view/columnTypes/viewHelper'

export interface LabelProps {
  value: number
  text: string
  color: LabelColor
}

export interface LabelColProps {
  text: string
  color: LabelColor
  pointer?: boolean
  autoWidth?: boolean
  endIcon?: ReactElement
  disableTextTransform?: boolean
  thinPadding?: boolean
  searchQuery?: string | null
}

export default function LabelCol({
  color,
  text,
  pointer,
  endIcon,
  disableTextTransform,
  thinPadding,
  searchQuery = null
}: LabelColProps) {
  return (
    <Label
      color={color}
      sx={{
        cursor: pointer ? 'pointer' : undefined,
        textTransform: disableTextTransform ? 'none' : undefined,
        paddingX: thinPadding ? 0.25 : 1
      }}
      width="100%"
      endIcon={endIcon}
    >
      {getHighlightedText(text, searchQuery)}
    </Label>
  )
}

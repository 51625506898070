import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import Column from 'src/entities/Column'
import {SelectItemData} from 'src/entities/ItemData'
import {ViewType} from 'src/entities/View'
import {BoardStore} from './BoardStore'
import {LoginStore} from './LoginStore'
import {Checklist} from 'src/entities/Checklist'
import {Note} from 'src/entities/Note'
import {ViewStore} from './ViewStore'
import {ItemStore} from './ItemStore'
import Item from '../entities/Item'

export class SelectStore {
  isChecklistView: boolean

  selectColumns?: Column[]

  selectFavoriteColumns?: Column[]

  currentChecklist?: Checklist

  checklistData?: Checklist[]

  notes?: Note[]

  constructor(
    readonly boardStore: BoardStore,
    readonly loginStore: LoginStore,
    readonly itemStore: ItemStore,
    readonly viewStore: ViewStore
  ) {
    this.isChecklistView = false
    makeAutoObservable(this)

    reaction(
      () => this.boardStore.currentBoard?.views,
      () => {
        this.selectColumns = this.boardStore.currentBoard?.views.find(
          v => v.viewType === ViewType.Select
        )?.columns
      }
    )

    reaction(
      () => this.boardStore.currentBoard?.views,
      () => {
        this.selectFavoriteColumns = this.boardStore.currentBoard?.views.find(
          v => v.viewType === ViewType.SelectFavorite
        )?.columns
      }
    )
  }

  get data() {
    const currentItem = this.viewStore.currentItemView
    return this.selectColumns?.map(i => {
      const value = currentItem?.itemData.find(x => x.columnId === i.id)
      return {
        ...value,
        isFavorite: this.selectFavoriteColumns?.find(c => c.id === i.id)
          ? true
          : false,
        direction: i.direction,
        columnName: i.name,
        columnType: i.type
      } as SelectItemData
    })
  }

  setCurrentChecklist = (checklist: Checklist | undefined) => {
    this.currentChecklist = checklist
  }

  setFavorite = async (columnId: number) => {
    const selectFavoriteView = this.boardStore.currentBoard?.views.find(
      v => v.viewType === ViewType.SelectFavorite
    )
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiUrl}/viewColumn`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ViewId: selectFavoriteView?.id,
            ColumnId: columnId,
            Sort: 0,
            Width: 100,
            Direction: 2
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to set favorite column')
      }

      this.selectFavoriteColumns?.push(
        this.selectColumns?.find(c => c.id === columnId)!
      )
      //this.owner.boardStore.setFavoriteColumns(
      // this.selectFavoriteColumns
      // )
    } catch (error) {
      //todo message error to user???
    }
  }

  setUnfavorite = async (columnId: number) => {
    const selectFavoriteView = this.boardStore.currentBoard?.views.find(
      v => v.viewType === ViewType.SelectFavorite
    )
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiUrl}/viewColumn/${columnId}/${selectFavoriteView?.id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to set favorite column')
      }
      this.selectFavoriteColumns?.splice(
        this.selectFavoriteColumns.findIndex(c => c.id === columnId),
        1
      )
    } catch (error) {
      //todo message error to user???
    }
  }

  createNote = async (item: Item, text: string) => {
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiUrl}/orders/${item.id}/note`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            orderNumber: item.order?.fileNumber,
            text: text
          })
        }
      )
      return await response.json()
    } catch {
      return undefined
    }
  }

  setIsChecklistView = () => {
    this.isChecklistView = !this.isChecklistView
  }

  setChecklistData = async () => {
    try {
      const url =
        config.SelectAccessor.basePath + config.SelectAccessor.getTasks
      const response = await fetch(
        `${url}&orderNumber=${this.itemStore.currentItem?.order?.fileNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      this.checklistData = await response.json()
    } catch {
      console.log('failed to get tasks from select.')
    }
  }

  setNotes = async () => {
    try {
      const url =
        config.SelectAccessor.basePath + config.SelectAccessor.getNotes
      const response = await fetch(
        `${url}&orderNumber=${this.itemStore.currentItem?.order?.fileNumber}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      this.notes = await response.json()
    } catch {
      console.log('failed to get notes from select.')
    }
  }

  createNoteToCheklist = async (item: Item, text: string) => {
    try {
      const response = await this.loginStore.fetchWithUser(
        `${config.apiUrl}/orders/${item.id}/checklistNote`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            taskDescription: this.currentChecklist?.description,
            orderNumber: item.order?.fileNumber,
            text: text
          })
        }
      )
      return await response.json()
    } catch {
      return undefined
    }
  }
}

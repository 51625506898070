import {IconButton} from '@mui/material'
import {useNavigate, generatePath} from 'react-router-dom'
import Item from 'src/entities/Item'
import MailIcon from 'src/images/ic_mail.svg?react'
import MailBadgeIcon from 'src/images/mail_badge.svg?react'
import {useMainStore} from 'src/context/Main'
import {PATH_WORKFLOW} from 'src/routes/paths'
import DisabledMailIcon from 'src/images/ic_mail_disabled.svg?react'

export interface LinkToMailboxColProps {
  item: Item
}

export default function LinkToMailboxCol({item}: LinkToMailboxColProps) {
  const navigate = useNavigate()

  const {boards} = useMainStore().boardStore

  const hasPermissionToBoard = boards.findIndex(b => b.id === item.boardId) > -1

  const onClick = () => {
    const path = generatePath(PATH_WORKFLOW.items, {
      boardId: item.boardId,
      itemId: item.id
    })
    const search = 'view=MailBox'
    navigate({
      pathname: path,
      search
    })
  }

  return (
    <IconButton disabled={!hasPermissionToBoard} onClick={onClick}>
      {!hasPermissionToBoard ? (
        <DisabledMailIcon />
      ) : item.hasUnreadEmail ? (
        <MailBadgeIcon />
      ) : (
        <MailIcon />
      )}
    </IconButton>
  )
}

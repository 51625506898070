import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListSubheader,
  Stack
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import NoContent from 'src/components/custom/NoContent'
import NotificationItem from 'src/components/notification/NotificationItem'
import NotificationSkeleton from 'src/components/notification/NotificationSkeleton'
import {useMainStore} from 'src/context/Main'
import {Flag} from 'src/entities/Notification'
import {getEnumOption} from 'src/utils/enum'
import FlagWarning from 'src/images/flagWarning.svg?react'
import FlagSuccess from 'src/images/flagSuccess.svg?react'
import FlagError from 'src/images/flagError.svg?react'
import FlagWarningFill from 'src/images/flagWarningFill.svg?react'
import FlagSuccessFill from 'src/images/flagSuccessFill.svg?react'
import FlagErrorFill from 'src/images/flagErrorFill.svg?react'
import Read from 'src/images/mail.svg?react'
import FlagIcon from 'src/images/flag.svg?react'
import Trash from 'src/images/ic_trash.svg?react'
import {useState} from 'react'
import DeleteNotificationDialog from 'src/components/notification/DeleteNotification'

export const flags: Record<Flag, [JSX.Element, JSX.Element]> = {
  [Flag.Warning]: [
    <FlagWarning key="warning" />,
    <FlagWarningFill key="warning-fill" />
  ],
  [Flag.Success]: [
    <FlagSuccess key="success" />,
    <FlagSuccessFill key="success-fill" />
  ],
  [Flag.Error]: [<FlagError key="error" />, <FlagErrorFill key="error-fill" />]
}

const NotificationsBody = () => {
  const {
    todayNotifications,
    olderNotifications,
    isLoading,
    selectedNotifications,
    onSelectAll,
    updateFlags,
    updateRead
  } = useMainStore().notificationStore

  const hasTodayNotifications = todayNotifications.length > 0
  const hasOlderNotifications = olderNotifications.length > 0
  const [isDelete, setIsDelete] = useState(false)

  if (isLoading)
    return (
      <>
        {' '}
        <NotificationSkeleton />
        <NotificationSkeleton />
      </>
    )

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" pl={1.75} pr={1.5}>
        {todayNotifications.length + olderNotifications.length > 0 ? (
          <FormControlLabel
            sx={{
              fontSize: '16px'
            }}
            control={
              <Checkbox
                checked={
                  selectedNotifications.length ===
                  olderNotifications.length + todayNotifications.length
                }
                indeterminate={
                  selectedNotifications.length > 0 &&
                  selectedNotifications.length <
                    olderNotifications.length + todayNotifications.length
                }
                onChange={onSelectAll}
              />
            }
            label="Select all"
          />
        ) : (
          ''
        )}
        {selectedNotifications.length > 0 && (
          <Stack direction="row">
            <IconButton
              onClick={e => {
                e.stopPropagation()
                updateFlags(undefined)
              }}
            >
              <FlagIcon />
            </IconButton>
            {getEnumOption(Flag).map((f: Flag) => (
              <IconButton
                key={f}
                onClick={e => {
                  e.stopPropagation()
                  updateFlags(f)
                }}
              >
                {flags[f][0]}
              </IconButton>
            ))}
            <IconButton
              onClick={e => {
                e.stopPropagation()
                updateRead()
              }}
            >
              <Read />
            </IconButton>
            <IconButton
              onClick={e => {
                e.stopPropagation()
                setIsDelete(true)
              }}
            >
              <Trash />
            </IconButton>
          </Stack>
        )}
      </Stack>
      <Stack maxHeight="80vh" overflow="auto">
        {hasTodayNotifications && (
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{pt: 0.5, px: 1.5, typography: 'overline'}}
              >
                Today
              </ListSubheader>
            }
          >
            {todayNotifications.map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        )}
        {hasOlderNotifications && (
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{pt: 0.5, px: 1.5, typography: 'overline'}}
              >
                Older notifications
              </ListSubheader>
            }
          >
            {olderNotifications.map(notification => (
              <NotificationItem
                key={notification.id}
                notification={notification}
              />
            ))}
          </List>
        )}
        {!hasTodayNotifications && !hasOlderNotifications && (
          <NoContent text="No notifications" />
        )}
        {isDelete && (
          <DeleteNotificationDialog onClose={() => setIsDelete(false)} />
        )}
      </Stack>
    </Stack>
  )
}

export default observer(NotificationsBody)

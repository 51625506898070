import {Divider, IconButton, Stack, Typography} from '@mui/material'
import Column, {ColumnType, Direction} from 'src/entities/Column'
import View from 'src/entities/View'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import {OrderCompany, Property} from 'src/entities/Order'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import config from 'src/config'
import redirectWebsite from 'src/utils/redirectWebsite'
import GooleMapsIcon from 'src/images/ic_google_maps.svg?react'
import {useMainStore} from 'src/context/Main'
import WorkspaceIcon from 'src/images/ic_workspace.svg?react'
import SelectLinkIcon from 'src/images/ic_select.svg?react'
import Item from 'src/entities/Item'

export interface PropertyViewProps {
  view: View
  properties?: Property[]
  item: Item
}

const render = (column: Column, value: any) => {
  switch (column.type) {
    case ColumnType.Text:
    case ColumnType.BoldText:
    case ColumnType.MultilineText:
      return (
        <>
          <Stack useFlexGap sx={{flexWrap: 'wrap'}}>
            <Typography sx={{fontSize: 10, fontWeight: 700, color: '#919EAB'}}>
              {column.name.toUpperCase()}
            </Typography>
            <Typography sx={{fontSize: 12, fontWeight: 400}}>
              {value || '----'}
            </Typography>
          </Stack>
        </>
      )
  }
}

const MenuPropertiesView = observer(
  ({view, properties, item}: PropertyViewProps) => {
    const [propertyIndex, setPropertyIndex] = useState(0)
    const {currentOverItem} = useMainStore().itemStore

    useEffect(() => {
      setPropertyIndex(0)
    }, [properties])

    const currentProperty = properties ? properties[propertyIndex] : undefined
    const addressParts =
      currentProperty !== undefined &&
      currentProperty.address &&
      currentProperty.state
        ? [
            currentProperty.address,
            currentProperty.city,
            currentProperty.state
          ].filter(Boolean)
        : undefined

    return (
      <Stack>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          display={'flex'}
        >
          {properties && properties.length > 1 && (
            <IconButton
              sx={{width: 12}}
              disabled={
                propertyIndex === 0 ||
                properties[propertyIndex - 1] === undefined
              }
              onClick={() => setPropertyIndex(propertyIndex - 1)}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          {currentProperty &&
            view?.columns
              ?.filter(c => c.direction === Direction.Up)
              .map(c => (
                <React.Fragment key={c.id}>
                  {render(
                    c,
                    currentProperty[c.dbColumn?.name as keyof Property]
                  )}
                </React.Fragment>
              ))}
          {properties && properties.length > 1 && (
            <IconButton
              sx={{width: 12}}
              disabled={properties[propertyIndex + 1] === undefined}
              onClick={() => setPropertyIndex(propertyIndex + 1)}
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </Stack>
        {view.columns?.length ? <Divider /> : ''}
        <Stack>
          <Stack m={1} mb={0} spacing={1} direction="column" alignItems="right">
            {currentProperty &&
              view?.columns
                ?.filter(c => c.direction === Direction.Down)
                .map(c => (
                  <React.Fragment key={c.id}>
                    {render(
                      c,
                      currentProperty[c.name.toLowerCase() as keyof Property]
                    )}
                  </React.Fragment>
                ))}
          </Stack>
          {!currentOverItem && item.order?.id && (
            <Stack direction="row" justifyContent="center" alignItems="center">
              {item.order?.guid && (
                <IconButton
                  onClick={() =>
                    redirectWebsite(`${config.linkToSelect}${item.order?.guid}`)
                  }
                >
                  <SelectLinkIcon />
                </IconButton>
              )}
              {item.order?.fileNumber &&
                (item.order?.state ||
                  item.order?.orderCompany === OrderCompany.Titlewave) && (
                  <IconButton
                    onClick={() => {
                      redirectWebsite(
                        `${config.workspaceLink}/orderservice/${item.order
                          ?.fileNumber}/${item.order?.isCommertial ? 1 : 0}/${
                          item.order?.state ??
                          (item.order?.orderCompany === OrderCompany.Titlewave
                            ? 'NJ'
                            : '')
                        }`
                      )
                    }}
                  >
                    <WorkspaceIcon />
                  </IconButton>
                )}
              {view.columns?.length && addressParts !== undefined ? (
                <IconButton
                  onClick={() => {
                    redirectWebsite(
                      `${config.googleMapsLink}${addressParts.join(', ')}`
                    )
                  }}
                >
                  <GooleMapsIcon />
                </IconButton>
              ) : (
                ''
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    )
  }
)

export default MenuPropertiesView

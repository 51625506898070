import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CuiResizable from 'src/components/custom/CuiResizable'
import {NAV} from 'src/config'
import {useMainStore} from 'src/context/Main'
import ViewContainer from 'src/components/itemView/ViewContainer'
import ItemViewModalHeader from 'src/components/itemView/ItemViewModalHeader'
import Mailbox from 'src/components/itemView/MailBox'
import {CSSProperties, Suspense, useCallback, useEffect} from 'react'
import {ViewType} from 'src/entities/View'
import {useSearchParams} from 'react-router-dom'
import {UserSettingKey} from 'src/entities/UserSettings'

interface ItemViewModalProps {
  itemId: number
}

const ItemViewModal = observer(({itemId}: ItemViewModalProps) => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {
    currentView,
    setCurrentView,
    isOpenMenu,
    currentItem,
    isOpemViewMoal,
    setIsOpemViewMoal
  } = mainStore.itemStore

  const {updateUserSettings, getUserSettingsByKey} = mainStore.userSettingsStore

  const [searchParams, setSearchParams] = useSearchParams()

  const resizableWidth =
    getUserSettingsByKey(
      UserSettingKey.ItemViewResizeWidth,
      currentView?.id
    )?.value?.replace(/['"]+/g, '') || '35%'

  const onCloseModal = useCallback(() => {
    setCurrentView(undefined)
    setSearchParams(prev => {
      prev.delete('view')
      prev.delete('viewEntityId')
      return prev
    })
    setIsOpemViewMoal(false)
  }, [setCurrentView, setSearchParams, setIsOpemViewMoal])

  const mailboxView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.MailBox
  )
  const generalDataView = currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.GeneralData
  )
  const isMailBoxCurrentView =
    currentView && currentView?.id === mailboxView?.id

  useEffect(() => {
    const view = searchParams.get('view')
    if (!view || view === ViewType[ViewType.GeneralData]) {
      if (isMailBoxCurrentView) {
        setSearchParams(prev => {
          prev.set('view', ViewType[ViewType.MailBox])
          return prev
        })
        setCurrentView(mailboxView)
      }
      if (isOpemViewMoal && currentItem?.order?.fileNumber) {
        setSearchParams(prev => {
          prev.set('view', ViewType[ViewType.GeneralData])
          return prev
        })
        setCurrentView(generalDataView)
      }
    }
  }, [
    isMailBoxCurrentView,
    searchParams,
    setSearchParams,
    isOpemViewMoal,
    generalDataView,
    mailboxView,
    setCurrentView,
    currentItem?.order?.fileNumber
  ])

  const floatingStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: (isOpenMenu ? NAV.W_ITEM : NAV.W_ITEM_CLOSED) + NAV.W_DASHBOARD_MINI,
    bottom: 0,
    zIndex: 999
  }

  return (
    <>
      <Stack
        display={isMailBoxCurrentView ? 'flex' : 'none'}
        bgcolor="white"
        minHeight="100%"
        sx={{...floatingStyle, right: 0}}
      >
        <Suspense fallback={<></>}>
          <div hidden={!isMailBoxCurrentView} style={{height: '100vh'}}>
            {mailboxView && (
              <ItemViewModalHeader view={mailboxView} onClose={onCloseModal} />
            )}
            <Mailbox />
          </div>
        </Suspense>
      </Stack>
      <CuiResizable
        minWidth="20%"
        maxWidth="80%"
        maxHeight="100%"
        minHeight="100%"
        size={{width: resizableWidth, height: '100%'}}
        onResizeStop={(_e, _direction, ref) => {
          if (ref && ref.parentElement) {
            const resizedWidth = ref.getBoundingClientRect().width
            const parentWidth = ref.parentElement.getBoundingClientRect().width
            if (parentWidth > 0) {
              const percentageWidth = (resizedWidth / parentWidth) * 100
              updateUserSettings(
                `${percentageWidth.toFixed(2)}%`,
                UserSettingKey.ItemViewResizeWidth,
                currentView?.id
              )
            }
          }
        }}
        enable={{right: true}}
        style={{
          height: '100%',
          display: !currentView || isMailBoxCurrentView ? 'none' : 'flex',
          ...floatingStyle
        }}
      >
        <Stack
          boxShadow={10}
          bgcolor="white"
          mr={1}
          height="100%"
          width="100%"
          overflow="auto"
        >
          <Suspense fallback={<></>}>
            {currentBoard?.itemMenuViews
              .filter(v => v.viewType !== ViewType.MailBox)
              .map(view => (
                <ViewContainer
                  key={view.id}
                  hidden={!(currentView && view.id === currentView.id)}
                  view={view}
                  onClose={onCloseModal}
                  itemId={itemId}
                />
              ))}
          </Suspense>
        </Stack>
      </CuiResizable>
    </>
  )
})
export default ItemViewModal

import {useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import Menu from 'src/components/itemView/Menu'
import {Stack} from '@mui/material'
import ItemViewModal from 'src/components/itemView/ItemViewModal'
import {useMainStore} from 'src/context/Main'
import {ViewType} from 'src/entities/View'
import ItemViewWorkspace from 'src/components/itemView/ItemViewWorkspace'
import ArchiveDialog from 'src/components/itemView/ArchiveDialog'
import {useParams, useSearchParams} from 'react-router-dom'
import {NAV} from 'src/config'

const ItemView = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {currentItem, isOpenMenu} = mainStore.itemStore

  if (!currentItem || !currentBoard) return null

  const wsContainerwidth = `calc(100% - ${
    isOpenMenu ? NAV.W_ITEM : NAV.W_ITEM_CLOSED
  }px)`
  return (
    <>
      {currentItem.isArchive ||
      !currentBoard?.items.some(i => i.id === currentItem.id) ? (
        <ArchiveDialog />
      ) : (
        <>
          {' '}
          <Menu />
          <ItemViewModal itemId={currentItem.id} />
          {
            <Stack width={wsContainerwidth} height="100vh">
              <ItemViewWorkspace />
            </Stack>
          }
        </>
      )}
    </>
  )
})

const ItemViewContainer = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {setCurrentItem, initCurrentView} = mainStore.itemStore

  const {itemId} = useParams()
  const [searchParams] = useSearchParams()
  const view = searchParams.get('view')

  useEffect(() => {
    const item = currentBoard?.items.find(i => i.id === Number(itemId))
    if (item) {
      setCurrentItem(item, ViewType[view as keyof typeof ViewType])
    }
  }, [itemId, view, currentBoard?.items, setCurrentItem])

  useEffect(() => {
    if (currentBoard?.views) {
      initCurrentView(ViewType[view as keyof typeof ViewType])
    }
  }, [view, currentBoard?.views, initCurrentView])

  useEffect(() => {
    return () => setCurrentItem(undefined)
  }, [setCurrentItem])

  return <ItemView />
})

export default ItemViewContainer

import {
  DocumentItemChangeEvent,
  DocumentPreviewDetails,
  DocumentPreviewEvent
} from '@madisoncres/title-general-package'
import {observer} from 'mobx-react-lite'
import {Suspense, useEffect} from 'react'
import CuiErrorBoundary from 'src/components/custom/CuiErrorBoundary'
import Item from 'src/entities/Item'
import loadable from '@loadable/component'
import {useMainStore} from 'src/context/Main'
import {ViewType} from 'src/entities/View'
import config from 'src/config'

const DocumentView = loadable(() => import('cbDocs/DocumentShow'))

interface DocsTabProps {
  item: Item
}

const DocsTab = observer(({item}: DocsTabProps) => {
  const mainStore = useMainStore()
  const {getDocsPermissions} = mainStore.itemStore
  const viewId = mainStore.boardStore.currentBoard?.itemMenuViews.find(
    v => v.viewType === ViewType.Documents
  )?.id
  const docPermissions = getDocsPermissions(viewId)

  useEffect(() => {
    DocumentItemChangeEvent.dispatch({
      fileNumber: item?.order?.fileNumber,
      isWorkspace: true,
      isWorkflow: true,
      itemId: item?.id || 0,
      isInfo: true,
      permissions: docPermissions,
      isCommercial: item?.boardId === config.commercialBoardId
    })
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.isPreviewOnly === true && details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [item?.id, item?.order?.fileNumber, docPermissions, item?.boardId])

  return (
    <>
      <CuiErrorBoundary>
        <Suspense fallback={<></>}>
          <DocumentView />
        </Suspense>
      </CuiErrorBoundary>
    </>
  )
})
export default DocsTab

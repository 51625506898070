import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Board from 'src/entities/Board'

export default class Department implements Base {
  id: number

  name: string

  boards: Board[]

  signature?: string

  constructor(department: Department) {
    this.id = department.id
    this.name = department.name
    this.boards = department.boards.map(b => new Board(b))
    this.signature = department.signature
    makeAutoObservable(this)
  }
}

import {
  FilePreviewDetails,
  FilePreviewEvent
} from '@madisoncres/title-general-package'
import {Stack} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useEffect, useState} from 'react'
import CuiSnackbarAlert from 'src/components/custom/CuiSnackbarAlert'

interface MailBoxPreviewProps {
  setTab: React.Dispatch<React.SetStateAction<number>>
}

const MailBoxPreview = observer(({setTab}: MailBoxPreviewProps) => {
  const [blobUrl, setBlobUrl] = useState<string>('')
  const [loadError, setLoadError] = useState(false)

  useEffect(() => {
    const callback = (e: CustomEvent<FilePreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        setBlobUrl(details.blobUrl)
        setLoadError(false)
        setTab(1)
        fetch(details.blobUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('BlobNotFound')
            }
          })
          .catch(() => {
            setBlobUrl('')
            setLoadError(true)
          })
      }
    }
    FilePreviewEvent.subscribe(callback)

    return () => FilePreviewEvent.unsubscribe(callback)
  }, [setTab])

  return (
    <>
      {blobUrl && (
        <Stack height={'100%'} width={'100%'}>
          <iframe
            src={blobUrl}
            width="100%"
            height="100%"
            style={{border: 'none'}}
            title="File Preview"
          />
        </Stack>
      )}
      {loadError && (
        <CuiSnackbarAlert
          severity={'error'}
          message={'Unable to preview document, please download to view'}
          onClose={() => {
            setLoadError(false)
          }}
        />
      )}
    </>
  )
})
export default MailBoxPreview

import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import EmptyContentIcon from 'src/images/illustration_empty_content.svg?react'

const NoSearchData = observer(({}) => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" mt={10}>
      <EmptyContentIcon width="272.5px" height="200px" />
      <Typography fontSize="20px" fontWeight={700} mt={2}>
        No data to display
      </Typography>
      <Typography fontSize="20px" color="GrayText" variant="body1">
        Please enter a search term to see results
      </Typography>
    </Stack>
  )
})

export default NoSearchData

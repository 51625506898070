import {Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import SearchIcon from 'src/images/globalSearch.svg?react'

const NoResults = observer(({}) => {
  return (
    <Stack
      height="100%"
      direction="row"
      sx={{mt: 10}}
      justifyContent="center"
      spacing={2}
    >
      <SearchIcon />
      <Typography fontSize="20px" mt={2}>
        No results matched your search. Try entering a different search query.
      </Typography>
    </Stack>
  )
})

export default NoResults

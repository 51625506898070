import {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {Outlet} from 'react-router-dom'
import {Stack} from '@mui/material'
import MainMenu from 'src/components/workspace/menu/Main'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const Container = observer(() => {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)

  return (
    <>
      <Stack
        position="absolute"
        height="25px"
        top={18}
        left={isMenuCollapsed ? 65 : 325}
        sx={{
          cursor: 'pointer',
          borderRadius: '50%',
          border:
            '0.5px dashed var(--11-Text-Color-Dark-02-Text-Secondary, #919EAB)',
          background: 'var(--12-Background-Dark-01-Default, #161C24)'
        }}
        onClick={() => {
          setIsMenuCollapsed(p => !p)
        }}
      >
        {isMenuCollapsed ? (
          <ChevronRightIcon sx={{color: '#919EAB'}} />
        ) : (
          <ChevronLeftIcon sx={{color: '#919EAB'}} />
        )}
      </Stack>
      <Stack direction="row" width="100%" height="100vh" overflow={'hidden'}>
        <MainMenu isMenuCollapsed={isMenuCollapsed} />
        <Stack flex={1} height="100%" width={'1px'}>
          <Outlet />
        </Stack>
      </Stack>
    </>
  )
})

export default Container

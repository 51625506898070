import {observer} from 'mobx-react-lite'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from '@mui/material'
import CommonHeader from 'src/components/layout/CommonHeader'
import {useState, useEffect, Suspense} from 'react'
import {useMainStore} from 'src/context/Main'
import Board from 'src/entities/Board'
import {lazy} from '@loadable/component'

import {
  UpdateBoardEvent,
  DocumentPreviewDetails,
  DocumentPreviewEvent
} from '@madisoncres/title-general-package'

const MyQuestionsComponent = lazy<{}>(() => import('cbChat/MyQuestions'))

const MyQuestions = observer(function MyQuestions() {
  const mainStore = useMainStore()

  const [selectedBoard, setSelectedBoard] = useState<Board>()
  const {boards, currentBoard, setCurrentBoard} = mainStore.boardStore

  useEffect(() => {
    if (!selectedBoard)
      setSelectedBoard(
        currentBoard !== undefined
          ? currentBoard
          : boards.filter(b => b.hasMyQS)?.[0]
      )
  }, [selectedBoard, boards, currentBoard, setSelectedBoard])

  useEffect(() => {
    if (!selectedBoard || currentBoard === selectedBoard) return
    UpdateBoardEvent.dispatch({
      boardId: selectedBoard.id,
      members: selectedBoard?.members.map(m => m.user)
    })
    setCurrentBoard(selectedBoard)
  }, [selectedBoard, currentBoard, setCurrentBoard])

  useEffect(() => {
    const callback = (e: CustomEvent<DocumentPreviewDetails>) => {
      const details = e.detail
      if (details.blobUrl) {
        const file = encodeURIComponent(details.blobUrl)
        window.open(`/preview?file=${file}`, '_blank')
      }
    }
    DocumentPreviewEvent.subscribe(callback)

    return () => DocumentPreviewEvent.unsubscribe(callback)
  }, [])

  return (
    <Stack flex={1} height="100vh" width="1px" overflow={'hidden'}>
      <CommonHeader>
        <Stack direction="row" spacing={2}>
          <Typography variant="h6">My Assigned Questions</Typography>
          <FormControl size="small">
            <InputLabel>Select board</InputLabel>
            <Select
              label="Select board"
              onChange={e =>
                setSelectedBoard(
                  boards.find(b => b.id === Number(e.target.value))
                )
              }
              value={selectedBoard?.id ?? ''}
              sx={{width: 270}}
            >
              {boards
                .filter(b => b.hasMyQS)
                .map(b => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
      </CommonHeader>
      <Stack width="100%" pb={3} px={1} overflow="auto">
        <Suspense>
          <MyQuestionsComponent />
        </Suspense>
      </Stack>
    </Stack>
  )
})

export default MyQuestions

import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Autocomplete,
  TextField,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Alert,
  Typography
} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import DownIcon from 'src/images/down.svg?react'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField
} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {Control, PermissionType} from 'src/entities/PermissionData'
import {useEffect, useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'
import KeyIcon from 'src/images/key.svg?react'

const FileViewToolbar: React.FC = observer(() => {
  const mainStore = useMainStore()
  const {
    collapseGroups,
    setAllCollapseGroup,
    setSelectedBoard,
    selectedBoard,
    assigneeIdSearch,
    setAssigneeId,
    onSearch,
    query,
    allBoards,
    setSelectedFilterDate,
    currentView,
    startDate,
    endDate
  } = mainStore.searchStore

  const {boardsMembers, boards} = mainStore.boardStore

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null])

  const hasPermissionToBoard =
    boards.findIndex(b => b.id === selectedBoard?.id) > -1

  useEffect(() => {
    setDateRange([
      startDate ? dayjs(startDate) : null,
      endDate ? dayjs(endDate) : null
    ])
  }, [startDate, endDate])

  const getFilterDatePermission = (): PermissionType => {
    if (!currentView?.id) return PermissionType.Invisible
    return mainStore.permissionStore.getPermissionType(
      currentView.id,
      undefined,
      undefined,
      Control.SearchFilterDate
    )
  }

  const getLabelFilterDate = () => {
    switch (selectedBoard?.id) {
      case 4:
        return 'Closing Date'
      case 13:
        return 'Closing Date'
      case 6:
        return 'Date Assigned'
      case 14:
        return 'Last Received Email'
      default:
        return 'Date' // ברירת מחדל במקרה שאף תנאי לא מתקיים
    }
  }

  const isShowFilterDate =
    getFilterDatePermission() !== PermissionType.Invisible
  const onDateChange = (newValue: any) => {
    if (newValue.some((v: any) => v)) {
      const from =
        newValue[0]?.$d instanceof Date && !isNaN(newValue[0].$d.getTime())
          ? newValue[0].$d
          : undefined

      const to =
        newValue.length > 1 &&
        newValue[1]?.$d instanceof Date &&
        !isNaN(newValue[1].$d.getTime())
          ? newValue[1].$d
          : undefined

      setSelectedFilterDate(from, to)
    } else {
      setSelectedFilterDate(undefined, undefined)
    }
  }

  const options = selectedBoard
    ? boardsMembers?.getMembersByBoardId(selectedBoard?.id) || []
    : []

  return (
    <Stack width="100%" spacing={2}>
      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => setAllCollapseGroup()}>
          {Object.values(collapseGroups).filter(v => v).length > 0 ? (
            <UnfoldMoreIcon />
          ) : (
            <UnfoldLessIcon />
          )}
        </IconButton>

        <Stack width="100%" direction="row" alignItems="center" spacing={2}>
          <FormControl size="small">
            <InputLabel>Select board</InputLabel>
            <Select
              label="Select board"
              onChange={e =>
                setSelectedBoard(
                  allBoards.find(b => b.id === Number(e.target.value))
                )
              }
              value={selectedBoard?.id ?? ''}
              sx={{width: 270, height: 40}}
            >
              {allBoards.map(b => (
                <MenuItem key={b.id} value={b.id}>
                  {b.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl size="small" sx={{width: 270}}>
            <Autocomplete
              sx={{width: 270, height: 40}}
              value={
                assigneeIdSearch
                  ? options.find(option => option.id === assigneeIdSearch)
                  : null
              }
              onChange={(_, value) => {
                setAssigneeId(value ? Number(value?.id) : null)
                onSearch(query)
              }}
              popupIcon={<DownIcon />}
              isOptionEqualToValue={(option, v) =>
                option.id ? option.id === v.id : option === v
              }
              options={options}
              getOptionLabel={option => option.fullName}
              renderInput={params => {
                const [firstWord, lastWord] = (() => {
                  const words = (params.inputProps.value as string)
                    .trim()
                    .split(/\s+/)
                  return [words[0], words[words.length - 1]]
                })()
                const isValid = options.find(
                  o => o.fullName === params.inputProps.value
                )
                return (
                  <TextField
                    {...params}
                    label="Assign user"
                    InputLabelProps={{
                      sx: {
                        mt: '-9px'
                      }
                    }}
                    InputProps={{
                      sx: {
                        height: 40,
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '0 10px'
                      },
                      ...params.InputProps,
                      startAdornment: assigneeIdSearch &&
                        isValid &&
                        params.inputProps.value && (
                          <CuiAvatar
                            name={`${firstWord} ${lastWord}`}
                            value={`${firstWord[0]}${lastWord[0]}`}
                            size={24}
                          />
                        )
                    }}
                  />
                )
              }}
              renderOption={(props, option: any) => (
                <ListItem {...props} key={option.id} dense disablePadding>
                  <ListItemAvatar>
                    <CuiAvatar
                      name={option.fullName}
                      value={option.initials}
                      size={24}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={option.fullName} />
                </ListItem>
              )}
            />
          </FormControl>
          {isShowFilterDate && (
            <FormControl size="small" sx={{width: 270}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  label={`${getLabelFilterDate()}`}
                  calendars={2}
                  slotProps={{
                    textField: {size: 'small'},
                    field: {clearable: true}
                  }}
                  value={dateRange}
                  closeOnSelect={true}
                  slots={{field: SingleInputDateRangeField}}
                  disableAutoMonthSwitching
                  disableFuture
                  onChange={onDateChange}
                  currentMonthCalendarPosition={1}
                  localeText={{start: 'From', end: 'To'}}
                />
              </LocalizationProvider>
            </FormControl>
          )}
          {!hasPermissionToBoard && (
            <Alert
              icon={<KeyIcon />}
              severity="info"
              sx={{
                backgroundColor: 'white',
                color: 'black',
                display: 'flex',
                alignItems: 'center',
                border: '1px solid var(--Info-Main, #00B8D9)',
                height: 40,
                width: 'auto'
              }}
            >
              <Typography
                sx={{
                  color: '#006C9C',
                  whiteSpace: 'nowrap'
                }}
              >
                {'No permission to access the files in this board'}
              </Typography>
            </Alert>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
})

export default FileViewToolbar

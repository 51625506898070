import {IconButton, Stack, Typography, TextField, Button} from '@mui/material'
import {observer} from 'mobx-react-lite'
import React, {useEffect, useState} from 'react'
import Item from 'src/entities/Item'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import Popover from '@mui/material/Popover'
import {useMainStore} from 'src/context/Main'
import {fDateTimestamp} from 'src/utils/formatTime'
import Update from 'src/entities/Update'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import {
  LocalizationProvider,
  MobileDateTimePicker,
  renderTimeViewClock
} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend'
import dayjs from 'dayjs'
import DeleteIcon from '@mui/icons-material/Delete'
import CuiWhiteTooltip from 'src/components/custom/CuiWhiteTooltip'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import {Tabs} from 'src/store/SharedStore'

export interface CommentPopupProps {
  item: Item
}

interface PopoverProps {
  onClose: () => void
  anchorEl: HTMLElement | null
  updates: Update[]
  itemId: number
  isEditable: boolean
}

const PopoverComponent = observer(
  ({onClose, anchorEl, updates, itemId, isEditable}: PopoverProps) => {
    const mainStore = useMainStore()
    const {currentBoard, addItemUpdate, deleteItemUpdate, boardsMembers} =
      mainStore.boardStore
    const {currentTab} = mainStore.sharedStore
    const {selectedBoard} = mainStore.searchStore

    const [text, setText] = useState('')
    const [loading, setLoading] = useState(false)
    const [openDatePicker, setOpenDatePicker] = useState(false)
    const [notifyOn, setNotifyOn] = useState<Date | null>(null)

    const board = currentTab === Tabs.Search ? selectedBoard : currentBoard

    useEffect(() => {
      setLoading(false)
    }, [updates.length])

    return (
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => onClose()}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'right'}}
        slotProps={{
          paper: {
            sx: {
              p: 3,
              width: 500,
              minHeight: 200,
              // maxHeight: 700,
              overflow: 'hidden',
              ml: 0.75,
              '& .MuiMenuItem-root': {
                px: 1,
                borderRadius: 0.75
              }
            }
          }
        }}
      >
        <Stack
          sx={{
            maxHeight: 450,
            overflowY: 'auto'
          }}
        >
          {updates.map(u => {
            const user = board
              ? boardsMembers
                  ?.getMembersByBoardId(board?.id)
                  .find(m => m.id === u.createdBy)
              : undefined
            return (
              <Stack spacing={2} key={u.id} mb={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <CuiAvatar
                      name={user?.fullName}
                      value={user?.initials}
                      size={30}
                      isHere={(user as any)?.isHere}
                    />
                    <Typography variant="body1">
                      {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems={'center'}>
                    <Typography
                      variant="caption"
                      color="text.disabled"
                      fontSize={14}
                    >
                      {fDateTimestamp(u.createdAt)}
                    </Typography>
                    {isEditable && (
                      <IconButton
                        onClick={() => deleteItemUpdate(u.id, itemId)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                    {u.notifyOn && !u.isNotificationSent && (
                      <CuiWhiteTooltip title={fDateTimestamp(u.notifyOn)}>
                        <NotificationsActiveIcon />
                      </CuiWhiteTooltip>
                    )}
                  </Stack>
                </Stack>
                <Typography color="GrayText" variant="body2">
                  {u.text}
                </Typography>
              </Stack>
            )
          })}
        </Stack>
        {isEditable && (
          <Stack spacing={2} mt={2}>
            <TextField
              placeholder="Write a comment..."
              value={text}
              onChange={e => {
                setText(e.target.value)
              }}
            />
            <Stack direction={'row-reverse'} justifyContent={'space-between'}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                <Button onClick={onClose} disabled={loading}>
                  Cancel
                </Button>
                <CuiProgressButton
                  variant="contained"
                  disabled={text.length === 0}
                  onClick={() => {
                    setNotifyOn(null)
                    addItemUpdate(text, itemId, notifyOn)
                    setText('')
                    setLoading(true)
                    onClose()
                  }}
                  loading={loading}
                >
                  Submit
                </CuiProgressButton>
              </Stack>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Button
                  variant="outlined"
                  onClick={() => setOpenDatePicker(true)}
                  startIcon={<ScheduleSendIcon />}
                >
                  {notifyOn ? fDateTimestamp(notifyOn) : 'Schedule Comment'}
                </Button>
                {openDatePicker && (
                  <MobileDateTimePicker
                    onAccept={value => {
                      setNotifyOn(value ? value.toDate() : null)
                    }}
                    open={openDatePicker}
                    defaultValue={dayjs()}
                    onClose={() => setOpenDatePicker(false)}
                    disablePast
                    views={['day', 'hours', 'minutes']}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock
                    }}
                    slots={{
                      textField: () => <></>
                    }}
                  />
                )}
              </LocalizationProvider>
            </Stack>
          </Stack>
        )}
      </Popover>
    )
  }
)

const UpdateCol = observer(({item}: CommentPopupProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const {currentTab} = useMainStore().sharedStore
  const onIconClick = (event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onClosePopover = () => {
    setAnchorEl(null)
  }

  const editable = currentTab !== Tabs.Search

  return (
    <>
      <IconButton
        disabled={!editable && !item.updates?.length}
        onClick={onIconClick}
        sx={{
          color:
            item.updates && item.updates.length > 0 ? 'primary.main' : 'grey'
        }}
      >
        <ChatOutlinedIcon />
      </IconButton>
      {anchorEl && (
        <PopoverComponent
          anchorEl={anchorEl}
          onClose={onClosePopover}
          updates={item.updates || []}
          itemId={item.id}
          isEditable={editable}
        />
      )}
    </>
  )
})
export default UpdateCol

import {Alert, IconButton, Stack, Typography} from '@mui/material'
import {observer} from 'mobx-react-lite'
import CloseIcon from '@mui/icons-material/Close'
import Divider from '@mui/material/Divider'
import Label from 'src/components/label'
import View, {ViewType} from 'src/entities/View'
import {useMainStore} from 'src/context/Main'
import ItemViewMailboxHeader from './ItemViewMailboxHeader'
import NotificationsPopover from 'src/components/notification/NotificationsPopover'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {RoleId} from 'src/entities/PermissionData'
import {OrderCompany} from 'src/entities/Order'

interface ItemViewModalHeaderProps {
  view: View
  count?: number
  unreadCount?: number
  onClose: () => void
}

const ItemViewModalHeader = observer(
  ({view, count, unreadCount, onClose}: ItemViewModalHeaderProps) => {
    const mainStore = useMainStore()
    const {isUnknownFN, currentItem} = mainStore.itemStore

    const isMailBoxView = view.viewType === ViewType.MailBox
    const isProofingView = view.viewType === ViewType.Proofing

    return (
      <Stack position="sticky" top={0} zIndex={2} bgcolor="background.paper">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          py={0.5}
          pl={2}
          pr={1}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Stack direction={'row'} spacing={1}>
              <Typography variant="h5"> {view.name}</Typography>
              {isMailBoxView && <ItemViewMailboxHeader />}
            </Stack>
            <Typography variant="subtitle1">
              {!!count ? `(${count})` : ''}
            </Typography>
            {!!unreadCount && <Label color="info">{unreadCount}</Label>}
            {currentItem?.order &&
              !currentItem.order.state &&
              currentItem.order.orderCompany === OrderCompany.Sutton && (
                <Alert severity="error" sx={{paddingY: 0}}>
                  The viewer cannot load until a state is saved for this file
                </Alert>
              )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="end"
            spacing={2}
            mr={isMailBoxView ? 2 : 0}
          >
            {isMailBoxView && <NotificationsPopover />}
            {!isUnknownFN && (
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        </Stack>
        {isProofingView && (
          <Stack direction="row" spacing={1} pl={2} pr={1} mb={1}>
            {currentItem?.getStatusAssignees(RoleId.Proofer).map(sa => {
              const user =
                mainStore.boardStore.currentBoard?.members?.find(
                  m => m.userId === sa.user?.id
                )?.user || sa.user

              return (
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={0.5}
                  key={user?.id ?? Math.random()}
                >
                  <CuiAvatar
                    name={user?.fullName}
                    value={user?.initials}
                    size={20}
                    isHere={user?.isHere}
                  />
                  <Typography fontSize="14px">{user?.fullName}</Typography>
                </Stack>
              )
            })}
          </Stack>
        )}
        <Divider />
      </Stack>
    )
  }
)

export default ItemViewModalHeader

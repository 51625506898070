import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'

export default class TimeTracking implements Base {
  id: number

  start: Date

  stop?: Date

  createdBy: number

  updatedBy?: number

  itemId: number

  roleId: number

  constructor(timeTracking: TimeTracking) {
    this.id = timeTracking.id
    this.start = timeTracking.start
    this.stop = timeTracking.stop
    this.createdBy = timeTracking.createdBy
    this.updatedBy = timeTracking.updatedBy
    this.itemId = timeTracking.itemId
    this.roleId = timeTracking.roleId
    makeAutoObservable(this)
  }

  setId = (id: number) => {
    this.id = id
  }

  setStop = (stop?: Date) => {
    this.stop = stop
  }

  setUpdatedBy = (updatedBy?: number) => {
    this.updatedBy = updatedBy
  }
}

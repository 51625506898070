import {makeAutoObservable, reaction, runInAction} from 'mobx'
import config from 'src/config'
import Board from 'src/entities/Board'
import Item, {FlowStatus} from 'src/entities/Item'
import Member from 'src/entities/Member'
import ItemData from 'src/entities/ItemData'
import View, {ViewType, UsingType} from 'src/entities/View'
import {MainStore} from 'src/store/MainStore'
import Document, {DocumentType} from 'src/entities/Document'
import {createAndDownloadZip} from 'src/utils/download'
import {FileType} from 'src/entities/FileType'
import Column, {
  DBColumn,
  DbTable,
  ColumnData,
  ColumnType
} from 'src/entities/Column'
import User from 'src/entities/User'
import {Control, PermissionType, RoleId} from 'src/entities/PermissionData'
import StatusAssignee, {Operation} from 'src/entities/StatusAssignee'
import RoleSettings from 'src/entities/RoleSettings'
import Order, {OrderCompany, Property} from 'src/entities/Order'
import {UserChecklist} from 'src/entities/UserChecklist'
import {stringToLowerCase} from 'src/utils/stringHelper'
import ViewGroup, {isInboundGroup} from 'src/entities/ViewGroup'
import {FunctionUsed, Tabs} from 'src/store/SharedStore'
import {
  MailboxDayWorkerChangeEvent,
  MailboxOrderChangeEvent,
  MailboxOrderPropertyEvent,
  NyPublicFolderType,
  OrderProperty,
  UpdateBoardEvent,
  ArchiveItemsEvent,
  ArchiveItemEvent
} from '@madisoncres/title-general-package'
import BoardReport from 'src/entities/Report'
import Condition from 'src/entities/Condition'
import {hasValue} from 'src/utils/validations'
import Update from 'src/entities/Update'
import TimeTracking from 'src/entities/TimeTracking'
import InvoiceChangeStatusEvent, {
  InvoiceChangeStatusDetails
} from '@madisoncres/title-general-package/src/customEvent/InvoiceChangeStatusEvent'
import {Dayjs} from 'dayjs'
import {toLocalDate} from 'src/utils/date'
import {User as BoardUser} from '@madisoncres/title-general-package/src/entities/chat/User'
import {BoardMembers} from '@madisoncres/title-general-package/src/entities/chat/BoardMembers'

const changeFailedMessage = 'The change you made was not saved in the system'

export class BoardStore {
  currentBoard?: Board

  loadingItemsStatus: Record<number, boolean> = {}

  loadingViewsStatus: Record<number, boolean> = {}

  showAllMyAssignFiles: boolean = false

  currentTimeTracking?: TimeTracking

  timeTrackingMap = new Map<number, TimeTracking[]>()

  openedInfoPopupItemId?: number

  quickSearchValue?: string[] = []

  archiveItemsId?: number[] = []

  boardsMembers?: BoardMembers

  setCurrentTimeTracking = (timeTracking: TimeTracking | undefined) => {
    this.currentTimeTracking = timeTracking
  }

  setQuickSearchValue = (value?: string[]) => {
    this.quickSearchValue = value
  }

  setArchiveItemsId = (value?: number[]) => {
    this.archiveItemsId = value
  }

  get boards() {
    return this.owner.departmentStore.departments.reduce(
      (accumulator: Board[], department) => {
        return accumulator.concat(department.boards)
      },
      []
    )
  }

  get items() {
    return this.boards.reduce((accumulator: Item[], board) => {
      return accumulator.concat(board.items)
    }, [])
  }

  get properties() {
    return this.boards.reduce(
      (accumulator: Record<number, Property[]>, board: Board) => {
        Object.keys(board.properties).forEach((key: string) => {
          const boardId = parseInt(key, 10) // Convert key to number if necessary
          if (accumulator[boardId]) {
            accumulator[boardId] = accumulator[boardId].concat(
              board.properties[boardId]
            )
          } else {
            accumulator[boardId] = board.properties[boardId]
          }
        })
        return accumulator
      },
      {}
    )
  }

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)
    reaction(
      () =>
        this.currentBoard?.items?.filter(
          item =>
            item.documents !== undefined &&
            !item.documents.some(d => d.documentType === DocumentType.Invoice)
        ).length,
      newValues => {
        this.currentBoard?.views.forEach(v => {
          if (v.viewType === ViewType.Invoices) v.count = newValues
        })
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )

    const invoiceDbColumn = {id: 68, name: 'InvoiceStatusId'} as DBColumn

    const updateInvoiceCallback = (
      e: CustomEvent<InvoiceChangeStatusDetails>
    ) => {
      if (e.detail.itemId)
        this.updateItem(
          e.detail.itemId,
          undefined,
          e.detail.isApproved,
          this.owner.itemStore.currentItem?.invoiceStatusId,
          invoiceDbColumn
        )
    }

    InvoiceChangeStatusEvent.subscribe(updateInvoiceCallback)
  }

  get currentMember() {
    const userId = this.owner.loginStore.user?.id
    if (!userId) return undefined

    if (this.owner.sharedStore.currentTab === Tabs.Search) {
      return this.boards
        ?.find(b => b.id === this.owner.searchStore.selectedBoard?.id)
        ?.members?.find(m => m.userId === userId)
    }

    return this.currentBoard?.members?.find(m => m.user?.id === userId)
  }

  get allFilesEmailsStatusSummary() {
    if (this.currentBoard?.id === config.boards.ncRequest) {
      return this.currentBoard?.activeItems.reduce((summary, item) => {
        item.emailsStatusSummary?.forEach((count, statusId) => {
          summary.set(statusId, (summary.get(statusId) ?? 0) + count)
        })
        return summary
      }, new Map<number, number>())
    }
  }

  setBoardsMembers(boardMembers: BoardMembers) {
    this.boardsMembers = boardMembers
  }

  getBoardMember = (boardId: number | undefined, userId: number) => {
    if (!boardId) return null
    return this.boardsMembers
      ?.getMembersByBoardId(boardId)
      .find(u => u.id === userId)
  }

  getBoardMembers = async () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/BoardMembers`)
      .then(res => {
        return res.json()
      })
      .then((data: any) => {
        if (data) {
          if (data) {
            const boardMembers = new BoardMembers()

            data.forEach((boardMember: any) => {
              // Add members to the board
              const users = boardMember.members
                .map((m: any) => new BoardUser(m))
                .sort((a: BoardUser, b: BoardUser) =>
                  a.fullName.localeCompare(b.fullName)
                ) // Create User instances
              users.forEach((user: BoardUser) =>
                boardMembers.addMemberToBoard(boardMember.boardId, user)
              )
            })

            // Update the state
            this.setBoardsMembers(boardMembers)
          }
        }
      })

      .catch(err => {
        console.error('Failed to copy: ', err)
      })
  }

  getWorkflowUser = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/WorkflowUser/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: User) => {
        if (data) {
          board.setWorkflowUser(new User(data))
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  setIsLoadingViewsStatus = (boardId: number, isLoading: boolean) => {
    this.loadingViewsStatus[boardId] = isLoading
  }

  getViews = async (board: Board) => {
    this.setIsLoadingViewsStatus(board.id, true)
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/views/all/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: View[]) => {
        if (data) {
          board.setViews(data.map(v => new View(v)))
          if (this.currentBoard?.id === board.id) {
            this.owner.viewStore.setCurrentView(this.getInitialView())
          }
        }
      })
      .finally(() => {
        this.setIsLoadingViewsStatus(board.id, false)
      })
  }

  setItems = (data: Item[], board: Board) => {
    board.setItems(
      data.map(
        i =>
          new Item({
            ...i,
            statusAssignees: i.statusAssignees?.map(
              sa =>
                new StatusAssignee({
                  ...sa,
                  user: this.owner.userStore.users.find(u => u.id === sa.userId)
                } as StatusAssignee)
            ),
            timeTrackings:
              this.timeTrackingMap.get(i.id)?.sort((a, b) => {
                return new Date(b.start).getTime() - new Date(a.start).getTime()
              }) || []
          } as Item)
      )
    )
    board.setHasCurrentItemOnly(false)
  }

  setIsLoadingItemsStatus = (boardId: number, isLoading: boolean) => {
    this.loadingItemsStatus[boardId] = isLoading
  }

  getItems = async (board: Board) => {
    this.setIsLoadingItemsStatus(board.id, true)
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: Item[]) => {
        if (data.length && board.items.length < 2) this.setItems(data, board)
      })
      .finally(() => {
        this.setIsLoadingItemsStatus(board.id, false)
      })
  }

  getAllItems = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/${board.id}/All`)
      .then(res => {
        return res.json()
      })
      .then((data: Item[]) => {
        if (data.length) {
          this.setItems(data, board)
        }
      })
  }

  getEmailStatusByItemId = async (item: Item) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.mailboxApiUrl}/Email/emailStatusSummaryByEntity/${item.id}`
      )

      const data: Record<number, number> = await response.json()

      if (!data) return

      const statusMap = new Map<number, number>(
        Object.entries(data).map(([key, value]) => [Number(key), value])
      )

      item?.setEmailsStatusSummary(statusMap)
    } catch (e) {
      console.error('Error fetching email status:', e)
    }
  }

  getAllItemsAndStatusAndUnreadEmails = async (board: Board) => {
    try {
      const isUser = !this.owner.loginStore.isManager
      this.setIsLoadingItemsStatus(board.id, true)
      const [itemsResponse, statusResponse, unreadEmailsResponse] =
        await Promise.all([
          this.owner.loginStore.fetchWithUser(
            `${config.apiUrl}/Items/${board.id}`
          ),
          this.owner.loginStore.fetchWithUser(
            `${config.mailboxApiUrl}/Email/emailStatusSummary/${board.id}`
          ),
          this.owner.loginStore.fetchWithUser(
            `${config.mailboxApiUrl}/Email/UnreadEmailsEntities/${board.id}`
          )
        ])
      if (!itemsResponse.ok) {
        this.setIsLoadingItemsStatus(board.id, false)
        throw new Error(`Failed to fetch items: ${itemsResponse.statusText}`)
      }
      if (!statusResponse.ok) {
        throw new Error(
          `Failed to fetch email status: ${statusResponse.statusText}`
        )
      }
      if (!unreadEmailsResponse.ok) {
        throw new Error(
          `Failed to fetch unread emails: ${unreadEmailsResponse.statusText}`
        )
      }
      const basicItems: Item[] = await itemsResponse.json()
      const emailStatuses: {
        entityId: number
        statusSummary: Record<number, number>
      }[] = await statusResponse.json()
      const itemsWithUnreadEmails = await unreadEmailsResponse.json()
      runInAction(() => {
        if (basicItems.length && board.items.length < 2)
          this.setItems(basicItems, board)
        this.setIsLoadingItemsStatus(board.id, false)
        this.updateItemStatuses(board.items, emailStatuses)
        this.updateItemHasUnreadEmails(board.items, itemsWithUnreadEmails)
      })
      if (isUser) {
        const allItemsResponse = await this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/Items/${board.id}/All`
        )
        if (!allItemsResponse.ok) {
          throw new Error(
            `Failed to fetch all items: ${allItemsResponse.statusText}`
          )
        }
        const allItems: Item[] = await allItemsResponse.json()
        runInAction(() => {
          this.setItems(allItems, board)
          this.updateItemStatuses(board.items, emailStatuses)
          this.updateItemHasUnreadEmails(board.items, itemsWithUnreadEmails)
        })
      }
    } catch (error) {
      console.error(
        'Error fetching items or email status or unread emails: ',
        error
      )
    }
  }

  getItemsAllItemsAndUnreadEmails = async (board: Board) => {
    try {
      const isUser = !this.owner.loginStore.isManager
      this.setIsLoadingItemsStatus(board.id, true)
      const [itemsResponse, unreadEmailsResponse] = await Promise.all([
        this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/Items/${board.id}`
        ),
        this.owner.loginStore.fetchWithUser(
          `${config.mailboxApiUrl}/Email/UnreadEmailsEntities/${board.id}`
        )
      ])
      if (!itemsResponse.ok) {
        this.setIsLoadingItemsStatus(board.id, false)
        throw new Error(`Failed to fetch items: ${itemsResponse.statusText}`)
      }
      if (!unreadEmailsResponse.ok) {
        throw new Error(
          `Failed to fetch unread emails: ${unreadEmailsResponse.statusText}`
        )
      }

      const basicItems: Item[] = await itemsResponse.json()
      const itemsWithUnreadEmails = await unreadEmailsResponse.json()

      runInAction(() => {
        if (basicItems.length && board.items.length < 2)
          this.setItems(basicItems, board)
        this.setIsLoadingItemsStatus(board.id, false)
        this.updateItemHasUnreadEmails(board.items, itemsWithUnreadEmails)
      })

      if (isUser) {
        const allItemsResponse = await this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/Items/${board.id}/All`
        )
        if (!allItemsResponse.ok) {
          throw new Error(
            `Failed to fetch all items: ${allItemsResponse.statusText}`
          )
        }
        const allItems: Item[] = await allItemsResponse.json()
        runInAction(() => {
          this.setItems(allItems, board)
          this.updateItemHasUnreadEmails(board.items, itemsWithUnreadEmails)
        })
      }
    } catch (error) {
      console.error('Error fetching items or unread emails:', error)
    }
  }

  updateItemStatuses = (
    items: Item[],
    emailStatuses: {entityId: number; statusSummary: Record<number, number>}[]
  ) => {
    const itemMap = new Map(items.map(item => [item.id, item]))

    for (const itemStatus of emailStatuses) {
      const statusMap = new Map<number, number>(
        Object.entries(itemStatus.statusSummary).map(([key, value]) => [
          Number(key),
          value as number
        ])
      )
      itemMap.get(itemStatus.entityId)?.setEmailsStatusSummary(statusMap)
    }
  }

  updateItemHasUnreadEmails = (
    items: Item[],
    itemsWithUnreadEmails: number[]
  ) => {
    const itemMap = new Map(items.map(item => [item.id, item]))
    itemsWithUnreadEmails.forEach(i => {
      itemMap.get(i)?.setHasUnreadEmail(true)
    })
  }

  getMembers = async (board: Board) => {
    return this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Members`)
      .then(res => {
        return res.json()
      })
      .then((data: Member[]) => {
        if (data) {
          board.setMembers(data)
          return data
        }
        return []
      })
  }

  getRoles = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Roles`)
      .then(res => {
        return res.json()
      })
      .then((data: RoleSettings[]) => {
        if (data.length > 0) board.setRoles(data)
      })
  }

  getReports = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Reports`)
      .then(res => {
        return res.json()
      })
      .then((data: BoardReport[]) => {
        if (data.length > 0) board.setReports(data)
      })
  }

  getChecklist = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/Checklist`)
      .then(res => {
        return res.json()
      })
      .then((data: UserChecklist[]) => {
        if (data.length > 0) board.setChecklist(data)
      })
  }

  getEmailAccountId = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/EmailAccountId`)
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) board.setEmailAccountId(data)
      })
  }

  getDocuments = async (item: Item) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Documents/${item.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: Document[]) => {
        if (data) {
          runInAction(() => (item.documents = data.map(d => new Document(d))))
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getBoardsDetails = () => {
    this.getAllTimeTrackings()
    this.boards.forEach(b => {
      this.getEmailAccountId(b)
      this.getWorkflowUser(b)
      this.getCountItems(b)
      if (b.isMocBetweenBoards) this.getMocAvailableBoards(b)
      this.getRoles(b)
      this.getMembers(b).then(() => {
        this.getViews(b)

        if (b.id === config.boards.ny || b.id === config.boards.ncRequest) {
          this.getAllItemsAndStatusAndUnreadEmails(b)
        } else if (b.isReadPerUser) {
          this.getItemsAllItemsAndUnreadEmails(b)
        } else {
          this.getItems(b)
          this.getAllItems(b)
        }

        this.getProperties(b)
      })

      this.getColumnData(b)
      this.getReports(b)
      this.getChecklist(b)
    })
    this.owner.mocBoardStore.setBoardsItemFileNumbers(
      this.boards.map(b => b.id)
    )
    this.getBoardMembers()
    this.getArchiveItems()
  }

  getArchiveItems = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/archive`)
      .then(res => {
        return res.json()
      })
      .then((data: number[]) => {
        ArchiveItemsEvent.dispatch({archiveItems: data})
        if (data) this.setArchiveItemsId(data)
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getCountItems = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/countItems/${board.id}`)
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) {
          board?.setItemsCount(data)
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  getColumnData = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/ColumnData`)
      .then(res => {
        return res.json()
      })
      .then((data: ColumnData[]) => {
        if (data) board.setColumnData(data)
      })
  }

  getMocAvailableBoards = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Boards/${board.id}/MocAvailableBoards`)
      .then(res => {
        return res.json()
      })
      .then((data: Board[]) => {
        if (data) {
          board.setMocAvailableBoards(data)
          this.owner.mocBoardStore.setBoardsItemFileNumbers(data.map(b => b.id))
        }
      })
  }

  getProperties = async (board: Board) => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Orders/${board.id}/properties`)
      .then(res => {
        return res.json()
      })
      .then((data: Record<number, Property[]>) => {
        if (
          this.owner.itemStore.currentItem?.order?.id &&
          this.owner.permissionStore.getPermissionType(
            this.currentBoard?.itemMenuViews.find(
              v => v.viewType === ViewType.MailBox
            )?.id,
            RoleId.User,
            undefined,
            Control.EmailTemplates
          ) !== PermissionType.Invisible
        )
          this.setMailboxOrderProperty(
            data[this.owner.itemStore.currentItem.order.id]?.[0]
          )
        board.setProperties(data)
      })
  }

  setMemberStatus = async (newValue: boolean) => {
    this.currentBoard?.members
      .find(m => m.user.id === this.owner.loginStore.user?.id)
      ?.setIsAvailable(newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Boards/${this.currentBoard?.id}/IsAvailable`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newValue)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update member available user')
      }
    } catch (error) {
      this.currentBoard?.members
        .find(m => m.user.id === this.owner.loginStore.user?.id)
        ?.setIsAvailable(!newValue)
      this.owner.setErrorMessage('Something went wrong, please try again')
    }
  }

  setMemberDayWorker = async (isDayWorker: boolean) => {
    this.currentMember?.setIsDayWorker(isDayWorker)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Members/${this.currentMember?.id}/DayWorker`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(isDayWorker)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update member day worker')
      }
      MailboxDayWorkerChangeEvent.dispatch({
        isDayWorker: isDayWorker
      })
    } catch (error) {
      this.currentMember?.setIsDayWorker(!isDayWorker)
      this.owner.setErrorMessage('Something went wrong, please try again')
    }
  }

  saveStatusAssignees = async (
    itemId: number,
    statusAssignees: StatusAssignee[],
    commentAssignee: Record<string, string>
  ) => {
    const addList: StatusAssignee[] = []
    const deleteList: number[] = []

    statusAssignees.forEach(i => {
      if (i.operation === Operation.Add) addList.push(i)
      if (i.operation === Operation.Delete && i.id) deleteList.push(i.id)
      if (i.operation === Operation.Edit) {
        if (i.id) deleteList.push(i.id)
        addList.push(i)
      }
    })

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/Assignees`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            additionalInfo: encodeURIComponent(JSON.stringify(commentAssignee))
          },
          body: JSON.stringify({
            AddedAssignees: addList,
            DeletededAssigneeIds: deleteList,
            BoardId: this.currentBoard?.id,
            ItemId: itemId
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to save the status assignees.')
      }
    } catch (error) {
      this.owner.setErrorMessage('Failed to assign user, please try again.')
    }
  }

  getInitialView = () => {
    const viewTabs = this.getViewTabs() || []

    if (this.currentMember?.roleId === RoleId.User) {
      const assignedView = viewTabs.find(v => v.isAssignedOnly)
      return assignedView || viewTabs[0]
    }

    return viewTabs[0]
  }

  setCurrentBoard = async (b: Board | undefined) => {
    if (this.currentBoard?.id !== b?.id) {
      this.currentBoard = this.boards.find(board => board.id === b?.id)
      if (this.currentBoard) {
        this.owner.viewStore.setCurrentView(this.getInitialView())
      }
      this.owner.reportStore.setSelectedBoard(this.currentBoard)
      this.owner.searchStore.setSelectedBoard(
        this.owner.searchStore.getBoardById(this.currentBoard?.id),
        true
      )
      let members = this.currentBoard?.members || []
      if ((members.length || 0) < 2 && this.currentBoard) {
        members = await this.getMembers(this.currentBoard)
      }
      UpdateBoardEvent.dispatch({
        boardId: this.currentBoard?.id,
        members: members.map(m => m.user) || []
      })
      this.setQuickSearchValue([])
    }
  }

  setWorkflowUser = async (isWorkflow: boolean) => {
    const prevWorkflowUser = this.currentBoard?.workflowUser
    this.currentBoard?.setWorkflowUser(
      isWorkflow ? this.owner.loginStore.user || undefined : undefined
    )
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Boards/${this.currentBoard?.id}/WorkflowUser`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(isWorkflow)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('failed to update workflow user')
      }
    } catch (error) {
      this.currentBoard?.setWorkflowUser(prevWorkflowUser)
      this.owner.setErrorMessage('Something went wrong, please try again')
    }
  }

  getDefaultRoleStatus = (item: Item, roleId?: number) => {
    const defaultStatus = item
      .defaultStatusAssignees()
      .find(s => s.roleId === roleId)?.statusId
    if (defaultStatus) return defaultStatus

    const roleSettings = this.currentBoard?.roles.find(r => r.id === roleId)
    return roleSettings?.defaultStatusId
  }

  getViewByType = (type: ViewType, using: UsingType) => {
    return this.currentBoard?.views.find(
      v => v.viewType === type && v.usingType === using
    )
  }

  getViewIdByType = (type: ViewType, using: UsingType) => {
    return this.getViewByType(type, using)?.id
  }

  getViewTabs = () => {
    return this.currentBoard?.views.filter(
      v =>
        v.usingType === UsingType.Tab &&
        this.owner.permissionStore.getPermissionType(v.id) !==
          PermissionType.Invisible
    )
  }

  getColumnsData(item: any, columns: Column[]) {
    return columns.reduce((a, c) => {
      let value = item.getValue(c.dbTableId, c.dbColumn, c.roleId)
      return {
        ...a,
        [c.id]: value
      }
    }, {})
  }

  isUserAssignee = (item: Item, userId?: number) => {
    const isUser = !!item.statusAssignees.find(sa => sa.user?.id === userId)
    return isUser
  }

  getBoardIdByItemId = (itemId: number) => {
    return this.boards.find(b => b.items.find(i => i.id === itemId))?.id
  }

  allSelfAssignRoleExist = (item: Item, selfAssignRoleIds: number[]) => {
    return selfAssignRoleIds.every(roleId =>
      item.statusAssignees.some(sa => sa.roleId === roleId)
    )
  }

  getItemWithColumn(item: Item) {
    const view =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.currentView
        : this.owner.viewStore.currentView
    const columnsData = this.getColumnsData(item, view?.columns || [])
    return {...item, ...columnsData} as Item
  }

  isRelevantStatus = (item: Item) => {
    const roleIdToCheck = 4
    // To do - support dynamic values
    const statuses = [20, 23, 37]
    const currentStatus = item.getValue(
      DbTable.AssigneStatus,
      undefined,
      roleIdToCheck
    )
    if (currentStatus && statuses.includes(Number(currentStatus))) return true
    return false
  }

  isDefaultRoleExist = (item: Item) => {
    const isExist = item.statusAssignees.filter(
      s => s.roleId === this.currentBoard?.defaultRole?.id && s.userId !== -1
    ).length
    return isExist
  }

  inboundItemsNJ = (conditions: Condition[]) => {
    const items = this.getFilterdItems((item: Item) => {
      const isMatchAnyCondition = conditions.some(c => {
        const statusAssignee = item.statusAssignees.find(
          sa =>
            sa.roleId === c.rolesToApply![0] &&
            sa.statusId === c.statusIds![0] &&
            sa.userId === -1
        )
        // if the condition is about reading status so check if have snder and not reader
        const roleId = c.rolesToApply![0] === 4 ? 8 : c.rolesToApply![0]
        const isUserAssigned = item.statusAssignees.find(
          sa => sa.userId !== -1 && sa.roleId === roleId
        )

        return statusAssignee && !isUserAssigned
      })
      return isMatchAnyCondition
    })

    const itemsWithProperty = items?.map(
      item =>
        ({
          ...item,
          inboundInAssignee: true
        }) as Item
    )

    return itemsWithProperty
  }

  get inboundItemsToAssign() {
    const selfAssignRolesIds =
      this.currentBoard?.roles.filter(r => r.selfAssign).map(r => r.id) || []

    const items = this.currentBoard?.items?.reduce(
      (arr: Item[], item: Item) => {
        if (
          !this.allSelfAssignRoleExist(item, selfAssignRolesIds) &&
          item.flowStatusId !== FlowStatus.Cancelled &&
          this.isRelevantStatus(item) &&
          this.isDefaultRoleExist(item)
        ) {
          const fullItem = this.getItemWithColumn(item)
          arr.push({...fullItem, inboundInAssignee: true} as Item)
        }
        return arr
      },
      []
    )

    return items
  }

  inboundCombinedItems = (group: ViewGroup, condition: Condition) => {
    const statusCondition = this.owner.sharedStore.conditions[
      FunctionUsed.FilterViewFiles
    ]?.filter(
      c => c.viewIds?.includes(this.owner.viewStore.currentView?.id || 0)
    )?.[0]
    const items =
      statusCondition &&
      this.getFilterdItems(
        (item: Item) =>
          this.isIncludeInGroup(group, item) ||
          (condition.viewGroupIds!.includes(item.itemGroupId) &&
            this.roleStatusCondition(statusCondition, item) &&
            !item.statusAssignees.find(
              sa =>
                condition.rolesToApply?.includes(sa.roleId) && sa.userId !== -1
            ))
      )

    return items
  }

  setShowAllMyAssignFiles = (isShow: boolean) => {
    this.showAllMyAssignFiles = isShow
  }

  setOpenedInfoPopupItemId = (itemId?: number) => {
    this.openedInfoPopupItemId = itemId
  }

  doesAssigneeMatchCondition = (
    assignee: StatusAssignee,
    condition: Condition,
    group: ViewGroup,
    readingStatus?: number
  ) => {
    if (!condition?.viewGroupIds?.includes(group.id)) return false

    if (!condition?.rolesToApply?.includes(assignee.roleId)) return false

    if (assignee.statusId) {
      return condition?.statusIds?.includes(assignee.statusId)
    }
    // sender pull the status from reading status
    return readingStatus && condition?.statusIds?.includes(readingStatus)
  }

  applyMyAssignFilterCondition = (item: Item, group: ViewGroup) => {
    if (this.showAllMyAssignFiles) return true

    const conditions =
      this.owner.sharedStore.conditions[
        FunctionUsed.ApplyMyAssignFilterCondition
      ]

    const userAssignees = item.statusAssignees.filter(
      sa => sa.userId === this.owner.loginStore.user?.id
    )

    const defaultReadingStatus = item.statusAssignees.find(
      sa => sa.userId === -1 && sa.roleId === 4
    )?.statusId
    // check if have assignee that dont match any condition, if so the user should see that item, oterwise - not
    const nonMatchingAssignees = userAssignees.filter(assignee => {
      // Check if the assignee does not match both conditions
      const matchesCondition1 = this.doesAssigneeMatchCondition(
        assignee,
        conditions[0],
        group
      )
      const matchesCondition2 = this.doesAssigneeMatchCondition(
        assignee,
        conditions[1],
        group,
        defaultReadingStatus
      )

      // Return true if the assignee does not match either condition
      return !matchesCondition1 && !matchesCondition2
    })

    if (nonMatchingAssignees.length > 0) return true
    return false
  }

  getNameBoardByItemId = (itemId: number | undefined, boardId?: number) => {
    if (boardId) {
      return this.boards.find(b => b.id === boardId)?.name
    }

    if (!itemId) {
      return ''
    }

    return this.boards.find(
      b => b.items.find(i => i.id === itemId) !== undefined
    )?.name
  }

  getRoleToFilterAssigneeItems = () => {
    const conditionFilterByRole =
      this.owner.sharedStore.conditions[
        FunctionUsed.FilterAssigneeFilesByRole
      ]?.[0]

    const viewId = this.owner.viewStore.currentView?.id!
    if (conditionFilterByRole?.viewIds?.includes(viewId)) {
      const index = conditionFilterByRole?.viewIds?.indexOf(viewId)
      if (index === -1) return undefined
      const roleId = conditionFilterByRole?.rolesToApply?.[index]
      if (roleId) return roleId
    }
    return undefined
  }

  getFilterdItems = (filterFunction: (item: Item) => boolean) => {
    const items =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.getItems
        : this.currentBoard?.activeItems
    return items?.reduce((arr: Item[], item: Item) => {
      if (filterFunction(item)) {
        const fullItem = this.getItemWithColumn(item)
        arr.push(fullItem)
      }
      return arr
    }, [])
  }

  isIncludeInGroup = (group: ViewGroup, item: Item) => {
    const nyDayWorkFolderId = 2
    const view =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.currentView?.id!
        : this.owner.viewStore.currentView?.id!
    const condition = this.owner.sharedStore.conditions[
      FunctionUsed.FilterViewFiles
    ].filter(c => c.viewIds?.includes(view))
    const trackingViewCondition = this.owner.sharedStore.conditions[
      FunctionUsed.TrackingViewCondition
    ].filter(c => c.viewIds?.includes(view))
    const invoiceViewCondition = this.owner.sharedStore.conditions[
      FunctionUsed.InvoiceViewCondition
    ].filter(c => c.viewIds?.includes(view))
    const questionNJViewCondition = this.owner.sharedStore.conditions[
      FunctionUsed.QuestionNJViewFiles
    ].filter(c => c.viewIds?.includes(view))
    const myNotHandledFilesCondition = this.owner.sharedStore.conditions[
      FunctionUsed.MyNotHandledFiles
    ].filter(c => c.viewIds?.includes(view))
    const NYPublicFolderTypeIsDayWork = this.owner.sharedStore.conditions[
      FunctionUsed.NYPublicFolderTypeIsDayWork
    ].filter(c => c.viewGroupIds?.includes(group.id))
    const nyDayWorkAndWaitingForAssignment = this.owner.sharedStore.conditions[
      FunctionUsed.DayWorkAndWaitingForAssignment
    ].some(c => c.viewGroupIds?.includes(group.id))

    return !!(
      group.itemGroupIds.includes(item.itemGroupId) &&
      (condition?.length
        ? this.roleStatusCondition(condition[0], item)
        : true) &&
      (trackingViewCondition?.length
        ? this.trackingViewCondition(item)
        : true) &&
      (invoiceViewCondition?.length
        ? this.invoiceViewCondition(invoiceViewCondition[0], item)
        : true) &&
      (questionNJViewCondition?.length
        ? this.questionViewCondition(questionNJViewCondition[0], item)
        : true) &&
      (myNotHandledFilesCondition?.length
        ? this.myNotHandledFiles(myNotHandledFilesCondition[0], item)
        : true) &&
      (NYPublicFolderTypeIsDayWork?.length
        ? item.nyPublicFolderType === nyDayWorkFolderId &&
          item.isDayWorkEmailsAllAssigned &&
          this.dayWorkView(item)
        : true) &&
      (nyDayWorkAndWaitingForAssignment === true
        ? item.nyPublicFolderType === nyDayWorkFolderId &&
          !item.isDayWorkEmailsAllAssigned
        : true)
    )
  }

  isMatchAssignView = (group: ViewGroup, item: Item) => {
    const roleToFilter = this.getRoleToFilterAssigneeItems()

    return (
      this.isIncludeInGroup(group, item) &&
      item.isUserAssignee({
        userId: this.owner.loginStore.user?.id,
        roleToFilter: roleToFilter
      }) &&
      this.applyMyAssignFilterCondition(item, group)
    )
  }

  roleStatusCondition = (condition: Condition, item: Item) =>
    !!item.statusAssignees.find(
      sa =>
        condition.rolesToApply?.find(r => r === sa.roleId) &&
        condition.statusIds?.find(s => s === sa.statusId)
    )

  trackingViewCondition = (item: Item) => {
    const today = new Date()
    const lastMonthDate = new Date()
    lastMonthDate.setMonth(today.getMonth() - 1)
    return (
      item?.completedOn &&
      item?.completedOn >= lastMonthDate &&
      item?.completedOn <= today &&
      item?.order?.orderCompany === OrderCompany.Titlewave
    )
  }

  invoiceViewCondition = (condition: Condition, item: Item) => {
    return condition.statusIds?.find(s => s === item.invoiceStatusId)
  }

  questionViewCondition = (condition: Condition, item: Item) => {
    return condition.statusIds?.find(
      s => s === item.questionStatusId || s === item.mailboxQuestionStatusId
    )
  }

  myNotHandledFiles = (condition: Condition, item: Item) => {
    return condition.statusIds?.find(s =>
      item.statusAssignees.find(
        sa => sa.userId === this.owner.loginStore.user?.id && sa.statusId === s
      )
    )
  }

  dayWorkView = (item: Item) => {
    return !!item.statusAssignees.find(
      sa => sa.additionalInfo?.toLowerCase() === 'true'
    )
  }

  nyPublicFolderTypeIsDayWork = (item: Item) => {
    return item.nyPublicFolderType === NyPublicFolderType.DayWork
  }

  isDayWorkAllAssigned = (item: Item) => {
    return item.isDayWorkEmailsAllAssigned === true
  }

  getInboundItems = (group: ViewGroup) => {
    const {conditions} = this.owner.sharedStore

    let condition = conditions[FunctionUsed.InboundGroupItems]
    if (condition?.[0].viewGroupIds?.includes(group.id)) {
      return this.inboundItemsToAssign
    }

    condition = conditions[FunctionUsed.NYPublicFolderTypeIsDayWork]
    if (condition?.[0].viewIds?.includes(this.owner.viewStore.currentView!.id))
      return this.getFilterdItems(
        (item: Item) =>
          group.itemGroupIds.includes(item.itemGroupId) &&
          this.nyPublicFolderTypeIsDayWork(item) &&
          !this.isDayWorkAllAssigned(item)
      )

    condition = conditions[FunctionUsed.CombinedInboundGroupItems]
    if (
      condition?.[0].boardIds?.includes(this.currentBoard!.id) &&
      condition?.[0].viewIds?.includes(this.owner.viewStore.currentView!.id)
    ) {
      return this.inboundCombinedItems(group, condition[0])
    }

    condition = conditions[FunctionUsed.InboundItemsNJ]
    if (
      condition?.[0].boardIds?.includes(this.currentBoard!.id) &&
      condition?.[0].viewIds?.includes(this.owner.viewStore.currentView!.id)
    ) {
      return this.inboundItemsNJ(condition)
    }

    return this.getFilterdItems((item: Item) =>
      this.isIncludeInGroup(group, item)
    )
  }

  getGroupItems = (group: ViewGroup) => {
    if (
      this.owner.sharedStore.currentTab === Tabs.Search &&
      !this.owner.searchStore.getItems.length
    )
      return []

    if (
      this.owner.sharedStore.currentTab === Tabs.Workflow &&
      !this.currentBoard?.activeItems.length
    )
      return []

    if (
      this.owner.viewStore.currentView?.isAssignedOnly &&
      this.owner.sharedStore.currentTab !== Tabs.Search
    ) {
      if (isInboundGroup(group.id)) return this.getInboundItems(group)

      return this.getFilterdItems((item: Item) =>
        this.isMatchAssignView(group, item)
      )
    }

    const items = this.getFilterdItems((item: Item) =>
      this.isIncludeInGroup(group, item)
    )
    if (this.currentBoard?.id && items) {
      const condition = this.owner?.sharedStore?.conditions[
        FunctionUsed.SortGroup
      ]?.find(
        c =>
          this.currentBoard?.id &&
          c.boardIds?.includes(this.currentBoard.id) &&
          c.viewGroupIds?.includes(group.id)
      )

      return condition ? this.sortByBoard(items, this.currentBoard.id) : items
    }

    return items
  }

  sortByBoard = (items: Item[], boardId: number) => {
    if (boardId === config.boards.commercial) {
      return [...items].sort((a, b) => {
        const dateA = a.statusAssignees.find(
          sa => sa.userId !== -1 && sa.roleId === 4
        )?.createdAt
        const dateB = b.statusAssignees.find(
          sa => sa.userId !== -1 && sa.roleId === 4
        )?.createdAt

        if (!dateA) return 1
        if (!dateB) return -1

        return new Date(dateB).getTime() - new Date(dateA).getTime()
      })
    }

    return items
  }

  getUpdateFunction = (tableId: number) => {
    switch (tableId) {
      case DbTable.Order:
        return this.updateOrder

      case DbTable.Item:
        return this.updateItem

      case DbTable.ItemData:
        return this.updateItemData

      case DbTable.AssigneStatus:
        return this.updateStatusAssignee

      default:
        return this.updateItemData
    }
  }

  getStatusesWithComment = (item: Item, board?: Board) => {
    const role = board?.roles.find(r => r.statusesWithComments)
    const statuses = item.statusAssignees.find(s => s.roleId === role?.id)
      ?.subStatuses

    const statusesString = statuses?.map((status: number) => {
      const columnData = board?.columnData.find(
        cd =>
          cd.roleId === role?.id &&
          cd.columnTypeId === ColumnType.Status &&
          Number(cd.columnValue) === status
      )
      if (columnData) return JSON.parse(columnData?.data).text as string
      return ''
    })

    return statusesString || []
  }

  getUserRoleByBoard = (boardId: number) => {
    const role = this.boards
      .find(b => b.id === boardId)
      ?.members.find(m => m.userId === this.owner.loginStore.user?.id)?.roleId

    return role
  }

  isUserByBoard = (boardId: number) => {
    return this.getUserRoleByBoard(boardId) === RoleId.User
  }

  updateItemData = async (
    itemId: number,
    columnId: number,
    newValue: any,
    prevValue: any
  ) => {
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.itemData.find(d => d.columnId === columnId)
      ?.setData(newValue)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/data`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            columnId: columnId,
            data: JSON.stringify(newValue)
          } as ItemData)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.itemData.find(d => d.columnId === columnId)
        ?.setData(prevValue)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  updateItem = async (
    itemId: number,
    columnId: number | undefined,
    newValue: any,
    prevValue: any,
    dbColumn?: DBColumn
  ) => {
    if (dbColumn === null)
      dbColumn = this.owner.viewStore.currentView?.columns?.find(
        c => c.id === columnId
      )?.dbColumn
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.setItemProperty(dbColumn?.name || '', newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/property`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            PropertyId: dbColumn?.id,
            Value: hasValue(newValue) ? newValue.toString() : undefined
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
      return true
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.setItemProperty(dbColumn?.name || '', prevValue)

      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  updateOrder = async (
    itemId: number,
    columnId: number | undefined,
    newValue: any,
    prevValue: any,
    dbColumn?: DBColumn
  ) => {
    if (dbColumn === null)
      dbColumn = this.owner.viewStore.currentView?.columns?.find(
        c => c.id === columnId
      )?.dbColumn
    const order = this.currentBoard?.items.find(i => i.id === itemId)?.order
    order?.setOrderProperty(dbColumn?.name || '', newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Orders/${order?.id}/property`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            PropertyId: dbColumn?.id,
            Value: newValue.toString()
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update data.')
      }
      return true
    } catch (error) {
      order?.setOrderProperty(dbColumn?.name || '', prevValue)
      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  updateStatusAssignee = async (
    itemId: number,
    _: number,
    newValue: any,
    prevValue: any,
    roleId: number
  ) => {
    this.currentBoard?.items
      .find(i => i.id === itemId)
      ?.statusAssignees.find(sa => sa.roleId === roleId)
      ?.setStatusId(newValue)

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/item/${itemId}/role/${roleId}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newValue)
        }
      )

      if (!response.ok) {
        throw new Error('Failed to update status.')
      }
      return true
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.statusAssignees.find(sa => sa.roleId === roleId)
        ?.setStatusId(prevValue)

      this.owner.setErrorMessage(changeFailedMessage)
      throw error
    }
  }

  addItemUpdate = async (
    text: string,
    itemId: number,
    notifyOn: Date | null
  ) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Updates`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({text: text, notifyOn: notifyOn, itemId: itemId})
        }
      )
      if (!response.ok) {
        throw new Error('Failed to add update.')
      }
      return true
    } catch (error) {
      this.owner.setErrorMessage(changeFailedMessage)
      console.log(error)
      throw error
    }
  }

  deleteItemUpdate = async (updateId: number, itemId: number) => {
    const item = this.currentBoard?.items.find(i => i.id === itemId)
    const prevUpdates = item?.updates
    const indexToDelete = item?.updates?.findIndex(u => u.id === updateId)
    if (indexToDelete && indexToDelete !== -1)
      item?.updates?.splice(indexToDelete, 1)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Updates/${updateId}`,
        {
          method: 'DELETE'
        }
      )
      if (!response.ok) {
        throw new Error('Failed to delete update.')
      }
    } catch (error) {
      this.owner.setErrorMessage(changeFailedMessage)
      console.log(error)
      item?.setUpdates(prevUpdates)
      throw error
    }
  }

  addTimeTrackingManually = async (
    value: [Dayjs, Dayjs],
    itemId: number,
    roleId: number
  ) => {
    let item = this.currentBoard?.items.find(i => i.id === itemId)
    if (!item) {
      this.owner.setErrorMessage('Failed to add time tracking manually.')
      return
    } else {
      const timeTracking = new TimeTracking({
        createdBy: this.owner.loginStore.user?.id,
        itemId: itemId,
        roleId: roleId,
        start: value[0].toDate(),
        stop: value[1].toDate()
      } as TimeTracking)
      let index = 0
      runInAction(() => {
        index = item!.timeTrackings.findIndex(t => t.start < timeTracking.start)
        index = index === -1 ? item!.timeTrackings.length : index
        item?.timeTrackings.splice(index, 0, timeTracking)
      })
      try {
        const response = await this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/TimeTracking/addManually`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(timeTracking)
          }
        )
        if (!response.ok) {
          throw new Error('Failed to add time tracking manually.')
        }
        const res = await response.json()
        item.timeTrackings.find(tt => tt.roleId === roleId)?.setId(res)
      } catch (error) {
        item.timeTrackings.splice(index, 1)
        this.owner.setErrorMessage(changeFailedMessage)
        console.log(error)
        throw error
      }
    }
  }

  startTimeTracking = async (start: Date, itemId: number, roleId: number) => {
    let item = this.currentBoard?.items.find(i => i.id === itemId)
    const timeTracking = new TimeTracking({
      createdBy: this.owner.loginStore.user?.id,
      itemId: itemId,
      roleId: roleId,
      start: start
    } as TimeTracking)
    runInAction(() => {
      item?.timeTrackings.unshift(timeTracking)
    })
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/TimeTracking/start/${itemId}/${roleId}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(start.toISOString())
        }
      )
      if (!response.ok) {
        throw new Error('Failed to start time tracking.')
      }
      const res = await response.json()
      item?.timeTrackings
        .find(tt => tt.roleId === roleId && !tt.stop)
        ?.setId(res)
    } catch (error) {
      const i = item?.timeTrackings.findIndex(
        tt => tt.roleId === roleId && !tt.stop
      )
      if (i) item?.timeTrackings.splice(i, 1)
      this.owner.setErrorMessage(changeFailedMessage)
      console.log(error)
      throw error
    }
  }

  stopTimeTracking = async (stop: Date, itemId: number, roleId: number) => {
    const item = this.currentBoard?.items.find(i => i.id === itemId)
    const index = item?.timeTrackings.findIndex(
      tt => tt.roleId === roleId && !tt.stop
    )
    if (index === -1 || index === undefined)
      this.owner.setErrorMessage('Failed to stop time tracking.')
    else {
      item?.timeTrackings[index].setStop(stop)
      try {
        const response = await this.owner.loginStore.fetchWithUser(
          `${config.apiUrl}/TimeTracking/stop/${item?.timeTrackings[index].id}`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(stop.toISOString())
          }
        )
        if (!response.ok) {
          throw new Error('Failed to stop time tracking.')
        }
        return true
      } catch (error) {
        item?.timeTrackings[index].setStop(undefined)
        this.owner.setErrorMessage(changeFailedMessage)
        console.log(error)
        throw error
      }
    }
  }

  deleteTimeTracking = async (itemId: number, timeTrackingId: number) => {
    const item = this.currentBoard?.items.find(i => i.id === itemId)
    const index = item?.timeTrackings.findIndex(t => t.id === timeTrackingId)
    let timeTracking: TimeTracking[]
    if (index !== undefined && index !== -1)
      runInAction(
        () => (timeTracking = item?.timeTrackings.splice(index, 1) || [])
      )
    else throw new Error('Failed to delete time tracking.')
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/TimeTracking/${timeTrackingId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (!response.ok) {
        throw new Error('Failed to start time tracking.')
      }
    } catch (error) {
      runInAction(
        () =>
          timeTracking.length &&
          item?.timeTrackings.splice(index, 0, timeTracking[0])
      )
      this.owner.setErrorMessage(changeFailedMessage)
      console.log(error)
      throw error
    }
  }

  uploadDocument = async (
    file: Blob,
    itemId: number,
    fileName: string,
    documentType: DocumentType
  ) => {
    try {
      const formdata = new FormData()
      formdata.append('file', file, fileName)
      formdata.append('documentType', documentType.toString())
      formdata.append('itemId', itemId.toString())
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Documents`,
        {
          method: 'POST',
          body: formdata
        }
      )
      const data = await response.json()
      if (data) {
        runInAction(
          () =>
            this.currentBoard?.items
              .find(m => m.id === data.itemId)
              ?.documents?.push(data)
        )

        return true
      } else {
        throw new Error('Failed to upload invoice.')
      }
    } catch (error) {
      throw error
    }
  }

  setItemGroup = async (
    itemId: number,
    prevGroupId: number,
    newGroupId: number
  ) => {
    var item = this.currentBoard?.items.find(i => i.id === itemId)
    item?.setGroup(newGroupId)
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/group`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(newGroupId)
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to change group.')
      }
    } catch (error) {
      this.currentBoard?.items.find(i => i.id === itemId)?.setGroup(prevGroupId)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  setIsArchive = async (itemId: number, isArchive: boolean) => {
    const boardId =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.selectedBoard?.id
        : this.currentBoard?.id

    var item = this.boards
      .find(b => b.id === boardId)
      ?.items.find(i => i.id === itemId)
    item?.setIsArchive(isArchive)

    // update search data
    this.owner.searchStore.items
      .find(i => i.id === itemId)
      ?.setIsArchive(isArchive)

    if (!isArchive) {
      this.setArchiveItemsId(this.archiveItemsId?.filter(id => id !== itemId))
    } else {
      this.setArchiveItemsId(this.archiveItemsId?.concat([itemId]))
    }

    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/archive/${isArchive}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      const data = await response.json()

      if (data) {
        ArchiveItemEvent.dispatch({itemId: itemId, isArchive: isArchive})
        if (!isArchive) this.addOrUpdateItem(new Item(data))
      } else {
        throw new Error('Failed to update isArchive.')
      }
    } catch (error) {
      this.currentBoard?.items
        .find(i => i.id === itemId)
        ?.setIsArchive(!isArchive)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  fixFileNumber = async (
    itemId: number,
    boardId: number,
    fileNumber: string
  ) => {
    try {
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/Items/${itemId}/fixFileNumber/${boardId}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(fileNumber)
        }
      )
      if (response.status === 204) {
        this.owner.setErrorMessage(
          `File number doesn't exist in Select${
            this.currentBoard?.isIncludeSuttonItems ? ' or Sutton' : ''
          }.`
        )
        return null
      }
      const data = await response.json()
      if (!data) throw new Error('Failed to fix fn')
      else if (data.srcItem === null)
        //case of new order
        runInAction(() => {
          this.setMailboxFullFileNumber(
            itemId,
            new Order(data.order).fullFileNumber!
          )
          this.items.find(i => i.id === itemId)?.setOrder(new Order(data.order))
          this.owner.mocBoardStore.addOrUpdateItem(
            itemId,
            boardId,
            data.order.fileNumber
          )
          this.owner.orderStore.getBoardItemsOfOrders()
          this.updateMailboxOrderProperty(data.order)
        })
      //case of merge
      else {
        if (data.isSrcRestored) this.addOrUpdateItem(new Item(data.srcItem))
      }
      return data
    } catch (error) {
      console.error(error)
      this.owner.setErrorMessage(changeFailedMessage)
    }
  }

  getCountItemNoDocuments = async () => {
    this.owner.loginStore
      .fetchWithUser(
        `${config.apiUrl}/Items/countWithoutDocuments/${DocumentType.Invoice}`
      )
      .then(res => {
        return res.json()
      })
      .then((data: number) => {
        if (data) {
          runInAction(() => {
            this.currentBoard?.views?.forEach(iv => {
              if (iv.viewType === ViewType.Invoices) iv.count = data
            })
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  downloadInvoices = async (filteredItems: Item[], folderName: string) => {
    const blobs: {name: string; blob: Blob}[] = []
    if (filteredItems) {
      for (const item of filteredItems) {
        for (const document of item.documents.filter(
          d => d.documentType === DocumentType.Invoice
        )) {
          const blob = await this.owner.azureStorageStore.getBlob(
            document.url,
            FileType.Document
          )
          if (blob) {
            blobs.push({name: document.name, blob})
          }
        }
      }
      await createAndDownloadZip(blobs, folderName)
    }
  }

  setStatusAssignee = async (
    statusAssignee: StatusAssignee,
    prevStatusId: number | undefined,
    newStatusId: number,
    prevSubStatus: number[],
    isOverrideSubStatus: boolean,
    comment?: string
  ) => {
    const prevComment = statusAssignee.comment
    try {
      if (!newStatusId) return
      statusAssignee.setStatusId(newStatusId, isOverrideSubStatus)
      statusAssignee.setComment(comment)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...statusAssignee,
            isOverrideSubStatus: isOverrideSubStatus
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to save status assignee.')
      }
    } catch (error) {
      statusAssignee.setStatusId(prevStatusId, false)
      statusAssignee.setSubStatusId(prevSubStatus)
      statusAssignee.setComment(prevComment)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setSubStatusAssignee = async (
    statusAssignee: StatusAssignee,
    newStatusId: number,
    prevStatusId: number,
    subStatus: ColumnData,
    prevSubStatus: number[],
    isAdded: boolean,
    isOverridePrev: boolean
  ) => {
    try {
      statusAssignee.setStatusAndSubId(
        newStatusId,
        parseInt(subStatus.columnValue),
        isAdded,
        isOverridePrev
      )
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/${statusAssignee.id}/status`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            statusAssigneeId: statusAssignee.id,
            statusId: newStatusId,
            subStatusId: subStatus.columnValue,
            isAdded: isAdded,
            isOverridePrev: isOverridePrev
          })
        }
      )
      const data = await response.json()
      if (!data) {
        throw new Error('Failed to update status assignee with sub status.')
      }
    } catch (error) {
      statusAssignee.setStatusId(prevStatusId, false)
      statusAssignee.setSubStatusId(prevSubStatus)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setMultiSubStatus = async (
    statusAssignee: StatusAssignee,
    newStatusId: number,
    subStatusIdsToAdd: number[],
    subStatusIdsToDelete: number[],
    comment?: string
  ) => {
    const prevStatusId = statusAssignee.statusId!
    const prevSubStatusIds = statusAssignee.subStatuses
    try {
      statusAssignee.setStatusId(newStatusId, true)
      statusAssignee.addSubStatusIds(subStatusIdsToAdd)
      statusAssignee.deleteSubStatusIds(subStatusIdsToDelete)
      const response = await this.owner.loginStore.fetchWithUser(
        `${config.apiUrl}/StatusAssignee/${statusAssignee.id}/multiSubStatus`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            statusAssigneeId: statusAssignee.id,
            statusId: newStatusId,
            subStatusIdsToAdd: subStatusIdsToAdd,
            subStatusIdsToDelete: subStatusIdsToDelete,
            comment: comment
          })
        }
      )
      if (!response.ok) {
        throw new Error('Failed to save multi sub statuses.')
      }
    } catch (error) {
      console.error(error)
      statusAssignee.setStatusId(prevStatusId, true)
      statusAssignee.setSubStatusId(prevSubStatusIds)
      this.owner.setErrorMessage('Failed to update status, please try again.')
    }
  }

  setMailboxFullFileNumber = (itemId: number, fullFileNumber: string) => {
    if (
      this.owner.itemStore.currentItem?.id === itemId &&
      this.owner.itemStore.currentItem?.order?.fullFileNumber !== fullFileNumber
    )
      MailboxOrderChangeEvent.dispatch({
        fullFileNumber: fullFileNumber
      })
  }

  setMailboxOrderProperty = (property: OrderProperty) => {
    MailboxOrderPropertyEvent.dispatch({
      orderProperty: property
    })
  }

  addOrUpdateItem = (newItem: Item, hasUnreadEmail?: boolean) => {
    const board = this.boards.find(b => b.id === newItem.boardId)
    const item = board?.items.find(i => i.id === newItem.id)
    if (newItem.order?.fileNumber && newItem.isMain)
      this.owner.mocBoardStore.addOrUpdateItem(
        newItem.id,
        newItem.boardId,
        newItem.order.fileNumber
      )
    if (item) {
      item.setItem(new Item(newItem), hasUnreadEmail)
    } else {
      runInAction(() => {
        if (hasUnreadEmail) newItem.hasUnreadEmail = true
        board?.items.unshift(newItem)

        this.owner.orderStore.getBoardItemsOfOrders()
      })
    }
    if (newItem.order) {
      this.updateOrderProperties(newItem, newItem.order)
      this.updateMailboxOrderProperty(newItem.order)
    }
    this.getEmailStatusByItemId(newItem)

    if (this.owner.searchStore.items.length) {
      const item = this.owner.searchStore.items.find(i => i.id === newItem.id)
      item?.setIsArchive(false)
    }

    if (this.archiveItemsId?.includes(newItem.id)) {
      this.setArchiveItemsId(
        this.archiveItemsId?.filter(id => id !== newItem.id)
      )
    }
  }

  get internalScreenViews() {
    return this.currentBoard?.views.filter(
      v => v.viewType === ViewType.FileData && v.usingType === UsingType.Item
    )
  }

  get infoFileDataViews() {
    const board =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.selectedBoard
        : this.currentBoard
    return board?.views.filter(
      v => v.viewType === ViewType.FileData && v.usingType === UsingType.App
    )
  }

  archiveOrRestoreItems = (itemsId: number[], isArchive: boolean) => {
    this.items
      .filter(item => itemsId.includes(item.id))
      .forEach(i => {
        i.setIsArchive(isArchive)
      })

    if (!isArchive)
      this.archiveItemsId = this.archiveItemsId?.filter(
        id => !itemsId.includes(id)
      )
    else {
      this.archiveItemsId?.push(...itemsId)
    }
  }

  deleteItem = (itemId: number) => {
    runInAction(() => {
      this.owner.mocBoardStore.deleteItem(itemId)
      const board = this.boards.find(
        b => this.items.find(i => i.id === itemId)?.boardId === b.id
      )
      const indexToRemove = board?.items?.findIndex(i => i.id === itemId)
      if (indexToRemove !== undefined && indexToRemove !== -1) {
        board?.items?.splice(indexToRemove, 1)
        this.owner.orderStore.getBoardItemsOfOrders()
      }
    })
  }

  fixfn = (itemId: number, order: Order) => {
    runInAction(() => {
      this.setMailboxFullFileNumber(itemId, new Order(order).fullFileNumber!)
      const item = this.items.find(i => i.id === itemId)
      item?.setOrder(new Order(order))
      this.owner.mocBoardStore.addOrUpdateItem(
        itemId,
        item?.boardId ?? 0,
        order.fileNumber!
      )
      this.owner.orderStore.getBoardItemsOfOrders()
      this.updateMailboxOrderProperty(order)
    })
  }

  updateIsHere = (userId: number, isHere: boolean) => {
    this.boards.forEach(
      b => b.members.find(m => m.user.id === userId)?.user?.setIsHere(isHere)
    )
    this.owner.userStore.users.find(u => u.id === userId)?.setIsHere(isHere)
  }

  updateAllIsHere = (isHere: boolean) => {
    this.boards.forEach(b => b.members.forEach(m => m.user?.setIsHere(isHere)))
    this.owner.loginStore.user?.setIsHere(isHere)
  }

  addOrUpdateProperty(property: Property) {
    const propToUpdate = this.properties[property.orderId].find(
      p => p.id === property.id
    )
    if (propToUpdate) propToUpdate?.setValues(new Property(property))
    else {
      if (!this.properties[property.orderId]) {
        this.properties[property.orderId] = []
      }
      this.properties[property.orderId].push(property)
    }
  }

  updateOrderProperties = (item: Item, order: Order) => {
    if (order.fileNumber)
      this.setMailboxFullFileNumber(item.id, new Order(order).fullFileNumber!)
    item.setOrderProperties(order)
    item.setItemProperty('isPriorityEditable', !order.isRush)
    if (order.isRush) {
      item.setItemProperty('priority', order.isRush)
    }
    this.boards
      .find(b => b.id === item.boardId)
      ?.setPropertiesByOrderId(order.id, order.properties)
  }

  updateMailboxOrderProperty = (order: Order) => {
    if (
      order.id === this.owner.itemStore.currentItem?.order?.id &&
      order.properties &&
      order.properties.length > 0 &&
      this.owner.permissionStore.getPermissionType(
        this.currentBoard?.itemMenuViews.find(
          v => v.viewType === ViewType.MailBox
        )?.id,
        RoleId.User,
        undefined,
        Control.EmailTemplates
      ) !== PermissionType.Invisible
    )
      this.setMailboxOrderProperty(order.properties[0])
  }

  getAllTimeTrackings = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/TimeTracking/all`)
      .then(res => {
        return res.json()
      })
      .then((timeTrackings: TimeTracking[]) => {
        if (timeTrackings) {
          timeTrackings.forEach(tracking => {
            if (!this.timeTrackingMap.has(tracking.itemId)) {
              this.timeTrackingMap.set(tracking.itemId, [])
            }
            this.timeTrackingMap.get(tracking.itemId)?.push(tracking)
          })
        }
      })
      .catch(e => {
        console.log('error:', e)
      })
  }

  listener = () => {
    this.owner.signalrStore.on(
      'SetWorkflowUser',
      (boardId: number, user: User | undefined) => {
        this.boards.find(b => b.id === boardId)?.setWorkflowUser(user)
      }
    )

    this.owner.signalrStore.on(
      'AddOrDeleteAssignee',
      (addList: StatusAssignee[], deleteItemsIds: number[], itemId: number) => {
        this.items
          .find(i => i.id === itemId)
          ?.setStatusAssignees(
            addList.map(
              a =>
                ({
                  ...a,
                  user: this.owner.userStore.users.find(u => u.id === a.userId)
                }) as StatusAssignee
            ),
            deleteItemsIds
          )
      }
    )

    this.owner.signalrStore.on(
      'AddOrUpdateStatusAssignee',
      (statusAssignee: StatusAssignee, isMultiUpdate: boolean) => {
        this.items
          .find(i => i.id === statusAssignee.itemId)
          ?.addOrUpdateStatusAssignee(
            {
              ...statusAssignee,
              user: this.owner.userStore.users.find(
                u => u.id === statusAssignee.userId
              )
            } as StatusAssignee,
            isMultiUpdate
          )
      }
    )

    this.owner.signalrStore.on(
      'UpdateStatusAssigneeAdditionalInfo',
      (statusAssignee: StatusAssignee) => {
        this.items
          .find(i => i.id === statusAssignee.itemId)
          ?.statusAssignees.find(sa => sa.id === statusAssignee.id)
          ?.setAdditionalInfo(statusAssignee.additionalInfo)
      }
    )

    this.owner.signalrStore.on(
      'UpdatePriority',
      (itemId: number, isRush: boolean) => {
        this.items
          .find(i => i.id === itemId)
          ?.setItemProperty('priority', isRush)
      }
    )

    this.owner.signalrStore.on(
      'UpdateLastEmailReceivedAt',
      (itemId: number, date: Date) => {
        this.items
          .find(i => i.id === itemId)
          ?.setItemProperty('lastEmailReceivedAt', date)
      }
    )

    this.owner.signalrStore.on(
      'UpdateIsArchive',
      (itemsId: number[], isArchive: boolean) => {
        this.archiveOrRestoreItems(itemsId, isArchive)
        ArchiveItemEvent.dispatch({itemId: itemsId[0], isArchive: isArchive})
        if (isArchive) {
          this.setArchiveItemsId(
            this.archiveItemsId
              ?.concat(itemsId)
              .filter((v, i, a) => a.indexOf(v) === i)
          )
        } else {
          this.setArchiveItemsId(
            this.archiveItemsId?.filter(id => !itemsId.includes(id))
          )
        }
      }
    )

    this.owner.signalrStore.on(
      'UpdateStatusAssigneeByRole',
      (itemId: number, roleId: number, statusId: number) => {
        this.items
          .find(i => i.id === itemId)
          ?.setStatusAssigneesByRole(roleId, statusId)
      }
    )

    this.owner.signalrStore.on(
      'DeleteStatusAssignee',
      (statusAssignee: StatusAssignee) => {
        this.items
          .find(i => i.id === statusAssignee.itemId)
          ?.deleteStatusAssignee(statusAssignee)
      }
    )

    this.owner.signalrStore.on(
      'AddOrUpdateItem',
      (newItem: Item, hasUnreadEmail?: boolean) => {
        this.addOrUpdateItem(new Item(newItem), hasUnreadEmail)
      }
    )

    this.owner.signalrStore.on(
      'AddOrUpdateItems',
      (items: Item[], unreadEmailsItems?: number[]) => {
        runInAction(() =>
          items.forEach(i =>
            this.addOrUpdateItem(new Item(i), unreadEmailsItems?.includes(i.id))
          )
        )
      }
    )

    this.owner.signalrStore.on(
      'UpdateItem',
      (
        itemId: number,
        dbColumnName: string,
        newValue: any,
        columnType: ColumnType | null
      ) => {
        const dbColumnNameLower = stringToLowerCase(dbColumnName)
        if (columnType === ColumnType.DateTime)
          newValue = newValue ? toLocalDate(newValue) : undefined
        this.items
          .find(i => i.id === itemId)
          ?.setItemProperty(dbColumnNameLower || '', newValue)
      }
    )

    this.owner.signalrStore.on(
      'UpdateOrder',
      (
        orderId: number,
        dbColumnName: string,
        newValue: any,
        columnType: ColumnType | null
      ) => {
        const dbColumnNameLower = stringToLowerCase(dbColumnName)
        if (columnType === ColumnType.DateTime)
          newValue = newValue ? toLocalDate(newValue) : undefined
        this.items.forEach(
          i =>
            i.order?.id === orderId &&
            i.order?.setOrderProperty(dbColumnNameLower || '', newValue)
        )
      }
    )

    this.owner.signalrStore.on('UpdateItemData', (itemData: ItemData) => {
      this.items
        .find(i => i.id === itemData.itemId)
        ?.itemData.find(id => id.columnId === itemData.columnId)
        ?.setData(itemData.data)
    })

    this.owner.signalrStore.on(
      'UpdateItemGroup',
      (itemId: number, groupId: number) => {
        this.items.find(i => i.id === itemId)?.setGroup(groupId)
      }
    )

    this.owner.signalrStore.on('syncOrders', (ordersToSync: Order[]) => {
      ordersToSync.forEach((o: Order) => {
        console.log('start to sync order', o.fileNumber)
        const items = this.items.filter(
          i => i.order?.fileNumber === o.fileNumber
        )
        items.forEach(item => this.updateOrderProperties(item, o))
        this.updateMailboxOrderProperty(o)
      })
    })

    this.owner.signalrStore.on(
      'UpdateMembersStatus',
      (members: Member[], boardId: number) => {
        const board = this.boards.find(b => b.id === boardId)
        members.forEach(
          m =>
            board?.members
              .find(mem => mem.user.id === m.userId)
              ?.setIsAvailable(m.isAvailable)
        )
      }
    )

    this.owner.signalrStore.on(
      'UpdateMemberStatus',
      (userId: number, boardId: number, status: boolean, updatedAt: Date) => {
        const board = this.boards.find(b => b.id === boardId)
        let member = board?.members.find(m => m.user.id === userId)
        member?.setIsAvailable(status)
        member?.setIsAvailableUpdatedAt(updatedAt)
      }
    )

    this.owner.signalrStore.on(
      'UpdateIsHere',
      (userId: number, isHere: boolean) => {
        this.updateIsHere(userId, isHere)
      }
    )

    this.owner.signalrStore.on('UpdateAllIsHere', (isHere: boolean) => {
      this.updateAllIsHere(isHere)
    })

    this.owner.signalrStore.on('AddMember', (member: Member) => {
      const board = this.boards.find(b => b.id === member.boardId)
      const existMember = board?.members.find(m => m.userId === member.userId)
      if (existMember === undefined) {
        board?.addMember(new Member(member))
        this.boardsMembers?.addMemberToBoard(
          member.boardId,
          new BoardUser(member.user)
        )
      }
    })

    this.owner.signalrStore.on(
      'UpdateDayWorker',
      (memberId: number, isDayWorker: boolean) => {
        const board = this.boards.find(b =>
          b.members.some(m => m.id === memberId)
        )
        board?.members.find(m => m.id === memberId)?.setIsDayWorker(isDayWorker)
        if (this.currentMember?.id === memberId)
          MailboxDayWorkerChangeEvent.dispatch({
            isDayWorker: isDayWorker
          })
      }
    )

    this.owner.signalrStore.on(
      'UpdateDayWorkerMembers',
      (members: Member[]) => {
        members.forEach(m => {
          const board = this.boards.find(b => b.id === m.boardId)
          board?.members.find(m => m.id === m.id)?.setIsDayWorker(false)
        })
      }
    )

    this.owner.signalrStore.on(
      'UpdateNotAvailableMembers',
      (members: Member[]) => {
        console.log('updatedMembers', members)
        members.forEach(m => {
          const board = this.boards.find(b => b.id === m.boardId)
          board?.members
            .find(mb => mb.userId === m.userId)
            ?.setIsAvailable(false)
        })
      }
    )

    this.owner.signalrStore.on(
      'UpdateComment',
      (itemId: number, comment: string, userId: number, createdOn: Date) => {
        this.items
          .find(i => i.id === itemId)
          ?.setComment(comment, createdOn, userId)
      }
    )

    this.owner.signalrStore.on(
      'fixFileNumber',
      (itemId: number, order: Order) => {
        this.fixfn(itemId, order)
      }
    )

    this.owner.signalrStore.on('DeleteItem', (itemId: number) => {
      this.deleteItem(itemId)
    })

    this.owner.signalrStore.on(
      'UpdateStatusAssignees',
      (statusAssignees: StatusAssignee[], updatedItem?: Item) => {
        const item = this.items.find(i => i.id === statusAssignees[0].itemId)
        statusAssignees.forEach(s => {
          const currentStatusAssignee = item?.statusAssignees.find(
            x => x.id === s.id
          )
          currentStatusAssignee?.setStatusId(s.statusId)
          currentStatusAssignee?.setSubStatusId(s.subStatuses)
          currentStatusAssignee?.setComment(s.comment)
        })
        if (updatedItem)
          // the comment is overrided in case of override sub status.
          item?.setComment(
            updatedItem?.comment,
            updatedItem?.commentCreatedOn,
            updatedItem?.commentUserId
          )
      }
    )

    this.owner.signalrStore.on('AddItemUpdate', (update: Update) => {
      const item = this.items.find(i => i.id === update.itemId)
      item?.updates?.push(update)
    })

    this.owner.signalrStore.on('updateItemUpdates', (updates: Update[]) => {
      updates.forEach(u => {
        const item = this.items.find(i => i.id === u.itemId)
        if (!item || !item.updates) return
        const existUpdate = item?.updates?.find(
          itemUpdate => itemUpdate.id === u.id
        )
        if (existUpdate) existUpdate.setUpdate(u)
      })
    })

    this.owner.signalrStore.on('DeleteItemUpdate', (update: Update) => {
      const item = this.items.find(i => i.id === update.itemId)
      item?.setUpdates(item?.updates?.filter(i => i.id !== update.id))
    })

    this.owner.signalrStore.on(
      'StartTimeTracking',
      (timeTracking: TimeTracking) => {
        const item = this.items.find(i => i.id === timeTracking.itemId)
        const tt = new TimeTracking({
          ...timeTracking,
          start: new Date(timeTracking.start)
        })
        item?.timeTrackings?.unshift(tt)
        this.setCurrentTimeTracking(tt)
      }
    )

    this.owner.signalrStore.on(
      'StopTimeTracking',
      (timeTracking: TimeTracking) => {
        const tt = new TimeTracking({
          ...timeTracking,
          stop: new Date(timeTracking.stop!)
        })
        const item = this.items.find(i => i.id === tt.itemId)
        item?.timeTrackings
          .find(t => t.id === tt.id)
          ?.setStop(timeTracking.stop)
        this.setCurrentTimeTracking(tt)
      }
    )

    this.owner.signalrStore.on(
      'DeleteTimeTracking',
      (timeTrackingId: number) => {
        const item = this.items.find(
          i => i.timeTrackings?.find(t => t.id === timeTrackingId)
        )
        const index = item?.timeTrackings.findIndex(
          t => t.id === timeTrackingId
        )
        if (index !== -1 && index !== undefined) {
          const roleId = item?.timeTrackings[index].roleId
          item?.timeTrackings.splice(index, 1)
          this.setCurrentTimeTracking({
            itemId: item?.id || 0,
            roleId: roleId
          } as TimeTracking)
        }
      }
    )

    this.owner.signalrStore.on(
      'AddTimeTracking',
      (timeTracking: TimeTracking) => {
        const item = this.items.find(i => i.id === timeTracking.itemId)
        const tt = new TimeTracking({
          ...timeTracking,
          start: new Date(timeTracking.start),
          stop: new Date(timeTracking.stop!)
        })
        let index = item?.timeTrackings.findIndex(t => t.start < tt.start)
        index = index === -1 ? item?.timeTrackings.length : index
        item?.timeTrackings.splice(index || 0, 0, tt)
        this.setCurrentTimeTracking(tt)
      }
    )

    this.owner.signalrStore.on(
      'UpdateEmailsStatusSummary',
      (itemId: number, data: any) => {
        const item = this.items.find(i => i.id === itemId)
        const statusMap = new Map<number, number>(
          Object.entries(data).map(([key, value]) => [
            Number(key),
            value as number
          ])
        )
        item?.setEmailsStatusSummary(statusMap)
      }
    )

    this.owner.signalrStore.on(
      'UpdateUnreadEmailsForUser',
      (users: number[], itemId: number, hasUnreadEmails: boolean) => {
        if (
          this.owner.loginStore.user?.id &&
          users.includes(this.owner.loginStore.user?.id)
        ) {
          const item = this.items.find(i => i.id === itemId)
          item?.setHasUnreadEmail(hasUnreadEmails)
        }
      }
    )
  }
}

import {
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Stack,
  Typography
} from '@mui/material'
import ReceiptIcon from '@mui/icons-material/Receipt'
import PersonIcon from '@mui/icons-material/Person'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {FunctionUsed} from 'src/store/SharedStore'

const AllFilesEmailsStatusSummary = observer(() => {
  const {
    boardStore: {allFilesEmailsStatusSummary, currentBoard},
    sharedStore: {conditions},
    viewStore: {currentView}
  } = useMainStore()

  const workingOnItStatusId = conditions[FunctionUsed.WorkingOnItStatusId].find(
    c => c.viewIds?.includes(currentView?.id ?? 0)
  )?.statusIds?.[0]

  return (
    <Card sx={{width: '100%'}}>
      <CardContent sx={{p: 1, paddingBottom: '8px !important'}}>
        <Stack alignItems={'center'} direction={'row'}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Stack position={'relative'} display={'inline-flex'}>
              <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                  color: theme => theme.palette.error.light,
                  height: 32,
                  width: 32
                }}
              />
              <PersonIcon
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '30%',
                  height: 16,
                  width: 16
                }}
                color="error"
              />{' '}
            </Stack>
            <Stack>
              <Typography variant="body2" fontWeight={700}>
                New Email
              </Typography>
              <Typography variant="caption">
                <Typography variant="caption" sx={{fontWeight: 600}}>
                  {allFilesEmailsStatusSummary?.get(
                    Number(currentBoard?.newEmailStatusId)
                  )}
                </Typography>{' '}
                Emails
              </Typography>{' '}
            </Stack>
          </Stack>
          <Divider
            sx={{
              borderStyle: 'dashed',
              borderColor: theme => theme.palette.grey[300],
              mx: 4
            }}
            flexItem
            orientation="vertical"
          />
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
            <Stack position={'relative'} display={'inline-flex'}>
              <CircularProgress
                variant="determinate"
                value={100}
                sx={{
                  color: theme => theme.palette.secondary.light,
                  height: 32,
                  width: 32
                }}
              />
              <ReceiptIcon
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '30%',
                  height: 16,
                  width: 16
                }}
                fontSize="small"
                color="secondary"
              />{' '}
            </Stack>
            <Stack>
              <Typography variant="body2" fontWeight={700}>
                Assigned
              </Typography>
              <Typography variant="caption">
                <Typography variant="caption" sx={{fontWeight: 600}}>
                  {allFilesEmailsStatusSummary?.get(
                    Number(workingOnItStatusId)
                  )}
                </Typography>{' '}
                Emails
              </Typography>{' '}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
})

export default AllFilesEmailsStatusSummary

import {
  LocalizationProvider,
  pickersLayoutClasses,
  TimeField
} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {StaticDateRangePicker} from '@mui/x-date-pickers-pro/StaticDateRangePicker'
import {useState} from 'react'
import dayjs, {Dayjs} from 'dayjs'

import {Box, Button, Stack, Typography} from '@mui/material'
import duration from 'dayjs/plugin/duration'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'

dayjs.extend(duration)

export interface ResponsiveDateRangePickersProps {
  onBack: () => void
  itemId: number
  roleId: number
  updateTotalMilliseconds: () => void
}

const ResponsiveDateRangePickers = observer(
  ({
    onBack,
    itemId,
    roleId,
    updateTotalMilliseconds
  }: ResponsiveDateRangePickersProps) => {
    const {
      setErrorMessage,
      boardStore: {addTimeTrackingManually}
    } = useMainStore()
    const [value, setValue] = useState<[Dayjs | null, Dayjs | null]>([
      dayjs(),
      dayjs()
    ])

    const onChangeTime = (val: Dayjs, index: number) => {
      if (!val.isValid()) return
      const newValue = [...value]
      if (newValue[index] !== null) {
        newValue[index] = (newValue[index] as Dayjs)
          .set('hour', val.hour())
          .set('minute', val.minute())
          .set('second', val.second())
      }
      setValue(newValue as [Dayjs | null, Dayjs | null])
    }

    const updateDate = (current: Dayjs | null, newVal: Dayjs | null) =>
      current
        ?.set('year', newVal?.year() || current.year())
        .set('month', newVal?.month() || current.month())
        .set('date', newVal?.date() || current.date()) || current

    const onChangeDate = (val: [Dayjs | null, Dayjs | null]) => {
      const updatedDateTime: [Dayjs | null, Dayjs | null] = [
        updateDate(value[0], val[0] || val[1]),
        updateDate(value[1], val[1] || val[0])
      ]

      setValue(updatedDateTime)
    }

    const getTimeDifference = () => {
      if (value[0] && value[1]) {
        const hours = value[1].diff(value[0], 'hour')
        const minutes = value[1].diff(value[0], 'minute') % 60
        const seconds = value[1].diff(value[0], 'second') % 60
        return `${hours}h ${minutes}m ${seconds}s`
      }
      return '0h 0m 0s'
    }

    return (
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize="18px" fontWeight={700}>
            Add session
          </Typography>
          <Button onClick={onBack}>Back</Button>
        </Stack>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <StaticDateRangePicker
            sx={{
              [`.${pickersLayoutClasses.contentWrapper}`]: {
                alignItems: 'center',
                height: '320px'
              }
            }}
            value={value}
            onChange={onChangeDate}
            slots={{toolbar: () => null, actionBar: () => null}}
            maxDate={dayjs()}
          />
          <Stack direction="row" justifyContent="space-around">
            <TimeField
              value={value[0]}
              onChange={(val: any) => {
                onChangeTime(val, 0)
              }}
              label="Start at"
              size="small"
              sx={{width: '120px'}}
            />
            <TimeField
              value={value[1]}
              onChange={(val: any) => {
                onChangeTime(val, 1)
              }}
              label="End at"
              size="small"
              sx={{width: '120px'}}
            />
          </Stack>
          <Stack direction="row" justifyContent="space-around" mt={4}>
            <Typography>{getTimeDifference()}</Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (value[0] && value[1] && value[1].isAfter(value[0])) {
                  addTimeTrackingManually(
                    value as [Dayjs, Dayjs],
                    itemId,
                    roleId
                  )
                  updateTotalMilliseconds()
                  onBack()
                } else {
                  setErrorMessage('You have entered invalid data')
                }
              }}
            >
              Add session
            </Button>
          </Stack>
        </LocalizationProvider>
      </Box>
    )
  }
)

export default ResponsiveDateRangePickers

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
type Guid = string

export default class Order implements Base {
  id: number

  fileNumber?: string

  isRush: boolean

  county: string

  dueDate: Date

  propertyUse: number

  orderType: number

  transactionType: number

  state: string

  guid: Guid

  closer: string

  orderEntry: string

  orderStatus: number

  titleCompany?: string

  propertyAddress?: string

  parcelNumber?: number

  salesPrice?: number

  buyersNames?: string

  sellersNames?: string

  checklist4Notes?: string

  checklist27Assignee?: string

  checklist5Notes?: string

  checklist6Notes?: string

  searchOrderBy?: string

  propertyType?: string

  prefix?: string

  loanAmount?: number

  closingDate?: Date

  properties?: Property[]

  orderCompany?: OrderCompany

  dt2DueDate?: Date

  njDueDate?: Date

  multiLoanAmounts?: string

  city?: string

  zipCode?: string

  closerName?: string

  closerEmail?: string

  closerPhone?: string

  lwProcessor?: string

  constructor(order: Order) {
    this.id = order.id
    this.fileNumber = order.fileNumber
    this.isRush = order.isRush
    this.county = order.county
    this.dueDate = order.dueDate
    this.propertyUse = order.propertyUse
    this.orderType = order.orderType
    this.transactionType = order.transactionType
    this.state = order.state
    this.guid = order.guid
    this.closer = order.closer
    this.orderEntry = order.orderEntry
    this.orderStatus = order.orderStatus
    this.titleCompany = order.titleCompany
    this.propertyAddress = order.propertyAddress
    this.parcelNumber = order.parcelNumber
    this.salesPrice = order.salesPrice
    this.checklist27Assignee = order.checklist27Assignee
    this.buyersNames = order.buyersNames
    this.sellersNames = order.sellersNames
    this.checklist4Notes = order.checklist4Notes
    this.checklist5Notes = order.checklist5Notes
    this.checklist6Notes = order.checklist6Notes
    this.searchOrderBy = order.searchOrderBy
    this.propertyType = order.propertyType
    this.prefix = order.prefix
    this.properties = order.properties
    this.closingDate = order.closingDate
    this.loanAmount = order.loanAmount
    this.orderCompany = order.orderCompany
    this.dt2DueDate = order.dt2DueDate
    this.njDueDate = order.njDueDate
    this.multiLoanAmounts = order.multiLoanAmounts
    this.city = order.city
    this.zipCode = order.zipCode
    this.closerName = order.closerName
    this.closerEmail = order.closerEmail
    this.closerPhone = order.closerPhone
    this.lwProcessor = order.lwProcessor
    makeAutoObservable(this)
  }

  get isCommertial() {
    return !['NJ', 'TX', 'NY'].includes(this.state?.toUpperCase())
  }

  get fullFileNumber() {
    return this.prefix
      ? this.prefix.toUpperCase() + '-' + this.fileNumber
      : this.fileNumber
  }

  setOrderProperty = (propertyName: string, value: any) => {
    if (propertyName in this) {
      ;(this as any)[propertyName] = value
    } else {
      console.log('Property not found or inaccessible.')
    }
  }
}

export class Property implements Base {
  id: number

  orderId: number

  address?: string

  apn?: string

  propertyType?: string

  block?: string

  lot?: string

  county?: string

  township?: string

  city?: string

  state?: string

  municipality?: string

  constructor(property: Property) {
    this.id = property.id
    this.orderId = property.orderId
    this.address = property.address
    this.apn = property.apn
    this.propertyType = property.propertyType
    this.block = property.block
    this.lot = property.lot
    this.county = property.county
    this.township = property.township
    this.city = property.city
    this.state = property.state
    this.municipality = property.municipality
    makeAutoObservable(this)
  }

  setValues = (property: Property) => {
    this.address = property.address
    this.apn = property.apn
    this.propertyType = property.propertyType
    this.block = property.block
    this.lot = property.lot
    this.county = property.county
    this.township = property.township
    this.municipality = property.municipality
  }
}

export enum OrderCompany {
  Select = 1,
  Titlewave,
  Sutton
}

import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {observer} from 'mobx-react-lite'
import Item from 'src/entities/Item'
import {useMainStore} from 'src/context/Main'
import {Typography, Stack} from '@mui/material'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import Label from 'src/components/label/Label'
import NoContent from 'src/components/custom/NoContent'
import {Tabs} from 'src/store/SharedStore'

interface InfoPopupTabsProps {
  item: Item
}

const AssignTab = observer(({item}: InfoPopupTabsProps) => {
  const mainStore = useMainStore()
  const {currentBoard, getBoardMember} = mainStore.boardStore
  const {selectedBoard} = mainStore.searchStore
  const {currentTab} = mainStore.sharedStore

  const board = currentTab === Tabs.Search ? selectedBoard : currentBoard

  const rows = item.statusAssigneesWithoutDefault().map((sa, i) => {
    const user = getBoardMember(board?.id, sa.userId)
    const role = board?.getRoleById(sa.roleId)
    const statusData = board?.getColumnDataByStatus(sa.statusId)
    return {
      id: i,
      user,
      role,
      statusData
    }
  })
  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'Name',
      flex: 1,
      renderCell: params => {
        return (
          <Stack
            direction="row"
            spacing={1.5}
            width="100%"
            alignItems="center"
            key={params.row.user?.id}
          >
            <CuiAvatar
              size={30}
              name={params.row.user?.fullName}
              value={params.row.user?.initials}
              isHere={params.row.user?.isHere}
            />
            <Typography variant={'subtitle2'}>
              {params.row.user?.fullName}
            </Typography>
          </Stack>
        )
      },
      valueGetter: params => {
        return params.row.user?.fullName
      },
      align: 'center'
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      renderCell: params => {
        if (params.row.role?.parentRoleName) {
          return (
            <div>
              <b>{params.row.role?.parentRoleName}</b>
              <br />
              {params.row.role?.name}
            </div>
          )
        }
        return <b>{params.row.role?.name}</b>
      },
      valueGetter: params => {
        return `${
          params.row.role?.parentRoleName
            ? params.row.role?.parentRoleName + ' '
            : ''
        }${params.row.role?.name}`
      }
    },
    {
      field: 'status',
      headerName: 'Role Status',
      flex: 1,
      renderCell: params =>
        params.row.statusData.text ? (
          <Label color={params.row.statusData.color} width="auto">
            {params.row.statusData.text}
          </Label>
        ) : (
          <></>
        ),
      valueGetter: params => {
        return params.row.statusData.text
      }
    }
  ]

  if (!rows.length) return <NoContent text="No assigned users" />

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      hideFooter
      rowHeight={60}
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none'
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
          outline: 'none !important'
        },
        '&, [class^=MuiDataGrid]': {border: 'none'},
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: 'grey.200'
        }
      }}
    />
  )
})
export default AssignTab

import {useState} from 'react'
import {
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material'
import MoreIcon from 'src/images/more.svg?react'
import MoveItemIcon from 'src/images/moveItem.svg?react'
import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import {Control, PermissionType} from 'src/entities/PermissionData'
import {FunctionUsed} from 'src/store/SharedStore'
import Condition from 'src/entities/Condition'

export interface MoreMenuProps {
  itemId: number
  itemGroupId: number
}

const MoreMenu = observer(({itemId, itemGroupId}: MoreMenuProps) => {
  const {
    boardStore: {setItemGroup, setIsArchive, currentBoard},
    viewStore: {currentView},
    permissionStore: {getPermissionType},
    sharedStore: {conditions}
  } = useMainStore()

  const showControl = (control: Control): PermissionType => {
    const permissionType = getPermissionType(
      currentView?.id,
      undefined,
      undefined,
      control
    )

    return permissionType
  }
  const moveToArchivePermissionType = showControl(Control.MoveToArchive)
  const moveToGroupPermissionType = showControl(Control.MoveToGroup)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [newItemGroupId, setNewItemGroupId] = useState<number>(0)
  const open = Boolean(anchorEl)

  const currentViewGroup = currentView?.viewGroups?.find(
    v => v.itemGroupIds?.includes(itemGroupId)
  )?.id

  const onMore = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onMoveGroup = () => {
    setOpenDialog(true)
  }

  const getAvaibleGroup = () => {
    const condition = conditions[FunctionUsed.MoveToAvaibleGroup]

    if (condition && currentView?.id) {
      const relevantConditions = condition.filter(
        c =>
          c.viewIds?.includes(currentView.id) &&
          currentViewGroup &&
          c.viewGroupIds?.includes(currentViewGroup)
      )
      const availableGroups = relevantConditions.map((c: Condition) => {
        if (c.rolesToApply?.length && c.statusIds?.length) {
          var statusAssignee = currentBoard?.items
            .find(i => i.id === itemId)
            ?.statusAssignees.find(s => s.roleId === c.rolesToApply![0])
          if (
            statusAssignee?.statusId &&
            c.statusIds[0] === statusAssignee?.statusId
          )
            return c.additionalInfo?.split(',')
          return undefined
        } else return c.additionalInfo?.split(',')
      })
      return availableGroups.filter(ag => ag !== undefined).flat()
    }
  }

  const avaibleGroups = getAvaibleGroup()

  const currentAvaibleGroups =
    currentView?.visibleViewGroups.filter(
      g => avaibleGroups && avaibleGroups.includes(g.id.toString())
    ) || []

  const onArchive = () => {
    setIsArchive(itemId, true)
    handleClose()
  }

  return (
    <>
      <IconButton onClick={onMore}>
        <MoreIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          py: 1.5
        }}
      >
        {moveToGroupPermissionType !== PermissionType.Invisible && (
          <MenuItem
            value="moveGroup"
            key="moveGroup"
            disabled={currentAvaibleGroups?.length === 0}
            onClick={() => {
              onMoveGroup()
              handleClose()
            }}
          >
            <ListItemIcon>
              <MoveItemIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Move to Another Group</Typography>
          </MenuItem>
        )}
        {moveToArchivePermissionType !== PermissionType.Invisible && (
          <MenuItem
            value="archiveItem"
            key="archiveItem"
            disabled={moveToArchivePermissionType === PermissionType.Readonly}
            onClick={() => {
              onArchive()
            }}
          >
            <ListItemIcon>
              <ArchiveOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body2">Archive</Typography>
          </MenuItem>
        )}
      </Menu>
      <CuiAlertDialog
        isFullWidth
        maxWidth="xs"
        open={openDialog}
        showCancel
        close={() => {
          setOpenDialog(false)
        }}
        title="Choose a group"
        body={
          <RadioGroup>
            {currentAvaibleGroups.map(g => (
              <FormControlLabel
                sx={{px: 3}}
                key={g.id}
                value={g.id}
                control={<Radio />}
                label={g.name}
                onClick={() => {
                  setNewItemGroupId(g.itemGroupIds[0])
                }}
              />
            ))}
          </RadioGroup>
        }
        disableOkButton={newItemGroupId === 0}
        okButtonText="Done"
        okClick={() => {
          if (newItemGroupId)
            setItemGroup(itemId, itemGroupId, newItemGroupId).finally(() => {
              setOpenDialog(false)
            })
        }}
      />
    </>
  )
})

export default MoreMenu

import loadable from '@loadable/component'
import {useMainStore} from 'src/context/Main'
import {useEffect, useState} from 'react'
import Item from 'src/entities/Item'
import {
  MailboxAssignEvent,
  MailboxOnAssignClickDetails,
  MailboxOnAssignClickEvent
} from '@madisoncres/title-general-package'
import UserMenuList from 'src/components/user/UserMenuList'

const EmailConversationsList = loadable(
  () => import('cbMailbox/EmailConversationsList')
)

interface MailboxTabProps {
  item: Item
}

const MailboxTab = ({item}: MailboxTabProps) => {
  const {
    itemStore: {setCurrentItem}
  } = useMainStore()

  const [assignee, setAssignee] = useState<{
    anchorEl: HTMLButtonElement | undefined
    currentAssignee: number | undefined
  }>({anchorEl: undefined, currentAssignee: undefined})

  useEffect(() => {
    setCurrentItem(item)
  }, [item, setCurrentItem])

  const onClose = () => {
    setAssignee({anchorEl: undefined, currentAssignee: undefined})
  }

  const onCancel = () => {
    MailboxAssignEvent.dispatch(undefined)
    onClose()
  }

  const onAssigned = (userId?: number, comment = '') => {
    MailboxAssignEvent.dispatch({userId, comment})
    onClose()
  }

  useEffect(() => {
    const callback = (e: CustomEvent<MailboxOnAssignClickDetails>) => {
      setAssignee({
        anchorEl: e.detail.anchor,
        currentAssignee: e.detail.currentAssigneeId
      })
    }
    MailboxOnAssignClickEvent.subscribe(callback)
    return () => MailboxOnAssignClickEvent.unsubscribe(callback)
  }, [])
  return (
    <>
      <EmailConversationsList />
      {assignee.anchorEl && (
        <UserMenuList
          ignoreUsers={
            assignee.currentAssignee ? [assignee.currentAssignee] : undefined
          }
          anchorEl={assignee.anchorEl}
          onChoose={onAssigned}
          onCancel={onCancel}
          enableUnassign
        />
      )}
    </>
  )
}

export default MailboxTab

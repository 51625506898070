import AvatarGroup, {AvatarGroupProps} from '@mui/material/AvatarGroup'
import Avatar, {avatarClasses} from '@mui/material/Avatar'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import User from 'src/entities/User'
import {observer} from 'mobx-react-lite'
import {Box} from '@mui/material'
import CuiWhiteTooltip from 'src/components/custom/CuiWhiteTooltip'

export interface CuiGroupAvatarsProps extends AvatarGroupProps {
  users: User[]
  size?: number
  borderedUserId?: number
}

const CuiGroupAvatars = observer(
  ({users, size, borderedUserId, ...props}: CuiGroupAvatarsProps) => {
    const uniqueUsers = [
      ...new Map(users.filter(u => u).map(u => [u.id, u])).values()
    ]
    const surplusUsers: User[] = uniqueUsers.slice(3)

    const renderSurplus = () => {
      return (
        <CuiWhiteTooltip
          title={
            <Box>
              {surplusUsers.map((user: User) => (
                <div key={user.id}>{user.fullName}</div>
              ))}
            </Box>
          }
          arrow
        >
          <Avatar sx={{width: 24, height: 24}}>+{surplusUsers.length}</Avatar>
        </CuiWhiteTooltip>
      )
    }

    return (
      <AvatarGroup
        {...props}
        renderSurplus={renderSurplus}
        sx={{
          ...props.sx,
          ...(size && {
            [`& .${avatarClasses.root}`]: {
              width: size,
              height: size,
              fontSize: size / 2
            }
          })
        }}
      >
        {uniqueUsers.map(u => (
          <CuiAvatar
            isHere={uniqueUsers.length === 1 ? u.isHere : undefined}
            key={u.id}
            name={u.fullName}
            value={u.initials}
            size={size}
            border={u.id === borderedUserId}
          />
        ))}
      </AvatarGroup>
    )
  }
)

export default CuiGroupAvatars

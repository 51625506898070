import {Stack, Typography, Button, Checkbox, TextField} from '@mui/material'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import Column, {ColumnData, DynamicGridColumn} from 'src/entities/Column'
import {observer} from 'mobx-react-lite'
import {PermissionType} from 'src/entities/PermissionData'
import ArrowRight from '@mui/icons-material/ArrowRight'
import Item from 'src/entities/Item'
import Dropdown, {
  DropdownMenuItem,
  DropdownNestedMenuItem
} from 'src/components/custom/CuiDropdown'
import {useMainStore} from 'src/context/Main'
import StatusAssignee from 'src/entities/StatusAssignee'
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useCallback, useEffect, useState} from 'react'

interface AssigneeStatusProps {
  column: Column | DynamicGridColumn
  item: Item
  permissionType: PermissionType
  isCell: boolean
}

interface CommentsProps {
  comment?: string
  setComment: React.Dispatch<React.SetStateAction<string | undefined>>
}

const getAllTopParents = (column: Column | DynamicGridColumn) => {
  return column
    .data!.map(d => {
      const topParent = getTopParent(column.data!, d.id)
      if (topParent?.id === d.id) return d
      return undefined
    })
    .filter(d => d !== undefined)
    .sort((a, b) => a.sort - b.sort)
}

const getTopParent = (
  data: ColumnData[],
  columnDataId: number
): ColumnData | undefined => {
  const columnData = data.find(d => d.id === columnDataId)
  if (!columnData) return undefined
  const myParent = data.find(
    d => d.subColumnData?.some(s => s.id === columnDataId)
  )
  if (!myParent) return columnData
  else {
    return getTopParent(data, myParent!.id)
  }
}

const getSubStatusTree = (
  columnData: ColumnData | undefined,
  subStatusId: number
): string[] => {
  if (!columnData) return []
  const names: string[] = []
  if (parseInt(columnData.columnValue) === subStatusId) {
    names.push(JSON.parse(columnData.data).text)
    return names
  }

  for (const subStatus of columnData.subColumnData!) {
    const subNames = getSubStatusTree(subStatus, subStatusId)
    if (subNames.length > 0) {
      names.push(JSON.parse(columnData.data).text)
      names.push(...subNames)
      return names
    }
  }
  return []
}

const getColumnDataText = (
  column: Column | DynamicGridColumn,
  columnDataId: number
) => {
  const columnDataIdStr = columnDataId.toString()

  const myColumnData = column.data!.find(d => d.columnValue === columnDataIdStr)
  if (myColumnData) return JSON.parse(myColumnData.data).text
}

const getTreeAsString = (
  column: Column | DynamicGridColumn,
  currentStatusAssignee: StatusAssignee,
  currentColumnData: ColumnData
) => {
  if (!currentStatusAssignee?.subStatuses) return ''
  const tree = getSubStatusTree(
    currentColumnData,
    currentStatusAssignee?.subStatuses[0]
  )
  tree.splice(
    tree.length - 1,
    1,
    currentStatusAssignee?.subStatuses
      .map(id => getColumnDataText(column, id))
      .join(', ')
  )
  tree.shift()
  return tree.join(' > ')
}

interface StatusLabelProps {
  column: Column | DynamicGridColumn
  currentStatusAssignee: StatusAssignee
}

export const StatusLabel = observer(
  ({column, currentStatusAssignee}: StatusLabelProps) => {
    const currentColumnData = column.data?.find(
      d => parseInt(d.columnValue) === currentStatusAssignee!.statusId
    )

    const currentData = currentColumnData
      ? JSON.parse(currentColumnData.data)
      : undefined

    const treeAsString = getTreeAsString(
      column,
      currentStatusAssignee,
      currentColumnData!
    )

    return (
      <LabelCol
        pointer
        text={
          currentData
            ? currentStatusAssignee?.subStatuses?.length > 0 &&
              currentColumnData?.subColumnData?.[0]
              ? treeAsString
              : currentData.text
            : ''
        }
        color={currentData ? currentData.color : 'primary'}
      />
    )
  }
)

const EditStatusLabel = observer(
  ({column, currentStatusAssignee}: StatusLabelProps) => {
    return (
      <Stack width="100%" direction="row" justifyContent="space-between">
        <StatusLabel
          column={column}
          currentStatusAssignee={currentStatusAssignee}
        />
        <ArrowDownIcon sx={{color: 'grey.500'}} />
      </Stack>
    )
  }
)

const Comment = ({comment, setComment}: CommentsProps) => {
  return (
    <>
      <Stack p={2}>
        <Typography fontSize="18px" fontWeight="700">
          Comments
        </Typography>
        <TextField
          fullWidth
          value={comment}
          id="outlined-textarea"
          placeholder="Write a comment…"
          multiline
          rows={2}
          onChange={e => {
            setComment(e.target.value)
          }}
          onKeyDown={e => {
            // Prevent focus shift to menu items when typing
            e.stopPropagation()
          }}
        />
      </Stack>
    </>
  )
}

const AssigneeStatus = observer(
  ({column, item, permissionType, isCell}: AssigneeStatusProps) => {
    const {
      boardStore: {
        setSubStatusAssignee,
        setStatusAssignee,
        setMultiSubStatus,
        currentBoard
      }
    } = useMainStore()
    const [selectedSubStatuses, setSelectedSubStatuses] = useState<number[]>([])
    const [forceClose, setForceClose] = useState(false)
    const [openSubMenuIndex, setOpenSubMenuIndex] = useState<number | null>(
      null
    )

    const isReadOnly = permissionType === PermissionType.Readonly

    const getSelectedSubStatuses = useCallback(() => {
      return column.data
        ?.map(d => +d.columnValue)
        .filter(s => currentStatusAssignee?.subStatuses?.includes(s))
    }, [column, currentStatusAssignee])

    var currentStatusAssignee = item.statusAssignees.find(
      s => s.roleId === column.roleId
    )

    const isStatusHasComment = (columnData: ColumnData) => {
      return (
        column.roleId &&
        currentBoard
          ?.getRoleById(column.roleId)
          ?.statusesWithComments?.toString() === columnData.columnValue
      )
    }

    const [comment, setComment] = useState(item.comment)

    useEffect(() => {
      setComment(item.comment)
    }, [item.comment])

    useEffect(() => {
      setSelectedSubStatuses(getSelectedSubStatuses() || [])
    }, [setSelectedSubStatuses, getSelectedSubStatuses])

    const onCancelAction = useCallback(() => {
      setSelectedSubStatuses(getSelectedSubStatuses() || [])
    }, [getSelectedSubStatuses, setSelectedSubStatuses])

    const onClick = (clickedColumnData: ColumnData) => {
      //click on parent menu, no need to do anythong, bacause parent cannot be chosen.
      if (
        clickedColumnData?.subColumnData &&
        clickedColumnData?.subColumnData.length > 0
      )
        return

      //click on multi sub status, no need to do anythong, bacause its saved only by submit button.
      if (clickedColumnData.isMultiSelect) return
      const topParent = getTopParent(column.data!, clickedColumnData.id)

      //click on sub status that not multi select.
      if (
        topParent &&
        topParent.id !== clickedColumnData.id &&
        !clickedColumnData.isMultiSelect
      ) {
        setSubStatusAssignee(
          currentStatusAssignee!,
          parseInt(topParent!.columnValue),
          currentStatusAssignee!.statusId!,
          clickedColumnData!,
          currentStatusAssignee!.subStatuses,
          true,
          true
        )
      }

      //click on regular status
      else {
        setStatusAssignee(
          currentStatusAssignee!,
          currentStatusAssignee!.statusId!,
          parseInt(clickedColumnData!.columnValue),
          currentStatusAssignee!.subStatuses,
          clickedColumnData.isOverrideSubStatus
        )
      }
    }

    const onToggleClick = (event: any) => {
      event.stopPropagation()
      const clickedStatus = parseInt(
        column.data?.find(d => d.id.toString() === event.target.id).columnValue
      )
      const currentIndex = selectedSubStatuses.indexOf(clickedStatus)
      const newSelected = [...selectedSubStatuses]
      if (currentIndex === -1) {
        newSelected.push(clickedStatus)
      } else {
        newSelected.splice(currentIndex!, 1)
      }
      setSelectedSubStatuses(newSelected)
    }

    const statusesToAdd = selectedSubStatuses.filter(
      id => !currentStatusAssignee?.subStatuses.includes(id)
    )
    const statusesToDelete = (currentStatusAssignee?.subStatuses ?? []).filter(
      id => !selectedSubStatuses.includes(id)
    )

    const onSubmit = (columnData: ColumnData) => {
      const topParent = getTopParent(column.data!, columnData.id)
      setForceClose(!forceClose)
      setMultiSubStatus(
        currentStatusAssignee!,
        parseInt(topParent!.columnValue),
        statusesToAdd,
        statusesToDelete,
        isStatusHasComment(columnData) ? comment : undefined
      )
    }

    const renderDropdownMenus = (columnData: ColumnData) => {
      const currentStatusData = JSON.parse(columnData.data)
      if (columnData.subColumnData?.length === 0) {
        return (
          <DropdownMenuItem
            id={columnData.id.toString()}
            onClick={() => onClick(columnData)}
          >
            {columnData.isMultiSelect && (
              <Checkbox
                id={columnData.id.toString()}
                onClick={e => onToggleClick(e)}
                checked={
                  selectedSubStatuses.indexOf(+columnData.columnValue) !== -1
                }
              />
            )}
            <LabelCol
              disableTextTransform
              pointer
              text={currentStatusData ? currentStatusData.text : ''}
              color={currentStatusData ? currentStatusData.color : 'primary'}
            />
          </DropdownMenuItem>
        )
      } else {
        return (
          <DropdownNestedMenuItem
            id={columnData.id}
            label={
              <LabelCol
                disableTextTransform
                pointer
                text={columnData ? JSON.parse(columnData.data).text : ''}
                color={
                  columnData ? JSON.parse(columnData.data).color : 'primary'
                }
                endIcon={
                  <ArrowRightIcon style={{paddingLeft: 8, fontSize: 20}} />
                }
              />
            }
            rightIcon={<ArrowRight />}
            extraComponent={
              isStatusHasComment(columnData) ? (
                <Comment comment={comment} setComment={setComment} />
              ) : (
                <></>
              )
            }
            item={item}
            openSubMenuIndex={openSubMenuIndex}
            setOpenSubMenuIndex={setOpenSubMenuIndex}
            menu={columnData.subColumnData?.map(x => renderDropdownMenus(x))}
            onSubmitAction={
              columnData.subColumnData?.find(s => s.isMultiSelect)
                ? () => {
                    onSubmit(columnData)
                  }
                : undefined
            }
            onCancelAction={onCancelAction}
            isSubmitDisabled={
              !(
                (statusesToAdd && statusesToAdd.length > 0) ||
                (statusesToDelete && statusesToDelete.length > 0) ||
                (comment && comment !== item.comment)
              )
            }
          />
        )
      }
    }

    if (currentStatusAssignee === undefined) return <></>

    return (
      <Stack sx={isCell ? {width: '100%', pr: 2, pl: 2} : {width: '200px'}}>
        {!isCell && (
          <Typography sx={{fontSize: 14, fontWeight: 600}}>
            {(column as Column).name}
          </Typography>
        )}
        <Dropdown
          isCell={isCell}
          trigger={
            isCell ? (
              <Stack>
                <EditStatusLabel
                  currentStatusAssignee={currentStatusAssignee}
                  column={column}
                />
              </Stack>
            ) : (
              <Button
                disabled={isReadOnly}
                variant="outlined"
                sx={{
                  p: 0.5,
                  flexDirection: 'column',
                  borderColor: theme => theme.palette.grey[500]
                }}
              >
                <EditStatusLabel
                  currentStatusAssignee={currentStatusAssignee}
                  column={column}
                />
              </Button>
            )
          }
          menu={getAllTopParents(column).map(d =>
            renderDropdownMenus(d as ColumnData)
          )}
          forceClose={forceClose}
        />
      </Stack>
    )
  }
)

StatusLabel.displayName = 'StatusLabel'
export default AssigneeStatus

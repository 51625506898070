import {makeAutoObservable, reaction} from 'mobx'
import Item from 'src/entities/Item'
import View, {UsingType, ViewType} from 'src/entities/View'
import {
  DocumentItemChangeEvent,
  MailboxEntityChangeEvent,
  MailboxControl,
  PermissionType,
  OrderProperty,
  DocumentCategory,
  ConversationEntityChangeEvent,
  ConversationCountEvent,
  ConversationCountDetails,
  UsersEvent,
  WorkspaceEntityChangeEvent,
  BoardMembersEvent
} from '@madisoncres/title-general-package'
import {MainStore} from 'src/store/MainStore'
import config from 'src/config'
import {Control, RoleId} from 'src/entities/PermissionData'
import {OrderCompany} from 'src/entities/Order'
import {FunctionUsed, Tabs} from 'src/store/SharedStore'
import {ColumnType} from 'src/entities/Column'
import {LabelProps} from '@madisoncres/title-general-package/src/entities/LabelProps'
import {moveObjectToStart} from 'src/utils/array'

export class ItemStore {
  currentItem?: Item

  currentOverItem?: Item

  currentView?: View

  isOpenMenu?: boolean = true

  isOpemViewMoal?: boolean = true

  questionCount?: number

  unreadQuestionCount?: number

  private isLoadingItem: boolean = false

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.boardStore.archiveItemsId,
      () => {
        if (this.currentItem) {
          this.dispatchBoardMembers()
        }
      }
    )

    this.questionCountListener()
  }

  get isUnknownFN() {
    return !this.currentItem?.order?.fileNumber
  }

  get myRolesId() {
    return this.currentItem?.statusAssignees
      .filter(a => a.userId === this.owner.loginStore.user?.id)
      ?.map(r => r.roleId)
  }

  get assignedItems() {
    return this.owner.boardStore.items.filter(i =>
      i.isUserAssignee({userId: this.owner.loginStore.user?.id})
    )
  }

  getMailboxPermissions = (viewId?: number) => {
    const newPermission = {} as Record<MailboxControl, PermissionType>
    newPermission[MailboxControl.CROrRDMenuItem] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.CROrRDMenuItem
      )
    newPermission[MailboxControl.ExternalQuestion] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.ExternalQuestion
      )
    newPermission[MailboxControl.Dt2Question] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.Dt2Question
      )
    newPermission[MailboxControl.RequestTypes] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.RequestTypes
      )
    newPermission[MailboxControl.NotHandled] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.NotHandled
      )
    newPermission[MailboxControl.Approval] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.Approval
      )
    newPermission[MailboxControl.EmailTemplates] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.EmailTemplates
      )
    newPermission[MailboxControl.DayWork] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.DayWork
      )
    newPermission[MailboxControl.Hide] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        undefined,
        undefined,
        Control.Hide
      )
    newPermission[MailboxControl.EmailStatus] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        undefined,
        undefined,
        Control.EmailStatus
      )
    newPermission[MailboxControl.ConversationEmailStatus] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        undefined,
        undefined,
        Control.ConversationEmailStatus
      )
    newPermission[MailboxControl.Thanks] =
      this.owner.permissionStore.getPermissionTypeByRoles(
        viewId!,
        [this.owner.boardStore.currentMember?.roleId ?? RoleId.User],
        undefined,
        Control.Thanks
      )
    newPermission[MailboxControl.Handled] =
      this.owner.permissionStore.getPermissionTypeByRoles(
        viewId!,
        [this.owner.boardStore.currentMember?.roleId ?? RoleId.User],
        undefined,
        Control.Handled
      )
    newPermission[MailboxControl.NotApproved] =
      this.owner.permissionStore.getPermissionTypeByRoles(
        viewId!,
        [this.owner.boardStore.currentMember?.roleId ?? RoleId.User],
        undefined,
        Control.NotApproved
      )
    newPermission[MailboxControl.Assign] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.EmailAssign
      )
    newPermission[MailboxControl.ConversationAssign] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.ConversationAssign
      )
    return newPermission
  }

  getDocsPermissions = (viewId?: number) => {
    const newPermission = {} as Record<DocumentCategory, PermissionType>
    newPermission[DocumentCategory.Invoices] =
      this.owner.permissionStore.getPermissionType(
        viewId,
        RoleId.User,
        undefined,
        Control.DocInvoiceTab
      )
    return newPermission
  }

  setOpemMenu = (isOpen: boolean) => {
    this.isOpenMenu = isOpen
  }

  setIsOpemViewMoal = (isOpen: boolean) => {
    this.isOpemViewMoal = isOpen
  }

  setCurrentItem = (item?: Item, viewType?: ViewType) => {
    const isDifferentItem = this.currentItem?.id !== item?.id
    const isDifferentBoard = this.currentItem?.boardId !== item?.boardId

    this.currentItem = item

    if (isDifferentItem) this.changeWorkspace()

    if (this.currentItem?.id) {
      if (isDifferentItem) {
        this.changeDocument()
        this.changeMailBox()
        this.changeQuestion()
        this.changeProofing()
        this.changeChat()
      }

      if (isDifferentBoard) {
        this.changeUsers()
      }
    }

    if (item === undefined) {
      this.setCurrentView(undefined)
      document.title = config.defaultTitle
    } else {
      document.title = item.order?.fileNumber || config.unknownFileNumberText
    }

    if (this.currentItem) {
      this.initCurrentView(viewType)
    }
  }

  setCurrentView = (view?: View) => {
    this.currentView = view
  }

  setCurrentHoverItem = (item?: Item) => {
    this.currentOverItem = item
  }

  setQuestionCount = (questionCount: number) => {
    this.questionCount = questionCount
  }

  setUnreadQuestionCount = (unreadQuestionCount: number) => {
    this.unreadQuestionCount = unreadQuestionCount
  }

  initCurrentView = (viewType?: ViewType) => {
    this.setCurrentView(
      this.owner.boardStore.currentBoard?.itemMenuViews.find(
        v => v.viewType === viewType
      )
    )
  }

  private changeDocument = () => {
    const board =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.searchStore.selectedBoard
        : this.owner.boardStore.currentBoard
    const docPermissions = this.getDocsPermissions(
      board?.itemMenuViews.find(v => v.viewType === ViewType.Documents)?.id
    )

    DocumentItemChangeEvent.dispatch({
      fileNumber: this.currentItem?.order?.fileNumber,
      itemId: this.currentItem?.id || 0,
      isInfo: false,
      permissions: docPermissions,
      isWorkspace: true,
      isWorkflow: true,
      isCommercial: this.currentItem?.boardId === config.commercialBoardId
    })
  }

  changeMailBox = () => {
    const board =
      this.owner.sharedStore.currentTab === Tabs.Search
        ? this.owner.boardStore.boards.find(
            b => b.id === this.owner.searchStore.selectedBoard?.id
          ) || this.owner.searchStore.selectedBoard
        : this.owner.boardStore.currentBoard

    const menuViewId = board?.itemMenuViews.find(
      v => v.viewType === ViewType.MailBox
    )?.id

    const permissions = this.getMailboxPermissions(menuViewId)
    const answeredLWStatusId = menuViewId
      ? this.owner.sharedStore.conditions[FunctionUsed.AnsweredLWStatusId].find(
          c => c.viewIds?.includes(menuViewId)
        )?.statusIds?.[0]
      : undefined
    const workingOnItStatusId = menuViewId
      ? this.owner.sharedStore.conditions[
          FunctionUsed.WorkingOnItStatusId
        ].find(c => c.viewIds?.includes(menuViewId))?.statusIds?.[0]
      : undefined
    const doneStatusId = menuViewId
      ? this.owner.sharedStore.conditions[FunctionUsed.DoneStatusId].find(
          c => c.viewIds?.includes(menuViewId)
        )?.statusIds?.[0]
      : undefined
    let orderProperty: OrderProperty | undefined
    if (
      permissions[MailboxControl.EmailTemplates] !== PermissionType.Invisible &&
      this.currentItem?.order?.id
    )
      orderProperty = board?.properties?.[this.currentItem.order.id]?.[0]

    const view = board?.views.find(
      v => v.viewType === ViewType.MailBox && v.usingType === UsingType.Info
    )
    let statuses: LabelProps[] = []
    let toAssignStatusId: number | undefined
    if (view) {
      const toAssignStatusCondition = this.owner.sharedStore.conditions[
        FunctionUsed.ToAssigneStatusId
      ].find(c => c.viewIds?.includes(view?.id))

      toAssignStatusId = toAssignStatusCondition?.statusIds?.[0]

      statuses =
        view?.columns
          ?.find(c => c.type === ColumnType.EmailStatus)
          ?.data?.sort((a, b) => a.sort - b.sort)
          .map(d => {
            const data = JSON.parse(d.data)

            return {
              value: d.columnValue,
              ...data
            } as LabelProps
          }) || []
    }

    MailboxEntityChangeEvent.dispatch({
      viewId: ViewType[ViewType.MailBox],
      entityId: this.currentItem?.id || 0,
      entityTypeId: config.itemEntityTypeId,
      boardId: board?.id || 0,
      isOrderExistInHomePage: true,
      fileNumber: this.currentItem?.order?.fileNumber ?? '',
      emailAccountId: board?.emailAccountId || 0,
      isDisplayPublicFolder: board?.isDisplayPublicFolder ?? false,
      isWrapUserIdInHtml: board?.isSendNotificationToSenderInNewEmail ?? false,
      isUpdateSentEmailDate: board?.isUpdateSentEmailDate ?? false,
      isSaveLastEmailBody: board?.isSaveLastEmailBody ?? false,
      isSaveSentEmailBody: board?.isSaveSentEmailBody ?? false,
      isHandledSentEmail: board?.isHandledSentEmail ?? false,
      isDayWorker: this.owner.boardStore.currentMember?.isDayWorker ?? false,
      isSelectFile:
        this.currentItem?.order?.orderCompany === OrderCompany.Select,
      replyToQuestionEmail: board?.replyToQuestionEmail,
      replyNameSignature: board?.replyNameSignature,
      departmentSignature: this.owner.departmentStore.departments.find(d =>
        d.boards.some(b => b.id === board?.id)
      )?.signature,
      fullFileNumber:
        this.currentItem?.order?.fullFileNumber ?? this.currentItem?.name ?? '',
      permissions: permissions,
      orderProperty: orderProperty,
      statuses: statuses,
      toAssignStatusId: toAssignStatusId,
      answeredLWStatusId: answeredLWStatusId,
      newEmailStatusId: board?.newEmailStatusId,
      isReadPerUser: board?.isReadPerUser,
      workingOnItStatusId: workingOnItStatusId,
      doneStatusId: doneStatusId
    })
  }

  changeQuestion = () => {
    ConversationEntityChangeEvent.dispatch({
      viewId: ViewType[ViewType.Question],
      entityId: this.currentItem?.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.question,
      additionalInfo: {
        fileNumber: this.currentItem?.order?.fileNumber,
        boardId: this.owner.boardStore.currentBoard?.id
      }
    })
  }

  changeProofing = () => {
    ConversationEntityChangeEvent.dispatch({
      viewId: ViewType[ViewType.Proofing],
      entityId: this.currentItem?.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.proofing,
      importanceConversationTypeId: config.conversationType.question,
      additionalInfo: {
        fileNumber: this.currentItem?.order?.fileNumber,
        boardId: this.owner.boardStore.currentBoard?.id
      }
    })
  }

  dispatchBoardMembers = () => {
    if (this.owner.boardStore.boardsMembers && !this.isUnknownFN) {
      const boardsName = this.owner.orderStore.getBoardsByFNWithFilter(
        this.currentItem?.order?.fileNumber,
        this.owner.boardStore.archiveItemsId || []
      )

      const members =
        this.owner.boardStore.boardsMembers?.getAllMembersByBoardsId(
          boardsName.map(b => b.id)
        )
      BoardMembersEvent.dispatch({
        boardsName: boardsName,
        allMembers: members,
        allBoardsMembers: this.owner.boardStore.boardsMembers
      })
    }
  }

  changeChat = () => {
    this.dispatchBoardMembers()

    ConversationEntityChangeEvent.dispatch({
      viewId: ViewType[ViewType.Chat],
      entityId: this.currentItem?.id,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.chat,
      additionalInfo: {
        fileNumber: this.currentItem?.order?.fileNumber,
        boardId: this.owner.boardStore.currentBoard?.id
      }
    })
  }

  changeWorkspace = () => {
    WorkspaceEntityChangeEvent.dispatch({
      orderNumber: this.currentItem?.order?.fileNumber ?? ''
    })
  }

  getItem = (itemId: number) => {
    if (this.isLoadingItem) return

    this.isLoadingItem = true
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/Items/${itemId}/full`)
      .then(res => {
        return res.json()
      })
      .then(data => {
        const item = new Item(data)
        const {currentBoard} = this.owner.boardStore
        if (currentBoard && !currentBoard.items.length) {
          this.owner.boardStore.setItems([item], currentBoard)
          currentBoard.setHasCurrentItemOnly(true)
          this.setCurrentItem(item)
        }
        this.isLoadingItem = false
      })
  }

  private changeUsers = () => {
    const users = this.owner.boardStore.currentBoard?.members?.map(m => m.user)
    const defaultRoleUser = this.currentItem?.getStatusAssigneeByRole(
      this.owner.boardStore.currentBoard?.defaultRole?.id!
    )[0]?.user
    const sortedUsers = moveObjectToStart(users || [], defaultRoleUser)
    UsersEvent.dispatch({users: sortedUsers})
  }

  private questionCountListener = () => {
    const callback = (e: CustomEvent<ConversationCountDetails>) => {
      const details = e.detail
      if (
        details.entityId === this.currentItem?.id &&
        details.entityTypeId === config.itemEntityTypeId &&
        details.conversationTypeId === config.conversationType.question
      ) {
        this.setQuestionCount(details.count)
        this.setUnreadQuestionCount(details.unreadCount)
      }
    }

    ConversationCountEvent.subscribe(callback)
  }
}

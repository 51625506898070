import * as ReactDOM from 'react-dom'
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import {
  GridToolbarContainer,
  GridToolbarQuickFilter
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import SearchIcon from 'src/images/search-textField.svg?react'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import {ViewType} from 'src/entities/View'
import {Control, PermissionType} from 'src/entities/PermissionData'
import {PeriodFilter} from '../../entities/Report'
import FieldFilterToolbar from './FieldFilterToolbar'
import {useCallback} from 'react'
import ReportTabs from 'src/components/report/ReportTabs'

const ReportToolbar: React.FC = observer(() => {
  const mainStore = useMainStore()
  const {getPermissionType} = mainStore.permissionStore
  const {user} = mainStore.loginStore
  const {boards} = mainStore.boardStore
  const {
    selectedBoard,
    setSelectedBoard,
    collapseGroups,
    setAllCollapseGroup,
    exportExcel,
    periodFilters,
    setCurrentPeriodFilter,
    currentPeriodFilter,
    currentReport: report
  } = mainStore.reportStore

  const onExport = () => {
    exportExcel()
  }

  const handlePeriodFilterChange = useCallback(
    (_event: React.SyntheticEvent, newValue: PeriodFilter | null) => {
      if (newValue) {
        setCurrentPeriodFilter(newValue)
      }
    },
    [setCurrentPeriodFilter]
  )

  const showExportControl = () => {
    const reportView = selectedBoard?.views.find(
      v => v.viewType === ViewType.Report
    )
    const currentRole = selectedBoard?.members.find(
      m => m.userId === user?.id
    )?.roleId

    const hasExportPermission = getPermissionType(
      reportView?.id,
      currentRole,
      undefined,
      Control.Export
    )

    return hasExportPermission !== PermissionType.Invisible
  }

  const showExportButton = showExportControl()

  return (
    <>
      {ReactDOM.createPortal(
        <GridToolbarContainer
          sx={{
            backgroundColor: 'white',
            alignItems: 'start'
          }}
        >
          <Stack width="100%" spacing={2}>
            {(selectedBoard?.reports?.length ?? 0) > 1 && <ReportTabs />}

            <ToggleButtonGroup
              exclusive
              color="primary"
              sx={{width: 'fit-content', mb: 1.5}}
              onChange={handlePeriodFilterChange}
              value={
                currentPeriodFilter ??
                periodFilters?.find(p => p.name === 'All')
              }
            >
              {periodFilters?.map(period =>
                report?.periodFilters.includes(period.id) ? (
                  <ToggleButton key={period.id} value={period}>
                    {period.name}
                  </ToggleButton>
                ) : null
              )}
            </ToggleButtonGroup>

            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <GridToolbarQuickFilter
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
                <FormControl size="small">
                  <InputLabel>Select board</InputLabel>
                  <Select
                    label="Select board"
                    onChange={e =>
                      setSelectedBoard(
                        boards.find(b => b.id === Number(e.target.value))
                      )
                    }
                    value={selectedBoard?.id ?? ''}
                    sx={{width: 270}}
                  >
                    {boards
                      .filter(
                        b =>
                          b.views.find(v => v.viewType === ViewType.Report) !==
                          undefined
                      )
                      .map(b => (
                        <MenuItem key={b.id} value={b.id}>
                          {b.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {report?.fieldsFilters?.map(f => (
                  <FieldFilterToolbar key={f} columnId={f} />
                ))}

                {showExportButton && (
                  <Button
                    color="inherit"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={onExport}
                  >
                    Export
                  </Button>
                )}
              </Stack>
              <IconButton onClick={() => setAllCollapseGroup()}>
                {Object.values(collapseGroups).filter(v => v).length > 0 ? (
                  <UnfoldMoreIcon />
                ) : (
                  <UnfoldLessIcon />
                )}
              </IconButton>
            </Stack>
          </Stack>
        </GridToolbarContainer>,
        document.getElementById('toolbar-box')!
      )}
    </>
  )
})

export default ReportToolbar

import {SettingsValueProps} from './settings'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32
}

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 78,
  //
  H_DASHBOARD_ITEM: 30,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,

  W_ITEM: 200,
  W_ITEM_CLOSED: 25
}

export const paletteColors = [
  'error',
  'primary',
  'warning',
  'info',
  'success',
  'secondary'
]

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22
}

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'blue',
  themeStretch: false
}
const config = {
  AADRedirectUrl: process.env.VITE_APP_AAD_REDIRECT_URL || '',
  AADConfig: {
    clientId: process.env.VITE_APP_AAD_CLIENT_ID || '',
    authority: process.env.VITE_APP_AAD_AUTHORITY || ''
  },
  apiUrl: process.env.VITE_APP_API || '',
  isProduction: process.env.VITE_APP_BRANCH_NAME === 'main',
  MUIDataGridLicenseKey: process.env.VITE_APP_MUI_DATA_GRID_LICENSE_KEY || '',
  viewerLicenseKey: process.env.VITE_APP_VIEWER_LICENSE_KEY,
  azureBlobStorage: {
    storageName: process.env.VITE_APP_STORAGE_NAME || '',
    emailContainerName: process.env.VITE_APP_EMAIL_CONTAINER_NAME || '',
    invoiceContainerName: process.env.VITE_APP_INVOICE_CONTAINER_NAME || ''
  },
  linkToHP: 'https://internal.mcres.com/new-home/',
  linkToSelect: 'sp-select://./ordertracking/order?id=',
  SelectAccessor: {
    basePath: process.env.VITE_APP_SELECT_ACCESSOR_BASE_PATH || '',
    createNote: process.env.VITE_APP_SELECT_ACCESSOR_CREATE_NOTE_PATH || '',
    getTasks: process.env.VITE_APP_SELECT_ACCESSOR_GET_TASKS_PATH || '',
    createNoteToChecklist:
      process.env.VITE_APP_SELECT_ACCESSOR_CREATE_CHECKLIST_NOTE_PATH || '',
    getNotes: process.env.VITE_APP_SELECT_ACCESSOR_GET_NOTES_PATH || ''
  },
  signalrConnectionUrl: process.env.VITE_APP_SIGNALR_URL || '',
  notificationUrl: process.env.VITE_APP_NOTIFICATION_API || '',
  itemEntityTypeId: 1,
  conversationType: {
    question: 1,
    proofing: 2,
    chat: 3
  },
  commercialBoardId: 4,
  historyUrl: process.env.VITE_APP_HISTORY_URL || '',
  logRocketAppId: process.env.VITE_APP_LOGROCKET_APPID || '',
  unknownFileNumberText: 'Unknown FN',
  isHubProduction: process.env.NODE_ENV === 'production',
  googleMapsLink: 'https://maps.google.com/?q=',
  continLink:
    'https://forms.office.com/Pages/ResponsePage.aspx?id=g-r-uw88zE-DjqCEjz_Lk8aKo3ROZ2VInqlhvqB_TG1UNUg3OFoyN1hCTk1HMEVXQlU5RThHWFo2RC4u',
  intelLink: 'https://madisonintel.com/search/states/:stateId/filter/Contacts',
  workspaceLink: process.env.VITE_APP_WORKSPACE_URL || '',
  inboundGroupIds: [27, 31, 32, 40, 58, 60, 71, 125, 134],
  mailboxApiUrl: process.env.VITE_APP_MAILBOX_API || '',
  boards: {
    commercial: 4,
    ny: 13,
    ncRequest: 5
  },
  defaultTitle: 'Hub',
  myQsTitle: 'My Questions',
  searchTitle: 'Search'
}

export default config

import CuiAlertDialog from 'src/components/custom/CuiAlertDialog'
import {useMainStore} from 'src/context/Main'
import Notification from 'src/entities/Notification'

export interface DeleteNotificationDialogProps {
  notification?: Notification
  onClose: () => void
}

const DeleteNotificationDialog = ({
  notification,
  onClose
}: DeleteNotificationDialogProps) => {
  const mainStore = useMainStore()
  const {deleteNotification, deleteNotifications} = mainStore.notificationStore

  return (
    <>
      <CuiAlertDialog
        isFullWidth
        showMessage={false}
        title={`Delete Notification${notification ? '' : 's'}`}
        okButtonText="Delete"
        showCancel
        open
        body={`Are you sure you want to delete ${
          notification ? 'this notification' : 'these notifications'
        }?`}
        okClick={() => {
          if (notification) deleteNotification(notification)
          else deleteNotifications()
          onClose()
        }}
        close={onClose}
      />
    </>
  )
}

export default DeleteNotificationDialog

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import {
  FilterPanelPropsOverrides,
  GridCallbackDetails,
  GridFilterModel,
  GridFilterPanel
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import * as _ from 'lodash'
import {FavoriteFilterSetting, UserSettingKey} from 'src/entities/UserSettings'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import FavoriteFilters from './FavoriteFilters'
import {useEffect, useState} from 'react'
import SendIcon from '@mui/icons-material/Send'

declare module '@mui/x-data-grid-pro' {
  interface FilterPanelPropsOverrides {
    filterModel: GridFilterModel
    saveFilters: () => void
    saveFavoriteFilters: (
      favoriteFilters: FavoriteFilterSetting[],
      name?: string
    ) => void
    onFilterModelChange: (
      newValue: GridFilterModel,
      details: GridCallbackDetails<'filter'> | undefined
    ) => void
  }
}

const FilterPanel = observer(
  ({
    filterModel,
    saveFilters,
    saveFavoriteFilters,
    onFilterModelChange
  }: FilterPanelPropsOverrides) => {
    const {getUserSettingsByKey} = useMainStore().userSettingsStore
    const {currentView} = useMainStore().viewStore
    const [isSavingMode, setIsSavingMode] = useState(false)
    const [favoriteName, setFavoriteName] = useState('')

    useEffect(() => {}, [filterModel])

    const favoriteFiltersSetting = getUserSettingsByKey(
      UserSettingKey.FavoriteFilters,
      currentView?.id
    )
    const favoriteFilters: FavoriteFilterSetting[] = JSON.parse(
      favoriteFiltersSetting ? favoriteFiltersSetting.value : '[]'
    )

    const isFilterModelChanged = !_.isEqual(
      getUserSettingsByKey(UserSettingKey.Filters),
      filterModel
    )

    const onSaveFavoriteFilter = (e: any) => {
      e.stopPropagation()
      setIsSavingMode(false)
      saveFavoriteFilters(favoriteFilters, favoriteName)
    }

    return (
      <>
        <Stack direction="column">
          <GridFilterPanel
            filterFormProps={{
              // Customize inputs by passing props
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small'
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: {mt: 'auto'}
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: {mt: 'auto'}
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small'
                }
              }
            }}
            sx={{
              '& .MuiDataGrid-panelFooter': {
                justifyContent: 'flex-end',
                px: 2.5
              },
              '& .MuiDataGrid-panelFooter .MuiButton-root:last-of-type': {
                color: theme => theme.palette.primary.main,
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(0, 171, 85, 0.08)'
                }
              },
              '& .MuiDataGrid-filterForm': {p: 2},
              '& .MuiDataGrid-filterFormLogicOperatorInput': {
                mr: 1,
                width: 75
              },
              '& .MuiDataGrid-filterFormColumnInput': {mr: 1, width: 150},
              '& .MuiDataGrid-filterFormOperatorInput': {mr: 1, width: 150},
              '& .MuiDataGrid-filterFormValueInput': {width: 280},
              '& .MuiDataGrid-filterFormDeleteIcon': {
                justifyContent: 'center'
              }
            }}
          />

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{flexDirection: 'row-reverse'}}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  m: 1,
                  minWidth: '100%',
                  justifyContent: 'space-between'
                }}
              >
                <Typography variant="button">My Favorite Filters</Typography>
                <Stack direction="row" spacing={1} mr={3}>
                  {!isSavingMode ? (
                    <Button
                      disabled={!isFilterModelChanged}
                      startIcon={<FavoriteIcon />}
                      onClick={e => {
                        e.stopPropagation()
                        setIsSavingMode(true)
                      }}
                    >
                      Save As Favorite
                    </Button>
                  ) : (
                    <TextField
                      onChange={e => setFavoriteName(e.target.value)}
                      size="small"
                      autoFocus
                      label="What is your filters name?"
                      variant="outlined"
                      error={!favoriteName && isSavingMode}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={favoriteName.length === 0}
                              onClick={onSaveFavoriteFilter}
                              edge="end"
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  {!isSavingMode && (
                    <Button
                      disabled={!isFilterModelChanged}
                      variant="contained"
                      onClick={saveFilters}
                    >
                      Save
                    </Button>
                  )}
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FavoriteFilters
                onFilterModelChange={onFilterModelChange}
                favoriteFilters={favoriteFilters}
                onSaveFavoriteFilters={saveFavoriteFilters}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
      </>
    )
  }
)

export default FilterPanel

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import Department from 'src/entities/Department'

export default class User implements Base {
  id: number

  lastName: string

  firstName: string

  phone: string

  department?: Department

  email?: string

  isHere?: boolean

  mlReturnDate?: Date

  mlStartDate?: Date

  constructor(user: User) {
    this.id = user.id
    this.lastName = user.lastName
    this.firstName = user.firstName
    this.department = user.department
    this.phone = user.phone
    this.email = user.email
    this.isHere = user.isHere
    this.mlReturnDate = user.mlReturnDate
      ? new Date(user.mlReturnDate)
      : undefined
    this.mlStartDate = user.mlStartDate ? new Date(user.mlStartDate) : undefined
    makeAutoObservable(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get isInML() {
    if (!this.mlStartDate) return false

    const today = new Date()
    return (
      today >= this.mlStartDate &&
      (!this.mlReturnDate || today <= this.mlReturnDate)
    )
  }

  get initials() {
    return ((this.firstName[0] || '') + (this.lastName[0] || '')).toUpperCase()
  }

  setIsHere(isHere: boolean) {
    this.isHere = isHere
  }

  setUser(user: User) {
    this.lastName = user.lastName
    this.firstName = user.firstName
    this.department = user.department
    this.phone = user.phone
    this.email = user.email
  }
}

import {Stack, ListItemText} from '@mui/material'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import View, {ViewType} from 'src/entities/View'
import {
  StyledDotIcon,
  StyledIcon,
  StyledItem
} from 'src/components/nav-section/styles'
import Iconify from 'src/components/iconify'
import {useSearchParams} from 'react-router-dom'
import {FunctionUsed} from 'src/store/SharedStore'
import {useCallback, useEffect} from 'react'

const MenuList = observer(() => {
  const mainStore = useMainStore()
  const {currentBoard} = mainStore.boardStore
  const {conditions} = mainStore.sharedStore
  const {currentView, setCurrentView, isUnknownFN, currentItem} =
    mainStore.itemStore
  const [searchParams, setSearchParams] = useSearchParams()

  const onChooseView = useCallback(
    (view: View) => {
      setCurrentView(view)
      setSearchParams(prev => {
        prev.set('view', ViewType[view.viewType])
        prev.delete('viewEntityId')
        return prev
      })
    },
    [setCurrentView, setSearchParams]
  )

  const viewsAllowedUnFN =
    currentBoard?.id === undefined
      ? []
      : conditions[FunctionUsed.isAllowedUnknowFN].find(
          c => c.boardIds?.includes(currentBoard?.id)
        )?.viewIds

  const itemMenuViews = currentBoard?.itemMenuViews.filter(
    v =>
      v.viewType !== ViewType.FileData &&
      v.viewType !== ViewType.Property &&
      v.viewType !== ViewType.Select &&
      (!isUnknownFN || viewsAllowedUnFN?.find(vu => vu === v.id))
  )

  useEffect(() => {
    if (!searchParams.has('view') && !currentItem?.order?.fileNumber) {
      const view = itemMenuViews?.find(v => v.viewType === ViewType.MailBox)
      if (view) {
        onChooseView(view)
      }
    }
  }, [currentItem, searchParams, itemMenuViews, onChooseView])

  return (
    <Stack>
      {itemMenuViews?.map(v => (
        <StyledItem
          key={v.id}
          active={v.id === currentView?.id}
          depth={1}
          onClick={() => onChooseView(v)}
        >
          <StyledIcon>
            <StyledDotIcon active={v.id === currentView?.id} />
          </StyledIcon>
          <ListItemText primary={v.name} />
          {v.id === currentView?.id && (
            <Iconify
              width={16}
              icon="eva:chevron-right-fill"
              color="primary"
              sx={{
                right: 16,
                position: 'absolute'
              }}
            />
          )}
        </StyledItem>
      ))}
    </Stack>
  )
})

export default MenuList

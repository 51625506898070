import './App.css'
import Router from './routes/root'
// theme
import ThemeProvider from './theme'
import MainStoreProvider from 'src/context/Main'
import {LicenseInfo} from '@mui/x-data-grid-pro'
import config from 'src/config'
import {ReportersContextProvider} from 'src/context/Reporters'
import ListenersApp from 'src/components/listeners/ListenersApp'

import loadable from '@loadable/component'

LicenseInfo.setLicenseKey(config.MUIDataGridLicenseKey)

const DocumentStoreProvider = loadable(() => import('cbDocs/MainStoreProvider'))
const MailboxStoreProvider = loadable(
  () => import('cbMailbox/MainStoreProvider')
)
const ChatStoreProvider = loadable<{children: any}>(
  () => import('cbChat/MainStoreProvider')
)
const WorkspaceStoreProvider = loadable<{children: any; isWF: boolean}>(
  () => import('workspace/MainStoreProvider')
)

function App() {
  return (
    <MainStoreProvider>
      <ReportersContextProvider>
        <DocumentStoreProvider>
          <WorkspaceStoreProvider isWF={true}>
            <ChatStoreProvider>
              <MailboxStoreProvider>
                <ThemeProvider>
                  <Router />
                  <ListenersApp />
                </ThemeProvider>
              </MailboxStoreProvider>
            </ChatStoreProvider>
          </WorkspaceStoreProvider>
        </DocumentStoreProvider>
      </ReportersContextProvider>
    </MainStoreProvider>
  )
}

export default App

import {makeAutoObservable, reaction} from 'mobx'
import config from 'src/config'
import User from 'src/entities/User'
import {MainStore} from 'src/store/MainStore'

export default class UserStore {
  users: User[] = []

  constructor(readonly owner: MainStore) {
    makeAutoObservable(this)

    reaction(
      () => this.owner.loginStore.isFirstLoggedin,
      isFirstLoggedin => {
        if (isFirstLoggedin) this.getUsers()
      }
    )

    reaction(
      () => this.owner.signalrStore.isConnected,
      async isConnected => {
        if (isConnected) {
          this.listener()
        }
      }
    )
  }

  setUsers = (users: User[]) => {
    this.users = users
  }

  addOrUpdateUser = (newUser: User) => {
    const user = this.users.find(i => i.id === newUser.id)
    if (user) {
      user.setUser(newUser)
    } else this.users.push(newUser)
  }

  deleteUser = (userId: number) => {
    const indexToRemove = this.users.findIndex(i => i.id === userId)
    if (indexToRemove !== undefined && indexToRemove !== -1)
      this.users.splice(indexToRemove, 1)
  }

  getUsers = () => {
    this.owner.loginStore
      .fetchWithUser(`${config.apiUrl}/user`)
      .then(res => res.json())
      .then((data: User[]) => this.setUsers(data.map(d => new User(d))))
  }

  listener = () => {
    this.owner.signalrStore.on('AddOrUpdateUser', (user: User) =>
      this.addOrUpdateUser(new User(user))
    )

    this.owner.signalrStore.on('DeleteUser', (userId: number) =>
      this.deleteUser(userId)
    )
  }
}

import {makeAutoObservable} from 'mobx'
import Base from 'src/entities/Base'
import {toLocalDate} from 'src/utils/date'

export default class Update implements Base {
  id: number

  text: string

  createdBy: number

  createdAt: Date

  itemId: number

  isNotificationSent?: boolean

  notifyOn?: Date

  constructor(update: Update) {
    this.id = update.id
    this.text = update.text
    this.createdBy = update.createdBy
    this.createdAt = update.createdAt
      ? toLocalDate(update.createdAt)
      : update.createdAt

    this.itemId = update.itemId
    this.isNotificationSent = update.isNotificationSent
    this.notifyOn = update.notifyOn
      ? toLocalDate(update.notifyOn)
      : update.notifyOn
    makeAutoObservable(this)
  }

  setUpdate = (update: Update) => {
    this.notifyOn = update.notifyOn
    this.isNotificationSent = update.isNotificationSent
  }
}

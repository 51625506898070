import {
  Autocomplete,
  Box,
  ClickAwayListener,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Paper,
  Popover,
  TextField
} from '@mui/material'
import User from 'src/entities/User'
import PopperComponent from 'src/components/custom/PopperComponent'
import SearchIcon from 'src/images/search-textField.svg?react'
import CuiAvatar from 'src/components/custom/CuiAvatar'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'

type ListItemAssigneeProps = ListItemProps & {
  user: User
  size?: number
}

export const ListItemAssignee = observer(
  ({user, size, ...props}: ListItemAssigneeProps) => {
    const {
      boardStore: {currentBoard}
    } = useMainStore()

    const isHere =
      currentBoard?.members.find(m => m.userId === user.id)?.user?.isHere ??
      user.isHere
    return (
      <ListItem {...props} key={user.id} dense disablePadding>
        <ListItemAvatar>
          <CuiAvatar
            name={user.fullName}
            value={user.initials}
            size={size}
            isHere={isHere}
          />
        </ListItemAvatar>
        <ListItemText primary={user.fullName} />
      </ListItem>
    )
  }
)

interface CuiAssigneeAutocompleteProps {
  anchorEl?: HTMLElement
  options?: User[]
  value?: User
  onChangeAssignee: (user: User | undefined) => void
  onClose: () => void
}

export default function CuiAssigneeAutocomplete({
  anchorEl,
  options,
  value,
  onChangeAssignee,
  onClose
}: CuiAssigneeAutocompleteProps) {
  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper sx={{width: 245}}>
          <Autocomplete
            open
            onClose={(_, reason) => {
              if (reason === 'escape') {
                onClose()
              }
            }}
            value={value}
            onChange={(_, newValue) => {
              onChangeAssignee(newValue || undefined)
            }}
            PopperComponent={PopperComponent}
            getOptionLabel={option => option.fullName}
            renderOption={(props, option) => (
              <ListItemAssignee {...props} key={option.id} user={option} />
            )}
            options={options || []}
            renderInput={params => (
              <Box p={1}>
                <TextField
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  sx={{m: 1.5}}
                  autoFocus
                  placeholder="Search..."
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
            )}
          />
        </Paper>
      </ClickAwayListener>
    </Popover>
  )
}

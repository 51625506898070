import {observer} from 'mobx-react-lite'
import Column, {ColumnData, DynamicGridColumn} from 'src/entities/Column'
import Item from 'src/entities/Item'
import {PermissionType} from 'src/entities/PermissionData'
import {Button, Menu, MenuItem, Stack, Typography} from '@mui/material'
import LabelCol from 'src/components/view/columnTypes/LabelCol'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {useEffect, useRef, useState} from 'react'
import {useMainStore} from 'src/context/Main'
import StatusAssignee from 'src/entities/StatusAssignee'

interface GridAssigneeStatusProps {
  column: Column | DynamicGridColumn
  item: Item
  permissionType: PermissionType
  isCell: boolean
}

const GridAssigneeStatus = observer(
  ({column, item, permissionType, isCell}: GridAssigneeStatusProps) => {
    const {
      boardStore: {setStatusAssignee}
    } = useMainStore()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const isReadOnly = permissionType === PermissionType.Readonly

    var currentStatusAssignee = item.statusAssignees.find(
      s => s.roleId === column.roleId
    )

    if (currentStatusAssignee === undefined) return <></>

    const updateStatus = (clickedColumnData: ColumnData) => {
      handleClose()
      setStatusAssignee(
        currentStatusAssignee!,
        currentStatusAssignee!.statusId!,
        parseInt(clickedColumnData.columnValue),
        currentStatusAssignee!.subStatuses,
        false
      )
    }

    return (
      <Stack sx={isCell ? {width: '100%', pr: 2, pl: 2} : {width: '200px'}}>
        {!isCell && (
          <Typography sx={{fontSize: 14, fontWeight: 600}}>
            {(column as Column).name}
          </Typography>
        )}

        {isCell ? (
          <Stack>
            <EditStatusLabel
              currentStatusAssignee={currentStatusAssignee}
              column={column}
              onClick={handleClick}
              isCell={isCell}
            />
          </Stack>
        ) : (
          <Button
            onClick={handleClick}
            disabled={isReadOnly}
            variant="outlined"
            sx={{
              p: 0.5,
              flexDirection: 'column',
              borderColor: theme => theme.palette.grey[500]
            }}
          >
            <EditStatusLabel
              currentStatusAssignee={currentStatusAssignee}
              column={column}
              onClick={handleClick}
            />
          </Button>
        )}

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              maxWidth: {xs: '90vw', sm: '60vw', md: '40vw'},
              maxHeight: '80vh',
              overflowY: 'auto'
            }
          }}
        >
          {column.data?.map(d => {
            const data = JSON.parse(d.data)
            return (
              <MenuItem
                sx={{display: 'inline-flex', width: '33%'}}
                onClick={() => updateStatus(d)}
                key={d.id}
              >
                <LabelCol
                  disableTextTransform
                  pointer
                  text={data.text ?? ''}
                  color={data.color ?? 'primary'}
                />
              </MenuItem>
            )
          })}
        </Menu>
      </Stack>
    )
  }
)
interface StatusLabelProps {
  column: Column | DynamicGridColumn
  currentStatusAssignee: StatusAssignee
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  isCell?: boolean
}

const EditStatusLabel = observer(
  ({column, currentStatusAssignee, onClick, isCell}: StatusLabelProps) => {
    let ref: any = useRef(null)

    useEffect(() => {
      if (isCell && ref.current) ref.current.click()
    }, [isCell])

    return (
      <Stack
        ref={ref}
        width="100%"
        direction="row"
        justifyContent="space-between"
        onClick={onClick}
      >
        <StatusLabel
          column={column}
          currentStatusAssignee={currentStatusAssignee}
        />
        <ArrowDownIcon sx={{color: 'grey.500'}} />
      </Stack>
    )
  }
)

const StatusLabel = observer(
  ({column, currentStatusAssignee}: StatusLabelProps) => {
    const currentColumnData = column.data?.find(
      (d: any) => parseInt(d.columnValue) === currentStatusAssignee!.statusId
    )

    const currentData = currentColumnData
      ? JSON.parse(currentColumnData.data)
      : undefined

    return (
      <LabelCol
        pointer
        text={currentData ? currentData.text : ''}
        color={currentData ? currentData.color : 'primary'}
      />
    )
  }
)

export default GridAssigneeStatus

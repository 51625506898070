import {makeAutoObservable} from 'mobx'

export default class BoardReport {
  id: number

  fieldsFilters?: number[]

  periodFilters: number[]

  fieldsWithTotals?: string[]

  viewId?: number

  constructor(report: BoardReport) {
    this.id = report.id
    this.fieldsFilters = report.fieldsFilters
    this.periodFilters = report.periodFilters
    this.viewId = report.viewId
    this.fieldsWithTotals = report.fieldsWithTotals
    makeAutoObservable(this)
  }
}

export class PeriodFilter {
  id: number

  value: number

  name: string

  functionName: string

  constructor(filter: PeriodFilter) {
    this.id = filter.id
    this.value = filter.value
    this.name = filter.name
    this.functionName = filter.functionName
    makeAutoObservable(this)
  }
}

import {Alert, Box, IconButton, Snackbar, Stack} from '@mui/material'
import {Outlet, useLocation} from 'react-router-dom'
import NavMini from 'src/components/layout/NavMini'
import {useMainStore} from 'src/context/Main'
import {CloseIcon} from 'src/theme/overrides/CustomIcons'
import {observer} from 'mobx-react-lite'
import config, {NAV} from 'src/config'
import {useEffect} from 'react'

const Main = observer(() => {
  const mainStore = useMainStore()
  const resetError = () => {
    mainStore.setErrorMessage('')
  }

  const mainContainerWidth = `calc(100vw - ${NAV.W_DASHBOARD_MINI}px)`
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('myQuestions')) {
      document.title = config.myQsTitle
    } else if (location.pathname.includes('search')) {
      document.title = config.searchTitle
    } else if (!mainStore.itemStore.currentItem) {
      document.title = config.defaultTitle
    }
  }, [location.pathname, mainStore.itemStore.currentItem])

  return (
    <Box display="flex" flexDirection="column" height="100%" overflow="hidden">
      <Box flexGrow={1} sx={{display: 'inline-flex'}}>
        <NavMini />
        <Stack component="main" direction="row" width={mainContainerWidth}>
          {mainStore.errorMessage && (
            <Snackbar
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
              open
              autoHideDuration={6000}
              onClose={resetError}
            >
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={resetError}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {mainStore.errorMessage}
              </Alert>
            </Snackbar>
          )}
          <Outlet />
        </Stack>
      </Box>
    </Box>
  )
})

export default Main

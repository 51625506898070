import {Stack, Typography} from '@mui/material'
import SeverErrorIllustration from 'src/images/illustrations/SeverErrorIllustration'

export default function CuiError() {
  return (
    <Stack m="auto">
      <SeverErrorIllustration sx={{height: 260, my: {xs: 5, sm: 10}}} />
      <Typography sx={{color: 'text.secondary', m: 'auto'}}>
        There was an error, please try again later.
      </Typography>
    </Stack>
  )
}

import {Suspense} from 'react'
import {observer} from 'mobx-react-lite'
import View, {ViewType} from 'src/entities/View'
import {useMainStore} from 'src/context/Main'
// import loadable from '@loadable/component'
import FileView from 'src/components/search/FileView'
import {Stack} from '@mui/material'

// const SearchEmails = loadable(() => import('cbMailbox/SearchEmails'))

const getViewComponent = (view: View) => {
  switch (view.viewType) {
    case ViewType.FileData:
      return <FileView view={view} />
    case ViewType.MailBox:
      return <>Mail</>
    case ViewType.Question:
      return <>Question</>
    case ViewType.Chat:
      return <>Chat</>
    case ViewType.Users:
      return <>Users</>
  }
}

const DisplaySearchView = observer(() => {
  const mainStore = useMainStore()
  const {getViewTabs, currentView} = mainStore.searchStore
  return (
    <Suspense fallback={<></>}>
      <Stack height="100%" width="100%">
        {getViewTabs.map(view => (
          <div
            key={view.id}
            hidden={!(currentView && view.id === currentView.id)}
          >
            {getViewComponent(view)}
          </div>
        ))}
      </Stack>
    </Suspense>
  )
})

export default DisplaySearchView

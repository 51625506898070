import {useState} from 'react'
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  List,
  Popover,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import {observer} from 'mobx-react-lite'
import {ListItemAssignee} from 'src/components/custom/CuiAssigneeAutocomplete'
import {useMainStore} from 'src/context/Main'
import NightPrimary from 'src/images/night_primary.svg?react'
import Night from 'src/images/night.svg?react'

const DayWorkButton = observer(() => {
  const {
    boardStore: {currentMember, currentBoard, setMemberDayWorker}
  } = useMainStore()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  return (
    <>
      <ButtonGroup
        sx={{
          '& .MuiButtonGroup-grouped': {
            minWidth: 24
          }
        }}
      >
        <Button
          onClick={() => setMemberDayWorker(!currentMember?.isDayWorker)}
          color={currentMember?.isDayWorker ? 'primary' : 'inherit'}
          sx={{
            px: 1,
            color: currentMember?.isDayWorker ? 'primary' : 'text.secondary'
          }}
          startIcon={currentMember?.isDayWorker ? <NightPrimary /> : <Night />}
        >
          <Stack direction={'row'} alignItems={'baseline'}>
            Day Work
            <Typography variant="body2" sx={{textTransform: 'none'}}>
              er
            </Typography>
          </Stack>
        </Button>
        <Tooltip title="Day Work Users" placement="top" arrow>
          <Button
            sx={{px: 0}}
            variant="contained"
            onClick={e => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <PersonIcon
              sx={{
                fontSize: 'medium',
                color: theme => theme.palette.background.paper
              }}
            />
          </Button>
        </Tooltip>
      </ButtonGroup>
      {anchorEl && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          slotProps={{
            paper: {
              sx: {
                maxHeight: 400,
                overflow: 'auto'
              }
            }
          }}
        >
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <List>
              {currentBoard?.members
                .filter(m => m.isDayWorker)
                .map(u => (
                  <ListItemAssignee
                    sx={{py: 0.5, px: 0.5}}
                    key={u.userId}
                    user={u.user}
                    size={20}
                  />
                ))}
            </List>
          </ClickAwayListener>
        </Popover>
      )}
    </>
  )
})

export default DayWorkButton

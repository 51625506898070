import {useState, useEffect, useMemo, useCallback} from 'react'
import {
  GridFilterModel,
  GridPreferencePanelsValue,
  gridFilteredSortedRowIdsSelector,
  useGridApiRef,
  GridColDef,
  GridCellParams,
  GridApiPro,
  GridColumnsInitialState,
  GridSortingInitialState,
  GridCallbackDetails
} from '@mui/x-data-grid-pro'
import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import User from 'src/entities/User'
import StatusAssignee from 'src/entities/StatusAssignee'
import View from 'src/entities/View'
import DataGridWrapper from 'src/components/custom/DataGridWrapper'
import ViewGroup from 'src/entities/ViewGroup'
import Item from 'src/entities/Item'
import {computed} from 'mobx'
import {FavoriteFilterSetting, UserSettingKey} from 'src/entities/UserSettings'
import {getUniqueDuplicates} from 'src/utils/array'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material'
import RightIcon from 'src/images/right.svg?react'
import LeftIcon from 'src/images/left.svg?react'
import DisplayGroup from 'src/components/itemTable/DisplayGroup'
import UpIcon from 'src/images/up.svg?react'
import {OrderCompany} from 'src/entities/Order'
import {FunctionUsed, Tabs} from 'src/store/SharedStore'

export interface GroupItemsProps {
  view?: View
  group: ViewGroup
  getGroupItems: (group: ViewGroup) => Item[] | undefined
  columns: GridColDef[]
  filterModel?: GridFilterModel
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>
  isExternalFilter?: boolean
  assigneeFilter?: User | undefined
  setAssigneeFilter?: React.Dispatch<React.SetStateAction<User | undefined>>
  showBAFiles?: boolean
  setShowBAFiles?: React.Dispatch<React.SetStateAction<boolean | undefined>>
  hasToolbar: boolean
  Toolbar?: React.JSXElementConstructor<any> | null
  FilterPanel?: React.JSXElementConstructor<any>
  hiddenColumns?: string[]
  setGroupGridpiRef?: (groupId: number, apiRef: GridApiPro) => void
  setNumOfResults: (groupId: number, numOfResults: number) => void
  isLoadingBoardItems: boolean
  currentViewId: number
  toolbarHeight?: number
  numOfResults: number
  isExpanded: boolean
  setCollapseGroup: (id: number, isCollapse: boolean) => void
  isFiltered: boolean
}

const GroupItems = observer(
  ({
    view,
    group,
    getGroupItems,
    columns,
    filterModel,
    setFilterModel,
    isExternalFilter,
    assigneeFilter,
    setAssigneeFilter,
    showBAFiles,
    setShowBAFiles,
    hasToolbar,
    Toolbar,
    FilterPanel,
    hiddenColumns,
    setGroupGridpiRef,
    setNumOfResults,
    isLoadingBoardItems,
    currentViewId,
    toolbarHeight,
    numOfResults,
    isExpanded,
    setCollapseGroup,
    isFiltered
  }: GroupItemsProps) => {
    const {
      userSettingsStore: {updateUserSettings, getUserSettingsByKey},
      boardStore: {currentBoard, setQuickSearchValue},
      sharedStore: {conditions, currentTab}
    } = useMainStore()

    const [filterButtonEl, setFilterButtonEl] =
      useState<HTMLButtonElement | null>(null)

    const isEditableCell = (params: GridCellParams) => {
      return conditions[FunctionUsed.SuttonEditableColumns]?.[0].additionalInfo
        ?.split(',')
        .includes(params.field) &&
        (params.row as Item).order?.orderCompany !== OrderCompany.Sutton
        ? false
        : true
    }

    const updateColumnsSettings = () => {
      updateUserSettings(
        apiRef.current.exportState().columns || {},
        UserSettingKey.ColumnSettings,
        view?.id
      )
    }

    const updateColumnsSorting = () => {
      updateUserSettings(
        apiRef.current.exportState().sorting || {},
        UserSettingKey.ColumnSort,
        view?.id
      )
    }

    const setQuickFilter = useCallback(
      (model: GridFilterModel) => {
        setFilterModel?.(prev => {
          if (prev.quickFilterValues !== model.quickFilterValues)
            return {...prev, quickFilterValues: model.quickFilterValues}
          return prev
        })
        setQuickSearchValue(model.quickFilterValues)
      },
      [setFilterModel, setQuickSearchValue]
    )

    const assigneeFilterFunction = useCallback(
      (r: any) =>
        view?.assigneeColumns?.find(
          c =>
            r[c.id]?.find(
              (sa: StatusAssignee) => sa.userId === assigneeFilter?.id
            )
        ),
      [assigneeFilter?.id, view?.assigneeColumns]
    )

    const stateFilterFunction = (r: any) => r.order?.fileNumber?.includes('BA')

    const filteredRows = useMemo(() => {
      return computed(() => {
        const rows = getGroupItems(group) as any[]

        if (!assigneeFilter && showBAFiles === undefined) return rows

        return rows?.filter(
          r =>
            (assigneeFilter ? assigneeFilterFunction(r) : true) &&
            (showBAFiles !== undefined
              ? showBAFiles
                ? stateFilterFunction(r)
                : !stateFilterFunction(r)
              : true)
        )
      })
    }, [
      assigneeFilter,
      getGroupItems,
      group,
      showBAFiles,
      assigneeFilterFunction
      // currentTab
    ]).get()

    const pinnedColumnsWidth = useMemo(
      () =>
        (view?.leftDirectionPinnedColumns?.reduce(
          (sum, column) => sum + column.width,
          0
        ) || 0) +
        (view?.rightDirectionPinnedColumns?.reduce(
          (sum, column) => sum + column.width,
          0
        ) || 0),
      [view]
    )

    const apiRef = useGridApiRef()

    useEffect(() => {
      setGroupGridpiRef?.(group.id, apiRef.current)
    }, [group.id, apiRef, setGroupGridpiRef])

    useEffect(() => {
      if (isLoadingBoardItems) return
      // Check this together in case there is no match item to filter and a new match item was inserted by signlr.
      const mergedList = [
        ...filteredRows.map(fr => fr.id),
        ...gridFilteredSortedRowIdsSelector(apiRef)
      ]
      const result = getUniqueDuplicates(mergedList)
      setNumOfResults(group.id, result.length)
    }, [
      group.id,
      apiRef,
      filterModel,
      filteredRows,
      isLoadingBoardItems,
      setNumOfResults
    ])

    const columnSettings = getUserSettingsByKey(
      UserSettingKey.ColumnSettings,
      view?.id
    )?.value

    const columnSorting = getUserSettingsByKey(
      UserSettingKey.ColumnSort,
      view?.id
    )?.value

    useEffect(() => {
      if (isLoadingBoardItems) {
        return
      }
      if (
        columnSettings &&
        JSON.parse(columnSettings) !== apiRef.current.exportState().columns
      )
        apiRef.current.restoreState({
          ...apiRef.current.exportState(),
          columns: JSON.parse(columnSettings) as GridColumnsInitialState
        })
      else {
        const exportedState = apiRef.current.exportState()

        apiRef.current.restoreState({
          ...exportedState,
          columns: exportedState.columns
        })
      }
      if (
        columnSorting &&
        JSON.parse(columnSorting) !== apiRef.current.exportState().sorting
      )
        apiRef.current.restoreState({
          ...apiRef.current.exportState(),
          sorting: JSON.parse(columnSorting) as GridSortingInitialState
        })
    }, [apiRef, columnSettings, columnSorting, isLoadingBoardItems])

    const getTogglableColumns = (columns: GridColDef[]) => {
      return columns.filter(c => c.headerName).map(c => c.field)
    }

    const onFilterModelChange = (
      newValue: GridFilterModel,
      details: GridCallbackDetails<'filter'> | undefined
    ) => {
      // remove blank filter item - get it after was value in filter and deleted

      const filterValues = {
        ...newValue,
        items: newValue.items.filter(item => item.value !== '')
      } as GridFilterModel

      if (!isExternalFilter) {
        if (setFilterModel) {
          setFilterModel(filterValues)
          setQuickSearchValue(newValue.quickFilterValues)
        }
        if (details?.reason === 'deleteFilterItem')
          apiRef.current.showFilterPanel()
      } else setQuickFilter(filterValues)
    }

    const scroll = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
      isLeft: boolean
    ) => {
      e.stopPropagation()
      if (apiRef.current) {
        const virtualScroller =
          apiRef.current.rootElementRef?.current?.querySelector(
            '.MuiDataGrid-virtualScroller'
          )
        const size =
          (apiRef.current.rootElementRef?.current?.offsetWidth || 1100) -
          (pinnedColumnsWidth + 50)
        if (virtualScroller) {
          virtualScroller.scrollBy({
            left: isLeft ? -size : size,
            behavior: 'smooth'
          })
        }
      }
    }

    const [hasVerticalScroll, setHasVerticalScroll] = useState(false)

    useEffect(() => {
      const checkScroll = () => {
        const containerDimensions = apiRef.current?.getRootDimensions()
        if (containerDimensions) {
          const {hasScrollX} = containerDimensions
          setHasVerticalScroll(hasScrollX)
        }
      }

      const timeout = setTimeout(checkScroll, 100)
      const handleStateChange = () => {
        checkScroll()
      }
      const unsubscribeStateChange = apiRef.current?.subscribeEvent(
        'stateChange',
        handleStateChange
      )
      return () => {
        clearTimeout(timeout)
        unsubscribeStateChange?.()
      }
    }, [apiRef])

    return (
      <Accordion
        key={group.id}
        expanded={isExpanded}
        onChange={(_: React.SyntheticEvent, isExpanded: boolean) => {
          setCollapseGroup(group.id, !isExpanded)
        }}
        sx={{
          backgroundColor: theme => theme.palette.background.paper,
          display:
            !group.isVisible ||
            (numOfResults === 0 &&
              ((isFiltered && currentTab !== Tabs.Search) ||
                currentTab === Tabs.Search))
              ? 'none'
              : undefined,
          mt: '0px!important',
          mx: '8px!important',
          mb: '4px!important',
          '&:before': {
            display: 'none'
          }
        }}
      >
        <AccordionSummary
          sx={{
            margin: '0px!important',
            minHeight: 0,
            '&.MuiAccordionSummary-root.Mui-focusVisible': {
              backgroundColor: 'white'
            },
            '&.Mui-expanded': {
              minHeight: '32px',
              margin: '0px !important'
            },
            '& .MuiAccordionSummary-content': {
              margin: 0.5
            },
            position: 'sticky',
            top: (toolbarHeight ?? 0) - 1,
            backgroundColor: theme => theme.palette.background.paper,
            zIndex: 2,
            flexDirection: 'row-reverse',
            width: '100%'
          }}
          expandIcon={<UpIcon />}
        >
          <Stack direction="row" justifyContent="space-between" width="100%">
            <DisplayGroup
              group={group}
              viewId={currentViewId}
              numOfItems={numOfResults}
            />
            {hasVerticalScroll && (
              <Stack direction="row" justifyContent="end" alignItems="center">
                <Tooltip title="Previous columns">
                  <IconButton
                    onClick={e => scroll(e, true)}
                    sx={{
                      '&:focus': {
                        outline: 'none'
                      }
                    }}
                  >
                    <LeftIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Next columns">
                  <IconButton
                    onClick={e => scroll(e, false)}
                    sx={{
                      '&:focus': {
                        outline: 'none'
                      }
                    }}
                  >
                    <RightIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{pt: 0}}>
          <DataGridWrapper
            getRowHeight={() =>
              currentTab === Tabs.Search
                ? 40
                : currentBoard?.newEmailStatusId
                  ? 'auto'
                  : null
            }
            isMaxHeight={
              currentBoard?.areGroupsScrollable && currentTab !== Tabs.Search
                ? true
                : false
            }
            columnBuffer={columns.length + 1}
            rowBuffer={filteredRows.length > 9 ? 9 : filteredRows.length}
            apiRef={apiRef}
            disableRowSelectionOnClick
            columns={columns}
            rows={filteredRows}
            autoHeight={false} // Keep the grid fixed-height to enable scrolling
            initialState={{
              pinnedColumns: {
                left: view?.leftDirectionPinnedColumns?.map(c =>
                  c.id.toString()
                ),
                right: view?.rightDirectionPinnedColumns?.map(c =>
                  c.id.toString()
                )
              },
              columns: {
                columnVisibilityModel: hiddenColumns?.reduce(
                  (acc, item) => ((acc[item] = false), acc),
                  {} as Record<string, boolean>
                )
              }
            }}
            hideFooter
            filterModel={filterModel}
            onColumnVisibilityModelChange={updateColumnsSettings}
            onColumnOrderChange={updateColumnsSettings}
            onColumnWidthChange={updateColumnsSettings}
            onSortModelChange={updateColumnsSorting}
            isCellEditable={(params: GridCellParams) =>
              params.row.isPropertyEditable(params.colDef.headerName) &&
              isEditableCell(params)
            }
            onFilterModelChange={onFilterModelChange}
            slots={{
              toolbar: hasToolbar ? Toolbar : undefined,
              filterPanel: FilterPanel,
              columnHeaderFilterIconButton: () => <></>
            }}
            slotProps={{
              panel: {
                anchorEl: filterButtonEl
              },
              toolbar: {
                setFilterButtonEl: setFilterButtonEl,
                onOpenColumnVisibilityPanel: () =>
                  apiRef.current?.showPreferences(
                    GridPreferencePanelsValue.columns
                  ),
                assigneeFilter: assigneeFilter,
                setAssigneeFilter: setAssigneeFilter,
                showBAFiles: showBAFiles,
                setShowBAFiles: setShowBAFiles,
                filterModel: filterModel,
                setFilterModel: setFilterModel
              },
              filterPanel: {
                filterModel: filterModel,
                saveFilters: () => {
                  updateUserSettings(
                    {
                      ...filterModel,
                      quickFilterValues: undefined
                    } as GridFilterModel,
                    UserSettingKey.Filters,
                    view?.id
                  )
                  apiRef.current.hideFilterPanel()
                },
                saveFavoriteFilters: (
                  favoriteFilters: FavoriteFilterSetting[],
                  name?: string
                ) => {
                  if (name)
                    //case of adding new favorite filters.
                    favoriteFilters.push({name: name, filterModel: filterModel})
                  updateUserSettings(
                    favoriteFilters,
                    UserSettingKey.FavoriteFilters,
                    view?.id
                  )
                },
                onFilterModelChange: onFilterModelChange
              },
              baseSelect: {native: false, defaultValue: ''},
              columnsPanel: {getTogglableColumns}
            }}
            localeText={{
              columnsPanelTextFieldLabel: 'Find columns to display',
              columnsPanelTextFieldPlaceholder: 'Search column'
            }}
          />
        </AccordionDetails>
      </Accordion>
    )
  }
)

export default GroupItems

import {IconButton} from '@mui/material'
import {useMainStore} from 'src/context/Main'
import Item from 'src/entities/Item'
import RestoreIcon from 'src/images/restore.svg?react'

export interface RestoreItemColProps {
  item: Item
}

export default function RestoreItemCol({item}: RestoreItemColProps) {
  const {setIsArchive} = useMainStore().boardStore

  if (!item.isArchive) return <></>
  return (
    <IconButton onClick={() => setIsArchive(item.id, false)}>
      <RestoreIcon />
    </IconButton>
  )
}

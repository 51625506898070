import {observer} from 'mobx-react-lite'
import {useMainStore} from 'src/context/Main'
import {useEffect, useState} from 'react'
import {
  ConversationAssignEvent,
  ConversationControl,
  ConversationOnAssignClickEvent,
  ConversationPermissionEvent,
  OnAssignClickDetails
} from '@madisoncres/title-general-package'
import PermissionData, {
  Control,
  PermissionType
} from 'src/entities/PermissionData'
import config from 'src/config'
import {ViewType} from 'src/entities/View'
import {lazy} from '@loadable/component'
import UserMenuList from 'src/components/user/UserMenuList'

const ConversationList = lazy<{
  conversationTypeId: number
  viewId: string
}>(() => import('cbChat/ConversationList'))

const preparePermissions = (permissions: PermissionData[]) => {
  const newPermission = {} as Record<ConversationControl, PermissionType>
  permissions.forEach(p => {
    switch (p.controlId) {
      case Control.Create:
        newPermission[ConversationControl.Create] = p.permissionTypeId
        return
      case Control.Filter:
        newPermission[ConversationControl.Filter] = p.permissionTypeId
        return
      case Control.Reply:
        newPermission[ConversationControl.Reply] = p.permissionTypeId
        return
      case Control.SeeAll:
        newPermission[ConversationControl.SeeAll] = p.permissionTypeId
        return
      case Control.Status:
        newPermission[ConversationControl.Status] = p.permissionTypeId
        return
      case Control.Assign:
        newPermission[ConversationControl.Assign] = p.permissionTypeId
        return
    }
  })
  return newPermission
}

interface QuestionListProps {
  viewId: number
}

const QuestionList = observer(({viewId}: QuestionListProps) => {
  const mainStore = useMainStore()
  const {currentItem, myRolesId} = mainStore.itemStore
  const {getPermissionTypesByView} = mainStore.permissionStore

  useEffect(() => {
    const permissions = getPermissionTypesByView(myRolesId, viewId)
    ConversationPermissionEvent.dispatch({
      permissions: preparePermissions(permissions),
      entityId: currentItem?.id ?? 0,
      entityTypeId: config.itemEntityTypeId,
      conversationTypeId: config.conversationType.question
    })
  }, [viewId, myRolesId, currentItem?.id, getPermissionTypesByView])

  return (
    <ConversationList
      conversationTypeId={config.conversationType.question}
      viewId={ViewType[ViewType.Question]}
    />
  )
})

interface QuestionProps {
  viewId: number
  onCountChange: (count: number) => void
  onUnreadCountChange: (count: number) => void
  itemId: number
}

const Question = observer(
  ({viewId, onCountChange, onUnreadCountChange, itemId}: QuestionProps) => {
    const mainStore = useMainStore()
    const {questionCount, unreadQuestionCount} = mainStore.itemStore

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>()
    const [currentAssignee, setCurrentAssignee] = useState<number>()

    useEffect(() => {
      onCountChange(questionCount ?? 0)
    }, [questionCount, onCountChange])

    useEffect(() => {
      onUnreadCountChange(unreadQuestionCount ?? 0)
    }, [unreadQuestionCount, onUnreadCountChange])

    const onClose = () => {
      setAnchorEl(undefined)
    }

    const onCancel = () => {
      ConversationAssignEvent.dispatch(undefined)
      onClose()
    }

    const onAssigned = (userId?: number, comment = '') => {
      ConversationAssignEvent.dispatch(
        userId
          ? {
              userId,
              entityId: itemId,
              entityTypeId: config.itemEntityTypeId,
              conversationTypeId: config.conversationType.question,
              comment: comment
            }
          : undefined
      )
      onClose()
    }

    useEffect(() => {
      const callback = (e: CustomEvent<OnAssignClickDetails>) => {
        const details = e.detail
        if (
          details.entityId === itemId &&
          details.entityTypeId === config.itemEntityTypeId &&
          details.conversationTypeId === config.conversationType.question
        ) {
          setAnchorEl(details.anchor)
          setCurrentAssignee(details.currentAssigneeId)
        }
      }
      ConversationOnAssignClickEvent.subscribe(callback)

      return () => ConversationOnAssignClickEvent.unsubscribe(callback)
    }, [itemId])

    return (
      <>
        <QuestionList viewId={viewId} />
        {anchorEl && (
          <UserMenuList
            ignoreUsers={currentAssignee ? [currentAssignee] : undefined}
            anchorEl={anchorEl}
            onChoose={onAssigned}
            onCancel={onCancel}
          />
        )}
      </>
    )
  }
)

export default Question
